<template>
  <div class="flex w-full flex-wrap justify-center gap-4">
    <div
      v-for="(item, index) in parentItems"
      :key="index"
      class="hover:border-primary max-h-36 w-44 rounded-lg border shadow transition-colors">
      <router-link
        v-if="item.link && item.title && showItemsByRights(item.viewRights)"
        class="text-primary flex h-full w-full flex-col content-between gap-2 text-wrap p-4 hover:brightness-75"
        :to="{ name: item.name }">
        <AKIcon v-if="item.icon" :framework-icon="item.icon" :scale="1.75" />
        <div class="flex grow items-center justify-center text-center">
          <h6 class="line-clamp-2 hyphens-auto" :lang="langKey" v-text="$t(item.title)" />
        </div>
      </router-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AKIcon } from '@ak_tools/components/media';
import { AKStore } from '@ak_tools/start/App';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { locale } = useI18n();

//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const parentItems = computed(() => {
  return AKStore.App.state.navigationMenu.filter(
    (item) => item.link && !item.hideInNav && showItemsByRights(item.viewRights) && item.name !== 'start',
  );
});

const langKey = computed(() => {
  return locale.value.split('_')[0];
});

//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const showItemsByRights = (viewRights?: string[]) => {
  return viewRights ? AKStore.User.state.application.modulesTitles.some((module) => viewRights.includes(module)) : true;
};
</script>

<style scoped lang="sass"></style>
