<template>
  <div v-if="false" />
</template>

<script setup lang="ts">
import {
  AKBECommPersonEmployeeUserRequest,
  AKBECommPersonEmployeeUserSchema,
  AKBECommSelectPersonFilter,
} from '@ak_tools/backend_communication/definitions/hr/AKBECommPersons';
import {
  AKBECommSelectUserGroupFilter,
  AKBECommUserGroupSchema,
} from '@ak_tools/backend_communication/definitions/users/AKBECommUsers';
import { AKBECommHR } from '@ak_tools/backend_communication/implementations/hr/AKBECommHR';
import { AKBECommUsers } from '@ak_tools/backend_communication/implementations/users/AKBECommUsers';
import AKEnv from '@ak_tools/framework_support/AKEnv';
import { AKVueProvider } from '@ak_tools/framework_support/AKVueProvider';
import { AKStore } from '@ak_tools/start/App';
import { AKSelectablePersons, AKSelectableUserGroups } from '@ak_tools/store/hr/types';

//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const backendCommHR = new AKBECommHR(new AKVueProvider());
const backendCommUserGroups = new AKBECommUsers(new AKVueProvider());

//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const setSelectablePersons = async () => {
  AKStore.HR.state.personsAvailable = false;

  const postBody: AKBECommPersonEmployeeUserRequest = {
    selected_filter: AKBECommSelectPersonFilter.all_existing_persons,
  };

  await backendCommHR.beCommGetPersons(postBody).then((response) => {
    AKStore.HR.state.selectablePersons = setPersonsResult(response.data.persons);
    AKStore.HR.state.personsAvailable = true;
  });
};

const setPersonsResult = (persons: Array<AKBECommPersonEmployeeUserSchema>) => {
  const resultList: Array<AKSelectablePersons> = [];

  persons.forEach((person: AKBECommPersonEmployeeUserSchema) => {
    resultList.push(person);
  });

  return resultList;
};

const setSelectableEmployees = async () => {
  AKStore.HR.state.employeesAvailable = false;

  const postBody: AKBECommPersonEmployeeUserRequest = {
    selected_filter: AKBECommSelectPersonFilter.just_all_employees,
  };

  await backendCommHR.beCommGetPersons(postBody).then((response) => {
    AKStore.HR.state.selectableEmployees = setEmployeeResult(response.data.persons);
    AKStore.HR.state.employeesAvailable = true;
  });
};

const setEmployeeResult = (persons: Array<AKBECommPersonEmployeeUserSchema>) => {
  const resultList: Array<AKSelectablePersons> = [];

  persons.forEach((person: AKBECommPersonEmployeeUserSchema) => {
    resultList.push(person);
  });

  return resultList;
};

const setSelectableUsers = async () => {
  AKStore.HR.state.usersAvailable = false;

  const postBody: AKBECommPersonEmployeeUserRequest = {
    selected_filter: AKBECommSelectPersonFilter.just_all_users,
  };

  await backendCommHR.beCommGetPersons(postBody).then((response) => {
    AKStore.HR.state.selectableUsers = setUserResult(response.data.persons);
    AKStore.HR.state.usersAvailable = true;
  });
};

const setUserResult = (persons: Array<AKBECommPersonEmployeeUserSchema>) => {
  const resultList: Array<AKSelectablePersons> = [];

  persons.forEach((person: AKBECommPersonEmployeeUserSchema) => {
    resultList.push(person);
  });

  return resultList;
};

const setSelectableUserGroups = async () => {
  AKStore.HR.state.userGroupsAvailable = false;

  await backendCommUserGroups
    .beCommGetUserGroupsByFilter(AKBECommSelectUserGroupFilter.all_user_groups_without_personal_groups)
    .then((response) => {
      AKStore.HR.state.selectableUserGroups = setUserGroupResult(response.data.user_groups);
      AKStore.HR.state.userGroupsAvailable = true;
    });
};

const setUserGroupResult = (userGroups: Array<AKBECommUserGroupSchema>) => {
  const resultList: Array<AKSelectableUserGroups> = [];

  userGroups.forEach((userGroup: AKBECommUserGroupSchema) => {
    resultList.push(userGroup);
  });

  return resultList;
};

const setIt = async () => {
  const ignoreSending = Number(AKEnv.VUE_APP_NO_LOGIN_REQUIRED) === 1;
  if (!ignoreSending) {
    await setSelectablePersons();
    await setSelectableEmployees();
    await setSelectableUsers();
    await setSelectableUserGroups();
  }
};

//  --------------------------------------------------------------------------------------------------------------------
//  public expose
//  --------------------------------------------------------------------------------------------------------------------
defineExpose({
  setIt,
});
</script>

<style lang="sass" scoped></style>
