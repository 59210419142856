export default class AKShortcutManager {
  private shortcuts: Record<string, () => void> = {};
  private keydownListener: (event: KeyboardEvent) => void = () => {};

  registerShortcut(keyCombination: string, callback: () => void) {
    const formatShortcut = (shortcut: string) => {
      const noFormatNeeded = ['Escape', 'ArrowDown', 'ArrowUp'];
      const formattedWords = shortcut
        .replaceAll(' ', '')
        .split('+')
        .map((word) => (noFormatNeeded.includes(word) ? word : word.toLowerCase()));

      return formattedWords.join('+');
    };

    const formattedShortcut = formatShortcut(keyCombination);

    this.shortcuts[formattedShortcut] = callback;
  }

  unregisterShortcuts() {
    Object.keys(this.shortcuts).forEach((keys) => {
      delete this.shortcuts[keys];
    });
  }

  addEventListener() {
    this.keydownListener = (event) => this.handleKeyPress(event);
    document.addEventListener('keydown', this.keydownListener);
  }

  removeEventListener() {
    document.removeEventListener('keydown', this.keydownListener);
  }

  private handleKeyPress(event: KeyboardEvent) {
    const keyCombination = [];

    if (event.altKey) {
      keyCombination.push('alt');
    }

    if (event.ctrlKey) {
      keyCombination.push('ctrl');
    }

    if (event.shiftKey) {
      keyCombination.push('shift');
    }

    keyCombination.push(event.key);
    const keys = keyCombination.join('+');

    const ignorePreventDefault = ['ArrowDown', 'ArrowUp'];

    if (this.shortcuts[keys]) {
      if (!ignorePreventDefault.includes(keys)) {
        event.preventDefault();
      }

      this.shortcuts[keys]();
    }
  }
}
