<template>
  <div class="ak-scrollbar-primary max-w-[100vw] overflow-x-auto">
    <div :style="`min-width: ${computedMinWidth}`">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
interface PropsInterface {
  minWidth: string | false;
}
import { computed } from 'vue'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const props = defineProps<PropsInterface>();
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const computedMinWidth = computed(() => {
  return props.minWidth ? props.minWidth : '0px';
});
</script>

<style lang="sass" scoped></style>
