import { AKBECommEmployeeSchema } from '@ak_tools/backend_communication/definitions/hr/AKBECommPersons';

export type AKPerson = {
  firstname: string;
  lastname: string;
};

export interface AKHRMethodsIF {
  getPersonFullName: (person: AKPerson | null) => string;
  getEmployeePersonalNumber: (employee: AKBECommEmployeeSchema | null) => string;
}

export class AKHR implements AKHRMethodsIF {
  getEmployeePersonalNumber = (employee: AKBECommEmployeeSchema | null) => {
    return employee ? employee.personal_number : '';
  };

  getPersonFullName = (person: AKPerson | null) => {
    return person ? `${person.firstname} ${person.lastname}`.trim() : '';
  };
}
