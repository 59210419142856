import { AKVueProvider } from '@ak_tools/framework_support/AKVueProvider';
import { AKStore } from '@ak_tools/start/App';
import { AKAppMessageSuccess, AKMessageType } from '@ak_tools/store/app/types';
import { AKDateTime } from '@ak_tools/ts_modules/core/AKDateTime';
import { useI18n } from 'vue-i18n';

import { BECommLocation, BECommLocationUpdateRequest } from '@/backend_communication/definitions/BECommLocations';
import { BECommParticipant } from '@/backend_communication/definitions/BECommParticipants';
import { BECommTimeframe } from '@/backend_communication/definitions/BECommTimeframes';
import { BECommTimeslot } from '@/backend_communication/definitions/BECommTimeslots';
import { BECommTournamentConLocationCreateRequest } from '@/backend_communication/definitions/BECommTournamentConLocations';
import {
  BECommTournament,
  BECommTournamentParticipantsPostRequest,
  BECommTournamentUpdateRequest,
} from '@/backend_communication/definitions/BECommTournaments';
import BECommLocations from '@/backend_communication/implementations/BECommLocations';
import BECommParticipants from '@/backend_communication/implementations/BECommParticipants';
import BECommTimeframes from '@/backend_communication/implementations/BECommTimeframes';
import BECommTimeslots from '@/backend_communication/implementations/BECommTimeslots';
import BECommTournamentConLocations from '@/backend_communication/implementations/BECommTournamentConLocations';
import BECommTournaments from '@/backend_communication/implementations/BECommTournaments';

import { TimeframeWithMinutes } from './TournamentTypes';

class TournamentDataHandlerDefaultData {
  tournaments: BECommTournament[] = [];
  current_tournament: BECommTournament | null = null;
  participants: BECommParticipant[] = [];
  timeslots: BECommTimeslot[] = [];
  tournament_locations: BECommLocation[] = [];
  tournament_timeframes: TimeframeWithMinutes[] = [];
  all_locations: BECommLocation[] = [];
  elementsAreLoading = false;
  elementIsLoading = false;
  selectedTableRows: BECommTournament[] = [];
}

export class TournamentDataHandler {
  private beCommTournaments = new BECommTournaments(new AKVueProvider());
  private beCommTimeslots = new BECommTimeslots(new AKVueProvider());
  private beCommTournamentConLocations = new BECommTournamentConLocations(new AKVueProvider());
  private beCommLocations = new BECommLocations(new AKVueProvider());
  private beCommParticipants = new BECommParticipants(new AKVueProvider());
  private beCommTimeframes = new BECommTimeframes(new AKVueProvider());
  private i18n = useI18n();
  data = new TournamentDataHandlerDefaultData();

  async getTournamentsFromBackend() {
    this.data.elementsAreLoading = true;
    return this.beCommTournaments
      .beCommGetTournaments()
      .then((response) => {
        this.data.tournaments = response.data.tournaments;
      })
      .finally(() => {
        this.data.elementsAreLoading = false;
      });
  }

  async getTournamentByIdFromBackend(tournament_id: number) {
    this.data.elementIsLoading = true;
    this.data.current_tournament = null;
    return this.beCommTournaments
      .beCommGetTournamentById(tournament_id)
      .then((response) => {
        this.data.current_tournament = response.data.tournament;
      })
      .finally(() => {
        this.data.elementIsLoading = false;
      });
  }

  async getLocationsFromBackend() {
    return this.beCommLocations.beCommGetLocations().then((response) => {
      this.data.all_locations = response.data.locations;
    });
  }

  async getParticipantsByTournamentIdFromBackend() {
    if (this.data.current_tournament) {
      return this.beCommParticipants
        .beCommGetParticipantsByTournament(this.data.current_tournament?.tournament_id)
        .then((response) => {
          this.data.participants = response.data.participants;
        });
    }
  }

  async getTimeslotsByMatchDurationFromBackend() {
    if (this.data.current_tournament!.match_duration) {
      this.data.timeslots = [];
      this.data.elementIsLoading = true;
      return this.beCommTimeslots
        .beCommTimeslotsByDuration(this.data.current_tournament!.match_duration)
        .finally(() => {
          this.data.elementIsLoading = false;
        });
    }
  }

  async generateTimeslotsFromBackend() {
    const timeframes: BECommTimeframe[] = [];
    const currentTimeframes: TimeframeWithMinutes[] = this.data.tournament_timeframes;
    currentTimeframes.forEach((timeframeWithMinutes) => {
      const convertedTimeframe = this.convertTimeframeWithMinutesToTimeframe(timeframeWithMinutes);
      timeframes.push(convertedTimeframe);
    });

    return this.beCommTimeslots
      .beCommGenerateTimeslot(timeframes, this.data.current_tournament!.match_duration!)
      .then(async (response) => {
        this.data.current_tournament!.timeslots = response.data.timeslots;
        await this.getTimeframesByTournamentIdFromBackend();
      });
  }

  async getTimeframesByTournamentIdFromBackend() {
    if (this.data.current_tournament) {
      return this.beCommTimeframes
        .beCommGetTimeframesByTournament(this.data.current_tournament?.tournament_id)
        .then((response) => {
          this.data.tournament_timeframes = response.data.timeframes;
          for (let i = 0; i < this.data.tournament_timeframes.length; i++) {
            this.convertTimeframeToTimeframeWithMinutes(this.data.tournament_timeframes[i]);
          }
        });
    }
  }

  async createTimeframeOnBackend() {
    let newTimeframe: BECommTimeframe | null = null;
    await this.beCommTimeframes
      .beCommCreateTimeframe(this.data.current_tournament!.tournament_id)
      .then(async (response) => {
        newTimeframe = response.data.timeframe;
        if (newTimeframe) {
          const newTimeframeWithMinutes = this.convertTimeframeToTimeframeWithMinutes(newTimeframe);
          this.data.tournament_timeframes.push(newTimeframeWithMinutes);
        }
      });
  }

  convertTimeframeToTimeframeWithMinutes(timeframe: BECommTimeframe) {
    const timeframeWithMinutes: TimeframeWithMinutes = timeframe;
    const startTimeOfDay = timeframeWithMinutes.start_time_of_day;
    const endTimeOfDay = timeframeWithMinutes.start_time_of_day + timeframeWithMinutes.length;
    timeframeWithMinutes.startHours = Math.floor(startTimeOfDay / 60);
    timeframeWithMinutes.startMinutes = startTimeOfDay % 60;
    timeframeWithMinutes.endHours = Math.floor(endTimeOfDay / 60);
    timeframeWithMinutes.endMinutes = endTimeOfDay % 60;
    return timeframeWithMinutes;
  }

  convertTimeframeWithMinutesToTimeframe(timeframeWithMinutes: TimeframeWithMinutes) {
    return {
      timeframe_id: timeframeWithMinutes.timeframe_id,
      start_time_of_day: timeframeWithMinutes.start_time_of_day,
      length: timeframeWithMinutes.length,
      tournament_id: timeframeWithMinutes.tournament_id,
    };
  }

  async deleteTimeframeOnBackend(timeframeId: number) {
    return this.beCommTimeframes.beCommDeleteTimeframe(timeframeId).then(() => {
      const index = this.data.tournament_timeframes.findIndex((t) => t.timeframe_id === timeframeId);
      if (index !== -1) {
        this.data.tournament_timeframes.splice(index, 1);
      }
    });
  }

  async deleteTournamentByIdOnBackend(tournament_id: number) {
    return this.beCommTournaments.beCommDeleteTournament(tournament_id).then(() => {
      const index = this.data.tournaments.findIndex((t) => t.tournament_id === tournament_id);
      if (index !== -1) {
        this.data.tournaments.splice(index, 1);
      }
    });
  }

  async removeLocationFromTournament(location_id: number) {
    await this.beCommTournamentConLocations.beCommDeleteTournamentConLocation(
      this.data.current_tournament!.tournament_id,
      location_id,
    );
  }

  async addLocationToCurrentTournament(location_id: number) {
    const tournament_id = this.data.current_tournament!.tournament_id;

    const postBody: BECommTournamentConLocationCreateRequest = {
      tournament_id: tournament_id,
      location_id: location_id,
    };

    await this.beCommTournamentConLocations.beCommCreateTournamentConLocation(postBody);
  }

  async addParticipantToCurrentTournament(personal_user_group_id: number) {
    const postBody: BECommTournamentParticipantsPostRequest = {
      tournament_id: this.data.current_tournament!.tournament_id,
      personal_user_group_id: personal_user_group_id,
    };
    await this.beCommTournaments.beAddParticipantToTournament(postBody);
  }

  async createLocation(name: string) {
    const postBody: BECommLocationUpdateRequest = {
      name: name,
      description: name,
    };
    await this.beCommLocations.beCommCreateLocation().then((response) => {
      if (response.data.location) {
        this.beCommLocations
          .beCommUpdateLocation(response.data.location.location_id, postBody)
          .then(async (response) => {
            this.data.all_locations.push(response.data.location);
            this.data.current_tournament!.locations.push(response.data.location);
            await this.addLocationToCurrentTournament(response.data.location.location_id);
          });
      }
    });
  }

  async removeParticipantFromCurrentTournament(participantId: number) {
    await this.beCommParticipants.beCommDeleteParticipant(participantId).then(() => {
      this.getParticipantsByTournamentIdFromBackend();
    });
  }

  async createTournamentOnBackend() {
    let newTournament: BECommTournament | null = null;
    this.data.elementIsLoading = true;
    this.data.timeslots = [];
    this.data.tournament_timeframes = [];
    this.data.participants = [];
    await this.beCommTournaments
      .beCommCreateTournament()
      .then(async (response) => {
        newTournament = response.data.tournament;
        if (newTournament) {
          this.data.tournaments.push(newTournament);
          this.data.current_tournament = newTournament;

          newTournament.timeframes.forEach((placeholderTimeframe) => {
            const placeholderTimeframeWithMinutes = this.convertTimeframeToTimeframeWithMinutes(placeholderTimeframe);
            this.data.tournament_timeframes.push(placeholderTimeframeWithMinutes);
          });
        }
      })
      .finally(() => {
        this.data.elementIsLoading = false;
      });

    return newTournament;
  }

  async updateTournamentBaseOnBackend(tournament: BECommTournament) {
    const akDateTime = new AKDateTime();
    if (
      tournament.start_date == 0 ||
      tournament.end_date == 0 ||
      tournament.start_date == null ||
      tournament.end_date == null
    ) {
      tournament.start_date = akDateTime.getCurrentTimeStamp();
      tournament.end_date = akDateTime.getCurrentTimeStamp();
    }
    const tournamentPutData: BECommTournamentUpdateRequest = {
      start_date: tournament.start_date,
      end_date: tournament.end_date,
      name: tournament.name,
      match_duration: tournament.match_duration,
    };
    return this.beCommTournaments
      .beCommUpdateTournament(tournament.tournament_id, tournamentPutData)
      .then((response) => {
        this.data.current_tournament = response.data.tournament;
        const index = this.data.tournaments.findIndex((t) => t.tournament_id === tournament.tournament_id);
        if (index !== -1) {
          this.data.tournaments[index] = this.data.current_tournament;
        }
      });
  }

  public async generateMatchPlan(tournament_id: number) {
    this.data.elementIsLoading = true;
    await this.beCommTournaments.beCommGenerateMatchPlan(tournament_id).finally(() => {
      this.triggerMatchplanToast();
      this.data.elementIsLoading = false;
    });
  }

  triggerMatchplanToast = () => {
    const matchplanSuccessMessage: AKAppMessageSuccess = {
      messageType: AKMessageType.SUCCESS,
      messageSummary: this.i18n.t('TournamentDataHandler.generate-matchplan.success'),
    };
    AKStore.App.state.messages.push(matchplanSuccessMessage);
  };
}
