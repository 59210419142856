import { BeCommAll } from '@ak_tools/backend_communication/AKBEComm';
import { AKBECommBaseMessages } from '@ak_tools/backend_communication/AKBECommBase';
import { AKBECommDefaultResponseSchema } from '@ak_tools/backend_communication/AKBECommDefault';
import { AKBECommUploadResponseSchema } from '@ak_tools/backend_communication/definitions/files/AKBECommFiles';
import { saveAs } from 'file-saver';

export class AKBECommFile extends BeCommAll {
  public beCommDownloadFile(apiUrl: string): void {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommFile.error.download.message'),
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.beComm.backendCommGet(apiUrl, { responseType: 'blob' }, messages).then((incoming: any) => {
      const filename = incoming.response.headers['ak-filename'];
      saveAs(incoming.data, filename);
    });
  }

  public beCommFileUpload(files: Array<Blob>, messages?: AKBECommBaseMessages): Promise<AKBECommUploadResponseSchema> {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('file', file);
    });
    const messagesToTransfer = messages || { error: 'File cannot be Uploaded' };
    return this.beComm.backendCommPost('/files/upload', formData, {}, messagesToTransfer);
  }

  public beCommDeleteFile(apiUrl: string): Promise<AKBECommDefaultResponseSchema> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommFile.error.delete.message'),
    };
    return this.beComm.backendCommDelete(apiUrl, {}, messages);
  }
}
//  --------------------------------------------------------------------------------------------------------------------
//  Exports
//  --------------------------------------------------------------------------------------------------------------------
export default AKBECommFile;
