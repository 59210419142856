<template>
  <AKIconField
    ref="akIconField"
    :key="updateInput"
    v-model="inputValue"
    class="ak-app-search-input"
    custom-iterable
    :autofocus="props.autofocus"
    :icon-classes="inputValue ? 'input-active-icon' : 'input-default-icon'"
    :custom-input-class="inputValue ? 'input-active' : 'input-default'"
    :input-placeholder="$t('AKAppSearchInput.placeholder')"
    input-size="small" />
</template>

<script setup lang="ts">
interface PropsInterface {
  autofocus?: boolean;
}
import { AKIconField } from '@ak_tools/components/form'
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const inputValue = defineModel<string>({ default: '' });
const props = withDefaults(defineProps<PropsInterface>(), {
  autofocus: false,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const route = useRoute();
const updateInput = ref(0);
const akIconField = ref<InstanceType<typeof AKIconField> | null>(null);
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const resetInput = () => {
  akIconField.value?.blurInput();
  inputValue.value = '';
  updateInput.value = Number(!updateInput.value);
  emitResetInput();
};
const focusInput = () => {
  akIconField.value?.focusInput();
};
//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watch(route, () => {
  resetInput();
});
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  resetInput: [];
}>();
const emitResetInput = () => {
  emits('resetInput');
};
//  --------------------------------------------------------------------------------------------------------------------
//  provided data + public expose
//  --------------------------------------------------------------------------------------------------------------------
defineExpose({ resetInput, focusInput });
</script>

<style lang="sass">
.ak-app-search-input
  ::placeholder
    @apply text-theme

.input-default
  @apply bg-primary-1 text-theme border-0

  &-icon
    @apply text-theme

.input-active
  @apply bg-white text-primary border-primary

  &-icon
    @apply text-primary
</style>
