<template>
  <AKModuleContainer ref="akModuleContainer">
    <template #table>
      <AKLoadingContent
        v-if="dataHandler.data.elementsAreLoading"
        class="ak-container-main-overlay-default z-module-overlay-layer" />
      <TournamentDataTable :enable-multi-select="enableMultiSelect" @on-row-click="onRowClick" />
    </template>
    <template #sidePanel>
      <AKLoadingContent
        v-if="dataHandler.data.elementIsLoading"
        class="ak-container-main-overlay-default z-module-overlay-layer" />
      <TournamentDataSidePanel
        v-if="dataHandler.data.current_tournament"
        :key="dataHandler.data.current_tournament.tournament_id"
        @close-side-panel="closeSidePanel"
        @maximize-side-panel="maximizeSidePanel"
        @minimize-side-panel="minimizeSidePanel" />
    </template>
    <template #moduleActions>
      <AKIcon class="ak-text-hover cursor-pointer" :framework-icon="PrimeIcons.PLUS" @click="createTournament"></AKIcon>
    </template>
  </AKModuleContainer>
</template>

<script setup lang="ts">
import { AKDataTableRowClickEvent } from '@ak_tools/components/data';
import AKModuleContainer from '@ak_tools/components/layout/AKModuleContainer.vue';
import { AKIcon } from '@ak_tools/components/media';
import { AKLoadingContent } from '@ak_tools/components/overlay';
import { AKStore } from '@ak_tools/start/App';
import { PrimeIcons } from 'primevue/api';
import { computed, nextTick, onMounted, provide, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

import { BECommTournament } from '@/backend_communication/definitions/BECommTournaments';
import { TournamentDataHandler } from '@/components/tournaments/TournamentDataHandler';
import TournamentDataSidePanel from '@/components/tournaments/TournamentDataSidePanel.vue';
import TournamentDataTable from '@/components/tournaments/TournamentDataTable.vue';

const { locale } = useI18n();
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const dataHandler = ref(new TournamentDataHandler());
const akModuleContainer = ref<InstanceType<typeof AKModuleContainer> | null>(null);
const router = useRouter();
const route = useRoute();
const currentRouteName = String(route.name);
const enableMultiSelect = false;

//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const currentRouterItemId = computed(() => {
  return Number(route.params.item_id);
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const closeSidePanel = () => {
  resetView();
};

const maximizeSidePanel = () => {
  akModuleContainer.value?.maximizeSidePanel();
};

const minimizeSidePanel = () => {
  akModuleContainer.value?.minimizeSidePanel();
};
const onRowClick = (_: AKDataTableRowClickEvent, tournamentRowData: BECommTournament) => {
  const tournament_id = tournamentRowData.tournament_id;

  nextTick(() => {
    enableMultiSelect && dataHandler.value.data.selectedTableRows.length > 1
      ? resetView()
      : addApplicationIdToRoute(tournament_id);
  });
};

const addApplicationIdToRoute = (tournament_id: number) => {
  if (currentRouterItemId.value !== tournament_id) {
    router.push({
      name: currentRouteName,
      params: { item_id: tournament_id },
    });
  }
};

const createTournament = async () => {
  await dataHandler.value.createTournamentOnBackend();
  nextTick(() => {
    addApplicationIdToRoute(dataHandler.value.data.current_tournament!.tournament_id);
  });
};
const resetView = () => {
  akModuleContainer.value?.closeSidePanel();

  dataHandler.value.data.current_tournament = null;
  updateRoute(true);
};
const updateRoute = (resetView = false) => {
  const tournament_id = resetView ? 0 : currentRouterItemId.value || 0;

  router.push({ name: currentRouteName, params: { item_id: tournament_id } });
};
const showSidePanel = () => {
  akModuleContainer.value?.showSidePanel();
};
const updateCurrentItem = () => {
  if (currentRouterItemId.value > 0) {
    openItemById(currentRouterItemId.value);
  }
};

const openItemById = async (id: number) => {
  showSidePanel();

  if (id !== dataHandler.value.data.current_tournament?.tournament_id) {
    await dataHandler.value.getTournamentByIdFromBackend(currentRouterItemId.value);
    await dataHandler.value.getTimeslotsByMatchDurationFromBackend();
    await dataHandler.value.getTimeframesByTournamentIdFromBackend();
    if (!dataHandler.value.data.current_tournament) {
      resetView();
    }
  }
};
const setSelectedListData = () => {
  const selectedTournament = dataHandler.value.data.current_tournament;

  if (selectedTournament) {
    const itemIndex = dataHandler.value.data.tournaments.findIndex(
      (tournament) => tournament.tournament_id === selectedTournament.tournament_id,
    );

    if (itemIndex !== -1) {
      dataHandler.value.data.selectedTableRows = [selectedTournament];
    }
  }
};
const setModuleTitle = () => {
  AKStore.App.state.currentModuleTitle = 'Turniere';
};
//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watch(currentRouterItemId, () => {
  updateCurrentItem();
});

watch(locale, () => {
  setModuleTitle();
});
//  --------------------------------------------------------------------------------------------------------------------
//  provided data + public expose
//  --------------------------------------------------------------------------------------------------------------------
provide('TournamentDataHandler', dataHandler.value);

//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
onMounted(async () => {
  updateCurrentItem();
  await dataHandler.value.getTournamentsFromBackend();
  await dataHandler.value.getLocationsFromBackend();
  await dataHandler.value.getTimeframesByTournamentIdFromBackend();
  setSelectedListData();
});

setModuleTitle();
</script>

<style lang="sass" scoped></style>
