export const akLayers = {
  justAboveCurrent: '1',
  nonVisibleLayer: '10000',
  wireframeUndergroundLayer: '20000',
  moduleLayer: '30000',
  moduleLayerFlex1: '40000',
  moduleLayerFix1: '50000',
  moduleLayerFlex2: '60000',
  moduleLayerFix2: '70000',
  moduleOverlayLayer: '80000',
  moduleOverlayLayerFlex1: '90000',
  moduleOverlayLayerFix1: '100000',
  moduleOverlayLayerFlex2: '110000',
  moduleOverlayLayerFix2: '120000',
  wireframeTopLayer: '130000',
  wireframeOverlayLayer: '140000',
  errorMessagesLayer: '150000',
  errorMessagesLayerFlex1: '160000',
  errorMessagesLayerFix1: '170000',
  errorMessagesLayerFlex2: '180000',
  errorMessagesLayerFix2: '190000',
  maxValue: '999999',
};

export const akGetLayer = (layer: AKLayersKey) => parseInt(akLayers[layer], 10);

export type AKLayersKey = keyof typeof akLayers;
