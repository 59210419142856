<template>
  <Avatar :image="props.picturePath" :label="avatarInitials" :size="props.size" :shape="props.shape">
    <template v-if="!props.picturePath" #icon>
      <AKIcon :framework-icon="PrimeIcons.USER" :scale="0.75" />
    </template>
    <slot></slot>
  </Avatar>
</template>

<script setup lang="ts">
interface PropsInterface {
  size?: 'normal' | 'large' | 'xlarge';
  shape?: 'square' | 'circle';
  fullName?: string;
  picturePath?: string;
}
import { AKIcon } from '@ak_tools/components/media'
import { PrimeIcons } from 'primevue/api'
import Avatar from 'primevue/avatar'
import { computed } from 'vue'
//  --------------------------------------------------------------------------------------------------------------------
//  props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  size: 'normal',
  shape: 'circle',
  fullName: undefined,
  picturePath: undefined,
});
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const avatarInitials = computed(() => {
  const allNames = props.fullName?.split(' ') || [];
  if (!props.picturePath && allNames.length > 0) {
    const firstLetterOfFirstName = allNames[0].charAt(0);
    const lastElementIndex = allNames.length - 1;
    const firstLetterOfLastName = allNames[lastElementIndex].charAt(0);
    return firstLetterOfFirstName + firstLetterOfLastName;
  }
  return undefined;
});
</script>
