<template>
  <div v-if="false" />
</template>

<script setup lang="ts">
interface PropsInterface {
  fittyCss?: string;
}
import { AKStore } from '@ak_tools/start/App'
import { AKBreakpoints } from '@ak_tools/store/app/types'
import fitty from 'fitty'
import { isMobile } from 'mobile-device-detect'
import { onBeforeUnmount, onMounted, watch } from 'vue'
class AKFitTextToParent {
  public load(cssClass = '.fitText') {
    fitty(cssClass, {
      minSize: 12,
      maxSize: 9000,
      observeMutations: {
        subtree: true,
        childList: true,
        characterData: true,
      },
    });
  }
}
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  fittyCss: '.ak-fit-text',
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const appFitty = new AKFitTextToParent();
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const refreshLayoutElements = (timeout = 100, retries = 5) => {
  return new Promise((resolve) => {
    appFitty.load(props.fittyCss);
    for (let i = 0; i < retries; i++) {
      setTimeout(() => {
        appFitty.load(props.fittyCss);
      }, timeout);
    }
    resolve({});
  });
};
const setBreakpoint = () => {
  const width = AKStore.App.state.viewportWidth;
  let breakpoint: AKBreakpoints = AKBreakpoints.MOBILE;
  if (width >= 640 && width < 768) {
    breakpoint = AKBreakpoints.SM;
  } else if (width >= 768 && width < 1024) {
    breakpoint = AKBreakpoints.MD;
  } else if (width >= 1024 && width < 1280) {
    breakpoint = AKBreakpoints.LG;
  } else if (width >= 1280 && width < 1536) {
    breakpoint = AKBreakpoints.XL;
  } else if (width >= 1536) {
    breakpoint = AKBreakpoints.XXL;
  }
  AKStore.App.state.breakpoint = breakpoint;
};
const checkViewportWidth = () => {
  AKStore.App.state.viewportWidth = window.innerWidth;
  setBreakpoint();
};
//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watch(
  [() => AKStore.App.state.currentWireframe],
  () => {
    refreshLayoutElements();
  },
  { immediate: true },
);
//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
onMounted(() => {
  checkViewportWidth();
  window.addEventListener('resize', checkViewportWidth);
});
onBeforeUnmount(() => {
  window.removeEventListener('resize', checkViewportWidth);
});
AKStore.App.state.isMobile = isMobile;
</script>

<style lang="sass" scoped></style>
