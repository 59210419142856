<template>
  <AKInputContainer
    :label="props.label"
    :label-cols="props.labelCols"
    :tooltip-text="props.tooltipText"
    :tooltip-placement="props.tooltipPlacement"
    :tooltip-theme="props.tooltipTheme">
    <textarea
      ref="textarea"
      v-model="input"
      class="p-inputtextarea p-inputtext p-component p-inputtextarea-resizable w-full"
      :data-test="`textarea-${props.dataTest}`"
      :class="[computedTextClass, computedFontClass, { 'ak-readmode inputtext': props.enableSwitchReadWriteMode }]"
      :placeholder="$t(props.placeholder)"
      :disabled="props.disabled"
      :cols="props.cols"
      @focus="focused"
      @blur="setModelValue"
      @input="onInput" />
    <template #tooltipContent>
      <slot name="tooltipContent"></slot>
    </template>
  </AKInputContainer>
</template>

<script setup lang="ts">
type _VTI_TYPE_AKTooltipValidThemes = 'ak-white'
type _VTI_TYPE_AKTooltipValidPlacements = | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end'
interface PropsInterface {
  dataTest: string;
  placeholder?: string;
  disabled?: boolean;
  cols?: number | undefined;
  autofocus?: boolean;
  enableSwitchReadWriteMode?: boolean;
  fontStyle?: 'headline';
  fontSize?: 'h1' | 'h2' | 'h3' | 'h4';
  label?: string | false;
  labelCols?: number | 'auto';
  tooltipText?: string;
  tooltipPlacement?: _VTI_TYPE_AKTooltipValidPlacements;
  tooltipTheme?: _VTI_TYPE_AKTooltipValidThemes;
  lazy?: boolean;
}
import { AKInputContainer } from '@ak_tools/components/layout'
import { useTextareaAutosize } from '@vueuse/core'
import { computed, nextTick, ref } from 'vue'
//  --------------------------------------------------------------------------------------------------------------------
//  props
//  --------------------------------------------------------------------------------------------------------------------
const textAreaValue = defineModel<string>({ default: '' });
const props = withDefaults(defineProps<PropsInterface>(), {
  placeholder: 'AKTextarea.default-placeholder',
  disabled: false,
  cols: undefined,
  autofocus: false,
  enableSwitchReadWriteMode: false,
  fontStyle: undefined,
  fontSize: undefined,
  label: undefined,
  labelCols: undefined,
  tooltipText: undefined,
  tooltipPlacement: undefined,
  tooltipTheme: undefined,
  lazy: false,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const { textarea, input } = useTextareaAutosize();
const focusValue = ref(false);
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const computedTextClass = computed(() => {
  return props.fontSize ? `ak-text-${props.fontSize}` : '';
});
const computedFontClass = computed(() => {
  return props.fontStyle ? `ak-${props.fontStyle}` : '';
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const setModelValue = () => {
  focusValue.value = false;
  textAreaValue.value = input.value;
};
const onInput = () => {
  if (!props.lazy) {
    textAreaValue.value = input.value;
  }
  emitOnInput();
};
const focus = () => {
  nextTick(() => {
    textarea.value.focus();
  });
};
const focused = () => {
  focusValue.value = true;
};
const isFocused = () => {
  return focusValue.value;
};
const setInitialValue = () => {
  input.value = textAreaValue.value;
};
//  --------------------------------------------------------------------------------------------------------------------
//  provided data + public expose
//  --------------------------------------------------------------------------------------------------------------------
defineExpose({ focus, isFocused });
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  onInput: [];
}>();
const emitOnInput = () => {
  emits('onInput');
};
//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
setInitialValue();
</script>

<style lang="sass" scoped></style>
