<template>
  <label class="absolute left-0 top-0 -z-[1] h-0 w-0 opacity-0">
    <span v-text="props.labelSpan" />
    <input v-model="inputValue" autocomplete="off" tabindex="-1" :type="props.inputType" />
  </label>
</template>

<script setup lang="ts">
type _VTI_TYPE_InputTypeHTMLAttribute = 'button' | 'checkbox' | 'color' | 'date' | 'datetime-local' | 'email' | 'file' | 'hidden' | 'image' | 'month' | 'number' | 'password' | 'radio' | 'range' | 'reset' | 'search' | 'submit' | 'tel' | 'text' | 'time' | 'url' | 'week' | (string & {})
interface PropsInterface {
  labelSpan?: string;
  inputType?: _VTI_TYPE_InputTypeHTMLAttribute;
}
import { ref } from 'vue'
const inputValue = ref('');
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  labelSpan: 'Password',
  inputType: 'password',
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const validate = () => {
  return inputValue.value ? false : true;
};
//  --------------------------------------------------------------------------------------------------------------------
//  public expose
//  --------------------------------------------------------------------------------------------------------------------
defineExpose({
  validate,
});
</script>
