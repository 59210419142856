<template>
  <div
    class="flex flex-col items-center gap-4 border-b border-gray-200 bg-white p-4 sm:h-[4rem] sm:flex-row sm:justify-between">
    <h2 class="text-center sm:text-left" v-text="currentModuleTitle" />
    <div v-if="slotInUse.default" class="flex flex-col items-center gap-4 sm:flex-row">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AKStore } from '@ak_tools/start/App';
import { computed, useSlots } from 'vue';

//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const slotInUse = useSlots();

//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const currentModuleTitle = computed(() => {
  return AKStore.App.state.currentModuleTitle;
});
</script>

<style scoped lang="sass"></style>
