<template>
  <svg
    class="w-full"
    version="1.1"
    xmlns:x="&ns_extend;"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 3287.05 2200"
    style="enable-background: new 0 0 3287.05 2200"
    xml:space="preserve">
    >
    <g>
      <g id="Layer_1">
        <rect class="st0" x="0" y="0" width="3287.05" height="2200" />
        <g>
          <path
            class="st1"
            d="M2959.54,862.6c0,0,32.53,328.66-58.08,596.58c81.22-18.53,177.76-29.08,292.57-28
            c0,0-203.74,679.01-755.34,470.5c0,0,32.48-51.28,47.41-102.73c-13.23-0.07-26.7-0.47-40.66-1.59
            C2445.45,1797.36,2160.1,1297.14,2959.54,862.6z" />
          <path
            class="st1"
            d="M2452.22,1759.36c0.96-0.24,1.83-0.85,2.36-1.78l468.77-815.03c1.04-1.8,0.42-4.11-1.39-5.14
            c-1.8-1.04-4.1-0.42-5.14,1.39l-468.77,815.03c-1.04,1.8-0.42,4.11,1.39,5.14C2450.31,1759.47,2451.3,1759.58,2452.22,1759.36z" />
          <path
            class="st1"
            d="M2788.62,1175.57l123.5-30.65c2.02-0.5,3.25-2.55,2.75-4.56c-0.5-2.02-2.55-3.25-4.56-2.75l-118.7,29.46
            l3.8-153.02c0.05-2.08-1.59-3.81-3.67-3.86c-2.08-0.06-3.81,1.59-3.86,3.67l-3.92,157.96c-0.03,1.17,0.49,2.3,1.41,3.03
            C2786.27,1175.59,2787.48,1175.86,2788.62,1175.57z" />
          <path
            class="st1"
            d="M2676.62,1370.71c0.13-0.03,0.26-0.07,0.39-0.12c19.62-7.17,191.81-70.11,197.96-73.38
            c1.84-0.98,2.53-3.26,1.56-5.09c-0.97-1.84-3.25-2.54-5.09-1.56c-4.89,2.49-116.28,43.45-192.7,71.39l-33.84-218.05
            c-0.32-2.06-2.24-3.46-4.3-3.15c-2.03,0.31-3.46,2.24-3.15,4.3l34.55,222.59c0.17,1.11,0.84,2.09,1.81,2.67
            C2674.65,1370.8,2675.67,1370.94,2676.62,1370.71z" />
          <path
            class="st1"
            d="M2557.3,1571.11c0.07-0.02,0.13-0.04,0.2-0.05l290.09-89.25c1.99-0.61,3.1-2.72,2.49-4.71
            c-0.61-1.99-2.66-3.11-4.71-2.49l-285.48,87.84c-5.28-98.66-11.93-244.26-9.25-253.67c0.7-1.96-0.32-4.09-2.28-4.79
            c-1.96-0.7-4.13,0.34-4.83,2.3c-4.13,11.5,6.85,219.75,9.09,261.38c0.06,1.16,0.66,2.22,1.61,2.89
            C2555.13,1571.17,2556.25,1571.37,2557.3,1571.11z" />
          <path
            class="st1"
            d="M2505.61,1884.11c0.41-0.1,0.81-0.27,1.18-0.52l614.68-407.89c1.73-1.15,2.21-3.49,1.06-5.22
            c-1.15-1.73-3.49-2.21-5.22-1.06L2516,1868.44l58.34-100.9c1.04-1.8,0.43-4.11-1.37-5.15c-1.81-1.04-4.11-0.43-5.15,1.37
            l-66.39,114.81c-0.88,1.52-0.59,3.43,0.68,4.63C2503.07,1884.1,2504.4,1884.41,2505.61,1884.11z" />
          <path
            class="st1"
            d="M2752.22,1814.28c1.59-0.39,2.79-1.8,2.86-3.52c0.07-2.08-1.55-3.82-3.63-3.9l-128.11-4.49l62.13-122.95
            c0.94-1.86,0.19-4.12-1.66-5.06c-1.86-0.94-4.12-0.19-5.06,1.66l-64.78,128.21c-0.58,1.15-0.53,2.51,0.12,3.62
            c0.66,1.11,1.83,1.8,3.11,1.85l134,4.69C2751.54,1814.4,2751.89,1814.36,2752.22,1814.28z" />
          <path
            class="st1"
            d="M2961.17,1683.06c1.77-0.44,3-2.11,2.84-3.98c-0.18-2.07-2-3.61-4.08-3.43l-174.59,15l50.27-122.93
            c0.79-1.93-0.14-4.13-2.06-4.91c-1.93-0.79-4.13,0.14-4.91,2.06l-52.6,128.64c-0.5,1.21-0.33,2.6,0.45,3.66s2.05,1.63,3.36,1.52
            l180.73-15.53C2960.78,1683.14,2960.98,1683.1,2961.17,1683.06z" />
          <path
            class="st1"
            d="M2949.55,1587.61c0.01,0,0.03-0.01,0.04-0.01l140.97-36.71c2.01-0.52,3.22-2.58,2.7-4.6
            c-0.52-2.01-2.55-3.31-4.6-2.7l-140.97,36.71c-2.01,0.52-3.22,2.58-2.7,4.6C2945.52,1586.9,2947.55,1588.1,2949.55,1587.61z" />
        </g>
        <g>
          <rect class="st2" x="465.26" y="828.64" width="15.42" height="1183.37" />
          <path
            class="st2"
            d="M477.55,863.64c22.27-11.31,96.98-64.01,5.75-232.11c-4.9-9.03-18-9.09-22.71,0.04
            c-26.8,51.94-85.93,188.3,5.8,232.22C469.9,865.48,474.07,865.41,477.55,863.64z" />
          <path
            class="st2"
            d="M480.99,1005.49c21.09,13.35,104.43,50.55,202.14-113.48c5.26-8.83-1.39-20.12-11.64-19.5
            c-58.28,3.5-205.43,22.35-196.34,123.47C475.5,999.86,477.69,1003.41,480.99,1005.49z" />
          <path
            class="st2"
            d="M466.84,1005.49c-21.09,13.35-104.43,50.55-202.14-113.48c-5.26-8.83,1.39-20.12,11.64-19.5
            c58.28,3.5,205.43,22.35,196.34,123.47C472.33,999.86,470.13,1003.41,466.84,1005.49z" />
          <path
            class="st2"
            d="M481.21,1222.22c22.94,14.93,120.81,61.55,235.5-133.35c5.2-8.83-1.45-19.96-11.69-19.41
            c-64.56,3.47-241.34,23.89-229.58,143.37C475.81,1216.67,477.97,1220.11,481.21,1222.22z" />
          <path
            class="st2"
            d="M466.62,1222.22c-22.94,14.93-120.81,61.55-235.5-133.35c-5.2-8.83,1.45-19.96,11.69-19.41
            c64.56,3.47,241.34,23.89,229.58,143.37C472.01,1216.67,469.86,1220.11,466.62,1222.22z" />
          <path
            class="st2"
            d="M481.51,1478.21c25.55,17.25,148.2,80.87,291.9-166.77c5.11-8.81-1.33-19.83-11.51-19.37
            c-74.01,3.39-302.2,26.07-285.99,176.87C476.31,1472.7,478.38,1476.1,481.51,1478.21z" />
          <path
            class="st2"
            d="M466.32,1478.21c-25.55,17.25-148.2,80.87-291.9-166.77c-5.11-8.81,1.33-19.83,11.51-19.37
            c74.01,3.39,302.2,26.07,285.99,176.87C471.51,1472.7,469.45,1476.1,466.32,1478.21z" />
          <path
            class="st2"
            d="M481.98,1772.22c28.62,19.97,184.12,106.75,366.24-211.03c4.97-8.67-1.53-19.45-11.52-19.06
            c-85.2,3.3-382.39,28.6-360.16,221.01C476.98,1766.8,478.96,1770.11,481.98,1772.22z" />
          <path
            class="st2"
            d="M465.84,1772.22c-28.62,19.97-184.12,106.75-366.24-211.03c-4.97-8.67,1.53-19.45,11.52-19.06
            c85.2,3.3,382.39,28.6,360.16,221.01C470.85,1766.8,468.87,1770.11,465.84,1772.22z" />
        </g>
        <g>
          <g>
            <rect
              class="st3"
              x="2334.22"
              y="638.59"
              transform="matrix(0.9999 -0.0106 0.0106 0.9999 -9.3475 25.5705)"
              width="141.72"
              height="510.2" />
            <path
              class="st3"
              d="M2413.38,416.62l36.96,68.23c6.07,11.2,1.9,25.2-9.29,31.26l-86.55,46.88c-11.2,6.07-25.19,1.9-31.26-9.29
              l-40.02-73.89c-15.06,22.79-23.7,50.16-23.39,79.53c0.83,78.27,64.95,141.04,143.22,140.21
              c78.27-0.83,141.04-64.95,140.21-143.22C2542.45,482.57,2485.45,422.59,2413.38,416.62z" />
          </g>
        </g>
        <g class="st4">
          <path
            class="st5"
            d="M1313.76,850.13c1.28-26.01-1.75-52.22-8.79-77.65l63.16-55.22l-37.48-65.32l-78.06,23.63
            c-1.29-1.48-2.4-3.06-3.74-4.51c-18.51-20.2-39.68-36.54-62.4-49.31l7.55-78.89l-72.49-22.35l-40.37,69.55
            c-28.15-2.08-56.51,0.62-83.91,8l-49.79-57.5l-66.61,36.04l20.97,71.74c-1.46,1.25-3.01,2.31-4.45,3.6
            c-20.33,18.22-36.67,39.17-49.34,61.7l-79.9-0.95l-17.39,73.11l66.75,30.99c-1.86,28.58,1.35,57.44,9.55,85.32l-60.67,55.84
            l38.4,64.8l75.55-24.98c0.6,0.67,1.1,1.4,1.71,2.06c18.19,19.87,38.95,36.03,61.24,48.7l-0.7,86.88l73.98,16.91l33.55-71.46
            c28.57,2.06,57.34-0.81,85.1-8.49l56.78,59.42l65.2-38.49l-25.31-73.02c0.54-0.47,1.12-0.86,1.66-1.34
            c21.04-18.86,37.76-40.65,50.59-64.09l79.27,6.05l21.33-72.09L1313.76,850.13z M1183.31,962.1
            c-69.79,62.55-178.95,56.25-243.33-14.03c-64.38-70.29-59.98-178.36,9.82-240.91c69.79-62.54,178.95-56.24,243.33,14.04
            C1257.5,791.5,1253.1,899.55,1183.31,962.1z" />
        </g>
        <g class="st4">
          <path
            class="st6"
            d="M2860.76,780.61c0.41-8.87-0.57-17.8-2.86-26.47l20.56-18.82l-12.2-22.27l-25.41,8.06
            c-0.42-0.5-0.78-1.04-1.22-1.54c-6.02-6.89-12.91-12.46-20.31-16.81l2.46-26.89l-23.6-7.62l-13.14,23.71
            c-9.16-0.71-18.39,0.21-27.31,2.73l-16.21-19.6l-21.68,12.29l6.82,24.46c-0.48,0.42-0.98,0.79-1.45,1.23
            c-6.62,6.21-11.93,13.35-16.06,21.03l-26.01-0.32l-5.66,24.92l21.73,10.56c-0.61,9.74,0.44,19.58,3.11,29.09l-19.75,19.04
            l12.5,22.09l24.59-8.52c0.2,0.23,0.36,0.48,0.56,0.7c5.92,6.77,12.68,12.28,19.93,16.6l-0.23,29.62l24.08,5.76l10.92-24.36
            c9.3,0.7,18.67-0.28,27.7-2.89l18.48,20.26l21.22-13.12l-8.24-24.89c0.17-0.16,0.36-0.29,0.54-0.46
            c6.85-6.43,12.29-13.86,16.47-21.85l25.8,2.06l6.94-24.57L2860.76,780.61z M2818.29,818.78c-22.72,21.32-58.25,19.18-79.2-4.78
            c-20.96-23.96-19.52-60.8,3.19-82.12c22.72-21.32,58.25-19.17,79.2,4.79C2842.44,760.63,2841.01,797.46,2818.29,818.78z" />
        </g>
        <g>
          <path
            class="st7"
            d="M1513.12,491.32c0.44-9.3-0.6-18.67-3-27.76l21.56-19.74l-12.79-23.36l-26.65,8.45
            c-0.44-0.53-0.82-1.09-1.28-1.61c-6.32-7.22-13.55-13.07-21.3-17.63l2.58-28.21l-24.75-7.99l-13.78,24.87
            c-9.61-0.74-19.29,0.22-28.65,2.86l-17-20.56l-22.74,12.89l7.16,25.65c-0.5,0.45-1.03,0.83-1.52,1.29
            c-6.94,6.51-12.52,14-16.84,22.06l-27.28-0.34l-5.94,26.14l22.79,11.08c-0.64,10.22,0.46,20.54,3.26,30.51l-20.71,19.97
            l13.11,23.17l25.79-8.93c0.2,0.24,0.37,0.5,0.58,0.74c6.21,7.1,13.3,12.88,20.91,17.41l-0.24,31.06l25.26,6.05l11.45-25.55
            c9.75,0.74,19.58-0.29,29.05-3.03l19.38,21.25l22.26-13.76l-8.64-26.11c0.18-0.17,0.38-0.31,0.57-0.48
            c7.18-6.74,12.89-14.53,17.27-22.91l27.06,2.16l7.28-25.77L1513.12,491.32z M1468.58,531.35
            c-23.83,22.36-61.09,20.11-83.07-5.02c-21.98-25.13-20.48-63.77,3.35-86.13c23.83-22.36,61.09-20.11,83.07,5.02
            C1493.91,470.36,1492.41,508.99,1468.58,531.35z" />
        </g>
        <path
          class="st8"
          d="M3012.25,2009.33H309.64c-16.97,0-30.86,13.89-30.86,30.86v1.9c0,16.97,13.89,30.86,30.86,30.86h2702.61
          c16.97,0,30.86-13.89,30.86-30.86v-1.9C3043.11,2023.21,3029.22,2009.33,3012.25,2009.33z" />
        <path
          class="st9"
          d="M2570.42,881.55H1004.5c-14.11,0-25.56,11.44-25.56,25.56v1050.14c0,14.11,11.44,25.56,25.56,25.56h1565.91
          c14.11,0,25.56-11.44,25.56-25.56V907.1C2595.97,892.99,2584.53,881.55,2570.42,881.55z" />
        <rect class="st0" x="1002.18" y="900.13" width="1563.59" height="1033.87" />
        <rect class="st71" x="1002.18" y="899.7" width="1563.59" height="52.54" />
        <ellipse
          class="st11"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -350.0531 1005.4265)"
          cx="1038.63"
          cy="925.26"
          rx="12.55"
          ry="12.55" />
        <ellipse
          class="st12"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -336.8463 1037.3105)"
          cx="1083.72"
          cy="925.26"
          rx="12.55"
          ry="12.55" />
        <ellipse
          class="st11"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -323.2311 1070.1807)"
          cx="1130.21"
          cy="925.26"
          rx="12.55"
          ry="12.55" />
        <g>
          <defs>
            <rect id="SVGID_1_" x="1044.67" y="981.51" width="1454.02" height="796.76" />
          </defs>
          <clipPath id="SVGID_00000092447052950965551130000005019845499148406706_">
            <use xlink:href="#SVGID_1_" style="overflow: visible" />
          </clipPath>
          <rect
            x="835.42"
            y="901.03"
            style="clip-path: url(#SVGID_00000092447052950965551130000005019845499148406706_); fill: #cce4f8"
            width="2052.26"
            height="1250.13" />
          <rect
            x="835.42"
            y="1745.19"
            style="clip-path: url(#SVGID_00000092447052950965551130000005019845499148406706_); fill: #88b7e6"
            width="2052.26"
            height="70.64" />
          <path
            style="clip-path: url(#SVGID_00000092447052950965551130000005019845499148406706_); fill: #ffffff"
            d="M1839.98,1010.55
            h-286.26c-23.04,0-41.89,18.85-41.89,41.89v101.24c0,23.04,18.85,41.89,41.89,41.89h286.26c23.04,0,41.89-18.85,41.89-41.89
            v-101.24C1881.88,1029.4,1863.02,1010.55,1839.98,1010.55z" />
          <g style="clip-path: url(#SVGID_00000092447052950965551130000005019845499148406706_)">
            <polygon class="st18" points="1794.69,1405.44 1861.46,1383.5 1884.03,1386.32 1797.2,1412.96" />
            <polygon class="st18" points="1795.63,1365 1841.4,1349.64 1840.77,1393.84 1797.2,1406.69" />
            <path
              class="st19"
              d="M1814.31,1381.87c2.62-9.07,9.11-16.81,14.51-17.28c5.4-0.47,7.65,6.51,5.04,15.58
              c-2.62,9.07-9.11,16.81-14.51,17.28C1813.95,1397.92,1811.7,1390.94,1814.31,1381.87z" />
            <polygon class="st20" points="1883.92,1335.24 1867.73,1334.59 1864.28,1383.18 1884.03,1386.32" />
            <path
              class="st20"
              d="M1872.75,1341.49l-10.76-3.84c0,0-2.82-0.68-2.7,1.72l-19.75,55.31c0,0,1.19,3.12,5.62,3.56l23.3-14.26
              L1872.75,1341.49z" />
            <polygon class="st21" points="1842.34,1402.93 1872.03,1384.4 1868.47,1383.97 1838.58,1400.74" />
            <path
              class="st22"
              d="M1855,1394.49c6.65-6.07,9.09-25.02,10.03-41.74c0.59-10.47-0.15-29.02-5.14-28.87l-2.84-0.34l0.63,51.96
              l-21.58,27.7l2.94,0.88C1839.05,1404.08,1849.71,1399.34,1855,1394.49z" />
            <path
              class="st23"
              d="M1852.06,1393.61c6.65-6.07,9.09-25.02,10.03-41.74c0.59-10.47-7.53-17.28-7.53-17.28l-19.75,11.6
              l1.29,57.01C1836.11,1403.2,1846.78,1398.46,1852.06,1393.61z" />
            <polygon class="st24" points="2182.97,1106.84 2182.59,1102.39 1247.83,1502.16 1248,1504.19" />
            <path
              class="st25"
              d="M1324.86,1548.12c1.31-0.15,2.03,1.03,1.69,3.06c-0.42,2.56-2.37,5.53-4.37,6.62
              c-0.21,0.11-0.4,0.2-0.59,0.27l-0.41,0.13c0,0,15-0.52,15.21-0.64c1.99-1.09,0.87-3.82,1.29-6.39
              c0.33-2.03-0.35-3.21-1.66-3.06l-10.6-0.05L1324.86,1548.12z" />
            <polygon class="st26" points="1113.39,1558.82 1318.96,1560.99 1330.51,1554.97 1126.55,1550.93" />
            <polygon class="st27" points="1115.26,1558.88 1111.81,1558.88 1123.22,1551.22 1126.55,1550.93" />
            <polygon class="st28" points="1126.4,1525.98 1330.51,1538.47 1330.51,1554.97 1125.56,1550.17" />
            <polygon class="st27" points="1127.28,1525.31 1123.81,1525.28 1123.22,1551.22 1126.55,1550.93" />
            <polygon class="st29" points="1112.47,1536.15 1123.81,1525.28 1123.22,1551.22 1111.81,1558.88" />
            <polygon class="st27" points="1332.61,1537.48 1330.56,1537.46 1330.89,1554.93 1332.76,1554.78" />
            <path
              class="st25"
              d="M1103.4,1544.65c-0.25-2.52,0.96-5.4,2.7-6.43c1.74-1.03,3.35,0.18,3.6,2.7c0.25,2.52-0.96,5.4-2.7,6.43
              C1105.26,1548.38,1103.64,1547.17,1103.4,1544.65z" />
            <path
              class="st25"
              d="M1107.31,1537.95c1.29-0.11,2.34,1.07,2.53,3.06c0.25,2.52-0.96,5.4-2.7,6.43
              c-0.18,0.11-0.36,0.19-0.53,0.25l-0.39,0.12c0,0,11.91,0.27,12.09,0.16c1.74-1.03,2.95-3.76,2.7-6.28c-0.2-2-1.2-3.17-2.5-3.06
              l-10.64-0.72L1107.31,1537.95z" />
            <polygon
              class="st29"
              points="2236.76,1185.05 2164.3,1121.91 1250.91,1504.9 1222.3,1531.75 1222.45,1550.5" />
            <path
              class="st25"
              d="M2412.79,1240.24c2.56,1.08,3.89,4.67,3.14,9.15c-0.95,5.65-4.9,10.57-8.83,11
              c-0.41,0.04-0.79,0.04-1.17-0.01l-0.81-0.15c0,0,23.3,11.99,23.71,11.95c3.93-0.43,8.34-3.06,9.29-8.71
              c0.75-4.48-1.07-10-3.63-11.09l-20.61-11.68L2412.79,1240.24z" />
            <polygon class="st30" points="2020.09,1075.93 2398.53,1270.45 2428.24,1263.2 2056.32,1058.93" />
            <polygon class="st27" points="2021.25,1078.66 2016.72,1076.69 2053.9,1058.4 2057.83,1060.56" />
            <polygon class="st31" points="2028.03,1077.96 2085.1,1106.64 2115.24,1093.11 2058.96,1062.79" />
            <polygon class="st32" points="2356.95,1243.53 2395.49,1266.88 2421.56,1261.58 2376.55,1236.73" />
            <path
              class="st26"
              d="M2375.36,1237.91c-0.84,2.15-1.35,4.75-1.34,7.65c0.03,9.06,4.23,15.29,10.1,16.75
              c1.28,0.32,3.11,2.57,4.24,2.22l5.51-1.74l-6-8.07l-6.05-14.36L2375.36,1237.91z" />
            <path
              class="st26"
              d="M2384.64,1241.72c1.3,10.07,6.9,18.81,13.59,20.47c4.93,1.22,9.25-1.69,11.66-7.08L2384.64,1241.72z" />
            <path
              class="st33"
              d="M2381.82,1240.37c0.54,11.2,6.54,21.43,13.84,23.24c2.72,0.67,5.24,0.09,7.37-1.47l2.64-1.44
              c-2.13,1.56-4.66,2.14-7.37,1.46c-7.3-1.81-11.62-9.78-13.66-20.45L2381.82,1240.37z" />
            <path
              class="st33"
              d="M2385.16,1264.97c-7.63-1.89-14.27-8.43-14.31-20.2c0-1.63,0.13-3.19,0.35-4.66l-2.27,1.28
              c-0.22,1.48-0.37,3.06-0.36,4.71c0.04,11.77,5.91,21.58,13.54,23.47c2.56,0.64,6.32-0.82,8.37-2.18
              C2390.48,1267.4,2389.69,1266.1,2385.16,1264.97z" />
            <path
              class="st26"
              d="M2395.78,1263.69c-2.5,4.58-7.11,4.65-11.64,3.52c-7.63-1.89-13.25-10.67-13.29-22.44
              c0-1.63,0.13-3.19,0.35-4.66l4.16-2.21c-0.84,2.15-1.35,4.75-1.34,7.65c0.03,9.06,4.23,15.29,10.1,16.75
              c1.28,0.32,3.11,2.57,4.24,2.22l5.51-1.74L2395.78,1263.69z" />
            <g>
              <path
                class="st26"
                d="M2058.52,1065.09c-1.22,2.93-1.97,6.49-1.99,10.49c-0.06,12.5,6.6,24.49,14.87,26.79
                c1.81,0.5,3.54,0.5,5.15,0.06l7.8-2.13l-8.38-11.41l-8.37-20.09L2058.52,1065.09z" />
              <path
                class="st26"
                d="M2071.59,1070.79c1.71,13.95,9.51,26.27,18.95,28.89c6.95,1.93,13.08-1.88,16.57-9.21L2071.59,1070.79z" />
              <path
                class="st33"
                d="M2067.61,1068.79c0.62,15.47,8.97,29.87,19.26,32.73c3.83,1.06,7.41,0.38,10.43-1.67l3.75-1.85
                c-3.03,2.05-6.6,2.73-10.43,1.67c-10.3-2.85-16.29-14.04-19.04-28.87L2067.61,1068.79z" />
              <path
                class="st26"
                d="M2087.04,1101.63c-3.59,6.19-9.28,9.27-15.67,7.5c-10.75-2.98-19.4-18.55-19.32-34.79
                c0.01-2.25,0.23-4.39,0.56-6.4l5.91-2.84c-1.22,2.93-1.97,6.49-1.99,10.49c-0.06,12.5,6.6,24.49,14.87,26.79
                c1.81,0.5,3.54,0.5,5.15,0.06l7.8-2.13L2087.04,1101.63z" />
              <path
                class="st33"
                d="M2071.37,1109.12c-10.84-2.66-19.4-18.55-19.32-34.79c0.01-2.25,0.23-4.39,0.56-6.4l-3.22,1.66
                c-0.34,2.04-0.56,4.21-0.57,6.48c-0.08,16.23,8.56,31.81,19.32,34.79c3.61,1,7,0.45,9.91-1.33
                C2078.05,1109.53,2076.27,1110.32,2071.37,1109.12z" />
            </g>
            <polygon class="st28" points="2056.26,998.21 2429.07,1223.91 2428.24,1263.2 2056.32,1058.93" />
            <polygon class="st27" points="2057.83,996.25 2053.63,993.76 2053.9,1058.4 2057.83,1059.97" />
            <polygon class="st29" points="2016.84,1015.09 2053.63,993.76 2053.9,1058.4 2016.72,1076.69" />
            <path
              class="st25"
              d="M2004.77,1037.41c1.18-7.06,6.12-13.22,11.03-13.75c4.91-0.53,7.94,4.76,6.76,11.82
              c-1.18,7.06-6.12,13.22-11.03,13.75C2006.61,1049.76,2003.59,1044.47,2004.77,1037.41z" />
            <path
              class="st25"
              d="M2018.69,1024.17c3.19,1.35,4.86,5.84,3.92,11.44c-1.18,7.06-6.12,13.22-11.03,13.75
              c-0.51,0.05-0.99,0.05-1.46-0.02l-1.01-0.18c0,0,22.98,9.93,23.49,9.88c4.91-0.53,9.75-6.29,10.93-13.35
              c0.94-5.6-0.66-10.03-3.85-11.39l-19.62-9.53L2018.69,1024.17z" />
            <polygon class="st27" points="2402.17,1273.36 2398.21,1271.15 2428.58,1263.83 2432.42,1265.57" />
            <polygon class="st27" points="2432.37,1223.55 2428.38,1221.27 2428.15,1263.25 2431.78,1264.93" />
            <polygon class="st27" points="2212.65,1175.23 2212.97,1180.44 2219.37,1178.54" />
            <polygon class="st30" points="2225.77,1185.73 2275.1,1208.84 1245.2,1553.13 1220.14,1552.29" />
            <polygon class="st20" points="1078.42,1556.27 2491.93,1593.38 2491.69,1597.3 1078.42,1578.33" />
            <polygon class="st34" points="1067.39,1587.23 2485.06,1601.16 2495.89,1599.14 1086.21,1579.43" />
            <polygon class="st22" points="1085.52,1574.47 2495.89,1597.84 2495.89,1599.14 1085.52,1578.93" />
            <polygon class="st34" points="1067.53,1562.73 1075.92,1563.06 1075.96,1557.87" />
            <polygon class="st34" points="1078.42,1556.27 2491.93,1593.38 2496.01,1591.37 1085.45,1551.94" />
            <polygon class="st35" points="1078.42,1556.27 1075.96,1557.87 1075.89,1579.77 1078.42,1578.33" />
            <polygon class="st35" points="1085.52,1574.47 1067.39,1584.23 1067.39,1587.23 1085.52,1578.93" />
            <polygon class="st35" points="1085.48,1548.55 1067.53,1559.32 1067.53,1562.73 1085.45,1551.94" />
            <polygon class="st27" points="2283.02,1211.72 2277.72,1213.46 2213.43,1180.41 2219.37,1178.54" />
            <polygon class="st36" points="1078.42,1556.27 2495.77,1591.37 2493.22,1593.39 1085.45,1551.94" />
            <polygon class="st37" points="1222.3,1531.75 1222.43,1552.83 1217.19,1552.15 1217.07,1531.49" />
            <polygon class="st38" points="1217.07,1531.49 1215.55,1532.11 1216.03,1552.3 1217.19,1552.15" />
            <polygon class="st22" points="1085.48,1548.55 2496.05,1590.4 2496.01,1591.37 1085.45,1551.94" />
            <polygon class="st38" points="2223.84,1186.08 2217.53,1183.01 1220.25,1550.91 1220.14,1552.29" />
            <polygon class="st39" points="1221.59,1531.54 1250.91,1505.24 1248,1504.19 1217.07,1531.49" />
            <polygon class="st40" points="1217.07,1531.49 1215.12,1531.54 1247.83,1502.16 1248,1504.19" />
            <path
              class="st41"
              d="M2282.86,1155.44c-0.47-7.2-4.78-13.84-9.64-14.82c-4.86-0.99-8.42,4.05-7.96,11.25
              c0.47,7.2,4.78,13.84,9.64,14.82C2279.76,1167.67,2283.32,1162.64,2282.86,1155.44z" />
            <path
              class="st42"
              d="M1841.05,1382.51c0.36-2.13,1.85-3.99,3.33-4.15c1.48-0.16,2.4,1.44,2.04,3.57
              c-0.36,2.13-1.85,3.99-3.33,4.15C1841.6,1386.24,1840.69,1384.64,1841.05,1382.51z" />
            <polygon class="st18" points="1799.08,1374.09 1780.59,1368.14 1780.59,1416.72 1799.08,1419.86" />
            <path
              class="st42"
              d="M1842.79,1364.35c0.36-2.13,1.85-3.99,3.33-4.15s2.4,1.44,2.04,3.57c-0.36,2.13-1.85,3.99-3.33,4.15
              C1843.35,1368.08,1842.43,1366.48,1842.79,1364.35z" />
            <path
              class="st43"
              d="M1809.61,1380.3c2.62-9.07,9.11-16.81,14.51-17.28c5.4-0.47,7.65,6.51,5.04,15.58
              c-2.62,9.07-9.11,16.81-14.51,17.28C1809.25,1396.35,1807,1389.37,1809.61,1380.3z" />
            <polygon class="st44" points="1875.03,1343.09 1871.18,1384.4 1868.47,1383.97 1872.46,1343.99" />
            <polygon class="st45" points="2126.1,1131.32 2136.16,1136.17 1250.91,1505.24 1248,1504.19" />
            <g>
              <path
                class="st33"
                d="M1813.53,1493.18c1.39-11.59-1.91-24.14-7.42-28.24c-5.58-4.16-11.42,1.81-13.01,13.54
                c-1.62,11.93,1.79,25.03,7.57,29.04C1806.38,1511.48,1812.12,1504.96,1813.53,1493.18z" />
              <path
                class="st46"
                d="M1796.58,1464.32c-3.64,1.23-6.79,6.56-7.9,14.61c-1.65,11.96,1.77,25.11,7.58,29.14
                c0.77,0.54,1.55,0.88,2.32,1.05l4.5-0.74c-0.76-0.17-1.52-0.51-2.29-1.04c-5.75-4.01-9.14-17.06-7.55-28.94
                c1.08-7.99,4.16-13.28,7.76-14.5L1796.58,1464.32z" />
              <g>
                <path
                  class="st47"
                  d="M1804.55,1521.67c-1.77,0.68-2.15-4.05-3.69-3c-1.69,1.2-4.45,8.93-5.22,10.81l0.92,1.2
                  C1800.25,1523.17,1804.01,1525.42,1804.55,1521.67z" />
                <path
                  class="st47"
                  d="M1791.34,1533.08c2.84,1.05,0.46,6.53,2.46,8.71c2.76,3.08,5.3,3.6,9.52,3.6
                  c3.46,0,9.19-2.47,11.67-4.8c8.6-8.11,3.58-19.15-2.15-21.01c-2.76-0.9-5.53-6.3-6.45-10.21v-2.4l-0.61-5.4
                  c-1.92,0-3-0.15-4.92-0.3c-0.84,1.65-1.54,8.33-1.54,10.21c0,4.65,1.08,7.36,3.69,11.11c1.92,2.78,4.3,4.35,4.3,7.81
                  c0,3.38-4.22,7.2-7.68,7.2c-3.53,0-2-8.11-5.53-8.11c-1.08,0-1.84,0.68-2.46,1.5L1791.34,1533.08z" />
              </g>
              <path
                class="st48"
                d="M1793.7,1467.09c-1.94,2.56-3.5,6.55-4.19,11.63c-0.8,5.86-0.39,12.02,0.94,17.26l2.4-0.26
                c-1.36-5.26-1.48-11.37-0.68-17.26c0.7-5.15,2.23-9.18,4.2-11.73L1793.7,1467.09z" />
              <path
                class="st33"
                d="M1822.98,1493.04c1.3-11.42-1.97-23.79-7.34-27.83c-5.43-4.09-11.08,1.79-12.56,13.35
                c-1.51,11.75,1.86,24.66,7.49,28.61C1816.12,1511.06,1821.66,1504.64,1822.98,1493.04z" />
              <path
                class="st49"
                d="M1819.58,1490.32c0.67-5.74-0.98-11.98-3.69-13.99c-2.72-2.02-5.52,0.99-6.23,6.77
                c-0.72,5.82,0.95,12.2,3.72,14.18C1816.14,1499.25,1818.91,1496.11,1819.58,1490.32z" />
              <path
                class="st46"
                d="M1806.28,1464.57c-3.53,1.21-6.56,6.47-7.6,14.4c-1.54,11.79,1.84,24.74,7.51,28.72
                c0.75,0.53,1.51,0.87,2.25,1.03l4.37-0.72c-0.73-0.16-1.48-0.5-2.23-1.03c-5.6-3.95-8.97-16.82-7.47-28.52
                c1.01-7.87,3.98-13.09,7.47-14.29L1806.28,1464.57z" />
              <path
                class="st33"
                d="M1817.06,1489.36c0.48-4.03-0.68-8.42-2.59-9.82c-1.92-1.41-3.88,0.71-4.38,4.77
                c-0.5,4.08,0.67,8.53,2.61,9.92C1814.63,1495.6,1816.58,1493.41,1817.06,1489.36z" />
              <path
                class="st48"
                d="M1803.44,1467.91c-1.87,2.53-3.37,6.46-4.02,11.47c-0.75,5.78-0.32,11.85,0.99,17.02l2.32-0.26
                c-1.34-5.18-1.49-11.21-0.75-17.02c0.65-5.08,2.12-9.06,4.02-11.57L1803.44,1467.91z" />
              <g>
                <polyline class="st51" points="1812.61,1469.89 1812.48,1466.3 1811.54,1466.5 1810.83,1470.46" />
                <path
                  class="st51"
                  d="M1813.35,1466.28l-0.01,3.55c0,0,1.57,0.81,1.74-0.4c0.11-0.77-1.22-1.4-1.22-1.4
                  c0.5-0.1,1.31,0.1,1.29-0.81c0,0-0.36-0.71-0.99-0.82l-0.79-0.23" />
                <path class="st51" d="M1816.32,1467.58l-0.61,2.86c0,0.82,0.69,1.28,1.29,1.48l0.92-2.56" />
                <line class="st51" x1="1818.53" y1="1470.45" x2="1819.66" y2="1472.42" />
                <polyline
                  class="st51"
                  points="1818.55,1470.55 1818.05,1471.88 1819.16,1473.73 1818.54,1474.8 1817.49,1472.92" />
                <line class="st52" x1="1811.23" y1="1469.14" x2="1812.45" y2="1468.83" />
              </g>
            </g>
            <g>
              <polygon class="st53" points="1798.98,1419.13 1780.48,1416 1778.25,1416.71 1797.03,1419.88" />
              <path
                class="st54"
                d="M1778.99,1373.31l1.22,43.47c-4.84,0.39-8.66-0.34-11.45-3.78c-2.9-3.59-5.87-5.08-8.66-11.57
                c-2.17-5.05-5.98-10.54-7.51-19.55L1778.99,1373.31z" />
              <path
                class="st55"
                d="M1735.26,1397.42l40.49,6.73c0,0,0.94,1.61,0.84,2.71c-0.12,1.34-1.87,2.9-1.87,2.9l-40.97-6.45
                L1735.26,1397.42z" />
              <path
                class="st55"
                d="M1806.04,1353.33l40.12,7.04c0,0,0.92,1.86,0.75,3.08c-0.17,1.26-1.59,2.84-1.59,2.84l-41.9-6.85
                L1806.04,1353.33z" />
              <path
                class="st55"
                d="M1803.99,1372.11l40.49,6.73c0,0,0.94,1.61,0.84,2.71c-0.12,1.34-1.87,2.9-1.87,2.9l-40.96-6.45
                L1803.99,1372.11z" />
              <path
                class="st23"
                d="M1810.03,1324.02c0.89-5.33,4.62-9.98,8.33-10.38c3.71-0.4,5.99,3.6,5.1,8.93
                c-0.89,5.33-4.62,9.98-8.33,10.38C1811.42,1333.35,1809.13,1329.35,1810.03,1324.02z" />
              <path
                class="st56"
                d="M1805.11,1323.11c0.99-5.92,5.13-11.09,9.25-11.53c4.12-0.45,6.66,3.99,5.67,9.92
                c-0.99,5.92-5.13,11.09-9.25,11.53C1806.65,1333.48,1804.12,1329.04,1805.11,1323.11z" />
              <path
                class="st42"
                d="M1799.87,1354.82c0.36-2.13,1.85-3.99,3.33-4.15c1.48-0.16,2.4,1.44,2.04,3.57
                c-0.36,2.13-1.85,3.99-3.33,4.15C1800.43,1358.55,1799.52,1356.96,1799.87,1354.82z" />
              <path
                class="st42"
                d="M1799.24,1374.89c0.36-2.13,1.85-3.99,3.33-4.15c1.48-0.16,2.4,1.44,2.04,3.57
                c-0.36,2.13-1.85,3.99-3.33,4.15C1799.8,1378.62,1798.89,1377.02,1799.24,1374.89z" />
              <g>
                <path
                  class="st57"
                  d="M1714.26,1373.36l2.27,42.88c-4.84,0.39-8.66-0.34-11.45-3.78c-2.9-3.58-5.87-5.08-8.66-11.57
                  c-2.17-5.05-5.98-10.54-7.51-19.55L1714.26,1373.36z" />
                <g>
                  <path
                    class="st58"
                    d="M1705.76,1380.48l-2.56-0.68l3.36,29.28c0.4,2.85,1.42,5.11,4.11,6.13c0,0,3.58,1.45,5.85,1.02
                    l2.6-1.92l-10.33-4.76L1705.76,1380.48z" />
                  <path
                    class="st56"
                    d="M1719,1397.3l-7.79,0.32c0,0-2.82-0.68-2.7,1.72l1.09,8.15c0,0,0.56,5.62,4.99,6.06l4.37,0.45
                    L1719,1397.3z" />
                </g>
                <g>
                  <path
                    class="st58"
                    d="M1778.45,1377.41l-3.26,1.8l1.06,9.96c0.4,2.85,1.42,5.11,4.11,6.13c0,0,3.58,1.45,5.85,1.02l2.6-1.92
                    l-7.84-4.49L1778.45,1377.41z" />
                  <path
                    class="st59"
                    d="M1797.44,1381.41l-16.56-3.7c0,0-2.82-0.68-2.7,1.72l1.09,8.15c0,0,0.57,5.62,4.99,6.06l4.38,0.45
                    L1797.44,1381.41z" />
                </g>
                <path
                  class="st43"
                  d="M1806.6,1383.29c6.65-6.07,9.09-25.02,10.03-41.74c0.59-10.47-0.15-29.02-5.14-28.87l-2.64,0.05
                  l0.43,51.58l-21.58,27.7l2.94,0.88C1790.65,1392.88,1801.31,1388.14,1806.6,1383.29z" />
                <path
                  class="st55"
                  d="M1803.66,1382.41c6.65-6.07,9.09-25.02,10.03-41.74c0.59-10.47-0.05-28.48-5.04-28.33
                  c-2.53,0.07-16.17,7.49-16.17,7.49c3.37,4.83-4.77,72.17-4.77,72.17S1798.38,1387.26,1803.66,1382.41z" />
                <path
                  class="st60"
                  d="M1691.35,1372.67l15.74,2.55l-14.44,7.64c-9.62,1.74-17.32-3.79-17.32-3.79l14.98-7.71L1691.35,1372.67
                  z" />
                <path
                  class="st61"
                  d="M1674.16,1347.37l14.87-6.84l-0.87,18.64l3.2,13.51l-14.72,6.92c-4.25,2-4.74-9.61-4.74-15.24
                  C1671.9,1359.3,1671.14,1348.75,1674.16,1347.37z" />
                <path
                  class="st58"
                  d="M1690.63,1338.13l-3.26,1.8l0.02,30.73c-0.44,2.6,1.42,5.11,4.11,6.13l18.8,3.53l1.42-0.81
                  l-18.51-29.26L1690.63,1338.13z" />
                <polygon class="st62" points="1759.75,1354.46 1728.6,1389.06 1690.53,1372.26 1749.3,1350.73" />
                <polygon class="st63" points="1749.82,1315.51 1690.42,1339.77 1690.53,1372.26 1749.3,1350.73" />
                <path
                  class="st58"
                  d="M1751.28,1313.61l-3.26,1.8l-0.03,31.1c0.14,2.88,1.42,5.11,4.11,6.13c0,0,2.46,0.83,4.04,0.61
                  l-0.43-2.08l-1.91-25.07L1751.28,1313.61z" />
                <path
                  class="st56"
                  d="M1782.34,1321.63l-28.63-7.72c0,0-2.82-0.68-2.7,1.72v29.29c0,0-0.12,6.01,4.31,6.45l17.13,4.08
                  L1782.34,1321.63z" />
                <line class="st64" x1="1694.24" y1="1341.19" x2="1746.49" y2="1319.23" />
                <line class="st64" x1="1674.3" y1="1348.88" x2="1686.17" y2="1343.85" />
                <g>
                  <path
                    class="st60"
                    d="M1747.74,1394.35l16.83,2.73l-28.67,9.71c-14.38,3.42-16.12-5.03-16.12-5.03l26.85-8.81
                    L1747.74,1394.35z" />
                  <path
                    class="st65"
                    d="M1721,1375.89l23.66-7.93l-0.34,11.95l3.42,14.45l-24,8c-4.77,1.59-5.81-5.01-5.81-11.03
                    C1717.92,1385.92,1717.64,1377.02,1721,1375.89z" />
                  <path
                    class="st61"
                    d="M1765.09,1367.78c-2.52-2.1-4.46-4.41-8.14-5.31c-4.14-1.01-7.12,0.31-10.82,1.58
                    c-2.79,0.96-3.1,8.67-3.12,14.43c-0.02,6.36,0.72,13.67,3.69,15.87c2.29,1.7,5.09,2.58,8.63,3.12
                    c3.64,0.56,7.84,0.53,9.43-0.46c1.51-0.94,3.72-8.47,3.86-14.04C1768.77,1376.27,1766.87,1369.26,1765.09,1367.78z" />
                  <path
                    class="st66"
                    d="M1722.74,1405.88l13.25,1.82l-9.84,2.56c-11.29,2.42-15.35-3.07-15.35-3.07l10.82-2.71
                    L1722.74,1405.88z" />
                  <path
                    class="st61"
                    d="M1711.57,1378.59l9.66-3.72c0,0-3.26,10.28-2.8,16.97c0.36,5.31,3.38,13.22,3.38,13.22l-7.81,3.07
                    c-3.01,1.18-7.81-9.35-7.15-15.32C1707.42,1387.43,1708.26,1379.87,1711.57,1378.59z" />
                  <line class="st67" x1="1746.31" y1="1394.64" x2="1721.69" y2="1402.72" />
                </g>
                <g>
                  <polygon
                    class="st62"
                    points="1748.53,1393.41 1764.75,1397.01 1772.93,1393.93 1786.23,1386.83 1779.24,1384.17
                    " />
                  <polygon class="st65" points="1786.23,1386.83 1790.21,1384.21 1792.66,1356.43 1785.47,1353.7" />
                  <path
                    class="st65"
                    d="M1793.6,1321.93c0,0-5.62-5.64-10.04-7.98c-4.88-2.58-13.59-3.95-13.59-3.95l0.38,39.36l5.47,4.71
                    l3.42,30.09l6.99,2.66l2.66-29.33h5.09L1793.6,1321.93z" />
                  <polygon
                    class="st65"
                    points="1769.97,1310 1760.32,1310.38 1753.86,1313.57 1752.87,1349.2 1752.57,1359.78
                    1754.75,1363.2 1756.22,1390.89 1761.06,1389.71 1779.24,1384.17 1775.82,1354.08 1770.35,1349.37" />
                  <polygon class="st65" points="1754.12,1363.31 1746.61,1366.56 1747.23,1393.3 1755.38,1390.89" />
                </g>
              </g>
            </g>
            <line class="st68" x1="1810.26" y1="1394.38" x2="1806.11" y2="1464.94" />
            <line class="st68" x1="1828.55" y1="1386.32" x2="1820.81" y2="1471.6" />
            <line class="st68" x1="1785" y1="1396.9" x2="1801.28" y2="1484.75" />
            <line class="st68" x1="1768.57" y1="1396.71" x2="1789.99" y2="1485.37" />
            <path
              class="st69"
              d="M1710.74,1387.96c-1.5,0.56-3.28,1.23-4.14,0.5c-1.2-1-0.44-9.63-2.1-9.5
              c-1.98,0.15-10.84,3.75-12.63,4.73c-3.41,1.86-3.51,0.18-3.51,0.18" />
            <path
              class="st69"
              d="M1710.21,1389.49c-1.5,0.56-3.64,1.11-4.5,0.38c-1.2-1-0.44-9.63-2.1-9.5
              c-1.98,0.15-10.95,4.4-12.73,5.41c-2.02,1.15-3.59-1.8-3.59-1.8" />
          </g>
          <polygon
            style="clip-path: url(#SVGID_00000092447052950965551130000005019845499148406706_); fill: #ffffff"
            points="
            1570.89,1191.79 1481.87,1247.64 1519.4,1151.64" />
          <g style="clip-path: url(#SVGID_00000092447052950965551130000005019845499148406706_)">
            <g>
              <polygon class="st70" points="1435.29,2924.64 1430.47,2731.6 1499.2,2722.61 1504.58,2909.55" />
              <g>
                <path
                  class="st71"
                  d="M1434.48,2910.03c0,0-5.2,46.58,2.66,83.71l207.65,3.75c0,0,13.52-39.59-33.23-50.4
                  c-46.75-10.8-106.5-47.63-106.5-47.63S1462.49,2934.72,1434.48,2910.03z" />
                <path
                  class="st72"
                  d="M1437.09,2993.74l207.65,3.75c0,0,3.3-9.73,0.96-20.85l-211.58-3.74
                  C1434.12,2980,1435.66,2987.01,1437.09,2993.74z" />
                <path
                  class="st73"
                  d="M1497.78,2946.33c-1.35,0-2.64-0.34-3.73-0.98c-2.3-1.32-3.81-3.98-4.01-7.12
                  c-0.17-2.66,0.53-5.18,1.27-7.54c1.87-5.83,4.21-11.43,7-16.68c1.83-3.48,3.99-7.09,7.28-9.73c4.71-3.78,10.93-4.9,16.4-5.5
                  c4.63-0.51,9.42-0.78,14.25-0.78h0.44c6.23,0.03,13.37,0.59,18.98,4.59c2.53,1.79,3.7,4.04,3.2,6.17
                  c-0.36,1.6-1.65,2.94-3.72,3.86c-1.99,0.87-4.23,1.31-6.84,1.31c-2.6,0-5.22-0.44-7.48-0.82
                  c-10.24-1.79-20.44-4.01-30.37-6.65c1.06,5.27,1.49,10.65,1.29,16c-0.26,6.89-1.6,12.4-4.09,16.85
                  c-1.52,2.74-3.26,4.63-5.33,5.81C1500.93,2945.89,1499.32,2946.33,1497.78,2946.33z M1507.6,2906.15
                  c-0.11,0.08-0.2,0.17-0.31,0.25c-2.91,2.33-4.88,5.66-6.59,8.89c-2.7,5.1-4.99,10.55-6.81,16.23
                  c-0.65,2.1-1.29,4.35-1.15,6.53c0.11,1.73,0.87,3.93,2.66,4.96c1.52,0.89,3.81,0.73,5.58-0.26c1.62-0.92,3.03-2.49,4.31-4.77
                  c2.27-4.07,3.5-9.19,3.75-15.62C1509.22,2916.92,1508.75,2911.48,1507.6,2906.15z M1511.5,2903.92
                  c9.73,2.56,19.74,4.76,29.78,6.5c2.15,0.37,4.63,0.78,7.01,0.78c2.22,0,4.1-0.34,5.75-1.07c0.72-0.31,1.94-0.98,2.16-1.97
                  c0.26-1.17-0.92-2.5-2.13-3.36c-5.01-3.54-11.61-4.06-17.41-4.09h-0.44c-4.73,0-9.42,0.26-13.96,0.78
                  C1518.75,2901.86,1514.91,2902.48,1511.5,2903.92z" />
              </g>
              <path
                class="st73"
                d="M1645.7,2977.99c0,0-0.02,0-0.03,0l-210.6-3.81c-0.75-0.02-1.34-0.62-1.32-1.38
                c0.02-0.73,0.61-1.32,1.35-1.32h0.03l210.6,3.81c0.75,0.02,1.34,0.62,1.32,1.38
                C1647.04,2977.4,1646.45,2977.99,1645.7,2977.99z" />
              <path
                class="st73"
                d="M1492.94,2974.89c-0.67,0-1.24-0.48-1.34-1.15c-6.53-45.13-57.07-43.43-57.55-43.56H1434
                c-0.73,0-1.32-0.56-1.35-1.29c-0.03-0.76,0.54-1.38,1.29-1.41c0.05,0,0.33-0.02,0.82-0.02c6.73,0,53.12,1.65,59.52,45.87
                c0.11,0.75-0.4,1.45-1.13,1.54C1493.07,2974.89,1493.01,2974.89,1492.94,2974.89z" />
              <path
                class="st73"
                d="M1518.39,2975.57c-0.48,0-0.93-0.25-1.18-0.7c-0.37-0.65-0.14-1.48,0.53-1.83l0.84-0.47
                c18.93-10.68,40.46-16.37,62.24-16.46l52.59-0.2l0,0c0.75,0,1.35,0.61,1.35,1.35s-0.59,1.35-1.35,1.35l-52.59,0.2
                c-21.31,0.09-42.38,5.67-60.92,16.12l-0.85,0.47C1518.84,2975.52,1518.61,2975.57,1518.39,2975.57z" />
              <path
                class="st73"
                d="M1562.17,2949.44c-0.2,0-0.4-0.05-0.59-0.14l-73.29-35.96c-0.68-0.33-0.96-1.13-0.62-1.8
                c0.31-0.68,1.13-0.96,1.8-0.62l72.28,35.44l9.5-13c0.45-0.61,1.26-0.75,1.9-0.28c0.59,0.44,0.73,1.29,0.28,1.9l-10.17,13.91
                C1563,2949.25,1562.59,2949.44,1562.17,2949.44z" />
            </g>
            <g>
              <polygon class="st70" points="1109.53,2934.4 1134.89,2705.49 1206.81,2740.16 1178.8,2919.31" />
              <g>
                <path
                  class="st71"
                  d="M1108.72,2919.8c0,0-5.2,46.56,2.66,83.67l207.56,3.75c0,0,13.51-39.58-33.22-50.37
                  c-46.73-10.8-106.45-47.61-106.45-47.61S1136.72,2944.48,1108.72,2919.8z" />
                <path
                  class="st72"
                  d="M1111.33,3003.47l207.56,3.75c0,0,3.3-9.72,0.96-20.84l-211.49-3.73
                  C1108.36,2989.73,1109.9,2996.75,1111.33,3003.47z" />
                <path
                  class="st73"
                  d="M1171.99,2956.08c-1.35,0-2.63-0.33-3.72-0.95c-2.32-1.35-3.81-4.03-4.01-7.14
                  c-0.19-2.69,0.56-5.3,1.27-7.55c1.82-5.74,4.17-11.35,6.98-16.66c1.83-3.48,4-7.09,7.28-9.73c4.66-3.75,10.62-4.85,16.37-5.5
                  c4.69-0.51,9.39-0.76,13.96-0.76h0.78c6.2,0.03,13.32,0.59,18.95,4.57c2.52,1.79,3.68,4.03,3.2,6.16
                  c-0.37,1.63-1.71,3-3.73,3.89c-1.97,0.87-4.21,1.29-6.82,1.29c-2.58,0-5.21-0.42-7.48-0.81c-10.12-1.76-20.32-4-30.36-6.65
                  c1.06,5.27,1.49,10.63,1.29,15.98c-0.26,6.89-1.6,12.41-4.09,16.85c-1.54,2.75-3.3,4.65-5.33,5.8
                  C1175.14,2955.64,1173.53,2956.08,1171.99,2956.08z M1181.81,2915.91c-0.11,0.08-0.22,0.17-0.31,0.25
                  c-2.91,2.33-4.88,5.66-6.59,8.89c-2.72,5.16-5.01,10.62-6.79,16.21c-0.67,2.1-1.31,4.34-1.15,6.54
                  c0.14,2.19,1.17,4.09,2.66,4.97c1.51,0.82,3.75,0.73,5.57-0.3c1.6-0.9,3.02-2.46,4.31-4.76c2.27-4.07,3.5-9.19,3.75-15.62
                  C1183.43,2926.68,1182.96,2921.24,1181.81,2915.91z M1185.68,2913.69c9.86,2.6,19.87,4.79,29.8,6.51
                  c2.15,0.37,4.65,0.76,7.01,0.76c2.22,0,4.1-0.34,5.74-1.06c0.58-0.25,1.93-0.95,2.18-2.01c0.26-1.15-0.93-2.49-2.13-3.34
                  c-5.01-3.53-11.61-4.04-17.39-4.07h-0.76c-4.48,0-9.06,0.25-13.66,0.76C1192.77,2911.65,1189.01,2912.27,1185.68,2913.69z" />
              </g>
              <path
                class="st73"
                d="M1319.85,2987.73c-0.02,0-0.02,0-0.02,0l-210.53-3.79c-0.75-0.02-1.34-0.62-1.32-1.38
                c0.02-0.75,0.65-1.48,1.38-1.32l210.51,3.79c0.75,0.02,1.34,0.62,1.32,1.38C1321.19,2987.14,1320.58,2987.73,1319.85,2987.73z
                " />
              <path
                class="st73"
                d="M1167.17,2984.63c-0.67,0-1.24-0.48-1.34-1.15c-6.03-41.55-48.13-43.57-56.26-43.57
                c-0.75,0-1.26-0.03-1.34,0.02c-0.73,0-1.32-0.57-1.35-1.31c-0.03-0.75,0.54-1.37,1.29-1.4c0.64,0.05,53.46-1.57,60.33,45.86
                c0.11,0.75-0.4,1.43-1.13,1.54C1167.29,2984.63,1167.23,2984.63,1167.17,2984.63z" />
              <path
                class="st73"
                d="M1192.6,2985.3c-0.48,0-0.93-0.25-1.18-0.7c-0.37-0.65-0.14-1.48,0.53-1.83l0.84-0.47
                c18.92-10.66,40.45-16.35,62.21-16.45l52.57-0.2l0,0c0.75,0,1.35,0.61,1.35,1.35s-0.59,1.35-1.35,1.35l-52.57,0.2
                c-21.3,0.09-42.36,5.66-60.89,16.1l-0.85,0.47C1193.05,2985.25,1192.82,2985.3,1192.6,2985.3z" />
              <path
                class="st73"
                d="M1236.34,2959.2c-0.2,0-0.4-0.05-0.59-0.14l-73.25-35.96c-0.68-0.33-0.96-1.13-0.62-1.8
                c0.31-0.68,1.15-0.95,1.8-0.62l72.24,35.44l9.51-13c0.44-0.59,1.29-0.73,1.9-0.28c0.59,0.44,0.73,1.29,0.28,1.9l-10.18,13.91
                C1237.18,2959.01,1236.76,2959.2,1236.34,2959.2z" />
            </g>
            <path
              class="st70"
              d="M1738.1,1471.85c0,0,0.57-54.7,1.13-63.76c0.51-8.18-1.9-30.66-5.99-48.28c-0.91-3.92,2.5-7.55,6.45-6.78
              c2.98,0.58,7.74,3.8,10.1,9.22c5.66,13.02,9.62,54.51,16.41,51.12c6.79-3.4,37.54-33.01,37.54-33.01l45.55,47.96
              c0,0-37.63,53.9-69.7,61.63l-25.65,59.04l-47.53-27.73L1738.1,1471.85z" />
            <path
              class="st70"
              d="M1501.56,1594.84c0,0,62.25,161.84,124.5,150.53c62.25-11.32,148.26-247.86,148.26-247.86l-36.22-25.65
              l-108.65,147.89l-59.98-95.07L1501.56,1594.84z" />
            <path
              class="st74"
              d="M1608.5,1654.81c0,0-0.12,0-0.19-0.02c-0.3-0.11-0.45-0.42-0.36-0.73c5.64-16.88,20.91-34.53,21.06-34.7
              c0.22-0.22,0.58-0.25,0.79-0.05c0.23,0.2,0.26,0.56,0.05,0.79c-0.14,0.17-15.27,17.64-20.81,34.32
              C1608.96,1654.67,1608.74,1654.81,1608.5,1654.81z" />
            <path
              class="st70"
              d="M1183.53,1566.92l-60.36,179.58l-3.02,256.54l-48.29,12.07c0,0-40.74-232.39-34.71-282.19
              c6.04-49.8,63.38-235.41,63.38-235.41L1183.53,1566.92z" />
            <path
              class="st0"
              d="M1390.39,1388.84c0,0,136.45,38.49,148.52,52.07c12.07,13.58,75.45,152.41,75.45,152.41
              s-25.65,35.46-96.58,51.31l-6.79,180.33h-300.3l-34.71-172.03l-15.09,58.85c0,0-83,7.55-137.32-40.74
              c0,0,64.89-203.72,102.61-235.41c37.73-31.69,156.94-52.82,156.94-52.82L1390.39,1388.84z" />
            <path
              class="st75"
              d="M1194.09,1778.19l30.18-6.04l8.3-226.36l254.27-1.51l33.2,218.81l15.09,199.19v903.91l-111.67,3.02
              c0,0-39.99-749.99-64.13-753.01c-24.14-3.02-100.35,519.11-139.59,745.46c0,0-4.53,22.64-113.18-1.51
              c0,0,33.2-404.42,36.22-470.82C1145.8,2322.95,1194.09,1778.19,1194.09,1778.19z" />
            <path
              class="st70"
              d="M1393.49,1283.79l2.72,98.74c0.11,4.12,2.69,7.76,6.54,9.23l6.95,2.66c0,0-41.59,53.95-136.66-10.19l0,0
              c12.97-2.83,22.34-14.12,22.72-27.39l3.04-106.16L1393.49,1283.79z" />
            <path class="st76" d="M1395.5,1354.9c0,0-77.58-0.96-96.91-81.11l95.89,33.76L1395.5,1354.9z" />
            <g>
              <path
                class="st77"
                d="M1425.08,1208.55c0,0,10.94-11.93,18.83-5.96c8.34,6.32,10.36,31.49-13.47,37.75
                C1430.44,1240.35,1423.74,1228.08,1425.08,1208.55z" />
              <image
                xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAvYAAAOyCAYAAAAYX5HuAAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
                  GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAGeBJREFUeNrs3d114tgWhVHjLJye
                  M3AkZEB6hMEd/TP6VpUxIDiS9l5nzsd+6sK2+LR8hN/eAAAAAAAAAAAAAACAvxy8BAC/O5+Olxn+
                  nR+fX94DAIQ9gGCfkZsBAGEPINrdBAAg7AHhLtyFPwDCHhDtiH4AYQ8g2hH9AMIeEPEg+AGEPSDi
                  QewDCHtAyIPYB4Q9IOJB7AMIe0DIg9gHEPaAiAehDyDsASEPQh8Q9oCIB4Q+IOwBIQ8iH0DYA0Ie
                  hD6AsAcxDwh9QNgDYh4Q+YCwB8Q8IPQBYQ9iHkDkA8IexDwg8gFhD4h5QOADwh4Q9IDIB4Q9iHkA
                  kQ8IexDzACIfhD0g5gGRDwh7EPMAIh8Q9iDmAQQ+IOxBzAOIfEDYg5gHEPgg7EHQAwh8QNiDoAcQ
                  +YCwB0EPIPABYY+gB0Dgg7AHMQ8g8gFhD4IeQOADwh4EPYDAB4Q9gh4AgQ/CHgQ9ACIfhD0IegCB
                  Dwh7BL2gBxD3gLBH0AMg8EHYg6AHQOCDsAdBD4DAB2GPoAdA4APCHlEPgLgHYQ+CHgCBD8IeBD0A
                  Ah+EPYIeAIEPCHsEPQDiHoQ9iHoABD4IewS9oAdA4IOwR9QDgMAHYY+gB0Dgg7AHQQ+AuAdhj6gH
                  AIEPwh5BDwACH4Q9oh4AxD3CHgQ9AAIfhD2CHgDEPQh7RD0AiHsQ9oh6ABD4CHsQ9ACIexD2iHoA
                  EPgg7BH0ACDuQdgj6gFA3CPsEfWiHgAEPsIeQQ8A4h6EPaIeAMQ9CHtEPQAIfIQ9gh4AEPcIe0Q9
                  AAh8EPaIegAQ9yDsEfQAIO5B2It6AEDgI+wR9QCAuEfYI+oBQNyDsEfUA4DAB2Ev6AEAcY+wR9QD
                  AOIeYY+oBwBxD8IeUQ8A4h6EvagHAMQ9wh5BDwCIe4Q9oh4AxD0Ie1EPAIh7EPaiHgAQ9wh7RD0A
                  IO4R9oh6AEDgI+xFPQAg7kHYi3oAQNwj7BH1AIC4R9gj6gEAcY+wF/UAgLgHYS/qAQBxj7BH1AMA
                  4h5hj6gHAMQ9wl7UAwCIe4S9qAcAxD3CHkEPAAh8Onn3Eoh6AACEPQAAGzEUcotf5/ghBACacSQH
                  YS/qAQBxj7BH1AMA4h5hL+oBAMQ9wl7UAwCIe37mU3FEPQAAAdzdiXoAIIDVHt8Aoh4AEPcIe0Q9
                  ACDuqcAZewCAIAbHebmj88MDAASy3At7RD0AIO4R9qIeAEDcswdn7EU9AAAB3MGJegAgnNVe2CPq
                  AQBxTxOO4gAATMBAmc+dmx8GAGAilnthL+oBAMQ9wl7UAwCIe4S9qAcAEPfc5OFZAAAI4C7tB9Z6
                  AGAGVnthL+oBAMQ9wl7UAwCIe0Zyxh4AAAK4M/uFtR4AmJnVXtiLegAAcY+wF/UAAOKeV01/xl7U
                  AwAg7AEAiGP47OngmxYAgGscyell2sVe1AMAIOwBAIhnCO3l4JsUAIBbHMnpYbrFXtQDACDsAQCY
                  jmG0h4NvSgAAHuFIjrAX9QAA4p6VOYoDAAABprjjstYDAIxjtRf2oh4AQNyzEkdxAAAgQPSdlrUe
                  AGA9VnthL+oBAMQ9gzmKAwAAASLvsKz1AADbsdoLe1EPACDuGcRRHAAAXmZcFfa+oQAAYICYX5mI
                  egCA/TmSsx9HcQAAGMbYKux9AwEAwAva/6pE1AMA1ONIzvYcxQEAgACt76Ss9QAAdVntt2WxBwCA
                  AG3voqz1AAD1We23Y7EHAGA1xlhh7xsEAADSwx4AgD6MssLeNwYAADyo1cMMoh4AoC8P0q7LURwA
                  AAjQ5q7JWg8A0J/Vfj0WewAANmOsnTzsfQMAAEBA2AMAkMNoO2nY+8IDAMB9pR9eEPUAALk8SDuW
                  ozgAABCg7F2StR4AIJ/VfhyLPQAABCh5h2StBwCYh9V+DIs9AAC7MuoKewAA4F/lfu3hjg0AYE6O
                  5LzGYg8AAAFK3RVZ6wEA5ma1f57FHgAAApS5I7LWAwDwF6v9cyz2AAAQoMTdkLUeAIBfWe2Xs9gD
                  AICwBwCA8ZzoaBj2vmgAABAQ9gAAcI0BuFHY+2IBAEBA2AMAwC2G4AZh74sEAAABYQ8AAI8wCBcO
                  e18cAAAICHsAAFjCMFww7H1RAAAgIOwBAABhDwDApJz8KBT2vhgAABAQ9gAA8ApDcYGw90UAAICA
                  sAcAgBEMxjuGvRcfAAACwh4AAEYyHO8Q9l50AAAICHsAAFiDAVnYAwBAnIO7KAAAOvv4/Dp4FSz2
                  AAAg7G+x1gMAQEDYAwDAFgzKwh4AAIQ9AABUYbVfKey9sAAAEBD2AACwtdnHZWEPAADC3p0SAABE
                  hj0AAOxl5pFZ2AMAQICDOyQAANJ8fH4dZvs3W+wBAEDYAwAAFQz7FYVjOAAAVDLbcRyLPQAACHsA
                  AEDYAwDASmY7Kv7uRQMAAGEPAABlzTRAC3sAABD2juEAAEBE2AMAQGWzDNHCHgAAZg97x3AAACAg
                  7AEAoIMZBmlhDwAAAQ7uegAAmMXH59ch9d9msQcAAGEPAABU8NSvIhzDAQCgq9TjOBZ7AAAQ9gAA
                  gLAHAICNpR4rf/dCAADAhGEPAADdJY7Vwh4AAIQ9AABQwaLP8HS+HgCAJEmfaW+xBwAAYQ8AAAh7
                  AADYUdJRc2EPAADCHgAAaBX2PhEHAAACwh4AABKlDNjCHgAAhD0AACDsAQCggITjOO+z/EMBACCZ
                  xR4AAIQ9AAAg7AEAoIjux8+FPQAABLgb9h6cBQCAgLAHAIBZdB61hT0AAAQQ9gAAIOwBACBL1+M4
                  74n/KAAAmI3FHgAAhD0AACDsAQCgoI5H0oU9AAAEeE+6SwEAAGEPAAD8p9vQLewBACCAsAcAAGEP
                  AAC5Oh3HEfYAABDgvfudCQAAYLEHAABhDwAA6bqcZhH2AAAQQNgDAICwBwCAfB2O4wh7AAAIIOwB
                  AEDYAwAAwh4AAJqofs5e2AMAQABhDwAAAQ5//ocuf1kLAAD28PH5daj4/2WxBwCAAMIeAACEPQAA
                  zKXq0XVhDwAAAYQ9AAAIewAAQNgDAEBDFc/ZC3sAAAgg7AEAQNgDAMCcqh3HEfYAABDgvfJdBwAA
                  8ETYAwAAwh4AAKZS6cSLsAcAgADCHgAAhD0AAMytynEcYQ8AAAGEPQAACHsAAEDYAwAAwh4AACqo
                  8ACtsAcAgADCHgAAhD0AACDsAQAgxN7n7IU9AAAEEPYAACDsAQAAYQ8AAAh7AACoZM8HaIU9AAAE
                  EPYAACDsAQAAYQ8AAAh7AACoZq8HaIU9AAAEEPYAACDsAQAAYQ8AAIH2OGcv7AEAIICwBwAAYQ8A
                  AAh7AABA2AMAQFVbP0Ar7AEAIICwBwAAYQ8AAAh7AAAItuU5e2EPAAABhD0AAAh7AABA2AMAAMIe
                  AACq2+oBWmEPAAABhD0AAAh7AABA2AMAAMIeAAA62OIBWmEPAAABhD0AAKSF/cfn18FLAgAAzcMe
                  AABYx9rn7IU9AAAEEPYAACDsAQAAYQ8AAAh7AADoZM0HaIU9AAAEEPYAACDsAQAAYQ8AAJNZ65z9
                  t7D/+Pw6eLkBAKAXiz0AAAh7AABA2AMAAMIeAAA6WuMBWmEPAAABhD0AAAh7AABA2AMAAMIeAAC6
                  Gv0A7dWw99dnAQCgF4s9AAAIewAAQNgDAADCHgAAEPYAACDsAQCA14z8yEthDwAAAYQ9AAAkh70/
                  UgUAAAFhDwAACHsAAEDYAwAAwh4AAJoY9ZGXwh4AAALcDHufjAMAAAFhDwAACHsAAEDYAwAAwh4A
                  AIQ9AACwlREfeXk37H0yDgAA1GexBwAAYQ8AAAh7AABA2AMAAMIeAADKePWTcR4Ke5+MAwAAtVns
                  AQBA2AMAAMIeAAAQ9gAAwMKw9wAtAAAEhD0AALCuVz7yUtgDAEAAYQ8AAMIeAAAQ9gAAwPZh75Nx
                  AAAgIOwBAABhDwAA3PHsR14KewAACCDsAQBgxrD3AC0AAASEPQAAIOwBAABhDwAAXPP0eflnP4YH
                  AAC4b+mzrRZ7AAAIIOwBAEDYAwAArcPe59kDAEBA2AMAAMIeAAC4YemnUAp7AAAI8FLYO2cPAAAB
                  YQ8AAAh7AABA2AMAAMPC3jl7AAAICHsAAGAdSz7yUtgDAEAAYQ8AAMIeAACICXsP0AIAQEDYAwAA
                  wh4AABD2AADAsLB3zh4AAALCHgAAGO/RP1Il7AEAIICwBwAAYf875+wBACAg7AEAAGEPAABUCXvH
                  cQAAICDsAQAAYQ8AAFQJe8dxAAAgIOwBAABhDwAAVAl7x3EAACAg7AEAgDHOp+NF2AMAwARWDXvH
                  cQAAICDsAQAAYQ8AAFQJe8dxAAAgIOwBAABhDwAAVAl7x3EAACAg7AEAAGEPAABUCXvHcQAAICDs
                  AQCA551Px4uwBwCAcJuGveM4AAAQEPYAAICwBwAAqoS94zgAABAQ9gAAgLAHAACqhL3jOAAAEBD2
                  AACAsAcAAKqEveM4AAAQEPYAAMAy59PxIuwBACDYrmHvOA4AAASEPQAAIOwBAIAqYe84DgAABIQ9
                  AAAg7AEAgCph7zgOAAAEhD0AABAS9lZ7AAAICHsAAOB55Vby8+l48WUBAICfXTvtYrEHAIAA5cLe
                  WXsAAAgIewAAQNgDAICwr8JxHAAACAh7AAAgJOyt9gAAEBD2AABASNhb7QEAICDsAQCAkLC32gMA
                  QEDYAwAAvzufjhdhDwAAgVqEveM4AAAQEPYAAEBI2FvtAQAgIOwBAICQsLfaAwBAQNgDAAAhYW+1
                  BwCAgLAHAACEPQAACPsqHMcBAICAsAcAAELC3moPAAABYQ8AAISEvdUeAAACwh4AAAgJe6s9AABY
                  7AEAQNgDAADCfhjHcQAAEPYhxD0AAMIeAAAQ9lVY7QEAEPYAAICwr8JqDwCAsAcAAIR9FVZ7AACE
                  PQAAIOyrsNoDACDsAQAAYV+F1R4AAGEv7gEAQNgDAADCfiirPQAAwh4AABD2VVjtAQAQ9gAAgLCv
                  wmoPAICwBwAAhH0VVnsAAIQ9AAAg7Kuw2gMAIOzFPQAACHsAAEDYD2W1BwBA2AMAAMK+Cqs9AADC
                  HgAAEPZVWO0BABD2AADA5s6n40XYX2G1BwCgM2Ev7gEAEPYAAICwL8hqDwCAsAcAAIR9FVZ7AACE
                  vbgHAABhDwAACPuhrPYAAAh7cQ8AAMIeAAAQ9kNZ7QEAEPbiHgAAhD0AACDsh7LaAwAg7MU9AAAI
                  ewAAQNgPZbUHAEDYi3sAABD2AACAsB/Kag8AgLAHAACEfRVWewAAhL24BwAAYQ8AAAj7oaz2AAAI
                  e3EPAADCHgAAZvPnqCzsV36BAQBgC8Je3AMAIOwBAABhH8xqDwCAsBf3AAAg7MU9AADCHgAAEPZ8
                  Z7UHAEDYi3sAABD24h4AAGEPAAAIe76z2gMAIOzFPQAACHtxDwCAsAcAAIQ931ntAQAQ9uIeAACE
                  PQAACHuGstoDACDsxT0AALyJyWLOp+PFqwAAwC3XRmGLPQAABBD2De6+AABA2It7AACEPeIeAABh
                  j7gHAEDYAwAAwj6C1R4AAGEv7gEAEPaIewAAhD3iHgAAYY+4BwBA2AMAgLCnNqs9AADCXtwDACDs
                  EfcAAAh7xD0AAMIeAAAQ9rGs9gAACHtxDwBAADEY5nw6XrwKAAC5fhp0LfaTfKEBAMgm7MU9AADC
                  HnEPAICwR9wDACDsEfcAAAh7cQ8AgLAHAACEPRuz2gMACHvEPQAAwh5xDwCAsEfcAwAg7BH3AADC
                  HnEPAICwBwAAhD0bsNoDAAh7xD0AAMIeAAAQ9gxltQcAEPaIewAAhD0AAPCIW0OssOfhbxYAAOoS
                  9oh7AABhj7gHAEDYAwAAwp51We0BAIQ94h4AAGEPAAAsYY3lIefT8eJVAADYz72TFBZ7hnwjAQCw
                  L2EPAADCnplY7QEAhD3iHgAAYQ8AAAh7hrLaAwAIewAAQNhThdUeAKAWccZL/OEqAID1PTKqWuwB
                  ACCAsGf1u0cAAIQ94h4AAGEPAADCHh5mtQcAEPaIewAAhD0AAGBhZTifbQ8AMM6jpyIs9uz2zQcA
                  wDjCHgAAhD1cZ7UHABD2AACAsKcKqz0AgLBH3AMAIOwBAEDYw1BWewCA9QkuNuMPVwEALLNkILXY
                  AwBAAGFPyTtOAACEPeIeAEDYAwAAwh7ustoDAAh7AABA2FOF1R4AYCxxxa58tj0AwHVLh1CLPQAA
                  BBD2tLoTBQBA2AMAgLCHtVjtAQCEPeIeAABhDwAAwh6GstoDADxPSFGOz7YHAGb3zOBpsQcAgADC
                  nog7VAAAYQ8AAAh7WIPVHgBA2CPuAQCEPQAAIOxhKKs9AICwR9wDAAh7AABge8+OmsKe6G9wAIBZ
                  CHsAABD2sB2rPQCAsEfcAwAIewAAQNjDUFZ7AIDvBBJtnU/Hi1cBAEjyyoBpsQcAgADCninvaAEA
                  hD0AACDsYSSrPQDAP0QRETxICwB09+pgabEHAIAAwh53uAAAwh7EPQCAsAcAAIawcBLHg7QAQDcj
                  Th5Y7AEAIICwxx0vAICwB3EPACDsAQCAIayaRPMgLQBQ3aiTBhZ7/KAAAAQQ9gAAIOyhPqs9ACDs
                  AQCAFiyZTMODtABANSNPFljs8YMDABBA2AMAgLCHXqz2AICwB3EPACDsAQCA/xs9Ngp7/CABAAQQ
                  9oh7AABhDwAACHvYmdUeAEghauDNX6UFALa1xrhosQcAgADCHt4cyQEAhD2IewAAYQ8AAIxgoYQ/
                  eJAWAFjTWqcELPaw0Q8bAMCahD0AAAh7yGS1BwCEPYh7AABhDwAALGeRhDt8Sg4AMMqaJwIs9rDj
                  DyAAgLAHAACEPSxhtQcAhD2IewAAYQ8AAAnWHgmFPRT6gQQAEPYg7gEAYQ8AAAh7mIzVHgCoRpzA
                  C/xVWgDgEVuMghZ7AAAIIOyh+N03AICwB3EPAAh7QNwDAMIeAADYbAAU9tDshxYAQNiDuAcAhD0g
                  7gEAYQ/iHgBA2AMAQDdbjn3CHgJ+kAEAhD2IewAggOiADZxPx4tXAQDmsvXAZ7EHAIAAwh4C79gB
                  gPmIDdiQIzkAMIc9Rj2LPYT/kAMAcxD2IO4BAGEPAAD8aq8RT9jDRD/wAEAuYQ/iHgAIICxgZz4p
                  BwBy7DncWexh4gsAAJBD2AMAQABLIRThSA4A9Lb3b+Et9uBiAAAEEPYg7gGAACICCnIsBwB6qTDO
                  WewBACCAxR6KstoDQA9VjtJa7MFFAgAIIOxB3AMAAUQDNOBYDgDUVGmEs9gDAEAAYQ8NOJIDANwj
                  FqARR3IAoI5qw5vFHlxAAIAAIgEastwDwL4qjm0We3AxAQACCHsAAFig6sAm7MFFBQAIIOxB3AMA
                  AUQBBPAwLQBso/KoZrEHFxkAIIAYgCCWewBYT/UhzWIPLjgAQABhD+IeAAggACCUYzkAME6H4cxi
                  Dy5AAEAAb/wQznIPAK/pMpZZ7AEAIICwh3CO5ADAHLzhwyQcyQGA5ToNZBZ7cGECAAJ4o4fJWO4B
                  4DHdRjGLPbhIAQABvMHDpCz3APCzjkOYxR5csACAAN7YYXKWewD4Xdfxy2IPLl5u8AEggDd04G+W
                  ewDoPXhZ7AEAIIDFHviP1R6AmXU/nmqxB2IuaAAwM2/iwDeWewBmkzBuWeyByIsbAMzGmzfwI8s9
                  ADNIGbQs9kD8hQ4AZuBNG7jLcg9AqqQRy2IPTHXRA4BU3qyBh1nuAUiSNlxZ7IFpL4AAkMSbNLCY
                  5R6A7hLHKos94GIIAAG8OQNPs9wD0FHqQGWxB1wYASCAN2XgZZZ7ALpIHqWEPSDwARD2wh5A3AMg
                  6itwxh5w0QSAAN6AgVVY7gGoZIbhSdgD4h4AUR/AURzAhRQAAnjTBVZnuQdgLzONTMIeEPcAiPoA
                  juIALq4AEMAbLbApyz0AW5hxULLYAy60ABDAGyywC8s9AGuZdUQS9oC4B0DUB3AUB3DxBYAA3lSB
                  3VnuARhh9sFI2APiHgBRL+wBBD4Awl7YA4h7AES9sAfEPQCi3qvwD5+KA7hQA0AAb5xAaZZ7AH5i
                  BBL2gMAHQNgLewBxD4CoF/YA4h4AUb8CD88CLuYAEMAbJNCS5R5gTgYeYQ8IfABEvbAHEPcAiHph
                  DyDuARD1K/PwLOCiDwABvBECcaz3ADkMN8IeEPfiHkDUC3sAcQ+AqBf2AOIeAFEv7AHEPYCoR9gD
                  CHwAYS/sAcQ9AKJe2AOIewBEvbAHEPgAoh5hDyDuAUS9sAcQ+AAIe2EPIO4BEPXCHkDcA4h6hD2A
                  wAcQ9cIeAHEPIOqFPYDAB0DU7+7dSwDgjQiA/rxZASxgvQd4jpFE2AOIewBRj7AHEPcAgl7YAyDw
                  AUS9sAcQ9wCiHmEPIPABRD3CHkDcA4h6YQ+AwAcQ9cIeAHEPiHqEPYDABxD1CHsAgQ8g6oU9AOIe
                  QNQLewAEPiDqEfYAAh9A0CPsAQQ+gKgX9gCIewBRL+wBEPiAqEfYAyDwAVGPsAcQ9wCCXtgDIPAB
                  RL2wB0DgA4IeYQ+AwAdEPcIeQNwDiHqEPYDABwQ9wh4AgQ+IeoQ9AAIfEPQIewBEPiDqEfYAAh8Q
                  9Ah7AAQ+IOgR9gAIfEDUI+wBEPiAoEfYAyDwQdAj7AEQ+ICgR9gDIPABQY+wB0Dkg6AHYQ+AwAdB
                  j7AHAJEPgh5hD4DIBwQ9wh4AgQ9iHoQ9ACIfBD3CHgBEPgh6hD0ACHwQ8wh7AEQ+iHkQ9gCIfBDz
                  CHsAEPkg5hH2ACD0EfMg7AFA5CPkQdgDIPRBxCPsAUDoI+JB2AOAyEfEg7AHAKGPkAdhDwBiX8SD
                  sAcAwY+IB2EPAGIfIQ/CHgAEv2gHhD0ACH+xDsIeAHADINRB2AMAzW4gxDrk+J8AAwAH6dvYSfKq
                  NQAAAABJRU5ErkJggg=="
                style="overflow: visible"
                width="753"
                height="942"
                transform="matrix(0.24 0 0 0.24 1254.3682 1111.1042)"></image>
              <path
                class="st78"
                d="M1288.57,1260.43c0,0,15.62-30.61,5.15-82.02c0,0,20.55-12.83,35.77-14.8
                c15.22-1.97,35.73,6.59,64.85-1.69c29.12-8.28,36.85-33.28,36.85-33.28s-5.8,1.75-9.32,2.29c0,0,6.4-11.95,12.4-32.21
                c0,0-6.95,3.16-36.23,5.69c0,0,6.79-8.91,9.14-18.58c0,0-21.68,10.25-51.59,12.44s-68.95,5.57-83.95,22.23
                c-14.99,16.66-15.23,19.37-15.23,19.37s1.11-22.48,8.15-28.82c0,0-17.61,13.23-14.18,33.28c0,0-10.82,4.33-16.17,7.53
                c-5.36,3.2,14.11-2.16,14.11-2.16s-21.51,61.33,36.53,126.01L1288.57,1260.43z" />
              <path
                class="st78"
                d="M1256.41,1139.85c0,0,7.61-26.07,27.83-39.8c20.22-13.73,65.35-17.23,80.55-27.41
                c0,0-8.52,18.82-33.34,32.42c-24.82,13.6-43.08,28.77-43.08,28.77L1256.41,1139.85z" />
              <path
                class="st78"
                d="M1408.02,1153.49c0,0,17.05,33.5,23.76,51.9c0,0-6.29-53.46-17.12-65.75
                S1408.02,1153.49,1408.02,1153.49z" />
              <path
                class="st70"
                d="M1296.86,1255.29c0,0-5.99-25.45-29.47-9.22c-9.32,6.44-2.06,35.14,27.86,31.68
                C1295.25,1277.75,1304.67,1272.73,1296.86,1255.29z" />
              <path
                class="st74"
                d="M1382.94,1270.99c-0.28,0.08-0.6,0.02-0.83-0.19c-1.87-1.61-2.32-3.2-1.64-5.87
                c0.36-1.43,1.77-1.95,2.9-2.37c0.33-0.12,0.65-0.24,0.94-0.38l0.98-0.47c1.79-0.85,3.48-1.66,4.82-2.92
                c1.38-1.3,2.45-3.45,1.75-5.33c-0.34-0.92-1.07-1.7-1.84-2.52l-0.17-0.18c-7.55-8.09-12.51-18.59-13.95-29.56
                c-0.07-0.5,0.31-0.98,0.79-1.03c0.5-0.07,0.96,0.29,1.03,0.79c1.4,10.6,6.18,20.73,13.48,28.55l0.17,0.18
                c0.86,0.92,1.75,1.87,2.22,3.13c0.99,2.67-0.39,5.59-2.21,7.31c-1.55,1.45-3.45,2.36-5.28,3.24l-0.96,0.47
                c-0.34,0.17-0.72,0.31-1.1,0.45c-0.81,0.3-1.64,0.61-1.77,1.11c-0.51,1.98-0.27,2.89,1.05,4.02c0.38,0.33,0.43,0.91,0.1,1.29
                C1383.27,1270.85,1383.11,1270.95,1382.94,1270.99z" />
              <path
                class="st0"
                d="M1414.74,1274.62c0,0-42.14,13.11-70.39,13.72c0,0,24.28,20.68,45.29,16.91
                C1410.66,1301.48,1414.74,1274.62,1414.74,1274.62z" />
            </g>
            <path
              class="st79"
              d="M1339.36,1803.85c-77.6,0-145.24-4.62-146.46-4.71c-0.54-0.03-0.93-0.5-0.9-1.03
              c0.05-0.53,0.47-0.96,1.03-0.9c2.29,0.14,227.39,15.53,327.92-10.15c0.45-0.26,1.03,0.17,1.17,0.7
              c0.14,0.51-0.17,1.04-0.7,1.17C1475.58,1800.64,1404.01,1803.85,1339.36,1803.85z" />
            <path
              class="st79"
              d="M1366.03,1839.34c-89.37,0-175.13-6.51-176.54-6.62c-0.53-0.05-0.93-0.51-0.89-1.04
              c0.05-0.53,0.48-0.87,1.04-0.89c2.33,0.17,234.85,17.83,333.19-5.27c0.51-0.14,1.03,0.19,1.17,0.73
              c0.11,0.51-0.2,1.04-0.73,1.17C1484.32,1836.56,1424.41,1839.34,1366.03,1839.34z" />
            <path
              class="st79"
              d="M1182.03,1946.4c-1.74,0-2.72-0.12-2.8-0.12c-0.53-0.08-0.9-0.58-0.82-1.1c0.08-0.54,0.58-0.93,1.1-0.82
              c0.08,0.02,0.98,0.12,2.58,0.12c11.86,0,61.78-6.22,89.17-106.93c0.14-0.53,0.67-0.85,1.18-0.68c0.51,0.14,0.82,0.67,0.68,1.18
              C1245.42,1939.93,1194.47,1946.4,1182.03,1946.4z" />
            <path
              class="st79"
              d="M1532.49,1944.01c0,0-0.09,0-0.16-0.02c-0.65-0.09-66.49-11.39-76.64-107.23
              c-0.05-0.54,0.33-1.01,0.85-1.07c0.05,0,0.08,0,0.08,0c0.51,0,0.93,0.36,0.99,0.85c9.98,94.39,74.37,105.44,75.02,105.55
              c0.53,0.08,0.89,0.58,0.79,1.1C1533.38,1943.67,1532.96,1944.01,1532.49,1944.01z" />
            <path
              class="st79"
              d="M1359.33,2117.18h-0.02c-0.53,0-0.96-0.45-0.95-0.98l4.52-276.92c0-0.53,0.44-0.95,0.96-0.95h0.02
              c0.53,0,0.96,0.45,0.95,0.98l-4.52,276.92C1360.3,2116.76,1359.86,2117.18,1359.33,2117.18z" />
            <path
              class="st79"
              d="M1361.59,2057.56c-0.28,0-0.56-0.11-0.75-0.34c-0.34-0.42-0.28-1.03,0.12-1.37l31.37-26.13l5.27-190.46
              c0.02-0.51,0.44-0.93,0.96-0.93h0.03c0.53,0.02,0.95,0.45,0.93,0.99l-5.29,190.89c0,0.28-0.12,0.53-0.34,0.72l-31.7,26.41
              C1362.02,2057.49,1361.81,2057.56,1361.59,2057.56z" />
            <path
              class="st79"
              d="M1380.83,1760.67c-0.09,0-0.19-0.02-0.28-0.05l-109.78-33.97c-0.4-0.11-0.67-0.47-0.68-0.89l-5.66-157.31
              c-0.02-0.26,0.08-0.51,0.26-0.7c0.19-0.19,0.44-0.3,0.7-0.3h202.58c0.53,0,0.95,0.4,0.96,0.93l4.52,144.88
              c0.02,0.37-0.19,0.73-0.53,0.9l-91.67,46.4C1381.13,1760.64,1380.99,1760.67,1380.83,1760.67z M1272,1725.03l108.74,33.64
              l90.78-45.95l-4.48-143.32h-200.64L1272,1725.03z" />
            <g>
              <rect class="st71" x="1245.27" y="2058.75" width="15.86" height="321.2" />
              <g>
                <g>
                  <rect class="st80" x="1158.38" y="2158.02" width="41.02" height="147.68" />
                  <path
                    class="st80"
                    d="M1182.75,2093.8l10.49,19.86c1.72,3.26,0.47,7.3-2.79,9.02l-25.2,13.3c-3.26,1.72-7.3,0.47-9.02-2.79
                    l-11.36-21.51c-4.43,6.55-7.01,14.45-7.01,22.95c0,22.66,18.37,41.02,41.02,41.02c22.66,0,41.02-18.37,41.02-41.02
                    C1219.91,2113.29,1203.59,2095.75,1182.75,2093.8z" />
                </g>
              </g>
              <g>
                <rect
                  class="st80"
                  x="1014.07"
                  y="2199.57"
                  transform="matrix(0.959 -0.2835 0.2835 0.959 -593.3976 382.7196)"
                  width="23.52"
                  height="84.67" />
                <path
                  class="st80"
                  d="M1005.52,2165.37l8.99,9.22c1.48,1.51,1.45,3.94-0.07,5.41l-11.69,11.41
                  c-1.51,1.48-3.94,1.45-5.41-0.07l-9.74-9.98c-1.37,4.32-1.51,9.08-0.13,13.76c3.68,12.46,16.76,19.57,29.22,15.89
                  s19.57-16.76,15.89-29.22C1029.11,2170.05,1017.29,2163.06,1005.52,2165.37z" />
              </g>
              <g>
                <polygon
                  class="st9"
                  points="1138.27,2123.25 1133.74,2102.26 1127.26,2102.26 1122.73,2123.25 1127.26,2130.76
                  1133.74,2130.76" />
                <rect class="st9" x="1127.39" y="2127.4" width="6.22" height="93.36" />
                <path
                  class="st81"
                  d="M1141.77,2223h-22.54c-1.92,0-3.5-1.57-3.5-3.5l0,0c0-1.92,1.57-3.5,3.5-3.5h22.54
                  c1.92,0,3.5,1.57,3.5,3.5l0,0C1145.27,2221.42,1143.7,2223,1141.77,2223z" />
                <path
                  class="st81"
                  d="M1138.66,2219.5h-16.32l-2.06,4.62c-1.46,3.27-2.21,6.82-2.21,10.4v34.46h24.87v-34.46
                  c0-3.58-0.75-7.13-2.21-10.4L1138.66,2219.5z" />
              </g>
              <g>
                <polygon
                  class="st0"
                  points="1262.39,2114.79 1264.51,2089.23 1257.1,2086.98 1244.63,2109.38 1247.2,2119.55 1254.6,2121.8
                                " />
                <rect
                  class="st0"
                  x="1232.13"
                  y="2114.41"
                  transform="matrix(0.9567 0.291 -0.291 0.9567 685.107 -265.7356)"
                  width="7.43"
                  height="111.51" />
                <path
                  class="st81"
                  d="M1231.71,2229.99l-25.76-7.84c-2.2-0.67-3.45-3.01-2.78-5.21l0,0c0.67-2.2,3.01-3.45,5.21-2.78
                  l25.76,7.84c2.2,0.67,3.45,3.01,2.78,5.21l0,0C1236.26,2229.41,1233.91,2230.66,1231.71,2229.99z" />
                <path
                  class="st81"
                  d="M1229.38,2224.91l-18.65-5.67l-3.96,4.56c-2.81,3.23-4.9,7.02-6.15,11.12l-11.98,39.38l28.42,8.65
                  l11.98-39.38c1.25-4.1,1.62-8.41,1.09-12.66L1229.38,2224.91z" />
              </g>
              <g>
                <path
                  class="st75"
                  d="M1073.56,2193.14l-5.69,0.75l-3.32-25.22c0,0,4.99,0.17,4.08-6.74s-7.66-4.78-7.66-4.78l-3.96-30.1
                  l3.25-0.43l19.36,27.66l3.69,28.06L1073.56,2193.14z" />
                <path
                  class="st75"
                  d="M1062.62,2195.55l5.48-1.72l-7.61-24.27c0,0-4.45,2.25-6.54-4.4c-2.09-6.65,4.94-7.57,4.94-7.57
                  l-9.08-28.97l-3.13,0.98l-5.92,33.24l8.46,27.01L1062.62,2195.55z" />
                <path
                  class="st9"
                  d="M1083.98,2189.46l-29.92,5.86c-2.1,0.41-3.49,2.47-3.08,4.57l0,0c0.41,2.1,2.47,3.49,4.57,3.08
                  l29.92-5.86c2.1-0.41,3.49-2.47,3.08-4.57l0,0C1088.14,2190.43,1086.08,2189.04,1083.98,2189.46z" />
                <path
                  class="st9"
                  d="M1077.04,2197.78c0,0,26.03,21.93,30.07,31.17s15.81,56.54,14.27,68.55
                  c-1.55,12.01-10.44,23.5-16.93,21.71c-6.48-1.8-10.75-22.14-8.23-34.89c2.52-12.75-4.68-50.92-5.42-53.28
                  c-0.74-2.36-22.51-32.38-22.51-32.38L1077.04,2197.78z" />
                <path
                  class="st9"
                  d="M1063.44,2200.45c0,0-15.83,30.13-16.09,40.22c-0.25,10.08,6.69,58.33,12.66,68.86
                  c5.97,10.54,18.54,17.83,23.87,13.72c5.33-4.11,1.6-24.56-5.55-35.42c-7.14-10.86-14.88-48.92-15.08-51.39
                  c-0.2-2.47,8.63-38.48,8.63-38.48L1063.44,2200.45z" />
              </g>
              <rect class="st71" x="922.84" y="2058.75" width="15.86" height="321.2" />
              <rect class="st9" x="938.88" y="2069.24" width="305.58" height="20.37" />
              <g>
                <rect
                  class="st81"
                  x="962.71"
                  y="2128.13"
                  transform="matrix(0.9303 -0.3668 0.3668 0.9303 -758.0072 515.5082)"
                  width="29.43"
                  height="248.24" />
                <rect
                  class="st9"
                  x="872.65"
                  y="2118.82"
                  transform="matrix(0.9303 -0.3668 0.3668 0.9303 -719.5761 490.9017)"
                  width="118.46"
                  height="39.99" />
              </g>
              <rect class="st71" x="923.25" y="2239.38" width="327.08" height="140.57" />
            </g>
            <path
              class="st70"
              d="M1069.6,2001.9c1.13,5.66-39.61,52.63-40.74,59.98c-1.13,7.36,9.05,35.65,13.58,36.22
              c4.53,0.57,4.53-9.05,4.53-9.05s5.66,9.62,10.19,9.62s5.66-10.19,5.66-10.19s5.66,9.05,11.32,9.05s4.53-8.49,4.53-8.49
              s9.05,7.92,14.71,7.92s11.32-6.79,11.32-6.79l-9.62-0.57c0,0-4.81-11.88-4.24-14.71c0.57-2.83,7.92-16.69,11.32-14.43
              c3.4,2.26,1.98,8.77,1.98,8.77h14.43c0,0,10.75-29.71,1.7-70.17L1069.6,2001.9z" />
            <polygon class="st75" points="1232.57,1545.79 1225.03,1394.89 1250.96,1389.33 1260.3,1547.77" />
            <polygon class="st75" points="1486.85,1544.29 1467.98,1411.68 1442.52,1403.19 1460.06,1546.36" />
            <path
              class="st77"
              d="M1841.41,1402.37l-0.8-0.8c-3.01-3.02-7.93-3.03-10.95-0.02l-28.53,28.42
              c-3.02,3.01-3.03,7.93-0.02,10.95l0.8,0.8c3.01,3.02,7.93,3.03,10.95,0.02l28.53-28.42
              C1844.4,1410.32,1844.41,1405.39,1841.41,1402.37z" />
            <path
              class="st77"
              d="M1850.23,1417.23l-1.48-1.48c-2.82-2.83-7.44-2.84-10.27-0.02l-24.12,24.02
              c-2.83,2.82-2.84,7.44-0.02,10.27l1.48,1.48c2.82,2.83,7.44,2.84,10.27,0.02l24.12-24.02
              C1853.04,1424.68,1853.04,1420.06,1850.23,1417.23z" />
            <path
              class="st77"
              d="M1821.58,1374.52l-0.74-0.75c-3.02-3.03-7.97-3.04-11-0.02l-28.87,28.75c-3.03,3.02-3.04,7.97-0.02,11
              l0.74,0.75c3.02,3.03,7.97,3.04,11,0.02l28.87-28.75C1824.59,1382.5,1824.6,1377.55,1821.58,1374.52z" />
            <path
              class="st77"
              d="M1831.58,1389.14l-0.74-0.75c-3.02-3.03-7.97-3.04-11-0.02l-28.87,28.75c-3.03,3.02-3.04,7.97-0.02,11
              l0.74,0.75c3.02,3.03,7.97,3.04,11,0.02l28.87-28.75C1834.59,1397.12,1834.6,1392.17,1831.58,1389.14z" />
          </g>
        </g>
        <rect class="st82" x="1043.74" y="1776.41" width="1455.92" height="42.77" />
        <path
          class="st0"
          d="M2473.63,1803.37h-1411.3c-4.6,0-8.37-3.77-8.37-8.37l0,0c0-4.6,3.77-8.37,8.37-8.37h1411.3
          c4.6,0,8.37,3.77,8.37,8.37l0,0C2482,1799.61,2478.24,1803.37,2473.63,1803.37z" />
        <path
          class="st11"
          d="M1778.21,1786.64h-715.88c-4.6,0-8.37,3.77-8.37,8.37c0,4.6,3.77,8.37,8.37,8.37h715.88V1786.64z" />
        <polygon class="st83" points="1773.56,1917.73 1773.56,1829.87 1850.27,1872.4" />
        <polygon class="st83" points="1879.51,1894.49 1879.51,1851.25 1917.25,1872.18" />
        <polygon class="st83" points="1907.4,1894.49 1907.4,1851.25 1945.14,1872.18" />
        <polygon class="st83" points="1715.5,1894.49 1715.5,1851.25 1677.76,1872.18" />
        <polygon class="st83" points="1687.61,1894.49 1687.61,1851.25 1649.87,1872.18" />
        <rect class="st9" x="2493.75" y="1916.58" width="98.74" height="27.88" />
        <rect class="st9" x="2194.04" y="1916.58" width="98.74" height="27.88" />
        <rect class="st9" x="2073.23" y="1916.58" width="98.74" height="27.88" />
        <rect class="st9" x="1952.42" y="1916.58" width="98.74" height="27.88" />
        <rect class="st9" x="1281.56" y="1916.58" width="98.74" height="27.88" />
        <rect class="st9" x="1155.23" y="1916.58" width="98.74" height="27.88" />
        <rect class="st9" x="1029.77" y="1916.58" width="98.74" height="27.88" />
        <rect class="st9" x="1411.66" y="1914.84" width="519.26" height="29.62" />
        <rect class="st9" x="2318.34" y="1916.58" width="142.88" height="27.88" />
        <path
          class="st9"
          d="M2663.35,1931.68H911.57v36.32c0,26.13,21.18,47.32,47.32,47.32h1650.22c29.96,0,54.24-24.28,54.24-54.24
          V1931.68z" />
        <!-- <g>
           <path class="st12" d="M1813.42,733.38c-1.26-0.19-2.13-1.38-1.93-2.64c0.19-1.26,1.37-2.14,2.64-1.93
            c15.43,2.39,31.5,2.57,46.96,0.57c1.29-0.16,2.43,0.73,2.59,2c0.17,1.27-0.73,2.43-1.99,2.59c-8.12,1.06-16.41,1.53-24.67,1.4
            C1829.12,735.25,1821.18,734.58,1813.42,733.38z M1767.26,719.25c-14.6-6.89-28.16-15.84-40.29-26.6
            c-0.95-0.85-1.04-2.31-0.19-3.27c0.85-0.96,2.31-1.04,3.27-0.19c11.8,10.47,24.98,19.18,39.19,25.88
            c1.16,0.55,1.65,1.92,1.11,3.08c-0.4,0.85-1.25,1.34-2.13,1.33C1767.89,719.47,1767.57,719.4,1767.26,719.25z M1905.11,719.77
            c-0.52-1.17,0.01-2.53,1.18-3.05c14.37-6.36,27.76-14.74,39.8-24.92c0.97-0.82,2.43-0.7,3.26,0.27c0.82,0.98,0.7,2.44-0.27,3.26
            c-12.38,10.47-26.14,19.09-40.92,25.62c-0.31,0.14-0.65,0.2-0.97,0.2C1906.32,721.14,1905.49,720.63,1905.11,719.77z
              M1695.78,655.81c-8.62-13.73-15.2-28.58-19.57-44.13c-0.34-1.23,0.37-2.51,1.6-2.85c1.25-0.35,2.51,0.38,2.86,1.6
            c4.25,15.12,10.65,29.56,19.04,42.92c0.68,1.08,0.35,2.51-0.73,3.19c-0.39,0.25-0.83,0.36-1.26,0.36
            C1696.95,656.88,1696.21,656.5,1695.78,655.81z M1977.96,659.92c-1.07-0.7-1.36-2.14-0.65-3.21
            c8.71-13.15,15.46-27.43,20.07-42.45c0.37-1.22,1.66-1.9,2.89-1.53c1.22,0.38,1.91,1.67,1.53,2.89
            c-4.74,15.44-11.69,30.13-20.63,43.64c-0.45,0.68-1.21,1.05-1.97,1.04C1978.77,660.3,1978.34,660.17,1977.96,659.92z
              M1669.91,563.82l0.01-0.68c0.25-16.25,2.78-32.29,7.54-47.67c0.38-1.22,1.65-1.9,2.9-1.53c1.22,0.38,1.9,1.67,1.53,2.9
            c-4.63,14.96-7.1,30.56-7.34,46.37l-0.01,0.68c-0.02,1.28-1.07,2.3-2.35,2.28C1670.92,566.15,1669.89,565.1,1669.91,563.82z
              M2004.62,567.88c0.21-15.82-1.82-31.48-6.02-46.56c-0.34-1.23,0.37-2.51,1.61-2.85c1.24-0.34,2.51,0.38,2.85,1.61
            c4.33,15.51,6.41,31.61,6.19,47.87c-0.02,1.28-1.07,2.3-2.34,2.28l-0.01,0C2005.62,570.2,2004.6,569.15,2004.62,567.88z
              M1698.84,475.08c-1.07-0.71-1.35-2.14-0.65-3.21c8.95-13.46,19.77-25.57,32.16-36c0.98-0.82,2.44-0.7,3.26,0.28
            c0.82,0.98,0.7,2.44-0.28,3.26c-12.05,10.15-22.58,21.93-31.28,35.03c-0.45,0.68-1.2,1.04-1.97,1.03
            C1699.66,475.46,1699.22,475.33,1698.84,475.08z M1979.65,478.36c-8.34-13.34-18.52-25.41-30.28-35.9
            c-0.95-0.85-1.04-2.31-0.19-3.27c0.86-0.95,2.32-1.04,3.27-0.19c12.09,10.77,22.56,23.19,31.13,36.9
            c0.68,1.08,0.35,2.51-0.74,3.19c-0.39,0.25-0.83,0.36-1.26,0.35C1980.82,479.43,1980.08,479.05,1979.65,478.36z M1770.13,413.38
            c-0.51-1.17,0.02-2.54,1.19-3.05c14.8-6.52,30.45-10.86,46.51-12.92c1.26-0.17,2.42,0.74,2.59,2c0.16,1.27-0.74,2.43-2,2.59
            c-15.61,2-30.83,6.23-45.23,12.56c-0.31,0.14-0.64,0.2-0.97,0.2C1771.34,414.75,1770.5,414.23,1770.13,413.38z M1910.24,416.5
            c-14.22-6.74-29.31-11.4-44.87-13.83c-1.26-0.2-2.12-1.38-1.93-2.65c0.2-1.26,1.36-2.1,2.65-1.93
            c15.99,2.51,31.52,7.29,46.13,14.23c1.16,0.55,1.65,1.93,1.1,3.08c-0.4,0.85-1.25,1.34-2.12,1.32
            C1910.87,416.72,1910.54,416.65,1910.24,416.5z"/> -->
        <!-- <path class="st12" d="M1817.37,784.07c-55.14-5.17-106-30.75-143.21-72.05c-37.4-41.51-57.54-95.17-56.69-151.08
            c1.84-121.71,102.36-219.23,224.07-217.38c5.83,0.09,11.72,0.41,17.49,0.95c55.14,5.17,106,30.75,143.2,72.04
            c37.4,41.51,57.53,95.17,56.69,151.08c-1.84,121.71-102.36,219.23-224.07,217.39C1829.03,784.93,1823.15,784.61,1817.37,784.07z
              M1858.76,347.49c-5.7-0.53-11.51-0.85-17.26-0.93c-120.06-1.82-219.21,94.38-221.03,214.43
            c-1.71,112.9,84.9,209.58,197.18,220.1c5.7,0.53,11.5,0.85,17.26,0.93c120.06,1.82,219.21-94.38,221.03-214.44
            C2057.65,454.69,1971.04,358.01,1858.76,347.49z"/> -->
        <!-- <path class="st9" d="M1825.63,729.73l0.18,0c1.98-0.13,3.43-1.77,3.3-3.67l-5.19-80.31c-0.11-1.9-1.72-3.4-3.66-3.23
            c-1.9,0.13-3.35,1.76-3.22,3.67l5.19,80.31C1822.34,728.31,1823.83,729.71,1825.63,729.73z"/>
          <path class="st9" d="M1852.38,730.01c0,0-0.18,0-0.26-0.01c-1.9-0.17-3.31-1.84-3.14-3.73l6.89-80.19
            c0.16-1.9,1.79-3.36,3.73-3.15c1.9,0.17,3.31,1.84,3.14,3.73l-6.89,80.19C1855.69,728.67,1854.17,730.03,1852.38,730.01z"/>
          <path class="st9" d="M1859.38,638.61c0.36,0,0.74-0.11,1.06-0.35c0.76-0.57,0.92-1.65,0.35-2.42l-5.53-7.39
            c-0.52-0.69-1.49-0.89-2.23-0.47l-10.13,5.7l-5.47-5.59c-0.63-0.63-1.65-0.7-2.34-0.12l-7.59,6.31l-6.59-5.61
            c-0.73-0.62-1.83-0.53-2.43,0.2c-0.63,0.73-0.53,1.81,0.19,2.44l7.7,6.55c0.66,0.55,1.59,0.54,2.23,0.01l7.48-6.23l5.28,5.4
            c0.56,0.56,1.41,0.68,2.09,0.3l9.97-5.61l4.61,6.18C1858.36,638.36,1858.87,638.6,1859.38,638.61z"/>
          <path class="st13" d="M1818.73,807.91l35.38,0.37c17.85,0.19,32.47-14.13,32.66-31.98l0.4-37.78l-100.02-1.06l-0.4,37.78
            C1786.57,793.1,1800.88,807.72,1818.73,807.91z"/>
          <path class="st13" d="M1782.88,747.68l107.49,1.14c5.47,0.06,9.99-4.37,10.05-9.84l0.11-10.89c0.06-5.47-4.37-9.99-9.84-10.05
            l-107.49-1.13c-5.47-0.06-9.99,4.37-10.05,9.84l-0.11,10.89C1772.99,743.1,1777.42,747.62,1782.88,747.68z"/>
          <path class="st13" d="M1887.29,748.38l-100.25-1.52l-0.28,28.39c0,0,57.66-38.18,100.03,1.52L1887.29,748.38z"/>
        </g> -->
        <g>
          <path
            class="st71"
            d="M2991.26,1685.53h-695.12c-9.84,0-17.81,7.97-17.81,17.81v298.43c0,9.83,7.97,17.81,17.81,17.81h695.12
            c9.83,0,17.81-7.97,17.81-17.81v-298.43C3009.06,1693.5,3001.09,1685.53,2991.26,1685.53z" />
          <path
            class="st84"
            d="M3010.2,2000.79v-296.47c0-10.38-8.41-18.79-18.79-18.79h-192.08c-10.38,0-18.79,8.41-18.79,18.79v296.47
            c0,10.38,8.41,18.79,18.79,18.79h192.08C3001.78,2019.58,3010.2,2011.17,3010.2,2000.79z" />
          <rect class="st85" x="2278.33" y="1766.96" width="730.73" height="6.26" />
          <g>
            <path
              class="st86"
              d="M2747.74,1742.25c0-3.84-3.12-6.96-6.96-6.96c-3.84,0-6.96,3.12-6.96,6.96s3.12,6.96,6.96,6.96
              C2744.62,1749.21,2747.74,1746.09,2747.74,1742.25z" />
            <path
              class="st86"
              d="M2680.93,1742.25c0-3.84-3.12-6.96-6.96-6.96s-6.96,3.12-6.96,6.96s3.12,6.96,6.96,6.96
              S2680.93,1746.09,2680.93,1742.25z" />
            <path
              class="st86"
              d="M2613.6,1742.25c0-3.84-3.12-6.96-6.96-6.96s-6.96,3.12-6.96,6.96s3.12,6.96,6.96,6.96
              S2613.6,1746.09,2613.6,1742.25z" />
            <path
              class="st86"
              d="M2546.79,1742.25c0-3.84-3.12-6.96-6.96-6.96c-3.84,0-6.96,3.12-6.96,6.96s3.12,6.96,6.96,6.96
              C2543.67,1749.21,2546.79,1746.09,2546.79,1742.25z" />
          </g>
          <g>
            <path
              class="st86"
              d="M2975.31,1742.25c0-3.84-3.12-6.96-6.96-6.96s-6.96,3.12-6.96,6.96s3.12,6.96,6.96,6.96
              S2975.31,1746.09,2975.31,1742.25z" />
            <path
              class="st86"
              d="M2908.5,1742.25c0-3.84-3.12-6.96-6.96-6.96s-6.96,3.12-6.96,6.96s3.12,6.96,6.96,6.96
              S2908.5,1746.09,2908.5,1742.25z" />
            <path
              class="st86"
              d="M2841.17,1742.25c0-3.84-3.12-6.96-6.96-6.96s-6.96,3.12-6.96,6.96s3.12,6.96,6.96,6.96
              S2841.17,1746.09,2841.17,1742.25z" />
          </g>
          <g>
            <path
              class="st86"
              d="M2472.85,1742.25c0-3.84-3.12-6.96-6.96-6.96c-3.84,0-6.96,3.12-6.96,6.96s3.12,6.96,6.96,6.96
              C2469.73,1749.21,2472.85,1746.09,2472.85,1742.25z" />
            <path
              class="st86"
              d="M2406.04,1742.25c0-3.84-3.12-6.96-6.96-6.96c-3.84,0-6.96,3.12-6.96,6.96s3.12,6.96,6.96,6.96
              C2402.92,1749.21,2406.04,1746.09,2406.04,1742.25z" />
            <path
              class="st86"
              d="M2338.7,1742.25c0-3.84-3.12-6.96-6.96-6.96c-3.84,0-6.96,3.12-6.96,6.96s3.12,6.96,6.96,6.96
              C2335.59,1749.21,2338.7,1746.09,2338.7,1742.25z" />
          </g>
          <g>
            <rect class="st10" x="2630.48" y="1743.29" width="37.23" height="49.41" />
            <path
              class="st87"
              d="M2659.27,1764.39c0-7.57-5.86-13.7-13.09-13.7c-7.23,0-13.09,6.13-13.09,13.7
              c0,7.57,5.86,13.7,13.09,13.7C2653.41,1778.09,2659.27,1771.96,2659.27,1764.39z" />
            <rect class="st88" x="2642.52" y="1755.8" width="3.26" height="18.01" />
          </g>
          <g>
            <rect class="st10" x="2354.19" y="1743.29" width="37.23" height="49.41" />
            <path
              class="st87"
              d="M2382.98,1764.39c0-7.57-5.86-13.7-13.09-13.7s-13.09,6.13-13.09,13.7c0,7.57,5.86,13.7,13.09,13.7
              S2382.98,1771.96,2382.98,1764.39z" />
            <rect class="st88" x="2366.24" y="1755.8" width="3.26" height="18.01" />
          </g>
          <g>
            <path
              class="st10"
              d="M2697.98,1839.51h-69.42c-7.46,0-13.57,6.11-13.57,13.57l0,0c0,7.46,6.11,13.57,13.57,13.57h69.42
              c7.46,0,13.57-6.11,13.57-13.57l0,0C2711.55,1845.61,2705.45,1839.51,2697.98,1839.51z" />
            <path
              class="st87"
              d="M2685.72,1886.48h-48.28c-8.49,0-15.4-6.91-15.4-15.4v-18.79c0-3.46,2.8-6.26,6.26-6.26
              c3.46,0,6.26,2.8,6.26,6.26v18.79c0,1.58,1.29,2.87,2.87,2.87h48.28c1.58,0,2.87-1.29,2.87-2.87v-18.53
              c0-3.46,2.8-6.26,6.26-6.26c3.46,0,6.26,2.8,6.26,6.26v18.53C2701.11,1879.58,2694.21,1886.48,2685.72,1886.48z" />
          </g>
          <g>
            <path
              class="st10"
              d="M2861.11,1839.51h69.42c7.46,0,13.57,6.11,13.57,13.57l0,0c0,7.46-6.11,13.57-13.57,13.57h-69.42
              c-7.46,0-13.57-6.11-13.57-13.57l0,0C2847.54,1845.61,2853.65,1839.51,2861.11,1839.51z" />
            <path
              class="st87"
              d="M2873.38,1886.48h48.28c8.49,0,15.4-6.91,15.4-15.4v-18.79c0-3.46-2.8-6.26-6.26-6.26
              c-3.46,0-6.26,2.8-6.26,6.26v18.79c0,1.58-1.29,2.87-2.87,2.87h-48.28c-1.58,0-2.87-1.29-2.87-2.87v-18.53
              c0-3.46-2.8-6.26-6.26-6.26c-3.46,0-6.26,2.8-6.26,6.26v18.53C2857.98,1879.58,2864.89,1886.48,2873.38,1886.48z" />
          </g>
          <g>
            <path
              class="st10"
              d="M2397.34,1839.51h-69.42c-7.46,0-13.57,6.11-13.57,13.57l0,0c0,7.46,6.11,13.57,13.57,13.57h69.42
              c7.46,0,13.57-6.11,13.57-13.57l0,0C2410.91,1845.61,2404.8,1839.51,2397.34,1839.51z" />
            <path
              class="st87"
              d="M2385.07,1886.48h-48.28c-8.49,0-15.4-6.91-15.4-15.4v-18.79c0-3.46,2.8-6.26,6.26-6.26
              c3.46,0,6.26,2.8,6.26,6.26v18.79c0,1.58,1.29,2.87,2.87,2.87h48.28c1.58,0,2.87-1.29,2.87-2.87v-18.53
              c0-3.46,2.8-6.26,6.26-6.26s6.26,2.8,6.26,6.26v18.53C2400.47,1879.58,2393.56,1886.48,2385.07,1886.48z" />
          </g>
        </g>
        <g>
          <g>
            <polygon class="st89" points="594.48,1976.5 597.85,1887.03 635.21,1892.66 617.68,1978.46" />
            <g>
              <path
                class="st11"
                d="M594.37,1967.72c0,0-8.25,22.23-4.61,39.42l96.08,1.74c0,0,6.26-18.32-15.38-23.32
                c-21.63-5-49.28-22.04-49.28-22.04S607.33,1979.14,594.37,1967.72z" />
              <path
                class="st0"
                d="M589.73,2007.13l96.08,1.74c0,0,1.53-4.5,0.45-9.65l-97.45-1.76
                C588.81,2000.74,589.07,2004.02,589.73,2007.13z" />
              <path
                class="st73"
                d="M616.09,1984.75c-1.07-0.62-1.76-1.85-1.85-3.3c-0.09-1.24,0.26-2.45,0.59-3.5
                c0.84-2.62,1.92-5.22,3.23-7.72c0.86-1.62,1.86-3.29,3.37-4.5c2.16-1.74,5.04-2.26,7.58-2.54c2.12-0.24,4.27-0.36,6.41-0.36
                h0.4c2.88,0.01,6.17,0.26,8.79,2.12c1.16,0.82,1.7,1.87,1.47,2.85c-0.17,0.74-0.78,1.37-1.72,1.78
                c-0.92,0.4-1.95,0.6-3.16,0.6c-1.18,0-2.36-0.19-3.46-0.37c-4.75-0.84-9.48-1.87-14.05-3.08c0.5,2.44,0.69,4.92,0.6,7.39
                c-0.12,3.17-0.73,5.73-1.88,7.81c-0.7,1.24-1.53,2.14-2.47,2.68c-0.64,0.36-1.39,0.57-2.1,0.57l0,0
                C617.19,1985.19,616.59,1985.04,616.09,1984.75z M622.22,1966.71c-1.34,1.07-2.26,2.61-3.06,4.11
                c-1.27,2.43-2.33,4.96-3.15,7.51c-0.3,0.98-0.6,2.01-0.53,3.03c0.06,1.01,0.54,1.89,1.23,2.3c0.69,0.4,1.74,0.34,2.57-0.12
                c0.74-0.43,1.41-1.16,1.99-2.2c1.05-1.9,1.62-4.27,1.73-7.24c0.1-2.51-0.12-5.03-0.65-7.49
                C622.32,1966.64,622.27,1966.68,622.22,1966.71z M629.15,1964.43c-1.71,0.19-3.45,0.47-5,1.13c4.49,1.19,9.13,2.2,13.79,3.02
                c2.05,0.34,4.34,0.54,5.91-0.14c0.26-0.12,0.88-0.43,0.99-0.92c0.12-0.54-0.42-1.16-0.98-1.55c-2.33-1.65-5.38-1.88-8.07-1.88
                h-0.39C633.33,1964.09,631.22,1964.21,629.15,1964.43z" />
            </g>
            <path
              class="st73"
              d="M686.26,1999.85c0.34,0,0.62-0.27,0.63-0.62c0.01-0.34-0.27-0.63-0.62-0.64l-97.44-1.76h-0.01
              c-0.34,0-0.62,0.27-0.63,0.62c-0.01,0.34,0.27,0.63,0.62,0.64L686.26,1999.85L686.26,1999.85z" />
            <path
              class="st73"
              d="M615.58,1998.42l0.09-0.01c0.34-0.05,0.58-0.36,0.53-0.71c-3.16-21.8-22.22-27.77-22.42-27.83
              c-0.29-0.09-0.67,0.09-0.78,0.43c-0.1,0.33,0.09,0.68,0.42,0.78c0.18,0.05,18.49,5.81,21.53,26.81
              C615,1998.19,615.27,1998.42,615.58,1998.42z" />
            <path
              class="st73"
              d="M627.35,1998.73c0.1,0,0.21-0.02,0.31-0.08l0.39-0.22c8.59-4.84,18.34-7.42,28.19-7.45l24.33-0.1
              c0.35,0,0.63-0.29,0.63-0.63c0-0.35-0.28-0.63-0.63-0.63l0,0l-24.33,0.1c-10.08,0.04-20.04,2.67-28.82,7.61l-0.39,0.22
              c-0.3,0.17-0.4,0.56-0.24,0.86C626.92,1998.61,627.13,1998.73,627.35,1998.73z" />
            <path
              class="st73"
              d="M647.6,1986.64c0.19,0,0.39-0.09,0.5-0.26l4.71-6.44c0.21-0.28,0.15-0.67-0.13-0.88
              c-0.29-0.21-0.67-0.14-0.88,0.13l-4.4,6.01l-33.44-16.41c-0.31-0.13-0.69-0.02-0.85,0.29c-0.15,0.31-0.02,0.69,0.29,0.85
              l33.91,16.65C647.42,1986.62,647.51,1986.64,647.6,1986.64z" />
          </g>
          <g>
            <polygon class="st89" points="803.6,1976.5 792.36,1890.05 821.88,1891.89 831.11,1974.92" />
            <g>
              <path
                class="st11"
                d="M803.49,1967.72c0,0-8.25,22.23-4.61,39.42l96.08,1.74c0,0,6.26-18.32-15.38-23.32
                c-21.63-5-49.28-22.04-49.28-22.04S816.45,1979.14,803.49,1967.72z" />
              <path
                class="st0"
                d="M798.85,2007.13l96.08,1.74c0,0,1.53-4.5,0.45-9.65l-97.45-1.76
                C797.93,2000.74,798.19,2004.02,798.85,2007.13z" />
              <path
                class="st73"
                d="M825.2,1984.75c-1.05-0.62-1.75-1.85-1.85-3.3c-0.08-1.23,0.26-2.45,0.6-3.49
                c0.85-2.66,1.93-5.26,3.23-7.72c0.85-1.62,1.86-3.28,3.37-4.5c2.16-1.74,4.92-2.25,7.58-2.54c2.13-0.24,4.28-0.36,6.41-0.36
                h0.4c2.88,0.01,6.17,0.26,8.78,2.12c1.17,0.82,1.71,1.86,1.48,2.85c-0.17,0.74-0.78,1.38-1.72,1.78
                c-0.92,0.4-1.96,0.6-3.17,0.6c-1.18,0-2.4-0.19-3.45-0.37c-4.74-0.83-9.46-1.87-14.05-3.08c0.49,2.41,0.69,4.89,0.6,7.39
                c-0.12,3.2-0.74,5.75-1.89,7.81c-0.71,1.26-1.52,2.14-2.47,2.68c-0.64,0.36-1.39,0.57-2.1,0.57l0,0
                C826.3,1985.19,825.71,1985.04,825.2,1984.75z M831.34,1966.71c-1.33,1.07-2.26,2.61-3.05,4.11
                c-1.26,2.39-2.32,4.92-3.15,7.51c-0.3,0.98-0.6,2.01-0.54,3.03c0.07,1.02,0.54,1.9,1.23,2.3c0.71,0.39,1.75,0.33,2.58-0.12
                c0.74-0.43,1.4-1.15,1.99-2.21c1.05-1.88,1.62-4.24,1.74-7.24c0.09-2.53-0.12-5.05-0.65-7.49
                C831.44,1966.64,831.38,1966.68,831.34,1966.71z M838.26,1964.43c-1.71,0.19-3.44,0.47-4.99,1.13
                c4.51,1.19,9.14,2.2,13.79,3.02c1.04,0.18,2.15,0.36,3.23,0.36c1.04,0,1.91-0.16,2.67-0.5c0.26-0.12,0.89-0.43,1-0.92
                c0.12-0.54-0.43-1.16-0.99-1.55c-2.32-1.65-5.38-1.88-8.06-1.88h-0.39C842.45,1964.09,840.34,1964.21,838.26,1964.43z" />
            </g>
            <path
              class="st73"
              d="M895.38,1999.85c0.34,0,0.62-0.27,0.63-0.62c0.01-0.34-0.27-0.63-0.62-0.64l-97.44-1.76h-0.01
              c-0.34,0-0.62,0.27-0.63,0.62c-0.01,0.34,0.27,0.63,0.62,0.64L895.38,1999.85L895.38,1999.85z" />
            <path
              class="st73"
              d="M824.7,1998.42l0.09-0.01c0.34-0.05,0.58-0.36,0.53-0.71c-3.16-21.8-22.23-27.77-22.42-27.83
              c-0.28-0.09-0.67,0.09-0.78,0.43c-0.1,0.33,0.09,0.68,0.42,0.78c0.18,0.05,18.49,5.81,21.54,26.81
              C824.12,1998.19,824.39,1998.42,824.7,1998.42z" />
            <path
              class="st73"
              d="M836.47,1998.73c0,0,0.21-0.02,0.31-0.08l0.39-0.22c8.59-4.84,18.33-7.42,28.19-7.45l24.33-0.1
              c0.35,0,0.63-0.29,0.63-0.63c0-0.35-0.28-0.63-0.63-0.63l0,0l-24.33,0.1c-10.08,0.04-20.04,2.67-28.81,7.61l-0.39,0.22
              c-0.3,0.17-0.4,0.56-0.24,0.86C836.04,1998.61,836.26,1998.73,836.47,1998.73z" />
            <path
              class="st73"
              d="M856.73,1986.64c0.19,0,0.39-0.09,0.5-0.26l4.71-6.44c0.21-0.28,0.15-0.67-0.13-0.88
              c-0.29-0.21-0.67-0.14-0.88,0.13l-4.4,6.01l-33.44-16.41c-0.32-0.13-0.7-0.02-0.85,0.29c-0.15,0.31-0.02,0.69,0.29,0.85
              l33.91,16.65C856.54,1986.62,856.63,1986.64,856.73,1986.64z" />
          </g>
          <path
            class="st89"
            d="M590.66,1537.97l-1.14,32.09c0,0-8.21,26.85-11.8,27.02c-3.59,0.17-27.87-12.19-27.87-12.19
            s9.1-14.52,18.8-31.39c0.14-0.25-2.42-23.44-2.42-23.44L590.66,1537.97z" />
          <path
            class="st89"
            d="M589.01,1558.12c0,0,3.92,10.59,5.3,26.8c0.52,6.18,6.93,20.11,2.84,20.27
            c-4.09,0.16-9.25-18.19-10.55-18.31c-1.31-0.13-6.9,8.77-6.9,8.77l0.11-16.93L589.01,1558.12z" />
          <path
            class="st89"
            d="M581.55,1589.7c0,0-8.74,38.53-12.05,38.35c-3.31-0.18,2.93-31.79,2.93-31.79s-12.99,32.68-16.12,31.96
            c-3.13-0.72,8.33-35.98,8.33-35.98s-18.47,28.82-21.04,27.39c-2.57-1.43,13.33-31.68,13.33-31.68s-18.67,20.12-20.93,18.79
            c-2.26-1.33,13.84-21.84,13.84-21.84l10.52-13.69L581.55,1589.7z" />
          <path
            class="st89"
            d="M960,1394.32l32.61,9.42c0,0,25.42,15.32,24.67,19.05c-0.75,3.73-19.67,25.44-19.67,25.44
            s-12.55-13.06-27.35-27.35c-0.22-0.21-24.66-3.55-24.66-3.55L960,1394.32z" />
          <path
            class="st89"
            d="M980.24,1401.2c0,0,11.86-1.3,28.84,1.46c6.47,1.05,22.41-1.94,21.51,2.3c-0.89,4.24-21.02,4.8-21.49,6.11
            c-0.47,1.31,7.22,9.32,7.22,9.32l-17.33-4.47L980.24,1401.2z" />
          <path
            class="st89"
            d="M1010.69,1416.96c0,0,37.26,18.87,36.22,22.22c-1.04,3.35-31.84-11.18-31.84-11.18
            s30.17,21.72,28.63,24.75c-1.55,3.03-34.75-17.8-34.75-17.8s24.8,26.35,22.67,28.61s-29.05-21.82-29.05-21.82
            s15.83,24.32,13.88,26.3c-1.95,1.98-18.83-19.81-18.83-19.81l-11.34-14.31L1010.69,1416.96z" />
          <path
            class="st89"
            d="M768.37,1330.5c0,0,57.2,67.35,64.58,71.04c7.38,3.69,145.77,23.06,145.77,23.06l1.85-23.99
            c0,0-113.48-35.98-123.63-37.83l-65.5-77.5L768.37,1330.5z" />
          <path
            class="st13"
            d="M628.6,1510.4l-40.01,442.45l46.46,5.05c0,0,68.01-348.12,74.74-376.4
            c6.73-28.28,30.97,150.83,37.71,176.42c6.73,25.59,39.05,196.95,39.05,196.95l47.47-5.05c0,0-36.7-371.01-44.78-414.11
            c-8.08-43.09-8.08-53.87-8.08-53.87s-96.96,14.81-137.36,2.69L628.6,1510.4z" />
          <path
            class="st90"
            d="M711.81,1578.59c0.22,0,0.41-0.16,0.45-0.37c0.06-0.33,6.02-33.37,0.35-89.69
            c-0.02-0.26-0.22-0.4-0.5-0.41c-0.26,0.02-0.43,0.25-0.41,0.5c5.65,56.17-0.28,89.1-0.33,89.42c-0.05,0.25,0.12,0.49,0.36,0.54
            L711.81,1578.59z" />
          <path
            class="st90"
            d="M713.49,1567.48c0.08,0,0.16-0.02,0.22-0.05c0.55-0.3,13.64-7.53,15.69-12.34
            c2.03-4.75,1.41-64.63,1.38-67.19c0-0.25-0.2-0.45-0.46-0.45h-0.01c-0.25,0-0.46,0.21-0.45,0.47
            c0.23,21.51,0.25,63.15-1.32,66.81c-1.93,4.51-15.15,11.81-15.29,11.89c-0.22,0.12-0.3,0.4-0.18,0.63
            C713.18,1567.39,713.33,1567.48,713.49,1567.48z" />
          <path
            class="st90"
            d="M619.9,1949.27c0.22,0,0.43-0.16,0.46-0.4l54.54-377.75c0.04-0.25-0.14-0.48-0.39-0.52
            c-0.22,0.02-0.49,0.14-0.53,0.39l-54.54,377.75c-0.04,0.26,0.14,0.49,0.4,0.53H619.9z" />
          <path
            class="st89"
            d="M623.28,1332.7c0,0-25.12,100.49-25.12,105.51c0,5.02-3.77,62.8-8.79,120.58l-20.1-5.44
            c0,0-11.72-85.41-5.86-135.66c5.86-50.24,23.45-104.67,23.45-104.67L623.28,1332.7z" />
          <path
            class="st90"
            d="M824.94,1944.22c0.02,0,0.04,0,0.04,0c0.28-0.04,0.45-0.27,0.42-0.52l-49.49-361.58
            c-0.03-0.26-0.26-0.41-0.52-0.4c-0.26,0.04-0.43,0.27-0.4,0.52l49.49,361.58C824.51,1944.06,824.71,1944.22,824.94,1944.22z" />
          <path
            class="st71"
            d="M670.07,1241.46c0,0-54.84,17.99-65.91,26.3c-11.07,8.3-39.27,100.73-39.27,100.73s24.12,9.65,50.87,20.73
            l7.78-28.28c0,0,4.61,65.5,5.54,77.5s-3.69,77.5-3.69,77.5s95.95,30.45,165.14-3.69c0,0-11.99-70.12-11.99-79.34
            c0-9.23,2.77-77.5,2.77-77.5l26.76,32.29c0,0,31.37-29.52,39.67-49.82c0,0-58.12-74.73-70.12-80.27
            c-11.99-5.54-56.36-16.15-56.36-16.15H670.07z" />
          <path
            class="st89"
            d="M667.96,1242.23L667.96,1242.23c4.96-2.1,8.19-6.95,8.24-12.33l0.26-29.18l43.43-0.34v31.15
            c0,5.28,3.21,10.03,8.12,12l0,0l0,0c-15.06,8.98-33.52,10.17-49.62,3.21L667.96,1242.23z" />
          <path class="st91" d="M676.46,1200.73l43.43-0.34v22.35c-5.88,2.65-25.43,9.07-43.52-12.02L676.46,1200.73z" />
          <g>
            <path
              class="st91"
              d="M731.67,1165.82c0,0,5.69-4.63,8.83-1.43c3.32,3.38,2.57,14.79-8.51,16.03
              C731.99,1180.41,729.79,1174.48,731.67,1165.82z" />
            <image
              xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAUkAAAG6CAYAAACFjLQfAAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
              GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAACBNJREFUeNrs3d11E0sQhVFPh6H0
              lIEDIQPSIw1YfhDLFtZoNPRPVffez/cuZGM+n5JAfnsDAAAAKtt8Cjjq188fv3v+epfru69PRJI1
              gieiiCSCKKCIJIIooogkYiicIJKiiGgiksKIYCKSwohoIpLCiGgiksKIaCKSwohgIpIII4Ipkogj
              YimSiCOCKZKII2KJSIojgolIiiNiiUiKI4KJSIojiKVIiiMIpkiKIwilSAokiKVIiiOIpUgKJIgl
              IimOCCUiKZAgliIpjiCWIimQIJYiKY4glCIpkCCWIimOIJYiKZAglCIpkCCWIimQgFBOHUmBBKEU
              SYEEsRRJgQShFElxBKEUSYEEsRRJgQShFEmBBNYLZaoPUCBBKEVSIEEsRVIgQShFUiBBKEVSIIGZ
              Qxn2AxFIEEqRFEgQyuCK30bA4Em0JK1IsChFUiBBKEVSIIH8oQzxgAUShFIkBRJIGEqvbgNEXZJW
              JFiTIimQQOJQDnmAAglkCWX3ByeQQKZQeuEGIMqStCKBbGuy24MSSCBjKJ3bQBgRx9S26gcOWJQh
              lqRAApk5twFn96hz24oEsp/dzZakQALObYDJr9Ft1g8McHZbkgDZlqQVCcy0Jqv+ogIJzBZK5zZA
              jyVpRQIzrklLEkil9yArGR80gCUJEGCYlUwPFsCSBAg00EqGBwlgSQIEHGol8oMDsCQBAq/JEvFB
              AViSAAnWZIn0YAAsSYBEa7JEeBAAliRAwjUpkgA7Dr95pVMbyKLmG/NakgAiCayk5uVbev+CAJYk
              gEgCzH9yl16/EIAlCSCSAOuc3MWpDWBJAtakSAJ0W5JObQBLEnByiySASAI0sNWepgBRnXmfSUsS
              QCQBRBKgun/uc89HAjN79XlJSxJAJAFEEqC6L7e55yOBFbzyvKQlCSCSACIJIJIA/+uV11/Kmf8J
              wJIEQCQBRBLgztGnGEUSQCQBRBKg+sktkgAiCSCSACIJUNOz5yXLkf8IwJIEQCQBRBLgBXtPOYok
              gCUJIJIAIgkgkgAiCRDHo1e4RRJgb0n6J4kAzm0AkQQQSQCRBBBJgFC+eyFbJAEsSQCRBBBJAJEE
              EEkAkQRI5f6vAYkkgCUJIJIAIgkgkgAiCSCSAOl8/mtAIgmwtyQv1/fNpwHAuQ0gkgAiCSCSACIJ
              IJIAIgkgkgAiCYBIAjx2+/fbIglgSQKIJIBIAogkgEgCJImkN94FsCQBRBJAJAFEEkAkAXJE0ivc
              AJYkwEu+rMfbWwMBYEkCiCRAtXPbyQ1gSQKIJIBIAogkgEgChPTtP0X0CjeAJQkgkgAiCdDIw7dH
              87wkgCUJIJIAIgnQwO6PbPC8JGBJAiCSACIJUNnTHyPreUnAkgRAJAGqn9tObsCSBEAkAUQSoKLt
              6H/oeUnAkgRAJAGqn9tObsCSBOD8krQmAUsSgPNL0poELEkARBKgybnt5AYsSQDOL0lrErAkAUQS
              gCbntpMbmNnl+r5ZkgAtz+2P0vo0AiIJIJIAVI+kkxsQSQCRtCYBLEmAEZG0JgGRBBBJAJpE0skN
              iCSASFqTAJYkwI6mi897TQJZ3S5iSxJg1LntuUlAJAFE0poERBKAEZG0JgGRFEpAJAFE0poEsCQB
              AkbSmgREEiCxz2OujH4AAJYkgEhak4BICiUgkgAEiaQ1CYgkQFJhVpyfhwNEcH/dlqgPDMC5DSCS
              1iQgkkIJiCSASFqTAJYkwBGhF5u/OwmMvmItSYCs57bnJgGRBBBJaxIQSQCRtCYBkQRYzKMxVrJ/
              AACWJIBIWpOASAKIJIBIOrkBkQSY3974KrN9QACWJIBIWpOASAKEln6N+REPQMur1JIEEEmARSPp
              BRxAJAEGmWaFeQEHaHGJWpIAK0TSc5OASAKIpDUJiCRAilElkgArRdLJDYgkgEhak4BIAoQeUsUn
              AcCSBBBJaxIQSYCOllha3kYNOHthWpIAIgkgktXmNYAlCSznzGAqPjkAIgkgktYkIJIAHUdS8YkC
              EEkAkbQmAZEE6DiMRBJAJJ3cgEgCVLf8mvJek+BqtCQBRHLMdxlAJAGWHUEiaU0CIgkgktYkIJIA
              PYePSFqTgEgKJSCSANVZTQ/454rgIrQknd2ASAI4t53dQJMr0JIEEMk435UA57azG5hm1FiSACLp
              7Aac285uMGQsSQDntrMbEEmA+ONFJK1JQCSFEhBJwGARSWsSsCSFEhBJgPgjRSStSUAkAeNEJK1J
              QCSFEhBJgCCDRCStSUAkhRIQScAIEUm/kYBICiUQhD/EHfi5OJB3eFiSACLp7Aac285uMDZEUihB
              IJ3bfrOBIEQSMCxE0m86cI4/rAN5fhLiDwpL0qIERBIwIkTSFwJQnT+gQXh+EmKOB5EUShBI57Yv
              DsCStCjBULAkfaEAliQWJcaBJYlFCZYkFiWkHgQiKZQgkM5tAEvSmgQr0pL0BQVYkliV+KYvkggl
              AimSCCWkDeQHz0n6ggMsSYsSfFMXSaEUSgTSuY3TGyxJLEp8AxdJhBKBFEmEEoF0biOWMGMgRVIo
              QSCf8Oq2L1rAksSqxDdkkUQoEUiRRCgRSJFEKBFIkUQsEUiRRCwRSJFEKBFIkUQsYbFAiiRiiUCK
              JEKJQIokYok4iiRiiUCKJEKJQIokYolAiiRiiTiKJAilQIokCKY4iiSIpUAikggm4iiSiCXiKJII
              JuIokggm4iiSIJbiKJIgmMIokiCa4iiSIJyiKJIgmoKISCKcoohIwuCoCh8AAADAX38EGACplhKm
              gJjchgAAAABJRU5ErkJggg=="
              style="overflow: visible"
              width="329"
              height="436"
              transform="matrix(0.24 0 0 0.24 656.0481 1115.9042)"></image>
            <path
              class="st9"
              d="M667.11,1180.1c0,0,9.01-12.69,7.69-36.41c0,0,10.05-4.4,16.99-4.28c6.95,0.12,15.57,5.3,29.16,3.5
              c13.59-1.8,18.69-12.49,18.69-12.49s-2.71,0.4-4.33,0.41c0,0,3.65-4.93,7.67-13.62c0,0-3.32,0.96-16.61,0.17
              c0,0,3.63-3.54,5.32-7.72c0,0-10.38,3.17-23.93,2.19c-13.54-0.98-31.26-2.03-39.07,4.44c-7.81,6.48-8.09,7.68-8.09,7.68
              s1.97-10,5.54-12.38c0,0-8.76,4.77-8.54,13.98c0,0-5.13,1.23-7.74,2.31c-2.61,1.08,6.46-0.04,6.46-0.04
              s-13.66,26.06,8.09,58.85L667.11,1180.1z" />
            <path
              class="st9"
              d="M660.62,1123.97c0,0,5.12-11.18,15.08-16c9.96-4.82,30.41-3.43,37.88-6.99c0,0-5.05,7.87-17.07,12.33
              c-12.01,4.46-21.19,10.06-21.19,10.06L660.62,1123.97z" />
            <path
              class="st9"
              d="M727.65,1140.03c0,0,5.44,16.13,7.24,24.81c0,0,0.69-24.36-3.35-30.58
              C727.49,1128.04,727.65,1140.03,727.65,1140.03z" />
            <path
              class="st89"
              d="M671.17,1178.34c0,0-1.01-11.79-12.6-6.07c-4.6,2.27-3.23,15.61,10.4,16.02
              C668.97,1188.29,673.52,1186.66,671.17,1178.34z" />
            <g>
              <path class="st92" d="M721.1,1129.75l0.01,0C721.14,1129.63,721.13,1129.67,721.1,1129.75z" />
            </g>
            <path
              class="st93"
              d="M709.25,1191.06c-0.12,0.01-0.25-0.03-0.33-0.13c-0.68-0.78-0.77-1.46-0.32-2.53
              c0.24-0.57,0.85-0.7,1.35-0.81c0.14-0.03,0.28-0.06,0.41-0.1l0.43-0.14c0.79-0.25,1.54-0.48,2.17-0.92
              c0.65-0.45,1.22-1.28,1.05-2.1c-0.09-0.4-0.34-0.77-0.61-1.15l-0.06-0.09c-2.63-3.81-4.05-8.45-3.98-13.08
              c0-0.21,0.19-0.39,0.39-0.38c0.21,0,0.38,0.18,0.38,0.39c-0.06,4.47,1.3,8.96,3.85,12.63l0.06,0.09
              c0.3,0.43,0.61,0.88,0.73,1.43c0.25,1.16-0.5,2.29-1.36,2.89c-0.73,0.51-1.57,0.77-2.38,1.02l-0.43,0.13
              c-0.15,0.05-0.32,0.08-0.48,0.12c-0.35,0.07-0.72,0.15-0.8,0.35c-0.33,0.79-0.29,1.18,0.19,1.73c0.14,0.16,0.12,0.4-0.04,0.54
              C709.4,1191.02,709.33,1191.05,709.25,1191.06z" />
            <path
              class="st0"
              d="M693,1196.3c0,0,14.12,2.18,27.75-1.13c0,0-4.88,9.93-12.7,9.76C700.23,1204.76,693,1196.3,693,1196.3z" />
          </g>
        </g>
        <g>
          <path
            class="st9"
            d="M2647.96,1365.83c0.57-10.3,1.57-20.69,4.93-30.44c3.36-9.75,9.29-18.91,17.98-24.45
            c13.01-8.29,30.45-7.17,44.25-0.27c6.15-3.63,14.52-0.71,19.13,4.75c4.61,5.46,6.31,12.75,7.56,19.79
            c1.24,7.04,2.24,14.32,5.81,20.51c4.92,8.52,14.08,13.83,19.45,22.07c5.01,7.69,6.25,17.73,3.25,26.41
            c-3,8.68-10.17,15.81-18.87,18.76c7.81-3.4,13.27-11.63,13.37-20.14c0.09-8.52-5.18-16.87-12.92-20.44
            c9.6,8.52,6.92,25.44-2.64,34c-9.57,8.56-23.4,10.44-36.23,9.87c4.57-1.44,8.45-4.93,10.38-9.33
            c-14.12,7.22-30.49,9.95-46.18,7.7c-14.36-2.06-28.76-8.15-42.88-4.86c7.59-3.59,14.87-7.83,21.74-12.67
            c-10.11,0.9-20.21,1.79-30.32,2.69c8.73-1.68,15.31-9.33,18.39-17.67C2647.24,1383.78,2647.47,1374.71,2647.96,1365.83z" />
          <g>
            <path
              class="st9"
              d="M2575.45,1942.29c0,0-1.32-6.68-13.28-5.06c-11.96,1.62-10.8,7.92-10.8,7.92s0.93,9.09-0.4,13.67
              s-10.35,29.36-12.6,31.61c-2.25,2.25-9.72,15.14-8.94,17.09c0.78,1.94,3.8,8.39,5.74,9.87c1.94,1.48,24.47,3.74,36.98-4.41
              c12.51-8.15,12.75-24.46,23.09-33.55c10.34-9.08,10.73-16,10.73-16s-18.78-31.24-23.75-32.41
              C2582.21,1931.03,2581.66,1942.06,2575.45,1942.29z" />
            <path
              class="st0"
              d="M2567.64,2004.56c13.12-8.39,13.62-24.81,24.49-34.11c6.22-5.32,9.04-9.86,10.33-12.79
              c2.14,3.41,3.54,5.75,3.54,5.75s-0.39,6.91-10.73,16c-10.34,9.08-10.58,25.4-23.09,33.55c-12.51,8.15-35.04,5.88-36.98,4.41
              c-1.39-1.05-3.32-4.63-4.6-7.31C2536.27,2011.33,2555.99,2012.02,2567.64,2004.56z" />
            <path
              class="st0"
              d="M2562.4,1984.53c0.39,0,0.73-0.28,0.81-0.66c0.1-0.46-0.18-0.9-0.64-0.99
              c-0.54-0.12-13.39-2.91-20.86-0.83c-0.44,0.11-0.7,0.58-0.58,1.02s0.59,0.79,1.02,0.58c7.04-1.92,19.93,0.84,20.06,0.86
              C2562.28,1984.52,2562.35,1984.53,2562.4,1984.53z" />
            <path
              class="st0"
              d="M2566.67,1977.56c0.36,0,0.69-0.25,0.8-0.61c0.14-0.44-0.12-0.91-0.57-1.04
              c-0.51-0.14-12.33-3.53-22.78-0.4c-0.44,0.12-0.7,0.59-0.57,1.04c0.12,0.47,0.69,0.72,1.04,0.57
              c10.01-2.97,21.71,0.36,21.84,0.4C2566.52,1977.55,2566.59,1977.56,2566.67,1977.56z" />
            <path
              class="st0"
              d="M2568.75,1971.86c0.37,0,0.72-0.26,0.81-0.64c0.11-0.44-0.15-0.9-0.61-1.02
              c-0.58-0.15-14.51-3.7-22.49-0.43c-0.43,0.18-0.64,0.66-0.46,1.09c0.17,0.43,0.66,0.61,1.09,0.46
              c7.44-3.05,21.29,0.48,21.44,0.51C2568.62,1971.84,2568.69,1971.86,2568.75,1971.86z" />
            <path
              class="st0"
              d="M2571.31,1965.27c0.37,0,0.72-0.26,0.81-0.65c0.11-0.44-0.17-0.9-0.62-1.01c-0.65-0.14-16-3.77-23.01-0.06
              c-0.41,0.22-0.57,0.73-0.36,1.13c0.22,0.43,0.72,0.54,1.13,0.36c6.53-3.45,21.69,0.15,21.85,0.19
              C2571.18,1965.26,2571.24,1965.27,2571.31,1965.27z" />
            <path
              class="st0"
              d="M2573.37,1957.85c0.33,0,0.65-0.21,0.77-0.52c0.18-0.43-0.03-0.91-0.46-1.09
              c-0.51-0.21-12.95-5.16-22.89-0.11c-0.41,0.21-0.58,0.72-0.37,1.12c0.21,0.4,0.72,0.61,1.12,0.37
              c9.28-4.72,21.4,0.14,21.51,0.17C2573.16,1957.82,2573.26,1957.85,2573.37,1957.85z" />
            <path
              class="st0"
              d="M2551.61,1951.97c0.15,0,0.29-0.04,0.43-0.12c8.9-5.29,22.9-2.5,23.04-2.47c0.43,0.07,0.88-0.18,0.98-0.65
              c0.1-0.46-0.19-0.9-0.65-0.98c-0.59-0.15-14.76-2.97-24.23,2.66c-0.4,0.23-0.52,0.75-0.29,1.15
              C2551.04,1951.81,2551.32,1951.97,2551.61,1951.97z" />
            <path
              class="st0"
              d="M2569.96,1971.87c-0.46-0.08-0.76-0.51-0.69-0.97c0.69-4.04-1.68-8.08-3.8-10.73
              c-0.87-1.12-1.81-2.17-2.76-3.23c-1.81-2.03-3.67-4.13-5.07-6.58l-0.01-0.01c-1.09,0.84-2.04,1.75-2.86,2.58
              c-0.72,0.72-1.41,1.46-2.1,2.21c-1.31,1.42-2.68,2.9-4.18,4.2c-1.35,1.16-3.35,1.99-4.68,0.66c-0.36-0.37-0.62-0.88-0.75-1.52
              c-0.33-1.56,0.01-3.34,1.02-5.14c2.73-4.87,9.12-7.56,14.45-5.95c0.1,0.03,0.18,0.07,0.26,0.12l0.07-0.01
              c0.7-0.4,1.46-0.76,2.26-1.05c3.3-1.19,6.86-0.87,9.32,0.9c1.93,1.41,3.12,3.58,4.07,5.31c1.45,2.66,3.09,5.69,3.93,8.99
              c0.25,1.01,0.43,2.19-0.11,3.27c-0.88,1.78-3.23,2.15-4.94,1.37c-1.53-0.72-2.54-2.15-3.35-3.44
              c-0.75-1.17-1.45-2.37-2.15-3.58c-1.75-3-3.58-6.1-5.99-8.6c-0.9-0.94-1.73-1.45-2.47-1.56c-0.15,0.08-0.29,0.17-0.43,0.25
              c0.03,0.06,0.06,0.11,0.1,0.18c1.3,2.29,3.02,4.22,4.86,6.29c0.97,1.08,1.92,2.15,2.83,3.29c3.37,4.27,4.75,8.32,4.13,12.05
              c-0.07,0.41-0.43,0.7-0.83,0.7L2569.96,1971.87z M2561.71,1948.03c-0.1,0.03-0.19,0.07-0.28,0.1c0.55,0.35,1.12,0.8,1.68,1.39
              c2.54,2.64,4.42,5.83,6.21,8.92c0.7,1.19,1.39,2.37,2.13,3.52c0.73,1.13,1.53,2.29,2.66,2.82c0.95,0.44,2.32,0.28,2.73-0.59
              c0.29-0.59,0.15-1.41-0.03-2.13c-0.77-3.08-2.37-6.01-3.77-8.59c-0.87-1.59-1.96-3.58-3.59-4.76c-1.2-0.86-2.73-1.28-4.38-1.28
              C2563.99,1947.42,2562.83,1947.62,2561.71,1948.03z M2545.55,1954.18c-0.57,0.99-1.16,2.5-0.86,3.96
              c0.08,0.4,0.21,0.59,0.3,0.68c0.41,0.46,1.55-0.01,2.4-0.75c1.44-1.23,2.76-2.66,4.04-4.06c0.7-0.76,1.41-1.52,2.14-2.26
              c0.94-0.95,2.07-2.02,3.38-3C2552.51,1948.11,2547.73,1950.28,2545.55,1954.18z" />
          </g>
          <path
            class="st94"
            d="M2587.51,1896.45c0,0-7.91,33.3-4.44,38.22c3.47,4.92-3.3,10.55-3.3,10.55l-15.64,2.94l-8.6-1.97
            c0,0,6.95-12.87,2.64-46.51C2556.02,1882.89,2587.51,1896.45,2587.51,1896.45z" />
          <path
            class="st95"
            d="M2553.64,1860.96l-7.54,53.29c0,0,23.15,10.23,38.22,4.31s11.3-22.07,11.3-22.07l-19.38-29.07
            L2553.64,1860.96z" />
          <g>
            <path
              class="st94"
              d="M2626.67,1920.4c0,0-6.32-6.46-17.09-30.14l-28.8,11.04c0,0,20.59,28.93,19.25,38.06
              c-0.68,4.66,5.36,12.52,5.36,12.52l22.56-10.17c0,0,1.8-8.67,1.31-9.41C2628.77,1931.55,2626.67,1920.4,2626.67,1920.4z" />
            <g>
              <path
                class="st82"
                d="M2624.42,1917.3c0,0,17.35,11.78,30.49,34.53c0,0-0.53,4.63-9.84,12.16
                c-9.31,7.54-19.13,15.4-22.55,19.42c-3.42,4.03-19.14,27.88-41.71,28.79c0,0-7.36-10.14-6.17-14.84
                c1.19-4.7,21.48-43.32,21.1-47.71c-0.38-4.38-1.46-14.73,1.28-16.52c2.74-1.8,16.41-5.42,17.05-3.35
                c0.65,2.07-1.71,7.18-1.71,7.18S2627.67,1924.43,2624.42,1917.3z" />
              <path
                class="st0"
                d="M2618.19,1975.92c3.49-4.07,13.46-12.04,22.93-19.68c6.59-5.31,8.87-9.18,9.64-11.04
                c1.41,2.1,2.8,4.3,4.14,6.63c0,0-0.53,4.62-9.84,12.16c-9.31,7.54-19.13,15.4-22.55,19.42c-3.42,4.03-19.14,27.88-41.71,28.79
                c0,0-2.44-3.37-4.28-7.12C2598.97,2003.55,2614.74,1979.94,2618.19,1975.92z" />
              <path
                class="st0"
                d="M2591.77,1977.31c0.43,0,0.81-0.32,0.88-0.76c0.08-0.48-0.25-0.94-0.75-1.02l-7.03-1.15
                c-0.57-0.17-0.95,0.26-1.02,0.75c-0.08,0.48,0.25,0.94,0.75,1.02l7.03,1.15L2591.77,1977.31z" />
              <path
                class="st0"
                d="M2594.69,1972.18c0.43,0,0.8-0.3,0.88-0.75c0.08-0.48-0.25-0.95-0.73-1.04l-7.1-1.23
                c-0.46,0-0.94,0.23-1.04,0.73c-0.08,0.48,0.25,0.95,0.73,1.04l7.1,1.23C2594.54,1972.16,2594.64,1972.18,2594.69,1972.18z" />
              <path
                class="st0"
                d="M2597.11,1967.12c0.43,0,0.8-0.3,0.88-0.75c0.08-0.48-0.23-0.95-0.73-1.04l-7.7-1.37
                c-0.44-0.01-0.94,0.23-1.04,0.73c-0.08,0.48,0.23,0.95,0.73,1.04l7.7,1.37C2596.96,1967.11,2597.05,1967.12,2597.11,1967.12z" />
              <path
                class="st0"
                d="M2599.66,1961.44c0.43,0,0.81-0.32,0.88-0.76c0.08-0.48-0.26-0.94-0.75-1.02l-7.9-1.24
                c-0.55-0.15-0.95,0.26-1.02,0.75c-0.08,0.48,0.26,0.94,0.75,1.02l7.9,1.24C2599.57,1961.44,2599.62,1961.44,2599.66,1961.44z" />
              <path
                class="st0"
                d="M2602.47,1956.12c0.44,0,0.83-0.33,0.88-0.79c0.07-0.5-0.29-0.94-0.77-0.99l-8.12-0.97
                c-0.04-0.01-0.08-0.01-0.08-0.01c-0.47,0-0.86,0.33-0.91,0.79c-0.07,0.5,0.29,0.94,0.77,0.99l8.12,0.97L2602.47,1956.12z" />
              <path
                class="st0"
                d="M2605.64,1950.16c0.5,0,0.9-0.4,0.9-0.9c0-0.48-0.4-0.9-0.9-0.9l-9.93-0.04l0,0c-0.5,0-0.9,0.4-0.9,0.9
                c0,0.5,0.4,0.9,0.9,0.9L2605.64,1950.16L2605.64,1950.16z" />
              <path
                class="st0"
                d="M2595.26,1944.94c0.1,0,0.18-0.01,0.28-0.04c4.21-1.37,14.11-1.05,14.22-1.06h0.03
                c0.48,0,0.88-0.39,0.9-0.87c0.01-0.5-0.37-0.91-0.87-0.92c-0.46,0-10.31-0.3-14.83,1.15c-0.47,0.15-0.73,0.66-0.58,1.13
                C2594.53,1944.69,2594.89,1944.94,2595.26,1944.94z" />
              <path
                class="st0"
                d="M2617.71,1962c-0.97-1.37-2.44-2.28-4-3.24c-0.95-0.59-1.93-1.2-2.82-1.93c-1.89-1.56-3.23-3.6-4.54-5.56
                c-0.29-0.46-0.59-0.9-0.9-1.35c-1.35-1.99-3.23-4.4-5.76-5.37c-0.69-0.25-1.17-0.32-1.49-0.17c-0.33,0.12-0.65,0.47-0.98,0.83
                l-0.19,0.21c-1.77,1.91-4,3.31-6.46,4.04c-1.74,0.52-3.85,0.47-4.79-1.17c-0.58-1.01-0.44-2.37,0.34-3.41
                c0.69-0.92,1.71-1.45,2.43-1.75c2.8-1.24,6.18-1.6,9.91-1.05c0.91-1.93,2.62-3.49,4.62-4.18c2.29-0.8,4.97-0.46,6.94,0.86
                c1.74,1.12,2.9,2.82,4.03,4.6c0.28,0.41,0.57,0.9,0.65,1.5c0.08,0.64-0.1,1.3-0.52,1.84c-0.51,0.66-1.31,1.12-2.14,1.21
                c-1.3,0.21-2.53-0.35-3.44-0.73c-1.52-0.64-3.02-1.26-4.54-1.79c1.15,1.12,2.08,2.4,2.86,3.53c0.32,0.46,0.62,0.91,0.92,1.37
                c1.28,1.95,2.5,3.78,4.17,5.18c0.8,0.65,1.68,1.2,2.62,1.78c1.64,1.02,3.35,2.08,4.53,3.75c0.29,0.4,0.19,0.95-0.22,1.24
                c-0.15,0.11-0.33,0.17-0.51,0.17C2618.16,1962.39,2617.89,1962.25,2617.71,1962z M2589.27,1944.75
                c-0.86,0.37-1.38,0.75-1.71,1.2c-0.36,0.46-0.46,1.06-0.23,1.44c0.4,0.7,1.67,0.66,2.72,0.36c2.17-0.65,4.13-1.88,5.67-3.55
                l0.18-0.21c0.11-0.11,0.22-0.23,0.35-0.36C2593.59,1943.52,2591.28,1943.86,2589.27,1944.75z M2603.67,1939.57
                c-1.41,0.5-2.62,1.53-3.37,2.83c3.06,0.69,6.02,1.86,9,3.11c0.93,0.4,1.89,0.79,2.53,0.62c0.36-0.04,0.73-0.26,0.95-0.54
                c0.12-0.17,0.18-0.35,0.17-0.5c-0.03-0.25-0.21-0.52-0.39-0.79c-1.06-1.68-2.06-3.15-3.49-4.07
                c-0.99-0.65-2.21-0.98-3.41-0.98C2604.98,1939.25,2604.3,1939.35,2603.67,1939.57z" />
            </g>
          </g>
          <path
            class="st13"
            d="M2499.23,1758.1c4.31,3.77,82.36,164.72,82.36,164.72s30.68-2.69,40.37-19.38l-16.15-38.76
            c0,0,22.61-66.21,21.53-90.43c0,0,102.28-57.6,114.66-74.82c12.38-17.23-5.92-69.98-5.92-69.98l-99.05-26.38
            c0,0-9.69,25.3-11.3,25.3s-96.89,43.6-123.27,88.82c0,0-9.69,14.53-10.23,19.38S2494.92,1754.33,2499.23,1758.1z
              M2557.36,1743.03c2.69-0.54,2.15-6.46,2.15-6.46c46.83-25.3,83.97-55.98,83.97-55.98s-64.6,55.98-67.83,63.52
            c-3.23,7.54-5.38,33.37-5.38,33.37S2554.67,1743.57,2557.36,1743.03z" />
          <path
            class="st75"
            d="M2605.82,1865.23c-0.22,0-0.41-0.13-0.51-0.34l-35.53-87.2c-0.11-0.28,0.03-0.6,0.3-0.71
            c0.26-0.12,0.58,0.02,0.72,0.3l35.53,87.2c0.11,0.28-0.03,0.6-0.3,0.71C2605.95,1865.22,2605.88,1865.23,2605.82,1865.23z" />
          <path
            class="st75"
            d="M2640.8,1682.48c0,0-0.14-0.01-0.19-0.03c-0.29-0.11-0.43-0.43-0.32-0.71l6.87-17.6
            c-6.47-12.79,1.39-36.54,1.74-37.55l0.12-0.37h14.11l-14.8,37.9c0.03,0.18-0.04,0.36-0.18,0.48l-6.85,17.55
            C2641.22,1682.35,2641.02,1682.48,2640.8,1682.48z M2649.81,1627.3c-0.97,3.02-7.19,23.7-2.1,35.43l13.82-35.43H2649.81z" />
          <path
            class="st75"
            d="M2683.59,1683.02c0,0-0.1-0.01-0.14-0.02c-0.3-0.08-30.43-8.32-36.73-8.32h-0.19l-0.14-0.12
            c-7.51-5.92-22.67-17.24-26.37-17.64c-0.3-0.03-0.52-0.3-0.48-0.61c0.03-0.29,0.32-0.46,0.59-0.48
            c4.79,0.54,24.7,16.12,26.78,17.76c6.82,0.15,35.59,8.03,36.82,8.37c0.29,0.08,0.47,0.38,0.39,0.67
            C2684.05,1682.86,2683.83,1683.02,2683.59,1683.02z" />
          <path
            class="st75"
            d="M2730.53,1672.74c-5.36,0-11.26-1.09-16.26-4.65c-8.45-6.02-12.42-17.58-11.83-34.35
            c0-0.29,0.25-0.52,0.54-0.52h0.01c0.3,0.01,0.54,0.26,0.52,0.57c-0.58,16.38,3.26,27.62,11.39,33.42
            c12.27,8.75,30.48,1.91,30.67,1.84c0.21-0.09,0.58,0.03,0.7,0.32c0.11,0.28-0.03,0.59-0.32,0.7
            C2745.85,1670.1,2738.86,1672.74,2730.53,1672.74z" />
          <path
            class="st75"
            d="M2627.49,1629.07c-0.59,0-1.21-0.05-1.86-0.16c-0.29-0.05-0.5-0.33-0.44-0.63
            c0.04-0.27,0.28-0.46,0.54-0.46c0,0,0.06,0,0.1,0.01c0.57,0.1,1.13,0.15,1.66,0.15c7.79,0,11.69-9.66,11.73-9.76
            c0.1-0.28,0.41-0.43,0.72-0.3c0.28,0.11,0.41,0.42,0.3,0.7C2640.19,1618.73,2635.99,1629.07,2627.49,1629.07z" />
          <path
            class="st70"
            d="M2648.87,1497.03c0,0-22.61,80.74-41.45,78.05c-18.84-2.69-50.6-104.43-50.6-104.43l17.23-6.46
            l32.84,52.75l11.84-35.53L2648.87,1497.03z" />
          <path
            class="st70"
            d="M2747.05,1513.46c0,0,14.08,45.15,15.92,53.09c1.84,7.93,30.54,81.43,42.04,99.79
            c11.5,18.36,24.01,24.73,27.43,26.56c3.42,1.83,15.95,11.18,15.95,11.18s1.89-4.69-2.93-8.01c-4.83-3.32-15.69-9.49-15.69-11.29
            c0.01-1.8,20.34-0.33,20.34-0.33s18.86,18.47,28.32,14.31l-10.7-10.06c0,0,11.53,7.41,16.62,4.08l-7.67-6.7
            c0,0,6.49,4.24,11.33,2.56c0,0-25.95-21.03-30.84-24.07c-4.89-3.04-23.55,1.18-30.8-6.77c-7.25-7.95-26.34-78.95-33.06-96.33
            c-6.72-17.38-12.27-60.11-12.27-60.11L2747.05,1513.46z" />
          <path
            class="st15"
            d="M2670.08,1435.99c0,0-30.9-1.82-44.35,12.6c-5,5.35-13.46,47.37-16.15,52.75l15.07,4.84l-3.23,10.77
            c0,0-1.08,17.76,15.61,24.22c0,0,3.77,37.68,3.77,39.83s-3.23,11.3-3.23,14c0,2.69-8.07,23.15-8.07,23.15
            s27.99,19.38,45.76,21.53c17.76,2.15,67.29-4.31,67.29-4.31s-1.08-8.07-2.69-11.3c-1.61-3.23-2.69-9.69-3.23-16.15
            s2.15-11.84-4.31-17.23l14.53-61.9l2.69,9.15c0,0,22.61,0.54,46.83-9.15c0,0-13.46-31.22-18.3-43.6
            c-4.84-12.38-14.95-43.84-29.07-47.91C2733.15,1432.71,2670.08,1435.99,2670.08,1435.99z" />
          <path
            class="st70"
            d="M2725.58,1435.39c0,0-40.1,26.11-56.25,0.27l4.83-1.07c5.34-1.18,9.27-5.71,9.69-11.16l2.53-33.17
            l30.32,2.6l0.27,38.76L2725.58,1435.39z" />
          <path
            class="st96"
            d="M2684.08,1420.32l2.29-30.05l30.32,2.6l0.1,14.39C2711.68,1413.28,2700.69,1422.87,2684.08,1420.32z" />
          <g>
            <image
              xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAATkAAAGpCAYAAAD2h0GyAAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
              GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAB7VJREFUeNrs3d1NI1sQhVF8RBKk
              RwZE4gycHmEwDyMkxI/dtruPa1ev9T5cxlx92tXg4ekJAAAAAJjp4CVgqffT8aPC5/Hy+ub/W0SO
              3IgJISKHoAkfIseegyZ8iJygIXoih7AhfCKHsIkdIoewCR4ih6gJHiInbAgeIiduCB4iJ26Incgh
              boidyCFsiJ3IiRuInciJG2KHyIkbYofIiRtih8iJG2IncggcYidy4gZiJ3LiBmIncgIHQidy4gZi
              J3LihtAhcgKH2ImcuIHQiZzAgdCJnMCB2ImcwIHQiZy4gdiJnMCB0O02cgKH2ImcwIHQiZy4gdiJ
              nMCB0ImcwIHQiZzAgdCJnMCB0LWPnMCB0LWNnMCB0LWNnMCB0LWNnMCB0LWNnMCB0LWNnMCB2LWN
              nMCB0LWNnMCB0LWNnMCB0LWNnMCB0LWNnMCB0F1r+DIBnYfH8GICnUWcqwIHzta2kRM4ELq2kRM4
              ELp7+cYD0HqYHLxYQOdFV3LJCRzQdskJHFhz7ZcckK/KYDl4UYDOi86SA1qPl4MXAui86EosOYED
              nKuAszXxXLXiwNlqyQEkLjkrDqy5tpETOBA65ypA4pKz4oBZa86SAx5mxuAZHf9SAA85VwUOmH22
              OlcBS86KA1LXnCUHlLDVEBrJnzzAw89VgQMeebY6V4HWZ+tI+mQBypyrAgdUOFudq0BrIgeUtNY1
              OCp/cgCWHGDNzYycFQdYcgCThtOo9MkAWHKANSdyAP+t9o4HpyqwtVveBWHJAZacFQekrjlLDmjt
              7shZcUDryAHMdO2wGjP/YwCWHMCKA0vkAEvOqQo4VwEKnqxjyw8OYMkBbLjmxhYfFMCSAxA5gPuu
              y7HmBwOw5ABEDmCdK3Os8UEALDkAkQMQOYAf/nqkNu75wwCWHIDIAWzj4u8vdKoCSb7/XlZLDnCu
              AogcQEFnn8l5Hgck+vpczpIDnKsA7SLnVAUsOQCRA5jr6yUqcsD+lpzncYBzFUDkAEQOYF7kPI8D
              LDkAkQMQOYBNfD56EznAkgMQOYDqkfPjI4AlByByACIHIHIAIgcgcoDIAYgcgMgBiByAyAGIHIDI
              ASIHIHIAIgcgcgAiByByACIH7MfL69tB5ABLDkDkAEQOQOQARA7gpsh9fssVwJIDEDkAkQNY1ddH
              byIHWHIAIgcgcgAiByByACIH7M73d26JHGDJAYgcgMgBbOe3f0lJ5ABLDkDkAEQOQOQArvbXr28Q
              OcCSAxA5AJEDWN+5X6cqcoAlByByACIHIHIAi537poPIAZYcgMgBiBzAui49j/s1ckv+EIAlByBy
              ACIH8MPSR2siB1hyACIHIHIAIgdw1jU/zytygCUHIHIAxU5VkQMsOQCRAyh4qoocYMkBiBxAwVNV
              5ABLDiB1xYkcYMkBiBxAwVNV5ABLDkDkAAqeqiIHWHIAyf6cgu+n44eXB0g+VS05wLkKIHIABU9V
              kQMsOQCRAyh4qoocYMkBiBxAwVNV5ABLDkDkAAqeqiIHWHIAqStO5ABLDkDkAAqeqiIHWHIAqStO
              5ABLDkDkAAqeqiIHWHIAqSvubORmfhIAlhyAyAFOVZEDLDkAkQMofaqKHGDJAaSuOJEDLDkAkQMo
              eKpejJy3dgGWHIDIAU5VkQMQOcCKEzkAkQNEDiD4VBU5wJIDSF1xIgdYct7aBThXAYoOI5EDLDkA
              kQOcqgWf4Ysc0Nri6r6fjh9eLiBpxVlyQHsiB4gcQOKpKnKAJQeQuuJEDmjvqgL7MRIgacVZckB7
              Ige0XXEiB1hyACIHOFU7RM4/hQ5YcoAVJ3IAIgdYcfMj57kcYMkBVpzIAWzv5jJ7sz5YcZYcgMgB
              iBzgVJ0bOT9KAlhygBUncgAiB1hx8yPnuRxgyQFWnMgBbGOVWnuLF1hxlhyAyAFWXMFz1ckKAmfJ
              AYgcYMUVjZwfDAYsOcCKS46cNQdYcoAVJ3KAwBWNnJMVsOQAK07kAO63Wc29zQusOEsOIHXJWXNg
              xVlyACIHWHFFz1UnKwicJQeQvOSsObDiLDmA5CVnzYEVZ8kBJC85aw6sOEsOELjkJWfNgcBZcgDJ
              S86aAyvOkgMETuQAip6rTlaw4iw5QOCSl5w1BwJnyQEkLzlrDqw4Sw4QuOTI+WIBlhxgGNyozIvl
              2RwInCUHAofIARQ8V52sYMVZciBwJC85aw4EzpIDSF5y1hxYcZYcCBzJS86aQ+Cw5EDgSI6cLzZg
              yYEVxwXlX1TP5hA4LDkQOEQOBM656mQFgRM5oQOBc64CAmfJWXMIHJYcCBx7WXLWHAJH+8gJHQKH
              cxUEjuQlZ80hcLSPnNAhcDhXQeAsufS/gDWHwNE6ckKHwOFcBYGz5Kw5EDiREzoQOJETOgQOkRM6
              xI0pfOMBBM6Ss+ZA4ERO6BA4RE7oEDhETugQN0RO6BA4lnj2EoC4WXLWHAKHyAkdAofIiR3ihsgJ
              HQKHyAkd4obICR0CJ3IInbghckKHuCFyQoe4IXJCh7ghcmKHuCFyQofAIXJCJ26IHGInbogcQids
              iJzYIW6InNAhbIic2AkbiJzYiRuInNgJGyKH2IkaIofYiRoixy6DJ2qIHG1iJ2iIHPHBEzJEjvjo
              CRkiR3z4hAwAAMj1T4ABAMMT95rYrhGrAAAAAElFTkSuQmCC"
              style="overflow: visible"
              width="309"
              height="420"
              transform="matrix(0.24 0 0 0.24 2660.7681 1311.9843)"></image>
            <path
              class="st97"
              d="M2686.03,1386.53c0.12,0.02,0.25-0.03,0.33-0.13c0.49-0.58,0.67-1.44,0.47-2.2
              c-0.04-0.15-0.15-0.26-0.29-0.29c-0.85-0.2-1.74-0.4-2.46-0.9c-0.59-0.41-1.22-1.17-1.19-2.09c0.02-0.7,0.4-1.35,0.8-2.03
              l0.17-0.3c2.42-4.2,2.69-9.58,2.9-13.89c0.01-0.24-0.17-0.46-0.36-0.45c-0.21-0.01-0.4,0.17-0.41,0.41
              c-0.22,4.44-0.48,9.46-2.78,13.48l-0.17,0.29c-0.42,0.72-0.9,1.53-0.92,2.47c-0.03,1.08,0.57,2.17,1.55,2.84
              c0.76,0.53,1.65,0.76,2.46,0.95c0.04,0.4-0.08,0.84-0.33,1.13c-0.15,0.17-0.14,0.44,0.02,0.61
              C2685.88,1386.49,2685.95,1386.52,2686.03,1386.53z" />
            <path
              class="st0"
              d="M2674.99,1389.96c0,0,14.26,2.27,22.05,0.97c0,0-5.84,10.05-11.51,8.92
              C2679.86,1398.72,2674.99,1389.96,2674.99,1389.96z" />
            <path
              class="st9"
              d="M2658.93,1339.93c1.45-5.99,4.44-11.49,7.69-16.73c2.23-3.59,4.7-7.21,8.27-9.48
              c3.09-1.96,6.77-2.76,10.4-3.16c15.34-1.71,31.3,3.3,42.93,13.45c3.16,2.76,6.06,5.95,7.77,9.79c1.96,4.38,2.25,9.3,2.17,14.1
              c-0.14,8.26-1.77,17.32-8.16,22.57c-12.64-10.28-20.51-26.19-21.02-42.47c-9.02,8.71-18.49,16.95-28.36,24.69
              c-4.89,3.83-10.05,7.75-12.71,13.36c0.12-5.73,3.48-10.91,7.38-15.1c3.91-4.2,8.48-7.78,11.96-12.34
              c-10.99,5.96-20.85,14.01-28.89,23.6C2657.76,1354.76,2657.17,1347.18,2658.93,1339.93z" />
            <g>
              <path
                class="st70"
                d="M2724.94,1372.38c0,0,2.74-10.05,10.85-3.05c3.22,2.78-0.06,14.11-10.78,12.07
                C2725.01,1381.41,2721.72,1379.19,2724.94,1372.38z" />
            </g>
          </g>
          <g>
            <path
              class="st70"
              d="M2574.59,1467.76l2.76-11.06c0,0,2.77-24.77-0.16-25.99c-2.93-1.22-28.32,1.83-28.32,1.83
              s-1.31,18.89,6.21,31.65c0.13,0.22,8.48,26.32,8.48,26.32L2574.59,1467.76z" />
            <path
              class="st70"
              d="M2573.36,1466.45c0,0,6.42-7.61,12.4-20.64c2.28-4.97,11.76-14.61,8.41-15.96
              c-3.35-1.35-13.1,12.32-14.23,12.04c-1.12-0.28-3.1-9.34-3.1-9.34l-4.96,14.08L2573.36,1466.45z" />
            <path
              class="st70"
              d="M2549.57,1435.8c0,0-10.55-18.79-7.82-19.81c1.45-0.55,13.09,14.72,13.09,14.72s-8.09-32.97-5.47-34.04
              c2.63-1.06,12.18,30.2,12.18,30.2s-1.23-30.15,1.34-30.42c2.58-0.27,7.21,32.8,7.21,32.8s2.06-28.9,4.36-29.08
              c2.31-0.18,3.27,25.08,3.27,25.08l-0.18,10.91L2549.57,1435.8z" />
          </g>
          <path
            class="st9"
            d="M2673.17,1523.96c0,0,29.81,19.63,53.44,4.73C2726.61,1528.69,2701.89,1565.04,2673.17,1523.96z" />
        </g>
      </g>
      <g id="Neu_Dazu"></g>
    </g>
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="sass" scoped>

.st0
  fill:#FFFFFF
.st1
  fill:#00376B
.st2
  fill:#00376B
.st3
  fill:#0080F7
.st4
  // opacity:0.68
.st5
  fill:#005FB8
.st6
  fill:#004B91
.st7
  fill:#0058AA
.st8
  fill:#00376B
.st9
  fill:#00376B
.st10
  fill:#FFC500
.st11
  fill:#FFC500
.st12
  fill:#FFC500
.st13
  fill:#005FB8
.st15
  fill:#004B91
.st18


  fill:#00376B
  stroke:#000000
  stroke-width:0.7087
  stroke-miterlimit:3.8637
.st19


  fill:#00376B
  stroke:#000000
  stroke-width:0.2835
  stroke-miterlimit:3.8637
.st20


  fill:#0058aa
  stroke:#00376B
  stroke-width:0.7087
  stroke-miterlimit:3.8637
.st21


  fill:#000000
.st22


  fill:#0058aa
  stroke:#000000
  stroke-width:0.5669


  stroke-miterlimit:3.8637
.st23


  fill:#0058aa
.st24


  fill:#FFC500
  stroke:#E6B000
  stroke-width:0.7087
  stroke-miterlimit:3.8637
.st25


  fill:#000000
  stroke:#000000
  stroke-width:0.7087


  stroke-miterlimit:3.8637
.st26


  fill:#FFC500
  stroke:#E6B000
  stroke-width:1.0001


  stroke-miterlimit:3.8637
.st27


  fill:#FFC500
  stroke:#E6B000
  stroke-width:0.7087


  stroke-miterlimit:3.8637
.st28


  fill:#FFC500
.st29


  fill:#FFC500
  stroke:#E6B000
  stroke-width:0.7087


  stroke-miterlimit:3.8637
.st30


  fill:#FFC500
  stroke:#FFC500
  stroke-width:0.5669


  stroke-miterlimit:3.8637
.st31


  fill:#FFC500
  stroke:#FFC500
  stroke-width:1.0001
  stroke-miterlimit:3.8637
.st32


  fill:#FFC500
  stroke:#FFC500
  stroke-width:1.0001


  stroke-miterlimit:3.8637
.st33


  fill:#F59B00
.st34


  fill:#004B91
  stroke:#000000
  stroke-width:0.5669


  stroke-miterlimit:3.8637
.st35


  fill:#FFFFFF
  stroke:#181716
  stroke-width:0.5669


  stroke-miterlimit:3.8637
.st36


  fill:#00498D
  stroke:#00498D
  stroke-width:1.0001
  stroke-miterlimit:3.8637
.st37


  fill:#E6B000
  stroke:#E6B000
  stroke-width:0.5669
  stroke-miterlimit:3.8637
.st38


  fill:#F59B00
  stroke:#5D5D5C
  stroke-width:0.5669


  stroke-miterlimit:3.8637
.st39


  fill:#DEA71B
  stroke:#DEA71B
  stroke-width:0.5669
  stroke-miterlimit:3.8637
.st40


  fill:#F59B00
  stroke:#E6B000
  stroke-width:0.5669


  stroke-miterlimit:3.8637
.st41


  fill:#181716
  stroke:#E6B000
  stroke-width:1.0001


  stroke-miterlimit:3.8637
.st42


  fill:#00498D
  stroke:#FFFFFF
  stroke-width:0.2835
  stroke-miterlimit:3.8637
.st43


  fill:#00498D
  stroke:#181716
  stroke-width:0.5669
  stroke-miterlimit:3.8637
.st44


  fill:#005BA9
  stroke:#181716
  stroke-width:0.5669
  stroke-miterlimit:3.8637
.st45


  fill:#DEA71B
  stroke:#DEA71B
  stroke-width:0.5669


  stroke-miterlimit:3.8637
.st46


  fill:#E6B000
  stroke:#F59B00
  stroke-width:0.5669


  stroke-miterlimit:3.8637
.st47


  fill:#F59B00
  stroke:#E6B000
  stroke-width:0.5669
  stroke-miterlimit:3.8637
.st48


  fill:#181716
  stroke:#315B9A
  stroke-width:0.5669


  stroke-miterlimit:3.8637
.st49


  fill:#E6B000
  stroke:#E6B000
  stroke-width:1.0001


  stroke-miterlimit:3.8637
.st51




  stroke:#315B9A
  stroke-width:0.6803


  stroke-miterlimit:3.8637
.st52




  stroke:#315B9A
  stroke-width:0.5669


  stroke-miterlimit:3.8637
.st53


    fill:#181716
    stroke:#181716
    stroke-width:0.5669


    stroke-miterlimit:3.8637
.st54


  fill:#00498D
  stroke:#00498D
  stroke-width:0.7087


  stroke-miterlimit:3.8637
.st55


  fill:#005BA9
.st56
  fill:#005BA9
  stroke:#315B9A
  stroke-width:1.0001
  stroke-miterlimit:3.8637
.st57
  fill:#005BA9
  stroke:#00498D
  stroke-width:0.7087
  stroke-miterlimit:3.8637
.st58
  fill:#839CC3
  stroke:#839CC3
  stroke-width:0.5669
  stroke-miterlimit:3.8637
.st59
  fill:#315B9A
  stroke:#315B9A
  stroke-width:1.0001
  stroke-miterlimit:3.8637
.st60
  fill:#00498D
  stroke:#315B9A
  stroke-width:0.9921
  stroke-miterlimit:3.8637
.st61
  fill:#0058AA
  stroke:#0058AA
  stroke-width:0.9921
  stroke-miterlimit:3.8637
.st62
  fill:#00376B
  stroke:#00376B
  stroke-width:0.9921
  stroke-miterlimit:3.8637
.st60, .st61, .st62, .st63
  fill:#0058aa
  stroke:#0058aa
  stroke-width:0.9921
  stroke-miterlimit:3.8637
.st64
  stroke:#FFFFFF
  stroke-width:0.9921
  stroke-miterlimit:3.8637
.st65
  fill:#0058aa
  stroke:#839CC3
  stroke-width:0.9921
  stroke-miterlimit:3.8637
.st66
  fill:#00498D
  stroke:#00498D
  stroke-width:0.5669
  stroke-miterlimit:3.8637
.st67
  stroke:#839CC3
  stroke-width:0.5669
  stroke-miterlimit:3.8637
.st68
  stroke:#5D5D5C
  stroke-width:1.0001
  stroke-miterlimit:3.8637
.st69


  fill:#0058aa
  stroke:#BAB9B9
  stroke-width:0.8504
  stroke-miterlimit:3.8637
.st70
  fill:#E89F90
.st71
  fill:#004B91
.st72
  fill:#F0EBE9
.st73
  fill:#333333
.st74
  fill:#BB5151
.st75
  fill:#0058aa
.st76
  fill:#D67871
.st77
  fill:#E4887F
.st78
  fill:#FFC500
.st79
  fill:#3B6894
.st80
  fill:#4478B8
.st81
  fill:#FFC500
.st82
  fill:#00376B
.st83
  fill:#005FB8
.st84
  opacity:0.58
  fill:#004B91
.st85
  opacity:0.62
.st86
  opacity:0.25
  fill:#000000
.st87
  fill:#C9E4ED
.st88
  fill:#515151
.st89
  fill:#E69D8E
.st90
  fill:#37374F
.st91
  fill:#E2867F
.st92
  opacity:0.25
  fill:#FFFFFF
.st93
  fill:#B95151
.st94
  fill:#E79E8F
.st95
  fill:#0058aa
.st96
  fill:#E3867F
.st97
  fill:#C25353
.st99
  fill:#D99B3A
.st100
  fill:#CEE6FA
.st101
  fill:#DC4738
.st102
  fill:#253259
.st103
  opacity:0.52
  fill:#5F4071
.st104
  fill:#FFFFFF
.st105
  fill:#FFFFFF
.st106
  fill:#151E35
</style>
