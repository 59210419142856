import colors from 'tailwindcss/colors';

import { AKColorScheme, appColorScheme } from '../../../../../src/AppThemeConfig';

// Farben aus ABUSFarbdefinition
const ABUSColorDefinition = {
  'ak-blue': '#005aaa',
  'ak-blue-01': '#00467B',
  'ak-blue-lighter': '#E5EEF5',
  'ak-blue-grey': '#CFD9E5',
  'ak-yellow-02': '#FFC500',
  'ak-yellow-04': '#FFAA00',
  'ak-yellow-ll': '#FFE27F',
  'ak-yellow-hover': '#CC9E00',
  'ak-creme': '#fcfbd0',
  'ak-grey': '#ededed',
  'ak-grey-1': '#dad7ce',
  'ak-grey-2': '#C0BEB5',
  'ak-grey-3': '#A6A59D',
  'ak-grey-4': '#807F79',
  'ak-grey-5': '#5A5955',
  'ak-grey-6': '#403F3C',
  'ak-grey-7': '#262624',
  'ak-grey-8': '#231F20',
  'ak-red': '#EF4444',
  'ak-red-hover-background': '#E05420',
  'ak-green': '#53C200',
  'ak-green-hover': '#429B00',
};

const ABUSVitalColor = '#c8d732';

// ABUS-Farbschema für Tailwind
const akColorsDefault = {
  theme: '#ffffff',
  'theme-inv': '#000000',
  backdrop: '#F0F7FF',
  primary: {
    DEFAULT: ABUSColorDefinition['ak-blue'],
    1: ABUSColorDefinition['ak-blue-01'],
  },
  secondary: {
    DEFAULT: ABUSColorDefinition['ak-yellow-02'],
    1: ABUSColorDefinition['ak-yellow-04'],
  },
};

// ABUS Vital-Farbschema für Tailwind
const akColorsVital = {
  theme: '#ffffff',
  'theme-inv': '#000000',
  backdrop: '#fafafa',
  primary: {
    DEFAULT: ABUSVitalColor,
    1: '#aec022',
  },
  secondary: {
    DEFAULT: ABUSColorDefinition['ak-yellow-02'],
    1: ABUSColorDefinition['ak-yellow-04'],
  },
};

let akColorScheme;

switch (appColorScheme.ColorScheme) {
  case AKColorScheme.VITAL:
    akColorScheme = akColorsVital;
    break;
  default:
    akColorScheme = akColorsDefault;
}

export const akColors = akColorScheme;

const tailwindColors = colors as unknown as { [key: string]: string };

delete tailwindColors['lightBlue'];
delete tailwindColors['warmGray'];
delete tailwindColors['trueGray'];
delete tailwindColors['coolGray'];
delete tailwindColors['blueGray'];

export const fullColorSet = { ...colors, ...akColors };
