<template>
  <div class="flex flex-col gap-1">
    <div class="flex items-center gap-4">
      <h6 v-text="t('AKAppSearchResultsNavigation.headline')" />
      <AKDivider />
    </div>

    <router-link
      v-for="navigationItem in foundRoutes"
      :key="navigationItem.link"
      class="hover:bg-backdrop hover:border-primary flex flex-col rounded-lg border border-transparent p-1.5"
      custom-iterable
      tabindex="0"
      :to="{ name: navigationItem.name }"
      @click="emitResetInput">
      <span class="ak-text-lg !font-bold" v-text="navigationItem.title" />
      <AKBreadcrumbs :show-last-angle="false" :items="getBreadcrumbItems(navigationItem)" />
    </router-link>

    <span v-if="!foundRoutes.length" class="ak-text-lg" v-text="t('AKAppSearchResultsNavigation.no-results-found')" />
  </div>
</template>

<script setup lang="ts">
import { AKBreadcrumbItem, AKBreadcrumbs } from '@ak_tools/components/menu';
import { AKDivider } from '@ak_tools/components/panel';
import { AKStore } from '@ak_tools/start/App';
import { AppNavigationItem } from '@ak_tools/store/app/types';
import { computed, inject, Ref, ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

type AKSearchResultNavigationItem = {
  name: string;
  title: string;
  link: string;
  path: string[];
};

//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const searchInputValue = inject('searchInputValue') as Ref<string>;
const foundRoutes = ref<AKSearchResultNavigationItem[]>([]);

//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const navigationItems = computed<AKSearchResultNavigationItem[]>(() => {
  const flattenNavigationMenu = (items: AppNavigationItem[]): AKSearchResultNavigationItem[] => {
    const result: AKSearchResultNavigationItem[] = [];
    const itemSet = new Set<string>();

    const addToResult = (item: AppNavigationItem) => {
      if (item.link && !itemSet.has(item.link) && showItemsByRights(item.viewRights)) {
        result.push({ name: item.name, title: t(item.title), link: item.link, path: item.tags });
        itemSet.add(item.link);
      }
    };

    const processItem = (item: AppNavigationItem) => {
      addToResult(item);
      if (item.children) {
        item.children.forEach(processItem);
      }
    };

    items.forEach(processItem);

    return result;
  };

  return flattenNavigationMenu(AKStore.App.state.navigationMenu);
});

//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const findRoutes = (searchStr: string) => {
  foundRoutes.value = navigationItems.value.filter((navigationItem) => {
    const lowerCaseSearchString = searchStr.toLowerCase();

    return Object.values(navigationItem).some((value) => {
      if (typeof value === 'string') {
        return t(value).toLowerCase().includes(lowerCaseSearchString);
      }

      return value.some((item) => item.toLowerCase().replaceAll('-', ' ').includes(lowerCaseSearchString));
    });
  });
};

const showItemsByRights = (viewRights?: string[]) => {
  if (!viewRights) {
    return true;
  }

  return AKStore.User.state.application.modulesTitles.some((module) => viewRights.includes(module));
};

const getBreadcrumbItems = (navigationItem: AKSearchResultNavigationItem) => {
  const result: AKBreadcrumbItem[] = [];

  const kebabToTitleCase = (kebabCaseString: string) => {
    return kebabCaseString
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  result.push({ title: t('AKAppSearchResultsNavigation.breadcrumbs.menu') });

  navigationItem.path.slice(0, -1).forEach((breadcrumb) => {
    result.push({ title: kebabToTitleCase(breadcrumb) });
  });

  result.push({ title: t(navigationItem.title) });

  return result;
};

//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watchEffect(() => {
  findRoutes(searchInputValue.value);
});

//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  resetInput: [];
}>();

const emitResetInput = () => {
  emits('resetInput');
};
</script>

<style lang="sass" scoped></style>
