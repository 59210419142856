<template>
  <Accordion :multiple="props.openMultiple" :active-index="props.activeIndex">
    <slot></slot>
  </Accordion>
</template>

<script setup lang="ts">
interface PropsInterface {
  openMultiple?: boolean | undefined;
  activeIndex?: number | number[] | null | undefined;
}
import Accordion from 'primevue/accordion'
//  --------------------------------------------------------------------------------------------------------------------
//  props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  openMultiple: undefined,
  activeIndex: undefined,
});
</script>

<script lang="ts"></script>

<style scoped></style>
