/**
 * https://date-fns.org/docs/Getting-Started
 */

import { format, getHours, getTime, isPast, parse } from 'date-fns';
import { de, enUS } from 'date-fns/locale';

interface AKDefaultDateFormatType {
  [key: string]: string;
}

const akDefaultDateFormats: AKDefaultDateFormatType = {
  de_DE: 'dd.MM.yyyy',
  en_EN: 'yyyy-MM-dd',
};

interface AKExtendedDefaultDateFormatType {
  locale: string;
  year: string;
  monthsYears: string;
  daysMonthsYears: string;
  daysMonths: string;
  hoursMinutes: string;
  minutesSeconds: string;
  secondsMilliseconds: string;
}

export class AKDateTime {
  private defaultDateFormat: string;
  private defaultLocale: Locale;

  constructor(locale: string = 'de_DE') {
    this.defaultDateFormat = akDefaultDateFormats.hasOwnProperty(locale)
      ? akDefaultDateFormats[locale]
      : akDefaultDateFormats['de_DE'];

    this.defaultLocale = locale === 'de_DE' ? de : enUS;
  }

  akExtendedDefaultDateFormats: AKExtendedDefaultDateFormatType[] = [
    {
      locale: 'de_DE',
      year: 'yyyy',
      monthsYears: 'MM.yyyy',
      daysMonthsYears: 'dd.MM.yyyy',
      daysMonths: 'dd.MM',
      hoursMinutes: 'HH:mm',
      minutesSeconds: 'MM:ss',
      secondsMilliseconds: 'ss:SSS',
    },
    {
      locale: 'en_EN',
      year: 'yyyy',
      monthsYears: 'MM/yyyy',
      daysMonthsYears: 'MM/dd/yyyy',
      daysMonths: 'MM/dd',
      hoursMinutes: 'hh:mm',
      minutesSeconds: 'MM:ss',
      secondsMilliseconds: 'ss:SSS',
    },
  ];

  /**
   *
   * @example
   * parseDate('01.01.2023') => Sun Jan 01 2023 00:00:00 GMT+0100
   */
  parseDate = (dateString: string) => {
    return parse(dateString, this.defaultDateFormat, new Date());
  };

  formatDate = (date: Date, dateFormat: string = this.defaultDateFormat, locale: Locale = this.defaultLocale) => {
    return format(date, dateFormat, { locale });
  };

  dateIsPast = (date: Date) => {
    return isPast(date);
  };

  getTimeStringFromTimeStamp = (timestamp: number, formatStr = 'HH:mm:ss') => {
    const time = this.getDateFromTimeStamp(timestamp);
    return this.formatTime(time, formatStr);
  };

  getStringFromTimeStamp = (timestamp: number) => {
    const date = this.getDateFromTimeStamp(timestamp);
    return this.formatDate(date);
  };

  timestampIsPast = (timestamp: number) => {
    return isPast(this.getDateFromTimeStamp(timestamp));
  };

  getDateFromTimeStamp = (timeStamp: number) => {
    return new Date(timeStamp);
  };

  getTimeStampFromDate = (date: Date) => {
    return date.getTime();
  };

  getTimeStampFromString = (dateString: string) => {
    return this.parseDate(dateString).getTime();
  };

  formatTime = (date: Date, formatStr = 'HH:mm:ss') => {
    return format(date, formatStr);
  };

  getCurrentTimeStamp = () => {
    return getTime(new Date());
  };

  getHours = (date: Date = new Date()) => {
    return getHours(date);
  };
}
