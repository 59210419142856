<template>
  <AKAppSearch ref="akAppSearch" v-model="searchInput" v-model:mobile-search-visibility="mobileSearchVisibility">
    <template #applicationSearchResults></template>
  </AKAppSearch>
</template>

<script setup lang="ts">
import { AKAppSearch } from '@ak_tools/components/app_support';
import { provide, ref } from 'vue';

//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const mobileSearchVisibility = defineModel<boolean>('mobileSearchVisibility', { default: false });

//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const searchInput = ref<string>('');
const akAppSearch = ref<InstanceType<typeof AKAppSearch> | null>(null);

//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const toggleMobileVisibility = () => {
  if (akAppSearch.value) {
    akAppSearch.value.toggleMobileVisibility();
  }
};

//  --------------------------------------------------------------------------------------------------------------------
//  provided data + public expose
//  --------------------------------------------------------------------------------------------------------------------
provide('searchInputValue', searchInput);
defineExpose({ toggleMobileVisibility });
</script>

<style lang="sass" scoped></style>
