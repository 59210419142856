import { defineStore } from 'pinia';
import { ref } from 'vue';

import { BECommTournament } from '@/backend_communication/definitions/BECommTournaments';

export const tournamentStore = defineStore('tournament', () => {
  const currentTournament = ref<BECommTournament | null>(null);

  const setCurrentTournament = (tournament: BECommTournament | null) => {
    currentTournament.value = tournament;
  };

  return { currentTournament, setCurrentTournament };
});
