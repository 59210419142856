<template>
  <div class="w-full">
    <AKIcon
      v-if="!inputIsVisible"
      class="hover:text-theme-inv cursor-pointer text-gray-500"
      :class="{ 'text-primary': inputValue }"
      :framework-icon="PrimeIcons.SEARCH"
      @click="showInput" />

    <div v-if="inputIsVisible" class="relative w-full sm:w-[250px] md:w-[300px]">
      <AKIconField
        ref="akIconField"
        v-model="inputValue"
        input-size="small"
        input-placeholder="Suche eingeben"
        custom-input-class="border-0 py-2"
        has-close-button />
      <AKIcon
        class="hover:text-theme-inv absolute right-2 top-1/2 -translate-y-1/2 cursor-pointer text-gray-500"
        :scale="0.75"
        :framework-icon="PrimeIcons.TIMES"
        @click="resetInput" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { AKIconField } from '@ak_tools/components/form';
import { AKIcon } from '@ak_tools/components/media';
import { PrimeIcons } from 'primevue/api';
import { nextTick, ref } from 'vue';

//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const inputValue = defineModel<string>({ default: '' });
const inputIsVisible = defineModel<boolean>('inputIsVisible', { default: false });

//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const akIconField = ref<InstanceType<typeof AKIconField> | null>(null);

const showInput = () => {
  inputIsVisible.value = true;

  nextTick(() => {
    akIconField.value?.focusInput();
  });
};

//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const resetInput = () => {
  inputIsVisible.value = false;
  inputValue.value = '';
};
</script>

<style lang="sass" scoped></style>
