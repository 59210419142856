import { AKAppConfig } from '@ak_tools/framework_support/AKAppConfigTypes';
import { akProvideTranslation } from '@ak_tools/framework_support/AKTranslatorMock';
import { PrimeIcons } from 'primevue/api';

import MatchPlanPageView from '@/views/modules/MatchPlanPageView.vue';
import Reservations from '@/views/modules/ReservationPlanPageView.vue';
import Tournaments from '@/views/modules/TournamentsView.vue';

import ScoreboardPageView from './views/modules/ScoreboardPageView.vue';

const { t } = akProvideTranslation();

export const appConfig: AKAppConfig = {
  currentVersion: { major: 1, minor: 0, patch: 8 },
  name: 'Liga Manager',
  navigationItems: [
    {
      title: t('AppConfig.navigation.scoreboard-page'),
      name: 'scoreboard',
      tags: ['scoreboard'],
      link: '/scoreboard/:item_id?',
      icon: PrimeIcons.STAR,
      vueComponent: ScoreboardPageView,
    },
    {
      title: t('AppConfig.navigation.match-plan'),
      name: 'match-plan',
      tags: ['match-plan'],
      link: '/match-plan/:item_id?',
      icon: PrimeIcons.CALENDAR,
      vueComponent: MatchPlanPageView,
    },
    {
      title: t('AppConfig.navigation.reservation-plan'),
      name: 'reservations',
      tags: ['reservations'],
      link: '/reservations/:item_id?',
      icon: PrimeIcons.MAP_MARKER,
      vueComponent: Reservations,
    },
    {
      title: t('AppConfig.navigation.tournament-page'),
      name: 'tournaments',
      tags: ['tournaments'],
      link: '/tournaments/:item_id?',
      icon: PrimeIcons.COG,
      vueComponent: Tournaments,
      viewRights: ['tournament_admin'],
    },
  ],
};
