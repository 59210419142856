import { AxiosResponse as AKBECommBaseResponse } from 'axios';

export enum AKBECommErrorCodes {
  no_error = 'no_error',
  unknown_error = 'unknown_error',
  validation_error = 'validation_error',
  warning = 'warning',
  access_denied = 'access_denied',
}

export type AKBECommErrorResponseSchema = {
  error_code: AKBECommErrorCodes | string;
  error_id: string;
  error_message: string;
  server_timestamp: string;
  error_text: string;
};

export type AKBECommDefaultResponseSchema = AKBECommErrorResponseSchema & {
  status_update_required: boolean;
};

export type AKBECommEmptyDeleteBody = {
  data: object;
};

export type AKBECommGraphQLQuerySchema = {
  query: string;
  variables: object;
};

export const AKBECommEmptyBody: AKBECommEmptyDeleteBody = {
  data: {},
};

export type AKBECommFileUploadReadySchema = {
  ready: boolean;
};

export type AKBECommDefaultResponse<T = unknown> = {
  data: T;
  response: AKBECommBaseResponse;
};
