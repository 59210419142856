<template>
  <TieredMenu
    ref="akTieredMenu"
    class="w-auto"
    :style="zIndexStyle"
    popup
    :model="menuProps.items"
    :append-to="menuProps.appendTo"
    @show="tieredMenuIsVisible = true"
    @hide="tieredMenuIsVisible = false">
    <template v-if="slotInUse.start" #start>
      <slot name="start"></slot>
    </template>
    <template #item="{ item, props, hasSubmenu }">
      <slot name="item" :item="item" :item-props="props" :has-submenu="hasSubmenu">
        <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
          <a :href="href" v-bind="props.action" @click="navigate">
            <AKIcon :framework-icon="item.icon" :ak-icon="item.akIcon" :icon-classes="item.iconClass" :scale="0.85" />
            <span class="ml-2 mr-2" v-text="item.label" />
          </a>
        </router-link>
        <a v-else class="flex items-center gap-2" v-bind="props.action">
          <div class="flex items-center gap-2" :class="item.textClass">
            <AKIcon
              v-if="item.icon || item.akIcon"
              :framework-icon="item.icon"
              :ak-icon="item.akIcon"
              :icon-classes="item.iconClass"
              :scale="0.85" />
            <span v-text="item.label" />
          </div>
          <div v-if="item.badge || item.shortcut || hasSubmenu" class="ml-auto">
            <AKBadge v-if="item.badge" class="ml-auto" :value="item.badge" />
            <span
              v-if="item.shortcut"
              class="bg-backdrop ml-auto text-nowrap rounded-lg border p-1 text-sm"
              v-text="item.shortcut" />
            <AKIcon v-if="hasSubmenu" class="ml-auto" :framework-icon="PrimeIcons.ANGLE_RIGHT" />
          </div>
        </a>
      </slot>
    </template>
    <template v-if="slotInUse.end" #end>
      <slot name="end"></slot>
    </template>
  </TieredMenu>
</template>
<script setup lang="ts">
type _VTI_TYPE_AKLayersKey = keyof typeof akLayers
interface PropsInterface {
  items?: AKMenuItem[];
  appendTo?: 'body' | 'self' | string | _VTI_TYPE_HTMLElement;
  zLayer?: _VTI_TYPE_AKLayersKey;
}
import { akGetLayer } from '@ak_tools/assets/ak_styles/vendors/tailwind/akLayers'
import { AKIcon } from '@ak_tools/components/media'
import { AKMenuItem } from '@ak_tools/components/menu'
import { AKBadge } from '@ak_tools/components/misc'
import AKShortcutManager from '@ak_tools/ts_modules/core/AKShortcutManager'
import { PrimeIcons } from 'primevue/api'
import TieredMenu from 'primevue/tieredmenu'
import { computed, nextTick, onMounted, onUnmounted, ref, useSlots, watch } from 'vue'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const tieredMenuIsVisible = defineModel<boolean>('menuIsVisible', { default: false });
const menuProps = withDefaults(defineProps<PropsInterface>(), {
  items: () => [],
  appendTo: undefined,
  zLayer: 'maxValue',
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const slotInUse = useSlots();
const akTieredMenu = ref<InstanceType<typeof TieredMenu> | null>(null);
const refreshStyleForRerendering = ref(0);
const shortcutManager = new AKShortcutManager();
const shortcutsRegistered = ref(false);
//  --------------------------------------------------------------------------------------------------------------------
//  computed properties
//  --------------------------------------------------------------------------------------------------------------------
const zIndexStyle = computed(() => {
  return `z-index: ${akGetLayer(menuProps.zLayer) + refreshStyleForRerendering.value}`;
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const refreshActionsAfterMenuFunctionIsCalled = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (akTieredMenu.value! as any).dirty = true;
  nextTick(() => {
    refreshStyleForRerendering.value += 1;
    refreshStyleForRerendering.value -= 1;
  });
};
const toggleMenu = (event: Event) => {
  akTieredMenu.value?.toggle(event);
  refreshActionsAfterMenuFunctionIsCalled();
};
const showMenu = (event: Event) => {
  akTieredMenu.value?.show(event);
  refreshActionsAfterMenuFunctionIsCalled();
};
const hideMenu = () => {
  akTieredMenu.value?.hide();
  refreshActionsAfterMenuFunctionIsCalled();
};
const registerShortcuts = (items: AKMenuItem[]) => {
  for (const item of items) {
    const command = item.command;
    const shortcut = item.shortcut;
    const disabled = item.disabled;
    if (shortcut && command && !disabled) {
      shortcutManager.registerShortcut(shortcut, command);
    }
    if (item.items) {
      registerShortcuts(item.items as AKMenuItem[]);
    }
  }
  if (!shortcutsRegistered.value) {
    shortcutManager.addEventListener();
    shortcutsRegistered.value = true;
  }
};
const unregisterShortcuts = () => {
  shortcutManager.unregisterShortcuts();
  shortcutManager.removeEventListener();
  shortcutsRegistered.value = false;
};
//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watch(
  () => menuProps.items,
  () => {
    unregisterShortcuts();
    registerShortcuts(menuProps.items);
  },
);
//  --------------------------------------------------------------------------------------------------------------------
//  public expose
//  --------------------------------------------------------------------------------------------------------------------
defineExpose({ toggleMenu, showMenu, hideMenu });
//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
onMounted(() => {
  registerShortcuts(menuProps.items);
});
onUnmounted(() => {
  unregisterShortcuts();
});
</script>

<style lang="sass" scoped></style>
