import { AKBECommErrorResponseSchema } from '@ak_tools/backend_communication/AKBECommDefault';
import { AKStore } from '@ak_tools/start/App';
import { AKAppMessage, AKAppMessageBeCommError, AKMessageType } from '@ak_tools/store/app/types';
import { useI18n } from 'vue-i18n';

export default class AKSendNotification {
  private store = AKStore;
  private i18n = useI18n();

  public displayErrorNotification(message: Array<string>, errorResponse: AKBECommErrorResponseSchema) {
    const messageToSend: AKAppMessageBeCommError = {
      messageType: AKMessageType.BE_COMM_ERROR,
      messageTranslatableParts: message,
      errorData: errorResponse,
    };
    this.store.App.displayMessage(messageToSend);
  }

  public displayWarningNotification(message: Array<string>) {
    const messageToSend: AKAppMessage = {
      messageType: AKMessageType.WARNING,
      messageSummary: this.i18n.t('AKNotification.warning-summary'),
      messageTranslatableParts: message,
    };
    this.store.App.displayMessage(messageToSend);
  }

  public displayInfoNotification(message: Array<string>) {
    const messageToSend: AKAppMessage = {
      messageType: AKMessageType.INFO,
      messageSummary: this.i18n.t('AKNotification.info-summary'),
      messageTranslatableParts: message,
    };
    this.store.App.displayMessage(messageToSend);
  }

  public displaySuccessNotification(message: Array<string>) {
    const messageToSend: AKAppMessage = {
      messageType: AKMessageType.SUCCESS,
      messageSummary: this.i18n.t('AKNotification.success-summary'),
      messageTranslatableParts: message,
    };
    this.store.App.displayMessage(messageToSend);
  }
}
