<template>
  <Button
    class="shadow-none transition-colors"
    :data-test="`ak-button-${props.dataTest}`"
    :label="props.label"
    :icon-pos="props.iconPos"
    :icon="props.icon"
    :as="props.as"
    :href="props.href"
    :target="props.target"
    :rel="props.rel"
    :severity="props.variant"
    :size="props.size"
    :outlined="props.outlined"
    :loading="props.loading"
    :disabled="props.disabled"
    :raised="props.raised"
    :rounded="props.rounded"
    :text="!props.hasBackground">
    <template v-if="slotInUse.icon" #icon>
      <slot name="icon"></slot>
    </template>
    <slot></slot>
    <template v-if="slotInUse.loadingicon" #loadingicon>
      <slot name="loadingicon"></slot>
    </template>
  </Button>
</template>

<script setup lang="ts">
interface PropsInterface {
  dataTest?: string;
  label?: string;
  iconPos?: 'left' | 'right' | 'top' | 'bottom';
  icon?: string;
  as?: string;
  href?: string;
  target?: string;
  rel?: string;
  variant?: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'help' | 'danger' | 'transparent';
  size?: 'small' | 'large';
  outlined?: boolean;
  loading?: boolean;
  disabled?: boolean;
  raised?: boolean;
  rounded?: boolean;
  hasBackground?: boolean;
  hasBorder?: boolean;
}
import Button from 'primevue/button'
import { useSlots } from 'vue'
//  --------------------------------------------------------------------------------------------------------------------
//  props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  dataTest: undefined,
  label: undefined,
  iconPos: 'left',
  icon: undefined,
  as: undefined,
  href: undefined,
  target: undefined,
  rel: undefined,
  variant: 'primary',
  size: undefined,
  outlined: false,
  loading: false,
  disabled: false,
  raised: false,
  rounded: false,
  hasBackground: true,
  hasBorder: false,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const slotInUse = useSlots();
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
</script>

<style scoped></style>
