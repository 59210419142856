import { fullColorSet } from '@ak_tools/assets/ak_styles/vendors/tailwind/akColors';
import { akCombineConfigs } from '@ak_tools/framework_support/AKAppConfigCombiner';
import { AKAppConfig } from '@ak_tools/framework_support/AKAppConfigTypes';
import { akProvideTranslation } from '@ak_tools/framework_support/AKTranslatorMock';
import { PrimeIcons } from 'primevue/api';

import { appConfig as appConfigProject } from '@/AppConfig';
import PlayGroundView from '@/views/ak_views/PlayGroundView.vue';
import StartView from '@/views/ak_views/StartView.vue';
import SupportView from '@/views/ak_views/SupportView.vue';

const { t } = akProvideTranslation();

const akAppConfig: AKAppConfig = {
  currentVersion: { major: 0, minor: 0, patch: 0 },
  name: 'AK Tools Application',
  navigationItems: [
    {
      title: t('AKApp.navigation.home'),
      name: 'start',
      tags: ['start'],
      link: '/',
      icon: PrimeIcons.HOME,
      vueComponent: StartView,
      order: 0,
      color: 'green',
    },
    {
      title: t('AKApp.navigation.support'),
      name: 'support',
      tags: ['support'],
      link: '/support',
      icon: PrimeIcons.QUESTION_CIRCLE,
      color: fullColorSet.red[700],
      vueComponent: SupportView,
    },
    {
      title: t('AKApp.navigation.playground'),
      name: 'playground',
      tags: ['playground'],
      link: '/playground',
      icon: PrimeIcons.DISCORD,
      color: fullColorSet.secondary[1],
      vueComponent: PlayGroundView,
      viewRights: ['playground'],
    },
  ],
};

export const appConfig = akCombineConfigs(akAppConfig, appConfigProject);
