<template>
  <ul class="overflow-x-hidden">
    <li v-for="(item, index) in navigationItems" :key="index" class="flex flex-wrap justify-start">
      <AKRouterMenuItem v-if="!item.hideInNav" :item="item" @on-menu-item-click="onMenuitemClick" />
    </li>
  </ul>
</template>

<script setup lang="ts">
import { AKMainMenuStates, AKRouterMenuItem } from '@ak_tools/components/menu';
import { AKStore } from '@ak_tools/start/App';
import { AKBreakpoints } from '@ak_tools/store/app/types';
import { computed, inject, provide, Ref } from 'vue';

//  --------------------------------------------------------------------------------------------------------------------
//  props
//  --------------------------------------------------------------------------------------------------------------------
const mainMenuState = inject('mainMenuState') as Ref<AKMainMenuStates>;

//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const navigationItems = computed(() => {
  return AKStore.App.state.navigationMenu.filter((menuItem) => showItemsByRights(menuItem.viewRights));
});

const mainMenuIsMaximized = computed(() => {
  return mainMenuState.value === AKMainMenuStates.MAXIMIZED;
});

const breakpointIsMobile = computed(() => {
  return AKStore.App.state.breakpoint;
});

//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const onMenuitemClick = () => {
  if (breakpointIsMobile.value <= AKBreakpoints.SM && mainMenuIsMaximized.value) {
    emitToggleMainMenuState();
  }
};

const showItemsByRights = (viewRights?: string[]) => {
  if (!viewRights) {
    return true;
  }

  return AKStore.User.state.application.modulesTitles.some((module) => viewRights.includes(module));
};

//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  toggleMainMenuState: [];
}>();

const emitToggleMainMenuState = () => {
  emits('toggleMainMenuState');
};

//  --------------------------------------------------------------------------------------------------------------------
//  provided data + public expose
//  --------------------------------------------------------------------------------------------------------------------
provide('mainMenuIsMaximized', mainMenuIsMaximized);
</script>

<style scoped lang="sass"></style>
