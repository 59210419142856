<template>
  <AKDataTable
    v-model:table-data="participantsWithCounts"
    v-model:table-filters="filters"
    v-model:rows-per-page="participantsWithCounts.length"
    v-model:selected-rows="providedDataHandler.data.selectedTableRows"
    data-key="participant_id"
    table-column-size="sm"
    :selection-mode="'single'"
    :index-to-display="selectedIndex"
    @on-clear-filter-click="onClearFilterClick"
    @on-row-click="onRowClick">
    <AKColumn field="rank" :header="$t('ParticipantDataTable.rank.header')" sortable>
      <template #filter="{ filterModel, filterCallback }">
        <AKInputText
          v-model="filterModel.value"
          class="scoreboard-rank-column-filter"
          data-test="participant-table-filter-rank"
          size="small"
          placeholder=""
          @on-input="(filterCallback(), checkFilterSet(filterModel.value))" />
      </template>
    </AKColumn>
    <AKColumn field="name" :header="'Name'" sortable>
      <template #filter="{ filterModel, filterCallback }">
        <AKInputText
          v-model="filterModel.value"
          class="scoreboard-name-column-filter"
          data-test="scoreboard-table-filter-name"
          size="small"
          placeholder=""
          @on-input="(filterCallback(), checkFilterSet(filterModel.value))" />
      </template>
    </AKColumn>
    <AKColumn field="count_of_matches" :header="$t('ParticipantDataTable.count-of-matches.header')" sortable>
      <template #filter="{ filterModel, filterCallback }">
        <AKInputText
          v-model="filterModel.value"
          class="scoreboard-count-of-matches-column-filter"
          data-test="scoreboard-table-filter-count-of-matches"
          size="small"
          placeholder=""
          @on-input="(filterCallback(), checkFilterSet(filterModel.value))" />
      </template>
    </AKColumn>
    <AKColumn field="points" :header="$t('ParticipantDataTable.points.header')" sortable>
      <template #filter="{ filterModel, filterCallback }">
        <AKInputText
          v-model="filterModel.value"
          class="scoreboard-points-column-filter"
          data-test="scoreboard-table-filter-points"
          size="small"
          placeholder=""
          @on-input="(filterCallback(), checkFilterSet(filterModel.value))" />
      </template>
    </AKColumn>
  </AKDataTable>
</template>

<script setup lang="ts">
import {
  AKColumn,
  AKDataTable,
  AKDataTableFilterMeta,
  AKDataTableRowClickEvent,
  AKFilterMatchMode,
} from '@ak_tools/components/data';
import { AKInputText } from '@ak_tools/components/form';
import { computed, inject, nextTick, onMounted, ref, watch } from 'vue';

import { BECommParticipant } from '@/backend_communication/definitions/BECommParticipants';
import { ParticipantDataHandler } from '@/components/participants/ParticipantDataHandler';

//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------

const providedDataHandler = inject('ParticipantDataHandler') as ParticipantDataHandler;
const filters = ref<AKDataTableFilterMeta>({});
const selectedIndex = ref(0);
const filterSet = ref(false);

//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const participantsWithCounts = computed(() => {
  return providedDataHandler.data.participants.map((participant) => {
    const playedMatches = participant.matches.filter(
      (match) => match.participant_one_score !== null && match.participant_two_score !== null,
    ).length;

    return {
      ...participant,
      count_of_matches: playedMatches,
    };
  });
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const setSelectedIndex = () => {
  const selectedParticipant = providedDataHandler.data.current_participant;

  if (selectedParticipant) {
    const itemIndex = providedDataHandler.data.participants.findIndex(
      (software) => software.participant_id === selectedParticipant.participant_id,
    );

    if (itemIndex !== -1) {
      selectedIndex.value = itemIndex;
      providedDataHandler.data.selectedTableRows = [selectedParticipant];
    }
  }
};

const initFilters = () => {
  filters.value = {
    rank: { value: '', matchMode: AKFilterMatchMode.CONTAINS },
    name: { value: '', matchMode: AKFilterMatchMode.CONTAINS },
    count_of_matches: { value: '', matchMode: AKFilterMatchMode.CONTAINS },
    points: { value: '', matchMode: AKFilterMatchMode.CONTAINS },
  };
};

const onClearFilterClick = () => {
  initFilters();
};

const checkFilterSet = (value: string) => {
  nextTick(() => {
    filterSet.value = value ? true : false;
  });
};

const onRowClick = (participantRowEvent: AKDataTableRowClickEvent, participantRowData: BECommParticipant) => {
  emitOnRowClick(participantRowEvent, participantRowData);
};

//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watch(
  () => providedDataHandler.data.current_participant || providedDataHandler.data.selectedTableRows,
  () => {
    if (!filterSet.value) {
      setSelectedIndex();
    }
  },
);

//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------

const emits = defineEmits<{
  onRowClick: [AKDataTableRowClickEvent, BECommParticipant];
}>();

const emitOnRowClick = (participantRowEvent: AKDataTableRowClickEvent, participantRowData: BECommParticipant) => {
  emits('onRowClick', participantRowEvent, participantRowData);
};

//  --------------------------------------------------------------------------------------------------------------------
//  Life cycle
//  --------------------------------------------------------------------------------------------------------------------
onMounted(() => {
  setSelectedIndex();
});
initFilters();
</script>

<style lang="sass" scoped></style>
