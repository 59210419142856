<template>
  <div v-if="false"></div>
</template>
<script setup lang="ts">
import { KeycloakLoginResponse, KeycloakLogoutResponse, KeycloakStatus } from '@ak_tools/components/renderless/';
import AKEnv from '@ak_tools/framework_support/AKEnv';
import Keycloak from 'keycloak-js';

let keycloak: Keycloak | null = null;

const checkLoginState = async (clientId: string, redirectRelativeUri?: string) => {
  keycloak = new Keycloak({
    realm: AKEnv.VUE_APP_KEYCLOAK_REALM!,
    url: AKEnv.VUE_APP_KEYCLOAK_URI!,
    clientId: clientId,
  });

  return keycloak
    .init({
      onLoad: 'login-required',
      redirectUri: `${AKEnv.VUE_APP_FRONTEND_SERVER_URL}${AKEnv.VUE_APP_RELATIVE_PATH}${redirectRelativeUri}`,
      messageReceiveTimeout: Number(AKEnv.VUE_APP_WAIT_FOR_KEYCLOAK_SERVER_RESPONSE_TIME),
      enableLogging: true,
    })
    .then((authSuccess) => {
      const keycloakResponse: KeycloakLoginResponse = {
        keycloakStatus: KeycloakStatus.authorizationFailed,
      };
      if (authSuccess && keycloak) {
        keycloakResponse.keycloakStatus = KeycloakStatus.serverIsAvailable;
        keycloakResponse.accessToken = keycloak.token;
        keycloakResponse.refreshToken = keycloak.refreshToken;
      }
      return keycloakResponse;
    })
    .catch(() => {
      const keycloakResponse: KeycloakLoginResponse = {
        keycloakStatus: KeycloakStatus.serverNotAvailable,
      };

      return keycloakResponse;
    });
};

const keycloakLogout = async () => {
  if (keycloak) {
    return keycloak
      .logout()
      .then(() => {
        const keycloakLogoutResponse: KeycloakLogoutResponse = {
          keycloakStatus: KeycloakStatus.serverIsAvailable,
          success: true,
        };
        return keycloakLogoutResponse;
      })
      .catch(() => {
        const keycloakLogoutResponse: KeycloakLogoutResponse = {
          keycloakStatus: KeycloakStatus.serverNotAvailable,
          success: false,
        };
        return keycloakLogoutResponse;
      });
  }
  return null;
};

defineExpose({
  checkLoginState,
  keycloakLogout,
});
</script>
