<template>
  <Skeleton
    :shape="props.shape"
    :size="props.size"
    :width="props.width"
    :border-radius="props.borderRadius"
    :height="props.height"
    :animation="props.animation" />
</template>

<script setup lang="ts">
interface PropsInterface {
  shape?: 'circle' | 'rectangle';
  size?: string;
  width?: string;
  height?: string;
  animation?: 'none' | 'wave';
  borderRadius?: string;
}
import Skeleton from 'primevue/skeleton'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  shape: 'rectangle',
  size: undefined,
  width: '100%',
  height: '1rem',
  animation: 'wave',
  borderRadius: undefined,
});
</script>

<style lang="sass" scoped></style>
