<template>
  <AKSystemAccess ref="referenceAKSystemAccess" />
  <AKLoginScreen :display-loading="getLoginState === LoginState.PortalLoginPending" @login="loginTriggered" />
</template>

<script setup lang="ts">
import { AKBECommLoginRequestLoginType } from '@ak_tools/backend_communication/definitions/users/AKBECommSystemAccess';
import { AKLoginScreen, AKLoginScreenForm } from '@ak_tools/components/login';
import { AKSystemAccess } from '@ak_tools/components/renderless/';
import { AKStore } from '@ak_tools/start/App';
import { LoginState } from '@ak_tools/store/users/types';
import { computed, ref } from 'vue';

//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const referenceAKSystemAccess = ref<InstanceType<typeof AKSystemAccess> | null>(null);

//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const getLoginState = computed(() => {
  return AKStore.User.state.loginState;
});

//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const loginTriggered = (form: AKLoginScreenForm) => {
  referenceAKSystemAccess.value!.login({
    login_type: AKBECommLoginRequestLoginType.default,
    username: form.email.value,
    password: form.password.value,
  });
};

AKStore.App.state.currentModuleTitle = 'Login';
</script>

<style lang="sass" scoped></style>
