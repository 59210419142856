<template>
  <AKInputContainer
    :label="props.label"
    :label-cols="props.labelCols"
    :tooltip-text="props.tooltipText"
    :tooltip-placement="props.tooltipPlacement"
    :tooltip-theme="props.tooltipTheme">
    <Tag
      class="bg-primary text-theme"
      data-test="ak-tag"
      :style="[{ backgroundColor: props.backgroundColor }, { color: props.textColor }]"
      :rounded="props.pill">
      <slot></slot>
      <template v-if="props.icon" #icon>
        <AKIcon :scale="0.75" :class="{ 'mr-1': slotInUse.default }" :framework-icon="props.icon" />
      </template>
    </Tag>
    <template #tooltipContent>
      <slot name="tooltipContent"></slot>
    </template>
  </AKInputContainer>
</template>

<script setup lang="ts">
type _VTI_TYPE_AKTooltipValidThemes = 'ak-white'
type _VTI_TYPE_AKTooltipValidPlacements = | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end'
interface PropsInterface {
  backgroundColor?: string;
  textColor?: string;
  pill?: boolean;
  icon?: string;
  label?: string | false;
  labelCols?: number | 'auto';
  tooltipText?: string;
  tooltipPlacement?: _VTI_TYPE_AKTooltipValidPlacements;
  tooltipTheme?: _VTI_TYPE_AKTooltipValidThemes;
}
import { AKInputContainer } from '@ak_tools/components/layout'
import { AKIcon } from '@ak_tools/components/media'
import Tag from 'primevue/tag'
import { useSlots } from 'vue'
//  --------------------------------------------------------------------------------------------------------------------
//  props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  backgroundColor: '',
  textColor: '',
  pill: false,
  icon: '',
  label: false,
  labelCols: undefined,
  tooltipText: undefined,
  tooltipPlacement: undefined,
  tooltipTheme: undefined,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const slotInUse = useSlots();
</script>

<style scoped></style>
