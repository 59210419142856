<template>
  <div v-if="props.items.length" class="flex flex-wrap gap-2 rounded-lg text-gray-400">
    <div v-for="(item, index) in props.items" :key="index" class="flex items-center gap-2">
      <router-link
        v-if="!item.private && item.routeLocation"
        class="flex cursor-pointer items-center gap-2 hover:underline"
        :to="item.routeLocation">
        <AKIcon v-if="item.archived" :framework-icon="PrimeIcons.BOOKMARK" :scale="0.8" />
        <span :class="{ italic: !itemHasTitle(item) }" v-text="itemTitle(item)" />
      </router-link>

      <div v-else-if="item.private" class="flex items-center gap-2">
        <AKIcon :framework-icon="PrimeIcons.LOCK" :scale="0.8" />
        <span v-text="$t('AKBreadcrumbs.private-info')" />
      </div>

      <div v-else-if="item.archived" class="flex items-center gap-2">
        <AKIcon :framework-icon="PrimeIcons.BOOKMARK" :scale="0.8" />
        <span v-text="itemTitle(item)" />
      </div>

      <span v-else v-text="item.title" />

      <AKIcon
        v-if="(props.showLastAngle && index === props.items.length - 1) || index !== props.items.length - 1"
        :framework-icon="PrimeIcons.ANGLE_RIGHT" />
    </div>
  </div>
</template>

<script setup lang="ts">
interface PropsInterface {
  items?: AKBreadcrumbItem[];
  showLastAngle?: boolean;
}
import { AKIcon } from '@ak_tools/components/media'
import { AKBreadcrumbItem } from '@ak_tools/components/menu'
import { PrimeIcons } from 'primevue/api'
import { useI18n } from 'vue-i18n'
const { t } = useI18n();
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  items: () => [],
  showLastAngle: true,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const itemTitle = (item: AKBreadcrumbItem) => {
  return item.title ? item.title : t('AKBreadcrumbs.missing-title');
};
const itemHasTitle = (item: AKBreadcrumbItem) => {
  return !!item.title;
};
</script>

<style lang="sass" scoped></style>
