import AKEnv from '@ak_tools/framework_support/AKEnv';
import AKRoutes from '@ak_tools/router';
import { routeMenuItem as akRouteMenuItem } from '@ak_tools/router/AKRouterTypes';
import { App, Component } from 'vue';
import { createRouter, createWebHistory, Router, RouteRecordRaw } from 'vue-router';

import Routes from './../AKAppRoutesGetter';
import AKNotFoundRouter from './AKNotFoundRouter.vue';

export const combinedRoutes: RouteRecordRaw[] = [
  ...Routes.map((item: akRouteMenuItem) => {
    return {
      component: item.component as Component,
      path: item.path,
      name: item.name,
    };
  }),
  ...AKRoutes.map((item: akRouteMenuItem) => {
    return {
      path: item.path,
      component: item.component as Component,
      name: item.name,
    };
  }),
];

const seenPaths = new Set();
export const routes: RouteRecordRaw[] = combinedRoutes.reduce((acc, item) => {
  if (!seenPaths.has(item.path)) {
    seenPaths.add(item.path);
    acc.push({
      path: item.path,
      component: item.component as Component,
      name: item.name,
    });
  }
  return acc;
}, [] as RouteRecordRaw[]);

class AKRouterPlugin {
  router!: Router;
  constructor(app: App) {
    this.router = createRouter({
      history: createWebHistory(AKEnv.VUE_APP_RELATIVE_PATH),
      routes: [
        ...routes,
        { path: '/error', component: AKNotFoundRouter, name: 'error' },
        { path: '/:pathMatch(.*)*', name: 'NotFound', component: AKNotFoundRouter },
      ],
    });
    app.use(this.router);
  }
}
export default AKRouterPlugin;
