<template>
  <AKDataTable
    v-model:table-data="matches_of_matchday"
    v-model:selected-rows="matchesDataHandler.data.selectedTableRows"
    data-key="match_id"
    table-column-size="sm"
    :selection-mode="'single'"
    show-pagination
    @on-row-click="onRowClick">
    <AKColumn field="participant_one_fullname"></AKColumn>
    <AKColumn>
      <template #body="slotProps">
        {{ slotProps.data.participant_one_score !== null ? slotProps.data.participant_one_score : 'TBA' }}
      </template>
    </AKColumn>
    <AKColumn class="ak-colon"></AKColumn>
    <AKColumn>
      <template #body="slotProps">
        {{ slotProps.data.participant_two_score !== null ? slotProps.data.participant_two_score : 'TBA' }}
      </template>
    </AKColumn>
    <AKColumn field="participant_two_fullname"></AKColumn>
  </AKDataTable>
</template>

<script setup lang="ts">
interface PropsInterface {
  matchday: number;
}
import { AKColumn, AKDataTable, AKDataTableRowClickEvent } from '@ak_tools/components/data'
import { computed, inject } from 'vue'
import { BECommMatch } from '@/backend_communication/definitions/BECommMatches'
import { MatchesDataHandler } from '@/components/matches//MatchesDataHandler'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  matchday: 0,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const matchesDataHandler = inject('MatchesDataHandler') as MatchesDataHandler;
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const matches_of_matchday = computed(() => {
  return matchesDataHandler.data.matches.filter((match) => match.match_day_number === props.matchday);
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const onRowClick = (matchRowEvent: AKDataTableRowClickEvent, matchRowData: BECommMatch) => {
  emitOnRowClick(matchRowEvent, matchRowData);
};
const emitOnRowClick = (matchRowEvent: AKDataTableRowClickEvent, matchRowData: BECommMatch) => {
  emits('onRowClick', matchRowEvent, matchRowData);
};
//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  onRowClick: [AKDataTableRowClickEvent, BECommMatch];
}>();
//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
</script>

<style lang="sass" scoped>
::v-deep(td.ak-colon::before)
  content: ':'
</style>
