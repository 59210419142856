<template>
  <div ref="sidePanelContainer" class="relative flex h-full w-full flex-col bg-transparent shadow-md">
    <AKSidePanelHeader
      @delete-item="deleteItem"
      @close-side-panel="closeSidePanel"
      @minimize-side-panel="minimizeSidePanel"
      @maximize-side-panel="maximizeSidePanel"
      @duplicate-item="emitDuplicateItem"
      @archive-item="emitArchiveItem"
      @history-item="emitHistoryItem"
      @print-item="emitPrintItem">
      <template #headerLeft>
        <slot name="headerLeft"></slot>
      </template>
      <template #headerAfterButtonMenu>
        <slot name="headerAfterButtonMenu"></slot>
      </template>
    </AKSidePanelHeader>
    <div class="ak-scrollbar-primary h-full w-full grow overflow-x-auto rounded-b-xl bg-white p-4">
      <div :style="`min-width: ${props.minWidth}`">
        <div class="ak-flex-col !gap-2">
          <slot></slot>

          <AKSidePanelAttachments
            v-if="props.showAttachments"
            @delete-attachment="emitDeleteAttachment"
            @download-attachment="emitDownloadAttachment"
            @upload-attachments="emitUploadAttachments" />

          <AKSidePanelCommentView v-if="props.showComments" @delete-comment="emitDeleteComment" />
        </div>
      </div>
    </div>

    <AKCommentSend v-if="props.enableCommentSend" class="mt-4 rounded-xl" @on-send-comment="emitSendComment" />
  </div>
</template>
<script setup lang="ts">
interface PropsInterface {
  showCloseButton?: boolean;
  showMaximizeButton?: boolean;
  showMinimizeButton?: boolean;
  showDeleteButton?: boolean;
  showCopyLocationButton?: boolean;
  showArchiveButton?: boolean;
  showHistoryButton?: boolean;
  showDuplicateButton?: boolean;
  showPrintButton?: boolean;
  showAttachments?: boolean;
  showComments?: boolean;
  enableCommentSend?: boolean;
  minWidth?: string;
  roundedTopRight?: boolean;
  showCommentAccordion?: boolean;
  showAttachmentAccordion?: boolean;
  itemIsArchived?: boolean;
}
import { AKBECommComment } from '@ak_tools/backend_communication/definitions/comments/AKBECommComments'
import { AKBECommAttachment } from '@ak_tools/backend_communication/definitions/files/AKBECommAttachments'
import { AKCommentSend } from '@ak_tools/components/form'
import { AKSidePanelAttachments, AKSidePanelAttachmentsInjectedData, AKSidePanelCommentView, AKSidePanelCommentViewInjectedData, AKSidePanelHeader, AKSidePanelHeaderInjectedData } from '@ak_tools/components/layout'
import { AKFileListItem } from '@ak_tools/components/misc'
import { computed, onBeforeUnmount, onMounted, onUnmounted, provide, ref, watchEffect } from 'vue'
//  --------------------------------------------------------------------------------------------------------------------
//  props + model
//  --------------------------------------------------------------------------------------------------------------------
const defaultLabelCols = defineModel<number>('defaultLabelCols', { default: 3 });
const sidePanelBreakpoint = defineModel<AKSidePanelBreakpoint>('sidePanelBreakpoint', {
  default: AKSidePanelBreakpoint.MD,
});
const attachments = defineModel<AKBECommAttachment[]>('attachments', { default: [] });
const comments = defineModel<AKBECommComment[]>('comments', { default: [] });
const props = withDefaults(defineProps<PropsInterface>(), {
  showCloseButton: true,
  showMaximizeButton: true,
  showMinimizeButton: true,
  showDeleteButton: true,
  showCopyLocationButton: false,
  showArchiveButton: false,
  showHistoryButton: false,
  showDuplicateButton: false,
  showPrintButton: false,
  showAttachments: false,
  showComments: false,
  enableCommentSend: false,
  minWidth: '300px',
  roundedTopRight: true,
  showCommentAccordion: false,
  showAttachmentAccordion: false,
  itemIsArchived: false,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const sidePanelContainer = ref<HTMLElement>();
const resizeObserver = ref<ResizeObserver>();
const providedHeaderData = computed(() => {
  const data: AKSidePanelHeaderInjectedData = {
    showCloseButton: props.showCloseButton,
    showMaximizeButton: props.showMaximizeButton,
    showMinimizeButton: props.showMinimizeButton,
    showDeleteButton: props.showDeleteButton,
    showCopyLocationButton: props.showCopyLocationButton,
    showArchiveButton: props.showArchiveButton,
    showHistoryButton: props.showHistoryButton,
    showPrintButton: props.showPrintButton,
    showDuplicateButton: props.showDuplicateButton,
    itemIsArchived: props.itemIsArchived,
  };
  return data;
});
const providedAttachmentsData = computed(() => {
  const data: AKSidePanelAttachmentsInjectedData = {
    attachments: attachments.value,
    showAttachmentAccordion: props.showAttachmentAccordion,
  };
  return data;
});
const providedCommentViewData = computed(() => {
  const data: AKSidePanelCommentViewInjectedData = {
    comments: comments.value,
    showCommentAccordion: props.showCommentAccordion,
  };
  return data;
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const onResize = () => {
  const width = Number(sidePanelContainer.value?.clientWidth);
  if (width < 700) {
    sidePanelBreakpoint.value = AKSidePanelBreakpoint.SM;
  } else if (width >= 700 && width < 1000) {
    sidePanelBreakpoint.value = AKSidePanelBreakpoint.MD;
  } else {
    sidePanelBreakpoint.value = AKSidePanelBreakpoint.LG;
  }
};
const closeSidePanel = () => {
  emitCloseSidePanel();
};
const minimizeSidePanel = () => {
  emitMinimizeSidePanel();
};
const maximizeSidePanel = () => {
  emitMaximizeSidePanel();
};
const deleteItem = () => {
  emitDeleteItem();
};
//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watchEffect(() => {
  defaultLabelCols.value = sidePanelBreakpoint.value === AKSidePanelBreakpoint.LG ? 1 : 3;
});
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  closeSidePanel: [];
  minimizeSidePanel: [];
  maximizeSidePanel: [];
  deleteItem: [];
  uploadAttachments: [files: AKFileListItem[]];
  deleteAttachment: [item: AKFileListItem];
  downloadAttachment: [item: AKFileListItem];
  sendComment: [comment: string];
  deleteComment: [itemId: number];
  archiveItem: [];
  historyItem: [];
  duplicateItem: [];
  printItem: [];
}>();
const emitCloseSidePanel = () => {
  emits('closeSidePanel');
};
const emitMinimizeSidePanel = () => {
  emits('minimizeSidePanel');
};
const emitMaximizeSidePanel = () => {
  emits('maximizeSidePanel');
};
const emitDeleteItem = () => {
  emits('deleteItem');
};
const emitUploadAttachments = (files: AKFileListItem[]) => {
  emits('uploadAttachments', files);
};
const emitDownloadAttachment = (item: AKFileListItem) => {
  emits('downloadAttachment', item);
};
const emitDeleteAttachment = (item: AKFileListItem) => {
  emits('deleteAttachment', item);
};
const emitArchiveItem = () => {
  emits('archiveItem');
};
const emitHistoryItem = () => {
  emits('historyItem');
};
const emitDuplicateItem = () => {
  emits('duplicateItem');
};
const emitPrintItem = () => {
  emits('printItem');
};
const emitSendComment = (comment: string) => {
  emits('sendComment', comment);
};
const emitDeleteComment = (itemId: number) => {
  emits('deleteComment', itemId);
};
//  --------------------------------------------------------------------------------------------------------------------
//  provided data + public expose
//  --------------------------------------------------------------------------------------------------------------------
provide('providedHeaderData', providedHeaderData);
provide('providedAttachmentsData', providedAttachmentsData);
provide('providedCommentViewData', providedCommentViewData);
//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
onMounted(() => {
  resizeObserver.value = new ResizeObserver(onResize);
  resizeObserver.value.observe(sidePanelContainer.value as HTMLElement);
});
onBeforeUnmount(() => {
  resizeObserver.value?.unobserve(sidePanelContainer.value as HTMLElement);
});
onUnmounted(() => {
  resizeObserver.value?.disconnect();
});
</script>

<script lang="ts">
export enum AKSidePanelBreakpoint {
  SM,
  MD,
  LG,
}
</script>

<style scoped lang="sass"></style>
