export interface AKKeycloakAccessInstance {
  checkLoginState: (clientId: string, redirectUri?: string) => Promise<KeycloakLoginResponse>;
  keycloakLogout: () => Promise<KeycloakLogoutResponse | null>;
}
export type KeycloakLoginResponse = {
  keycloakStatus: KeycloakStatus;
  accessToken?: string | undefined;
  refreshToken?: string | undefined;
};
export type KeycloakLogoutResponse = {
  keycloakStatus: KeycloakStatus;
  success: boolean;
};
export enum KeycloakStatus {
  serverNotAvailable,
  serverIsAvailable,
  authorizationFailed,
}
