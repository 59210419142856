import { createPinia, Pinia } from 'pinia';
import { App } from 'vue';

class AKStorePlugin {
  store!: Pinia;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(app: App) {
    this.store = createPinia();
    app.use(this.store);
  }
}
export default AKStorePlugin;
