<template>
  <div class="bg-primary-1 text-theme flex h-20 min-h-20 w-full justify-between overflow-x-hidden">
    <AKButton
      class="ak-main-menu-button-mobile"
      :class="activeMenuPage === AKMainMenuPages.NAVIGATION ? 'menu-button-active' : 'menu-button-inactive'"
      @click="activeMenuPage = AKMainMenuPages.NAVIGATION">
      <AKIcon :framework-icon="PrimeIcons.BARS" :scale="1.5" />
      <div class="text-base" v-text="$t('AKMainMenuContainer.button.menu')" />
    </AKButton>
    <div class="my-5 flex w-1" :class="activeMenuPage === AKMainMenuPages.PROFILE ? 'bg-white' : ''" />
    <AKButton
      v-if="enableMultiLanguageSupport"
      class="ak-main-menu-button-mobile"
      :class="activeMenuPage === AKMainMenuPages.LANGUAGE ? 'menu-button-active' : 'menu-button-inactive'"
      @click="activeMenuPage = AKMainMenuPages.LANGUAGE">
      <AKIcon :framework-icon="PrimeIcons.LANGUAGE" :scale="1.5" />
      <div class="text-base" v-text="$t('AKMainMenuContainer.button.language')" />
    </AKButton>
    <div class="my-5 flex w-1" :class="activeMenuPage === AKMainMenuPages.NAVIGATION ? 'bg-white' : ''" />
    <AKButton
      class="ak-main-menu-button-mobile"
      :class="activeMenuPage === AKMainMenuPages.PROFILE ? 'menu-button-active' : 'menu-button-inactive'"
      @click="activeMenuPage = AKMainMenuPages.PROFILE">
      <AKAvatar
        :full-name="akHR.getPersonFullName(AKStore.User.state.person)"
        :class="activeMenuPage === AKMainMenuPages.PROFILE ? 'menu-button-inactive' : 'menu-button-active'" />
      <div class="mt-1 text-base" v-text="getInitialsAndPersonalNumber" />
    </AKButton>
  </div>
</template>

<script setup lang="ts">
import { AKButton } from '@ak_tools/components/button';
import { AKIcon } from '@ak_tools/components/media';
import { AKMainMenuPages } from '@ak_tools/components/menu';
import { AKAvatar } from '@ak_tools/components/misc';
import AKEnv from '@ak_tools/framework_support/AKEnv';
import { AKStore } from '@ak_tools/start/App';
import { AKHR } from '@ak_tools/ts_modules/hr/AKHR';
import { PrimeIcons } from 'primevue/api';
import { computed } from 'vue';

//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const activeMenuPage = defineModel<AKMainMenuPages>();

//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const akHR = new AKHR();

//  --------------------------------------------------------------------------------------------------------------------
//  computed props
//  --------------------------------------------------------------------------------------------------------------------
const getInitialsAndPersonalNumber = computed(() => {
  const initials =
    AKStore.User.state.person.firstname.substring(0, 2).toUpperCase() +
    AKStore.User.state.person.lastname.substring(0, 1);
  const personalNumber = AKStore.User.state.person.personalNumber || '0000';
  return initials + personalNumber;
});

const enableMultiLanguageSupport = computed(() => {
  return !!Number(AKEnv.VUE_APP_ENABLE_MULTI_LANGUAGE);
});
</script>

<style lang="sass" scoped>
.ak-main-menu-button-mobile
  @apply block h-full w-full rounded-none

.menu-button-active
  @apply text-primary bg-white
.menu-button-inactive
  @apply text-theme bg-primary-1
</style>
