import { AKVueProvider } from '@ak_tools/framework_support/AKVueProvider';

import { BECommParticipant } from '@/backend_communication/definitions/BECommParticipants';
import { BECommTournament } from '@/backend_communication/definitions/BECommTournaments';
import BECommTournaments from '@/backend_communication/implementations/BECommTournaments';

class TournamentSelectDefaultData {
  tournaments: BECommTournament[] = [];
  current_participant: BECommParticipant | null = null;
  current_tournament: BECommTournament | null = null;
  participants: BECommParticipant[] = [];
  dataIsLoading = true;
}

export class TournamentSelectDataHandler {
  private beCommTournaments = new BECommTournaments(new AKVueProvider());
  data = new TournamentSelectDefaultData();

  async fetchTournaments() {
    this.data.dataIsLoading = true;
    return this.beCommTournaments
      .beCommGetTournaments()
      .then((response) => {
        this.data.tournaments = response.data.tournaments;
        if (this.data.tournaments.length > 0) {
          this.data.current_tournament = this.data.tournaments[0];
        }
      })
      .finally(() => {
        this.data.dataIsLoading = false;
      });
  }
}
