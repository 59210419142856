<template>
  <div class="z-error-messages-layer fixed top-0 flex w-full flex-col gap-1 sm:right-4 sm:top-14 sm:w-auto">
    <AKToast
      v-for="message in localMessages"
      :key="message.messageId"
      :message="message"
      @on-close="removeMessageFromStore(message)"
      @copy-error-message="copyErrorData(message)" />
  </div>
</template>

<script setup lang="ts">
import { AKToast } from '@ak_tools/components/messages';
import { AKStore } from '@ak_tools/start/App';
import { AKAppMessage, AKMessageType } from '@ak_tools/store/app/types';
import { computed, ref, watch } from 'vue';

//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const localMessages = ref<AKAppMessage[]>([]);

//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const storeMessages = computed(() => {
  return AKStore.App.state.messages;
});

//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const setRandomId = (message: AKAppMessage) => {
  const currentIds = storeMessages.value.map((storeMessage) => storeMessage.messageId).filter((id) => id !== undefined);
  const randomId = Math.floor(Math.random() * 100) + 1;

  if (currentIds.includes(randomId)) {
    setRandomId(message);
  }

  message.messageId = randomId;
};

const removeMessageFromStore = (message: AKAppMessage) => {
  if (message.messageId) {
    AKStore.App.removeMessage(message.messageId);
  }
};

const copyErrorData = (message: AKAppMessage) => {
  if (message.messageType === AKMessageType.BE_COMM_ERROR) {
    navigator.clipboard.writeText(
      'error-code:\n' +
        message.errorData.error_code +
        '\n\n' +
        'id:\n' +
        message.errorData.error_id +
        '\n\n' +
        'server timestamp:\n' +
        message.errorData.server_timestamp +
        '\n\n' +
        'message:\n' +
        message.errorData.error_message,
    );
  }
};

//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watch(AKStore.App.state.messages, (newMessages) => {
  newMessages.forEach((message) => {
    if (!message.messageId) {
      setRandomId(message);
    }
  });

  localMessages.value = AKStore.App.state.messages;
});
</script>

<script lang="ts"></script>
