<template>
  <div class="text-primary flex w-full flex-col justify-center gap-4 px-5">
    <div id="form-header" class="text-center">
      <h1 class="ak-fit-text" v-text="appName" />
      <h3 v-text="$t('AKLoginScreen.headline.login')"></h3>
    </div>
    <form id="form-body" class="flex flex-col gap-4">
      <AKInputEmail v-model="email" data-test="login-email" @on-enter-pressed="emitLoginData" />
      <AKInputPassword v-model="password" data-test="login-password" @on-enter-pressed="emitLoginData" />
      <AKHoneypot ref="referenceAKHoneypot" />
      <AKButton
        class="w-full"
        data-test="login-submit"
        :label="$t('AKLoginScreen.button.login')"
        @click="emitLoginData" />
    </form>
    <p class="px-1" v-text="$t('AKLoginScreen.portal-info-text')" />

    <AKButtonSet class="w-full">
      <AKButton class="w-1/2" data-test="login-signup" @click="goToUrl('https://portal.abus-kransysteme.de/register')">
        <div class="flex w-full items-center justify-center">
          <AKIcon class="mr-2" :scale="0.8" :framework-icon="PrimeIcons.USER_PLUS" />
          <span v-text="$t('AKLoginScreen.button.register')" />
        </div>
      </AKButton>
      <AKButton
        class="w-1/2"
        data-test="login-forgot"
        variant="secondary"
        @click="goToUrl('https://portal.abus-kransysteme.de/login')">
        <div class="flex w-full items-center justify-center">
          <AKIcon class="mr-2" :scale="0.8" :framework-icon="PrimeIcons.QUESTION_CIRCLE" />
          <span v-text="$t('AKLoginScreen.button.forgot-password')" />
        </div>
      </AKButton>
    </AKButtonSet>

    <div class="px-1 text-sm hover:underline">
      <a
        href="https://form.asana.com/?k=sqq8wqbt7XGxpd0ziaPsUw&d=1199942936521883"
        target="_blank"
        v-text="$t('AKLoginScreen.support-info-text')" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { AKButton, AKButtonSet } from '@ak_tools/components/button';
import { AKHoneypot, AKInputEmail, AKInputPassword } from '@ak_tools/components/form';
import { AKIcon } from '@ak_tools/components/media';
import { AKStore } from '@ak_tools/start/App';
import { PrimeIcons } from 'primevue/api';
import { computed, ref } from 'vue';

export interface FormItemString {
  value: string;
  valid: boolean;
}

export interface AKLoginScreenForm {
  email: FormItemString;
  password: FormItemString;
}

//  --------------------------------------------------------------------------------------------------------------------
//  variables
//  --------------------------------------------------------------------------------------------------------------------
const email = ref<string>('');
const password = ref<string>('');
const referenceAKHoneypot = ref<InstanceType<typeof AKHoneypot> | null>(null);

//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const goToUrl = (url: string) => {
  window.open(url, '_blank');
};

//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const appName = computed(() => {
  return AKStore.App.state.appName;
});

//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  login: [AKLoginScreenForm];
}>();

const emitLoginData = () => {
  const form: AKLoginScreenForm = {
    email: {
      value: email.value,
      valid: Boolean(email.value),
    },
    password: {
      value: password.value,
      valid: Boolean(password.value),
    },
  };

  if (form.password.valid && form.email.valid && referenceAKHoneypot.value?.validate()) {
    emits('login', form);
  }
};
</script>
