<template>
  <VTooltip
    auto-boundary-max-size
    flip
    :theme="props.theme"
    :shown="props.showTooltip"
    :placement="props.placement"
    :delay="props.delay"
    :triggers="props.triggers"
    :popper-triggers="props.popperTriggers"
    :distance="props.tooltipDistance"
    :skidding="props.tooltipSkidding"
    :disabled="props.tooltipDisabled"
    :popper-class="props.customClass">
    <slot></slot>
    <template #popper>
      <slot name="content">
        <div class="text-center">
          <span v-if="props.text" v-text="props.text" />
        </div>
      </slot>
    </template>
  </VTooltip>
</template>

<script setup lang="ts">
type _VTI_TYPE_AKTooltipValidDelay = {
  show: number;
  hide: number;
}
type _VTI_TYPE_AKTooltipValidPlacements = | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end'
type _VTI_TYPE_AKTooltipValidThemes = 'ak-white'
interface PropsInterface {
  theme?: _VTI_TYPE_AKTooltipValidThemes;
  placement?: _VTI_TYPE_AKTooltipValidPlacements;
  delay?: _VTI_TYPE_AKTooltipValidDelay | number;
  text?: string;
  triggers?: AKTooltipValidTriggers[];
  popperTriggers?: AKTooltipValidTriggers[];
  tooltipDistance?: number;
  tooltipSkidding?: number;
  customClass?: string;
  tooltipDisabled?: boolean;
  showTooltip?: boolean;
}
import { AKTooltipValidTriggers } from '@ak_tools/components/overlay'
//  --------------------------------------------------------------------------------------------------------------------
//  props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  theme: undefined,
  placement: undefined,
  delay: undefined,
  text: undefined,
  triggers: () => ['hover', 'focus'],
  popperTriggers: () => ['hover', 'focus'],
  tooltipDistance: undefined,
  tooltipSkidding: undefined,
  customClass: undefined,
  tooltipDisabled: undefined,
  showTooltip: undefined,
});
</script>

<script lang="ts"></script>

<style lang="sass">
@import '@/assets/ak_styles/theme'

.ak-tooltip-list
  @apply text-left pl-4 mb-0

  li
    @apply ml-0

.v-popper--theme
  &-ak-white
    .v-popper__inner
      color: $ak-text-color
      @apply bg-white shadow-2xl min-w-[30rem] max-w-[30rem] #{!important}

    .v-popper__arrow-outer
      @apply border-white shadow-2xl

.v-popper__popper
  @apply z-max-value
</style>
