<template>
  <AKSidePanelAccordion
    v-if="providedCommentViewData.showCommentAccordion"
    data-test="ak-sidepanel-comment-viewer-accordion"
    :header-title="$t('AKSidepanelCommentViewer.accordion-title')">
    <AKCommentView class="h-full" :comments="providedCommentViewData.comments" @delete-comment="emitDeleteComment" />
  </AKSidePanelAccordion>

  <AKCommentView
    v-else
    class="h-full"
    :comments="providedCommentViewData.comments"
    @delete-comment="emitDeleteComment" />
</template>

<script setup lang="ts">
import { AKCommentView } from '@ak_tools/components/form';
import { AKSidePanelAccordion, AKSidePanelCommentViewInjectedData } from '@ak_tools/components/layout';
import { inject, Ref } from 'vue';

//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const providedCommentViewData = inject('providedCommentViewData') as Ref<AKSidePanelCommentViewInjectedData>;

//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  deleteComment: [itemId: number];
}>();

const emitDeleteComment = (itemId: number) => {
  emits('deleteComment', itemId);
};
</script>

<style lang="sass" scoped></style>
