<template>
  <div class="flex flex-wrap items-center gap-2" tabindex="0">
    <AKTag v-for="person in props.persons" :key="person.person_id">
      <AKTooltip theme="ak-white" placement="bottom-start">
        <template #content>
          <AKEmployeeCard :show-groups="false" :person-data="person" />
        </template>
        <span class="text-base font-normal" v-text="hrHelperMethods.getPersonFullName(person)" />
      </AKTooltip>
    </AKTag>
  </div>
</template>

<script setup lang="ts">
interface PropsInterface {
  persons: AKBECommPersonEmployeeUserSchema[];
}
import { AKBECommPersonEmployeeUserSchema } from '@ak_tools/backend_communication/definitions/hr/AKBECommPersons'
import { AKTag } from '@ak_tools/components/misc'
import { AKTooltip } from '@ak_tools/components/overlay'
import { AKEmployeeCard } from '@ak_tools/components/panel'
import { AKHR } from '@ak_tools/ts_modules/hr/AKHR'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const props = defineProps<PropsInterface>();
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const hrHelperMethods = new AKHR();
</script>

<style lang="sass" scoped></style>
