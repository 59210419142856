<template>
  <div class="flex items-center justify-between p-4 px-4 sm:px-0" :class="envIsDev ? 'bg-red-600' : 'bg-primary'">
    <div class="flex items-center">
      <div class="my-1 hidden flex-1 cursor-pointer items-center justify-center px-4 text-center sm:flex">
        <div class="text-theme hidden items-center justify-center sm:flex">
          <AKIcon :framework-icon="PrimeIcons.BARS" :scale="1.35" @click="menuStateChange()" />
        </div>
      </div>

      <div class="flex items-center gap-4">
        <router-link to="/">
          <AKLogo class="text-theme h-6 sm:h-4" parent-text-color />
        </router-link>

        <AKTooltip v-if="envIsDev" class="flex items-center" placement="right">
          <AKIcon class="text-theme cursor-help" :scale="1.25" :framework-icon="PrimeIcons.EXCLAMATION_TRIANGLE" />

          <template #content>
            <span v-text="$t('AKApplicationHeader.dev-warning')" />
          </template>
        </AKTooltip>
      </div>
    </div>

    <AppSearch v-if="showAppSearch" ref="appSearch" v-model:mobile-search-visibility="mobileSearchVisibility" />

    <div class="flex items-center gap-2">
      <div v-if="!backendAvailable" class="cursor-help">
        <AKTooltip class="flex items-center" placement="bottom" theme="ak-white">
          <template #content>
            <div class="flex flex-col gap-2">
              <div class="flex items-center gap-2">
                <AKIcon
                  :class="envIsDev ? 'text-primary' : 'text-red-600'"
                  :framework-icon="PrimeIcons.EXCLAMATION_CIRCLE" />
                <h6 v-text="$t('AKApp.header.network-error.title')" />
              </div>
              <span v-text="$t('AKApp.header.network-error.message')" />
            </div>
          </template>
          <AKIcon
            icon-classes="bg-red-600 text-theme rounded-full"
            :scale="1.25"
            :framework-icon="PrimeIcons.EXCLAMATION_CIRCLE" />
        </AKTooltip>
      </div>

      <AKSelectLanguage v-if="enableMultiLanguageSupport" class="hidden sm:flex" />
      <AKLogoutButton />

      <div class="text-theme flex items-center gap-4 sm:hidden">
        <AKIcon
          v-if="showAppSearch"
          class="cursor-pointer"
          :scale="1.25"
          :framework-icon="PrimeIcons.SEARCH"
          @click="appSearch?.toggleMobileVisibility()" />

        <AKIcon class="cursor-pointer" :framework-icon="toggleMenuIcon" :scale="1.75" @click="menuStateChange()" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AKLogoutButton } from '@ak_tools/components/button';
import { AKIcon, AKLogo } from '@ak_tools/components/media';
import { AKMainMenuStates, AKSelectLanguage } from '@ak_tools/components/menu';
import { AKTooltip } from '@ak_tools/components/overlay';
import AKEnv from '@ak_tools/framework_support/AKEnv';
import { AKStore } from '@ak_tools/start/App';
import { PrimeIcons } from 'primevue/api';
import { computed, inject, Ref, ref } from 'vue';

import AppSearch from '@/components/ak_components/AppSearch.vue';

//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const mainMenuState = inject('mainMenuState') as Ref<AKMainMenuStates>;
const envIsDev = inject('envIsDev') as boolean;
const appSearch = ref<InstanceType<typeof AppSearch> | null>(null);
const mobileSearchVisibility = ref(false);

//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const backendAvailable = computed(() => {
  return AKStore.App.state.backendAvailable;
});

const mainMenuIsMaximized = computed(() => {
  return mainMenuState.value === AKMainMenuStates.MAXIMIZED;
});

const toggleMenuIcon = computed(() => {
  return mainMenuIsMaximized.value ? PrimeIcons.TIMES : PrimeIcons.BARS;
});

const enableMultiLanguageSupport = computed(() => {
  return !!Number(AKEnv.VUE_APP_ENABLE_MULTI_LANGUAGE);
});

const showAppSearch = computed(() => {
  return !!Number(AKEnv.VUE_APP_ENABLE_SEARCH);
});

//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  onMenuStateChange: [];
}>();

const menuStateChange = () => {
  emits('onMenuStateChange');
};
</script>

<style scoped lang="sass"></style>
