<template>
  <div class="ak-main-menu-container h-full sm:flex sm:min-w-[3rem] sm:max-w-[20rem]" :class="mainMenuWidthClass">
    <div class="flex w-full px-2">
      <AKDivider class="mb-2 mt-0" />
    </div>

    <div id="mainMenuScrollableArea" class="ak-scrollbar-primary w-full grow overflow-auto">
      <AKRouterMenu v-if="showRouterMenu" @toggle-main-menu-state="toggleMainMenuState" />
      <AKLanguageMenu v-else-if="activeMenuPage === AKMainMenuPages.LANGUAGE && enableMultiLanguageSupport" />
      <AKProfileMenu v-else-if="activeMenuPage === AKMainMenuPages.PROFILE" />
    </div>

    <AKMainMenuSelectMenu v-if="currentBreakpoint === AKBreakpoints.MOBILE" v-model="activeMenuPage" />

    <div v-else class="flex w-full flex-col p-2">
      <AKDivider class="my-4" />

      <div class="flex flex-row justify-center gap-2" :class="{ 'justify-start': mainMenuIsMaximized }">
        <AKAvatar
          class="text-theme bg-gray-500"
          :full-name="akHR.getPersonFullName(AKStore.User.state.person)"
          :picture-path="AKStore.User.state.person.picture" />
        <span
          v-if="mainMenuIsMaximized"
          class="grow truncate"
          v-text="akHR.getPersonFullName(AKStore.User.state.person)" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AKLanguageMenu, AKMainMenuSelectMenu, AKProfileMenu, AKRouterMenu } from '@ak_tools/components/menu';
import { AKAvatar } from '@ak_tools/components/misc';
import { AKDivider } from '@ak_tools/components/panel';
import AKEnv from '@ak_tools/framework_support/AKEnv';
import { AKStore } from '@ak_tools/start/App';
import { AKBreakpoints } from '@ak_tools/store/app/types';
import { AKHR } from '@ak_tools/ts_modules/hr/AKHR';
import { computed, inject, Ref, ref } from 'vue';

//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const mainMenuState = inject('mainMenuState') as Ref<AKMainMenuStates>;
const activeMenuPage = ref<AKMainMenuPages>(AKMainMenuPages.NAVIGATION);
const akHR = new AKHR();

//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const mainMenuWidthClass = computed(() => {
  return mainMenuState.value === AKMainMenuStates.MAXIMIZED ? 'flex w-full sm:w-[20rem]' : 'hidden sm:w-[3.7rem]';
});

const mainMenuIsMaximized = computed(() => {
  return mainMenuState.value === AKMainMenuStates.MAXIMIZED;
});

const currentBreakpoint = computed(() => {
  return AKStore.App.state.breakpoint;
});

const showRouterMenu = computed(() => {
  return activeMenuPage.value === AKMainMenuPages.NAVIGATION || AKStore.App.state.breakpoint >= AKBreakpoints.SM;
});

const enableMultiLanguageSupport = computed(() => {
  return !!Number(AKEnv.VUE_APP_ENABLE_MULTI_LANGUAGE);
});

//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const toggleMainMenuState = () => {
  mainMenuState.value =
    mainMenuState.value === AKMainMenuStates.MAXIMIZED ? AKMainMenuStates.MINIFIED : AKMainMenuStates.MAXIMIZED;
};

//  --------------------------------------------------------------------------------------------------------------------
//  provided data + public expose
//  --------------------------------------------------------------------------------------------------------------------
defineExpose({
  toggleMainMenuState,
});
</script>

<script lang="ts">
export enum AKMainMenuStates {
  MINIFIED,
  MAXIMIZED,
}

export enum AKMainMenuPages {
  NAVIGATION,
  LANGUAGE,
  PROFILE,
}
</script>

<style scoped lang="sass">
.ak-main-menu-container
  @apply bg-primary relative flex-col items-start text-theme transition-all duration-300
</style>
