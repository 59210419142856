<template>
  <AKAccordion :active-index="opened">
    <AKAccordionTab>
      <template #header>
        <AKTooltip
          v-if="props.showTooltip"
          :placement="props.tooltipPlacement"
          :tooltip-distance="35"
          :text="props.tooltipText"
          :theme="props.tooltipTheme">
          <span v-text="props.headerTitle" />
          <template #content>
            <slot name="tooltipContent" />
          </template>
        </AKTooltip>
        <span v-else v-text="props.headerTitle" />
      </template>
      <slot></slot>
      <AKButton v-if="showAddButton" :data-test="dataTest" :icon="PrimeIcons.PLUS" @click="openMenuOrAddItem" />
      <AKMenu v-if="props.buttonMenuItems" ref="akMenu" :items="props.buttonMenuItems" />
    </AKAccordionTab>
  </AKAccordion>
</template>

<script setup lang="ts">
type _VTI_TYPE_AKTooltipValidThemes = 'ak-white'
type _VTI_TYPE_AKTooltipValidPlacements = | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end'
interface PropsInterface {
  dataTest: string;
  headerTitle?: string;
  buttonMenuItems?: AKMenuItem[];
  showAddButton?: boolean;
  active?: boolean;
  tooltipText?: string;
  tooltipPlacement?: _VTI_TYPE_AKTooltipValidPlacements;
  tooltipTheme?: _VTI_TYPE_AKTooltipValidThemes;
  showTooltip?: boolean;
}
import { AKButton } from '@ak_tools/components/button'
import { AKAccordion, AKAccordionTab } from '@ak_tools/components/layout'
import { AKMenu, AKMenuItem } from '@ak_tools/components/menu'
import { AKTooltip } from '@ak_tools/components/overlay'
import { PrimeIcons } from 'primevue/api'
import { computed, ref } from 'vue'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  headerTitle: undefined,
  buttonMenuItems: () => [],
  active: false,
  showAddButton: false,
  tooltipText: undefined,
  tooltipPlacement: undefined,
  tooltipTheme: undefined,
  showTooltip: false,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const akMenu = ref<InstanceType<typeof AKMenu> | null>(null);
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const opened = computed(() => {
  return props.active ? 0 : undefined;
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const openMenuOrAddItem = (e: Event) => {
  if (props.buttonMenuItems.length != 0) {
    akMenu.value?.showMenu(e);
  } else {
    emitOnAdd();
  }
};
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  onAdd: [];
}>();
const emitOnAdd = () => {
  emits('onAdd');
};
</script>

<style lang="sass" scoped></style>
