import { AKBECommErrorCodes, AKBECommErrorResponseSchema } from '@ak_tools/backend_communication/AKBECommDefault';
import AKEnv from '@ak_tools/framework_support/AKEnv';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { wrapper } from 'axios-cookiejar-support';
import { CookieJar } from 'tough-cookie';
// eslint-disable-next-line max-len
/* eslint max-classes-per-file: ["error", 2] */

const AKBackendSuccess: Array<string> = [AKBECommErrorCodes.no_error, AKBECommErrorCodes.warning];

const AKBackendWarning: Array<string> = [AKBECommErrorCodes.warning];

class AKBackendCommunicationCanceler {
  public controller: AbortController | null;

  constructor() {
    this.controller = null;
  }
}
class AKBackendCommunication {
  jar: CookieJar = new CookieJar();

  communicator: AxiosInstance;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleRetry(config: any, error?: any) {
    if (!config || !config.retry) {
      return Promise.reject(error);
    }
    config.retry -= 1;
    if (config.retry) {
      return this.communicator(config);
    } else {
      return Promise.reject(error);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onEveryValidResponse = (response: AxiosResponse<any, any>) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const config = response.config as any;
    if (response.data?.error_code === undefined) {
      return response;
    }
    if (response.data?.error_code === 'no_error') {
      return response;
    }
    if (!config || !config.retry) {
      return Promise.reject(response);
    }
    return this.handleRetry(config, response);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onEveryError = (error: any) => {
    const { config, message } = error;
    if (!config || !config.retry) {
      return Promise.reject(error);
    }
    if (!(message.includes('timeout') || message.includes('Network Error'))) {
      return Promise.reject(error);
    }
    return this.handleRetry(config, error);
  };

  constructor() {
    const jar: CookieJar = new CookieJar();
    this.communicator = wrapper(
      axios.create({
        jar,
        baseURL: AKEnv.VUE_APP_BACKEND_SERVER_URL,
        timeout: parseInt(AKEnv.VUE_APP_BACKEND_COMMUNICATION_TIMEOUT!, 10) ?? 5000,
        withCredentials: true,
      }),
    );

    this.communicator.interceptors.response.use(this.onEveryValidResponse, this.onEveryError);
  }

  public newCanceler(): AKBackendCommunicationCanceler {
    const result = new AKBackendCommunicationCanceler();
    return result;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public isCancelError(error: any): boolean {
    return axios.isCancel(error);
  }
}

const AKBackendCommunicationObj = new AKBackendCommunication();

export {
  AKBackendCommunication,
  AKBackendCommunicationCanceler,
  AKBackendCommunicationObj,
  AKBackendSuccess,
  AKBackendWarning,
  AKBECommErrorCodes,
};
export type { AKBECommErrorResponseSchema };
