<template>
  <ProgressSpinner
    :stroke-width="props.strokeWidth"
    :fill="props.backgroundColor"
    style="width: 100%; height: 100%"
    :animation-duration="props.animationDuration" />
</template>

<script setup lang="ts">
interface PropsInterface {
  strokeWidth?: string | undefined;
  backgroundColor?: string | undefined;
  animationDuration?: string | undefined;
}
import ProgressSpinner from 'primevue/progressspinner'
//  --------------------------------------------------------------------------------------------------------------------
//  component props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  strokeWidth: '4',
  backgroundColor: undefined,
  animationDuration: '2s',
});
</script>

<style lang="sass" scoped></style>
