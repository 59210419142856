import { AKBECommDefaultResponse, AKBECommDefaultResponseSchema } from '../../AKBECommDefault';
import { AKBECommPersonEmployeeUserSchema } from '../hr/AKBECommPersons';
import { AKBECommUserGroupSchema } from './AKBECommUsers';

export enum AKBECommLoginRequestLoginType {
  default = 0,
  keycloak = 1,
}

export type AKBECommApplicationModuleSchema = {
  module_id: number;
  title: string;
};

export type AKBECommApplicationSchema = {
  uuid: string;
  title: string;
  version: string;
  modules: Array<AKBECommApplicationModuleSchema>;
};

export type AKBECommLoginData = {
  user_id: number;
  logged_in: boolean;
  username: string;
  user_groups: Array<AKBECommUserGroupSchema>;
  application: AKBECommApplicationSchema;
  person: AKBECommPersonEmployeeUserSchema;
  able_to_logout: boolean;
};

export type AKBECommLoginRequest = {
  login_type: AKBECommLoginRequestLoginType;
  username?: string;
  password?: string;
  access_token?: string;
  refresh_token?: string;
};

export type AKBECommLoginResponse = AKBECommDefaultResponseSchema & {
  login_data: AKBECommLoginData;
};

export type AKBECommLoginResponseR = AKBECommDefaultResponse<AKBECommLoginResponse>;
