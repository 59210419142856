<template>
  <AKInputContainer
    :label="props.label"
    :label-cols="props.labelCols"
    :tooltip-text="props.tooltipText"
    :tooltip-placement="props.tooltipPlacement"
    :tooltip-theme="props.tooltipTheme"
    :show-tooltip="props.showTooltip">
    <AKWYSIWYGTextEditor
      ref="akWYSIWYGTextEditor"
      v-model="modelValue"
      :placeholder="props.placeholder"
      :lazy="props.lazy"
      hide-close-icon
      auto-focus
      :enable-markdown-switch="props.enableMarkdownSwitch"
      @on-input="emitOnInput"
      @on-blur="emitOnEditorBlur"
      @on-close="emitOnEditorBlur" />
  </AKInputContainer>
</template>

<script setup lang="ts">
type _VTI_TYPE_AKTooltipValidThemes = 'ak-white'
type _VTI_TYPE_AKTooltipValidPlacements = | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end'
interface PropsInterface {
  placeholder?: string;
  label?: string | false;
  labelCols?: number | 'auto';
  showTooltip?: boolean;
  tooltipText?: string;
  tooltipPlacement?: _VTI_TYPE_AKTooltipValidPlacements;
  tooltipTheme?: _VTI_TYPE_AKTooltipValidThemes;
  lazy?: boolean;
  enableMarkdownSwitch?: boolean;
}
import { AKWYSIWYGTextEditor } from '@ak_tools/components/form'
import { AKInputContainer } from '@ak_tools/components/layout'
import { nextTick, ref } from 'vue'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const modelValue = defineModel<string | null>({ default: null });
const props = withDefaults(defineProps<PropsInterface>(), {
  placeholder: undefined,
  label: false,
  labelCols: undefined,
  showTooltip: undefined,
  tooltipText: undefined,
  tooltipPlacement: undefined,
  tooltipTheme: undefined,
  lazy: false,
  enableMarkdownSwitch: undefined,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const akWYSIWYGTextEditor = ref<InstanceType<typeof AKWYSIWYGTextEditor> | null>();
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const focusEditor = (moveToEnd?: boolean) => {
  nextTick(() => {
    akWYSIWYGTextEditor.value?.focusEditor(moveToEnd);
  });
};
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  onInput: [];
  onEditorBlur: [];
}>();
const emitOnInput = () => {
  emits('onInput');
};
const emitOnEditorBlur = () => {
  emits('onEditorBlur');
};
//  --------------------------------------------------------------------------------------------------------------------
//  provided data + public expose
//  --------------------------------------------------------------------------------------------------------------------
defineExpose({ focusEditor });
</script>

<style lang="sass" scoped></style>
