import 'primeicons/primeicons.css';

import BadgeDirective from 'primevue/badgedirective';
import PrimeVue, { PrimeVueConfiguration } from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import { App } from 'vue';

class AKPrimeVuePlugin {
  constructor(app: App) {
    const primeVueConfig: PrimeVueConfiguration = {
      ripple: true,
    };

    app.use(PrimeVue, primeVueConfig);
    app.use(ConfirmationService);
    app.use(ToastService);
    app.directive('badge', BadgeDirective);
  }
}

export default AKPrimeVuePlugin;
