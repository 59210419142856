<template>
  <AKSelect
    v-model="personValue"
    track-by="person_id"
    :selectable-options="filteredOptions"
    :label="props.label"
    :label-cols="props.labelCols"
    :tooltip-text="props.tooltipText"
    :tooltip-placement="props.tooltipPlacement"
    :tooltip-theme="props.tooltipTheme"
    :show-tooltip="props.showTooltip"
    :default-search="false"
    :placeholder="props.placeholder"
    :multi-select-tag-content="multiSelectTagContent"
    :allow-empty="props.allowEmpty"
    :select-by-index="selectByIndex"
    :select-by-indexes="selectByIndexes"
    :show-loading="showLoading"
    :multiselect="props.multiselect"
    :enable-switch-read-write-mode="props.enableSwitchReadWriteMode"
    :disabled="props.disabled"
    :select-label="props.selectLabel"
    :deselect-label="props.deselectLabel"
    :selected-label="props.selectedLabel"
    @on-open="onOpen"
    @on-close="onClose"
    @on-select="onSelect"
    @on-remove="onRemove"
    @on-search="onSearch">
    <template #tooltipContent>
      <slot name="tooltipContent" />
    </template>
    <template #singleLabel="{ option }: { option: AKBECommPersonEmployeeUserSchema }">
      <div class="grid grid-cols-12 place-items-stretch">
        <div class="col-span-9 flex h-full flex-col items-center">
          <div class="w-full text-left" v-text="customFullName(option)" />
          <div v-if="displayEmail(option)" class="w-full text-left" v-text="option.user?.email" />
        </div>
        <div class="col-span-3 grid grid-cols-12 place-items-stretch">
          <div class="col-span-6 flex h-full items-center">
            <span v-if="displayMainOrgUnit(option)" v-text="option.employee?.main_org_unit_abbr" />
          </div>
          <div class="col-span-6 flex h-full items-center">
            <div v-if="displayPersonalNumber(option)">
              <span>PN:</span>
              <div v-text="option.employee?.personal_number" />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #option="{ option }: { option: AKBECommPersonEmployeeUserSchema }">
      <AKTooltip theme="ak-white" placement="right">
        <template #content>
          <AKEmployeeCard :show-groups="false" :person-data="option" />
        </template>
        <div class="grid grid-cols-12 place-items-stretch">
          <div class="col-span-9 flex h-full flex-col items-center">
            <div class="w-full text-left" v-text="customFullName(option)" />
            <div v-if="displayEmail(option)" class="w-full text-left" v-text="option.user?.email" />
          </div>
          <div class="col-span-3 grid grid-cols-12 place-items-stretch">
            <div class="col-span-6 flex h-full items-center">
              <span v-if="displayMainOrgUnit(option)" v-text="option.employee?.main_org_unit_abbr" />
            </div>
            <div class="col-span-6">
              <div v-if="displayPersonalNumber(option)">
                <span>PN:</span>
                <div v-text="option.employee?.personal_number" />
              </div>
            </div>
          </div>
        </div>
      </AKTooltip>
    </template>
    <template #ak-text="{ value }: { value: AKBECommPersonEmployeeUserSchema | AKBECommPersonEmployeeUserSchema[] }">
      <AKSelectPersonsTemplate v-if="value && valueIsArray(value)" :persons="value" />
      <AKSelectPersonTemplate v-else-if="value && !valueIsArray(value)" :person="value" />
    </template>
  </AKSelect>
</template>

<script setup lang="ts">
type _VTI_TYPE_AKBECommSelectPersonFilter = /* enum */ string
type _VTI_TYPE_AKTooltipValidThemes = 'ak-white'
type _VTI_TYPE_AKTooltipValidPlacements = | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end'
interface PropsInterface {
  label?: string | false;
  labelCols?: number | 'auto';
  selectableOptions?: AKBECommPersonEmployeeUserSchema[];
  placeholder?: string;
  selectById?: number;
  selectByIds?: number[];
  allowEmpty?: boolean;
  multiselect?: boolean;
  setOptionsOnMount?: boolean;
  disabled?: boolean;
  enableSwitchReadWriteMode?: boolean;
  tooltipText?: string;
  showTooltip?: boolean;
  tooltipPlacement?: _VTI_TYPE_AKTooltipValidPlacements;
  tooltipTheme?: _VTI_TYPE_AKTooltipValidThemes;
  showEmail?: boolean;
  showPersonalNumber?: boolean;
  showMainOrgUnit?: boolean;
  personFilter?: _VTI_TYPE_AKBECommSelectPersonFilter;
  selectLabel?: string | false;
  deselectLabel?: string | false;
  selectedLabel?: string | false;
}
import { AKBECommPersonEmployeeSmallSchema, AKBECommPersonEmployeeUserSchema, AKBECommSelectPersonFilter } from '@ak_tools/backend_communication/definitions/hr/AKBECommPersons'
import { AKTooltip } from '@ak_tools/components/overlay'
import { AKEmployeeCard } from '@ak_tools/components/panel'
import { AKSelect, AKSelectPersonsTemplate, AKSelectPersonTemplate } from '@ak_tools/components/select'
import { AKStore } from '@ak_tools/start/App'
import { computed, onMounted, ref, watch } from 'vue'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const personValue = defineModel<
  | AKBECommPersonEmployeeSmallSchema
  | AKBECommPersonEmployeeSmallSchema[]
  | AKBECommPersonEmployeeUserSchema
  | AKBECommPersonEmployeeUserSchema[]
  | null
>({
  default: null,
});
const props = withDefaults(defineProps<PropsInterface>(), {
  label: 'AKSelectPerson.default-label',
  labelCols: undefined,
  selectableOptions: () => [],
  placeholder: 'AKSelectPerson.default-placeholder',
  selectById: undefined,
  selectByIds: () => [],
  allowEmpty: undefined,
  multiselect: undefined,
  setOptionsOnMount: undefined,
  disabled: undefined,
  enableSwitchReadWriteMode: undefined,
  tooltipText: undefined,
  showTooltip: undefined,
  tooltipPlacement: undefined,
  tooltipTheme: undefined,
  showEmail: true,
  showPersonalNumber: true,
  showMainOrgUnit: true,
  personFilter: AKBECommSelectPersonFilter.all_existing_persons,
  selectLabel: false,
  deselectLabel: false,
  selectedLabel: false,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const filteredOptions = ref<AKBECommPersonEmployeeUserSchema[]>([]);
const internalSelectableOptions = ref(props.selectableOptions);
const showLoading = ref(false);
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const selectByIndex = computed(() => {
  if (props.selectById) {
    return filteredOptions.value.findIndex((person) => person.person_id === props.selectById);
  }
  return -1;
});
const selectByIndexes = computed(() => {
  if (props.selectByIds.length) {
    return filteredOptions.value
      .filter((option) => props.selectByIds.includes(option.person_id))
      .map((option) => filteredOptions.value.indexOf(option));
  }
  return [];
});
const personsAvailable = computed(() => {
  return AKStore.HR.state.personsAvailable;
});
const selectablePersons = computed(() => {
  return AKStore.HR.state.selectablePersons;
});
const updateSelectablePersons = computed(() => {
  return (
    personsAvailable.value &&
    !props.selectableOptions.length &&
    props.personFilter === AKBECommSelectPersonFilter.all_existing_persons
  );
});
const employeesAvailable = computed(() => {
  return AKStore.HR.state.employeesAvailable;
});
const selectableEmployees = computed(() => {
  return AKStore.HR.state.selectableEmployees;
});
const updateSelectableEmployees = computed(() => {
  return (
    employeesAvailable.value &&
    !props.selectableOptions.length &&
    props.personFilter === AKBECommSelectPersonFilter.just_all_employees
  );
});
const usersAvailable = computed(() => {
  return AKStore.HR.state.usersAvailable;
});
const selectableUsers = computed(() => {
  return AKStore.HR.state.selectableUsers;
});
const updateSelectableUsers = computed(() => {
  return (
    usersAvailable.value &&
    !props.selectableOptions.length &&
    props.personFilter === AKBECommSelectPersonFilter.just_all_users
  );
});
//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
onMounted(() => {
  if (props.setOptionsOnMount) {
    setSelectableOptions();
  }
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const onOpen = () => {
  emitOnOpen();
  if (!props.setOptionsOnMount) {
    setSelectableOptions();
  }
};
const onClose = () => {
  emitOnClose();
};
const onSelect = (person: AKBECommPersonEmployeeUserSchema) => {
  emitOnSelect(person);
};
const onRemove = (person: AKBECommPersonEmployeeUserSchema) => {
  emitOnRemove(person);
};
const onSearch = (query: string) => {
  const queryStr = query.toLowerCase();
  const options = internalSelectableOptions.value.filter((option) => customSearch(option).includes(queryStr));
  filteredOptions.value = options;
};
const setSelectableOptions = () => {
  if (props.selectableOptions.length) {
    filteredOptions.value = props.selectableOptions;
  } else {
    getSelectableOptionsFromStore();
  }
};
const customSearch = (person: AKBECommPersonEmployeeUserSchema) => {
  const searchArray: Array<string> = [];
  searchArray.push(person.firstname);
  searchArray.push(person.lastname);
  searchArray.push(person.title);
  searchArray.push(`${person.firstname[0]}${person.lastname[0]}`);
  if (person.phone_number) {
    searchArray.push(person.phone_number);
  }
  if (person.employee) {
    searchArray.push(person.employee.personal_number);
    searchArray.push(person.employee.main_org_unit_abbr);
    searchArray.push(person.employee.main_org_unit_title);
  }
  if (person.user) {
    searchArray.push(person.user.email);
  }
  return searchArray.join(' ').toLowerCase();
};
const multiSelectTagContent = (person: AKBECommPersonEmployeeUserSchema) => {
  return customFullName(person);
};
const valueIsArray = (
  val: AKBECommPersonEmployeeUserSchema | AKBECommPersonEmployeeUserSchema[],
): val is AKBECommPersonEmployeeUserSchema[] => {
  return Array.isArray(val);
};
const customFullName = (person: AKBECommPersonEmployeeUserSchema) => {
  return `${person.firstname} ${person.lastname}`;
};
const displayEmail = (person: AKBECommPersonEmployeeUserSchema) => {
  return !!(props.showEmail && person.user && person.user.email);
};
const displayMainOrgUnit = (person: AKBECommPersonEmployeeUserSchema) => {
  return !!(props.showMainOrgUnit && person.employee && person.employee.main_org_unit_abbr);
};
const displayPersonalNumber = (person: AKBECommPersonEmployeeUserSchema) => {
  return !!(props.showPersonalNumber && person.employee && person.employee.personal_number);
};
const setResultAsOptions = (persons: Array<AKBECommPersonEmployeeUserSchema>) => {
  internalSelectableOptions.value = persons;
  filteredOptions.value = internalSelectableOptions.value;
};
const setSelectablePersons = () => {
  if (selectablePersons.value.length) {
    setResultAsOptions(selectablePersons.value);
    showLoading.value = false;
  } else {
    // TODO: Store Comm -> setSelectablePersons()
  }
};
const setSelectableUsers = () => {
  if (selectableUsers.value.length) {
    setResultAsOptions(selectableUsers.value);
    showLoading.value = false;
  } else {
    // TODO: Store Comm -> setSelectableUsers()
  }
};
const setSelectableEmployees = () => {
  if (selectableEmployees.value.length) {
    setResultAsOptions(selectableEmployees.value);
    showLoading.value = false;
  } else {
    // TODO: Store Comm -> setSelectableEmployees()
  }
};
const getSelectableOptionsFromStore = () => {
  showLoading.value = true;
  if (props.personFilter === AKBECommSelectPersonFilter.all_existing_persons) {
    setSelectablePersons();
  }
  if (props.personFilter === AKBECommSelectPersonFilter.just_all_users) {
    setSelectableUsers();
  }
  if (props.personFilter === AKBECommSelectPersonFilter.just_all_employees) {
    setSelectableEmployees();
  }
};
//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
const observables = [
  { available: personsAvailable, update: updateSelectablePersons, options: selectablePersons },
  { available: employeesAvailable, update: updateSelectableEmployees, options: selectableEmployees },
  { available: usersAvailable, update: updateSelectableUsers, options: selectableUsers },
];
observables.forEach(({ available, update, options }) => {
  watch(available, () => {
    if (update.value) {
      setResultAsOptions(options.value);
      showLoading.value = false;
    }
  });
});
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  onOpen: [];
  onClose: [];
  onRemove: [person: AKBECommPersonEmployeeUserSchema];
  onSelect: [person: AKBECommPersonEmployeeUserSchema];
}>();
const emitOnOpen = () => {
  emits('onOpen');
};
const emitOnClose = () => {
  emits('onClose');
};
const emitOnRemove = (person: AKBECommPersonEmployeeUserSchema) => {
  emits('onRemove', person);
};
const emitOnSelect = (person: AKBECommPersonEmployeeUserSchema) => {
  emits('onSelect', person);
};
</script>

<style lang="sass" scoped></style>
