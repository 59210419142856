<template>
  <ConfirmDialog
    class="ak-confirm-dialog"
    :auto-z-index="false"
    :group="props.group"
    :pt="{
      root: {
        style: 'width: 35rem',
      },
      content: {
        class: 'pb-2',
      },
      footer: {
        class: 'p-0 pt-2',
      },
      mask: {
        style: [zIndexStyle],
      },
    }">
    <template #message>
      <div class="w-full">
        <div v-if="props.confirmMethod === ValidConfirmMethods.INPUT_CONFIRM" class="ak-flex-col">
          <span v-text="confirmInputMessage" />

          <div class="space-y-2">
            <AKInputText
              v-model="inputValueText"
              data-test="ak-input-text-confirm-action"
              autofocus
              :placeholder="props.checkValue"
              @on-enter-pressed="confirmAction" />

            <AKButton
              v-if="confirmIsAllowed"
              class="ak-confirm-action-button-style"
              label="OK"
              @click="confirmAction" />

            <AKButton
              class="ak-confirm-action-button-style abort"
              label="Abbrechen"
              :has-background="false"
              @click="closeConfirmAction" />
          </div>
        </div>
        <div v-else class="ak-flex-col">
          <span v-if="props.message" v-text="props.message" />

          <div class="space-y-2">
            <AKButton class="ak-confirm-action-button-style" label="OK" @click="confirmAction" />
            <AKButton
              class="ak-confirm-action-button-style abort"
              label="Abbrechen"
              :has-background="false"
              @click="closeConfirmAction" />
          </div>
        </div>
      </div>
    </template>
  </ConfirmDialog>
</template>

<script setup lang="ts">
type _VTI_TYPE_AKLayersKey = keyof typeof akLayers
interface PropsInterface {
  group: string;
  checkValue?: string;
  headline?: string;
  message?: string;
  confirmMethod?: _VTI_TYPE_ValidConfirmMethods;
  zLayer?: _VTI_TYPE_AKLayersKey;
}
import { akGetLayer } from '@ak_tools/assets/ak_styles/vendors/tailwind/akLayers'
import { AKButton } from '@ak_tools/components/button'
import { AKInputText } from '@ak_tools/components/form'
import { ConfirmationOptions } from 'primevue/confirmationoptions'
import ConfirmDialog from 'primevue/confirmdialog'
import { useConfirm } from 'primevue/useconfirm'
import { computed, ref, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n();
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const showConfirmAction = defineModel<boolean>();
const props = withDefaults(defineProps<PropsInterface>(), {
  headline: ' ',
  message: undefined,
  checkValue: undefined,
  confirmMethod: ValidConfirmMethods.DEFAULT_CONFIRM,
  zLayer: 'wireframeOverlayLayer',
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const confirmObject = useConfirm();
const inputValueText = ref('');
//  --------------------------------------------------------------------------------------------------------------------
//  computed props
//  --------------------------------------------------------------------------------------------------------------------
const confirmationOptions = computed(() => {
  const options: ConfirmationOptions = {
    header: props.headline,
    group: props.group,
    acceptClass: 'hidden',
    rejectClass: 'hidden',
    onHide: () => {
      resetValues();
    },
  };
  return options;
});
const confirmInputMessage = computed(() => {
  return t('AKConfirmAction.message', {
    checkValue: props.checkValue,
  });
});
const confirmIsAllowed = computed(() => {
  if (props.confirmMethod !== ValidConfirmMethods.INPUT_CONFIRM) {
    return true;
  }
  return inputValueText.value === props.checkValue ? true : false;
});
const zIndexStyle = computed(() => {
  return `z-index: ${akGetLayer(props.zLayer) + 1};`;
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const openConfirmAction = () => {
  confirmObject.require(confirmationOptions.value);
};
const closeConfirmAction = () => {
  confirmObject.close();
  emitOnCancel();
};
const confirmAction = () => {
  if (confirmIsAllowed.value) {
    confirmObject.close();
    emitOnConfirm();
  }
};
const resetValues = () => {
  showConfirmAction.value = false;
  inputValueText.value = '';
};
//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watchEffect(() => {
  if (showConfirmAction.value) {
    openConfirmAction();
  }
});
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  onConfirm: [];
  onCancel: [];
}>();
const emitOnConfirm = () => {
  emits('onConfirm');
  resetValues();
};
const emitOnCancel = () => {
  emits('onCancel');
  resetValues();
};
</script>

<script lang="ts">
export enum ValidConfirmMethods {
  DEFAULT_CONFIRM,
  INPUT_CONFIRM,
}
</script>

<style lang="sass" scoped>
.ak-confirm-action-button-style
  @apply w-full

  &.abort
    @apply text-gray-500 border-solid border-gray-300 #{!important}
</style>
