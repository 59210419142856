import {
  AKBECommDefaultResponse,
  AKBECommDefaultResponseSchema,
} from '@ak_tools/backend_communication/AKBECommDefault';
import {
  AKBECommNotificationField,
  AKBECommNotificationType,
} from '@ak_tools/backend_communication/definitions/events/AKBECommNotifications';
import {
  AKBECommPersonEmployeeUserSchema,
  AKBECommUserGroupSmall,
} from '@ak_tools/backend_communication/definitions/hr/AKBECommPersons';
import { AKBECommStatusSchema } from '@ak_tools/backend_communication/definitions/statuses/AKBECommStatuses';

//  --------------------------------------------------------------------------------------------------------------------
//  Types
//  --------------------------------------------------------------------------------------------------------------------
export enum AKBECommApplicationIds {
  wapp_home = 1,
  absence_management = 2,
  zp = 3,
  asana = 4,
}

export type AKBECommApplication = {
  application_id: number;
  title: string;
  internal: boolean;
  git_project_id: number | null;
};

export enum AKBECommApplicationTypes {
  just_all_applications = 'just_all_applications',
  just_internal_applications = 'just_internal_applications',
  just_external_applications = 'just_external_applications',
}

export type AKBECommApplicationInfo = {
  secure_information: string;
};

export type AKBECommModule = {
  module_id: number;
  title: string;
};

export type AKBECommApplicationModule = {
  application_module_id: number;
  application: AKBECommApplication | null;
  module: AKBECommModule | null;
};

export type AKBECommModuleNotificationSetting = {
  module_id: number;
  notification_type: AKBECommNotificationType | null;
  notification_field: AKBECommNotificationField | null;
  value: boolean;
  default_value: boolean;
};

export type AKBECommApi = {
  api_id: number;
  title: string;
  application: AKBECommApplication | null;
  whitelist: AKBECommUserGroupSmall[];
  blacklist: AKBECommUserGroupSmall[];
  get: boolean | null;
  post: boolean | null;
  put: boolean | null;
  delete: boolean | null;
};

export type AKBECommApiToken = {
  api_token_id: number | null;
  token: string | null;
  application: AKBECommApplication | null;
  user_group: AKBECommUserGroupSmall | null; // Assuming AKUserGroupSRSmall is defined somewhere
  user: AKBECommPersonEmployeeUserSchema | null;
  expire_date: number | null;
  description: string;
};

export type AKBECommApiTokenCreateRequest = {
  application_id: number;
  user_group_id: number | null; // Assuming AKUserGroupSRSmall is defined somewhere
  user_id: number | null;
  token: string | null;
  description: string | null;
  expire_date: number | null;
};

export type AKBECommApiTokenUpdateRequest = {
  user_group_id: number | null; // Assuming AKUserGroupSRSmall is defined somewhere
  user_id: number | null;
  token: string | null;
  expire_date: number | null;
  description: string | null;
};

export type AKBECommApplicationInfoUpdateRequest = {
  secure_information: string;
};
//  --------------------------------------------------------------------------------------------------------------------
//  Application Modules
//  --------------------------------------------------------------------------------------------------------------------
export type AKBECommApplicationModulesGetResponse = AKBECommDefaultResponseSchema & {
  application_modules: AKBECommApplicationModule[];
};
export type AKBECommApplicationModulesGetResponseR = AKBECommDefaultResponse<AKBECommApplicationModulesGetResponse>;

//  --------------------------------------------------------------------------------------------------------------------
//  Application
//  --------------------------------------------------------------------------------------------------------------------

export type AKBECommApplicationsGetResponse = AKBECommDefaultResponseSchema & {
  applications: AKBECommApplication[];
};
export type AKBECommApplicationsGetResponseR = AKBECommDefaultResponse<AKBECommApplicationsGetResponse>;

export type AKBECommApplicationInfoGetResponse = AKBECommDefaultResponseSchema & {
  application_info: AKBECommApplicationInfo;
};

export type AKBECommApplicationGetResponseR = AKBECommDefaultResponse<AKBECommApplicationInfoGetResponse>;

export type AKBECommApplicationUpdateResponseR = AKBECommDefaultResponse<AKBECommApplicationInfoGetResponse>;

//  --------------------------------------------------------------------------------------------------------------------
//  application module notification settings
//  --------------------------------------------------------------------------------------------------------------------
export type AKBECommApplicationModuleModuleNotificationSettingsGetResponse = AKBECommDefaultResponseSchema & {
  application_module: AKBECommApplicationModule | null;
  module_notification_settings: AKBECommModuleNotificationSetting[];
};
export type AKBECommApplicationModuleModuleNotificationSettingsGetResponseR =
  AKBECommDefaultResponse<AKBECommApplicationModuleModuleNotificationSettingsGetResponse>;

export type AKBECommApplicationModuleModuleNotificationSettingsCreateRequest = {
  field_id: number;
  type_id: number;
  value: boolean;
};
export type AKBECommApplicationModuleModuleNotificationSettingsCreateResponse = AKBECommDefaultResponseSchema & {
  application_module: AKBECommApplicationModule | null;
  module_notification_setting: AKBECommModuleNotificationSetting | null;
};
export type AKBECommApplicationModuleModuleNotificationSettingsCreateResponseR =
  AKBECommDefaultResponse<AKBECommApplicationModuleModuleNotificationSettingsCreateResponse>;

//  --------------------------------------------------------------------------------------------------------------------
//  application module notification setting
//  --------------------------------------------------------------------------------------------------------------------
export type AKBECommApplicationModuleModuleNotificationSettingUpdateRequest = {
  value: boolean;
};
export type AKBECommApplicationModuleModuleNotificationSettingUpdateResponse = AKBECommDefaultResponseSchema & {
  module_notification_setting: AKBECommModuleNotificationSetting | null;
};
export type AKBECommApplicationModuleModuleNotificationSettingUpdateResponseR =
  AKBECommDefaultResponse<AKBECommApplicationModuleModuleNotificationSettingUpdateResponse>;

//  --------------------------------------------------------------------------------------------------------------------
//  ak apis
//  --------------------------------------------------------------------------------------------------------------------
export type AKBECommApisGetResponse = AKBECommDefaultResponseSchema & {
  apis: AKBECommApi[];
};

export type AKBECommApiTokensGetResponse = AKBECommDefaultResponseSchema & {
  api_tokens: AKBECommApiToken[];
};

export type AKBECommApiTokenGetResponse = AKBECommDefaultResponseSchema & {
  api_token: AKBECommApiToken;
};

export type AKBECommApisGetResponseResponseR = AKBECommDefaultResponse<AKBECommApisGetResponse>;

export type AKBECommApiTokensGetResponseResponseR = AKBECommDefaultResponse<AKBECommApiTokensGetResponse>;

export type AKBECommApiTokenGetResponseResponseR = AKBECommDefaultResponse<AKBECommApiTokenGetResponse>;

export type AKBECommApisCreateResponse = AKBECommDefaultResponseSchema & {
  api: AKBECommApi | null;
};

export type AKBECommApiTokenCreateResponse = AKBECommDefaultResponseSchema & {
  api_token: AKBECommApiToken | null;
};
export type AKBECommApisCreateResponseR = AKBECommDefaultResponse<AKBECommApisCreateResponse>;

export type AKBECommApiTokenCreateResponseR = AKBECommDefaultResponse<AKBECommApiTokenCreateResponse>;

//  --------------------------------------------------------------------------------------------------------------------
//  ak api
//  --------------------------------------------------------------------------------------------------------------------
export type AKBECommApiGetResponse = AKBECommDefaultResponseSchema & {
  api: AKBECommApi | null;
};
export type AKBECommApiGetResponseR = AKBECommDefaultResponse<AKBECommApiGetResponse>;

export type AKBECommApiUpdateRequest = {
  title: string;
  get: boolean | null;
  post: boolean | null;
  put: boolean | null;
  delete: boolean | null;
  whitelist_ids: number[];
  blacklist_ids: number[];
  application_id: number | null;
};
export type AKBECommApiUpdateResponse = AKBECommDefaultResponseSchema & {
  api: AKBECommApi | null;
};

export type AKBECommApiTokenUpdateResponse = AKBECommDefaultResponseSchema & {
  api_token: AKBECommApiToken | null;
};
export type AKBECommApiUpdateResponseR = AKBECommDefaultResponse<AKBECommApiUpdateResponse>;

export type AKBECommApiTokenUpdateResponseR = AKBECommDefaultResponse<AKBECommApiTokenUpdateResponse>;

export type AKBECommApiDeleteResponse = AKBECommDefaultResponseSchema;
export type AKBECommApiDeleteResponseR = AKBECommDefaultResponse<AKBECommApiDeleteResponse>;

export type AKBECommApiTokenDeleteResponse = AKBECommDefaultResponseSchema;
export type AKBECommApiTokenDeleteResponseR = AKBECommDefaultResponse<AKBECommApiTokenDeleteResponse>;

//  --------------------------------------------------------------------------------------------------------------------
//  ak asana api
//  --------------------------------------------------------------------------------------------------------------------
export type AKBECommAsanaPersonalOverview = {
  status_name: string;
  task_count: number;
};

export type AKBECommPersonalAsanaOverviewGetResponse = AKBECommDefaultResponseSchema & {
  asana_overview: AKBECommAsanaPersonalOverview[];
};

export type AKBECommPersonalAsanaOverviewGetResponseR =
  AKBECommDefaultResponse<AKBECommPersonalAsanaOverviewGetResponse>;

//  --------------------------------------------------------------------------------------------------------------------
// ak zp dashboard info
//  --------------------------------------------------------------------------------------------------------------------
export type AKBECommZPDashboardInfo = {
  zp_count: number;
  status: AKBECommStatusSchema;
};

export type AKBECommZPDashboardInfosResponse = {
  zp_dashboard_infos: AKBECommZPDashboardInfo[];
};

export type AKBECommZPDashboardInfosResponseR = AKBECommDefaultResponse<AKBECommZPDashboardInfosResponse>;
