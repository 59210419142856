import { AKBECommPersonEmployeeUserSchema } from '../hr/AKBECommPersons';
import { AKBECommDefaultResponse, AKBECommDefaultResponseSchema } from './../../AKBECommDefault';

export enum AKRoleType {
  responsible = 1,
  contributor = 2,
  releaser = 3,
  interested = 4,
  admin = 5,
  maintainer = 6,
  user = 7,
}

export enum AKGroupType {
  wapp = 1,
  personal = 2,
  imported = 3,
}

export enum AKBECommSelectUserGroupFilter {
  all_existing_groups = 'all_existing_groups',
  all_personal_groups = 'all_personal_user_groups',
  all_user_groups_without_personal_groups = 'all_user_groups_without_personal_groups',
  all_my_admin_user_groups = 'all_my_admin_user_groups',
  all_my_admin_and_maintainer_user_groups = 'all_my_admin_and_maintainer_user_groups',
}

export type AKBECommRoleSchema = {
  role_id: number;
  title: string;
  description: string;
};

export type AKBECommUserGroupPersonSchema = AKBECommPersonEmployeeUserSchema & {
  role: AKBECommRoleSchema | null;
  active: boolean;
};

export type AKBECommUserGroupTypeSchema = {
  type_id: number;
  title: string;
  description: string;
};

export type AKBECommUserGroupSourceSchema = {
  source_id: number;
  title: string;
  description: string;
  abbr: string;
  short_abbr: string;
};

export type AKBECommUserGroupCategorySchema = {
  category_id: number;
  title: string;
  description: string;
  abbr: string;
};

export type AKBECommUserGroupSchema = {
  user_group_id: number;
  title: string;
  abbr: string;
  description: string;
  source: AKBECommUserGroupSourceSchema | null;
  type: AKBECommUserGroupTypeSchema | null;
  active: boolean;
  persons: Array<AKBECommUserGroupPersonSchema>;
  parent_group_ids: Array<number>;
  selected_parent_group_ids: Array<number>;
  child_group_ids: Array<number>;
};

//  --------------------------------------------------------------------------------------------------------------------
//  user group
//  --------------------------------------------------------------------------------------------------------------------

export type AKBECommUserGroupGetResponse = AKBECommDefaultResponseSchema & {
  user_group: AKBECommUserGroupSchema | null;
};
export type AKBECommUserGroupGetResponseR = AKBECommDefaultResponse<AKBECommUserGroupGetResponse>;

export type AKBECommUserGroupUpdateRequest = {
  title: string;
  abbr: string;
  description: string;
  active: boolean;
};
export type AKBECommUserGroupUpdateResponse = AKBECommDefaultResponseSchema & {
  user_group: AKBECommUserGroupSchema | null;
};
export type AKBECommUserGroupUpdateResponseR = AKBECommDefaultResponse<AKBECommUserGroupUpdateResponse>;

export type AKBECommUserGroupDeleteResponse = AKBECommDefaultResponseSchema;
export type AKBECommUserGroupDeleteResponseR = AKBECommDefaultResponse<AKBECommUserGroupDeleteResponse>;

//  --------------------------------------------------------------------------------------------------------------------
//  user group Source Type + user group Type
//  --------------------------------------------------------------------------------------------------------------------
export type AKBECommUserGroupSourceTypesGetResponse = AKBECommDefaultResponseSchema & {
  user_group_source_types: Array<AKBECommUserGroupSourceSchema>;
};
export type AKBECommUserGroupSourceTypesGetResponseR = AKBECommDefaultResponse<AKBECommUserGroupSourceTypesGetResponse>;

export type AKBECommUserGroupTypesGetResponse = AKBECommDefaultResponseSchema & {
  user_group_types: Array<AKBECommUserGroupTypeSchema>;
};
export type AKBECommUserGroupTypesGetResponseR = AKBECommDefaultResponse<AKBECommUserGroupTypesGetResponse>;

export type AKBECommUserGroupSourceTypeUpdateRequest = {
  title: string;
  description: string;
  abbr: string;
  short_abbr: string;
};
export type AKBECommUserGroupTypeUpdateRequest = {
  title: string;
  description: string;
};
export type AKBECommUserGroupSourceTypeUpdateResponse = AKBECommDefaultResponseSchema & {
  user_group_source_type: AKBECommUserGroupSourceSchema | null;
};
export type AKBECommUserGroupSourceTypeUpdateResponseR =
  AKBECommDefaultResponse<AKBECommUserGroupSourceTypeUpdateResponse>;

export type AKBECommUserGroupSourceTypeGetResponse = AKBECommDefaultResponseSchema & {
  user_group_source_type: AKBECommUserGroupSourceSchema | null;
};
export type AKBECommUserGroupSourceTypeGetResponseR = AKBECommDefaultResponse<AKBECommUserGroupSourceTypeGetResponse>;

export type AKBECommUserGroupTypeUpdateResponse = AKBECommDefaultResponseSchema & {
  user_group_type: AKBECommUserGroupTypeSchema | null;
};
export type AKBECommUserGroupTypeUpdateResponseR = AKBECommDefaultResponse<AKBECommUserGroupTypeUpdateResponse>;

export type AKBECommUserGroupTypeGetResponse = AKBECommDefaultResponseSchema & {
  user_group_type: AKBECommUserGroupTypeSchema | null;
};
export type AKBECommUserGroupTypeGetResponseR = AKBECommDefaultResponse<AKBECommUserGroupTypeGetResponse>;

export type AKBECommUserGroupSourceTypeCreateResponse = AKBECommDefaultResponseSchema & {
  user_group_source_type: AKBECommUserGroupSourceSchema | null;
};
export type AKBECommUserGroupSourceTypeCreateResponseR =
  AKBECommDefaultResponse<AKBECommUserGroupSourceTypeCreateResponse>;

export type AKBECommUserGroupTypeCreateResponse = AKBECommDefaultResponseSchema & {
  user_group_type: AKBECommUserGroupTypeSchema | null;
};
export type AKBECommUserGroupTypeCreateResponseR = AKBECommDefaultResponse<AKBECommUserGroupTypeCreateResponse>;

export type AKBECommUserGroupSourceTypeDeleteResponse = AKBECommDefaultResponseSchema;
export type AKBECommUserGroupSourceTypeDeleteResponseR =
  AKBECommDefaultResponse<AKBECommUserGroupSourceTypeDeleteResponse>;

export type AKBECommUserGroupTypeDeleteResponse = AKBECommDefaultResponseSchema;
export type AKBECommUserGroupTypeDeleteResponseR = AKBECommDefaultResponse<AKBECommUserGroupTypeDeleteResponse>;

//  --------------------------------------------------------------------------------------------------------------------
//  user group categories
//  --------------------------------------------------------------------------------------------------------------------

export type AKBECommUserGroupCategoriesGetResponse = AKBECommDefaultResponseSchema & {
  user_group_categories: Array<AKBECommUserGroupCategorySchema>;
};
export type AKBECommUserGroupCategoriesGetResponseR = AKBECommDefaultResponse<AKBECommUserGroupCategoriesGetResponse>;

export type AKBECommUserGroupCategoryUpdateRequest = {
  title: string;
  abbr: string;
  description: string;
};

export type AKBECommUserGroupCategoryUpdateResponse = AKBECommDefaultResponseSchema & {
  user_group_category: AKBECommUserGroupCategorySchema | null;
};
export type AKBECommUserGroupCategoryUpdateResponseR = AKBECommDefaultResponse<AKBECommUserGroupCategoryUpdateResponse>;

export type AKBECommUserGroupCategoryGetResponse = AKBECommDefaultResponseSchema & {
  user_group_category: AKBECommUserGroupCategorySchema | null;
};
export type AKBECommUserGroupCategoryGetResponseR = AKBECommDefaultResponse<AKBECommUserGroupCategoryGetResponse>;

export type AKBECommUserGroupCategoryCreateResponse = AKBECommDefaultResponseSchema & {
  user_group_category: AKBECommUserGroupCategorySchema | null;
};
export type AKBECommUserGroupCategoryCreateResponseR = AKBECommDefaultResponse<AKBECommUserGroupCategoryCreateResponse>;

export type AKBECommUserGroupCategoryDeleteResponse = AKBECommDefaultResponseSchema;
export type AKBECommUserGroupCategoryDeleteResponseR = AKBECommDefaultResponse<AKBECommUserGroupCategoryDeleteResponse>;

//  --------------------------------------------------------------------------------------------------------------------
//  user groups
//  --------------------------------------------------------------------------------------------------------------------
export type AKBECommUserGroupsGetRequest = {
  selected_filter: AKBECommSelectUserGroupFilter;
  excluded_user_group_ids: Array<number>;
};

export type AKBECommUserGroupsGetResponse = AKBECommDefaultResponseSchema & {
  user_groups: Array<AKBECommUserGroupSchema>;
};
export type AKBECommUserGroupsGetResponseR = AKBECommDefaultResponse<AKBECommUserGroupsGetResponse>;

export type AKBECommUserGroupsCreateResponse = AKBECommDefaultResponseSchema & {
  user_group: AKBECommUserGroupSchema | null;
};
export type AKBECommUserGroupsCreateResponseR = AKBECommDefaultResponse<AKBECommUserGroupsCreateResponse>;

//  --------------------------------------------------------------------------------------------------------------------
//  user group con person con role
//  --------------------------------------------------------------------------------------------------------------------
export type AKBECommUserGroupPersonRoleCreateResponse = AKBECommDefaultResponseSchema & {
  person: AKBECommUserGroupPersonSchema | null;
};
export type AKBECommUserGroupPersonRoleCreateResponseR =
  AKBECommDefaultResponse<AKBECommUserGroupPersonRoleCreateResponse>;

export type AKBECommUserGroupPersonRoleUpdateRequest = {
  active: boolean;
};
export type AKBECommUserGroupPersonRoleUpdateResponse = AKBECommDefaultResponseSchema;
export type AKBECommUserGroupPersonRoleUpdateResponseR =
  AKBECommDefaultResponse<AKBECommUserGroupPersonRoleUpdateResponse>;

export type AKBECommUserGroupPersonRoleDeleteResponse = AKBECommDefaultResponseSchema;
export type AKBECommUserGroupPersonRoleDeleteResponseR =
  AKBECommDefaultResponse<AKBECommUserGroupPersonRoleDeleteResponse>;

//  --------------------------------------------------------------------------------------------------------------------
//  user group con parent group
//  --------------------------------------------------------------------------------------------------------------------
export type AKBECommUserGroupParentGroupCreateResponse = AKBECommDefaultResponseSchema & {
  user_group_id: number;
  parent_group: AKBECommUserGroupSchema | null;
};
export type AKBECommUserGroupParentGroupCreateResponseR =
  AKBECommDefaultResponse<AKBECommUserGroupParentGroupCreateResponse>;

export type AKBECommUserGroupParentGroupDeleteResponse = AKBECommDefaultResponseSchema;
export type AKBECommUserGroupParentGroupDeleteResponseR =
  AKBECommDefaultResponse<AKBECommUserGroupParentGroupDeleteResponse>;

//  --------------------------------------------------------------------------------------------------------------------
//  user group con child group
//  --------------------------------------------------------------------------------------------------------------------
export type AKBECommUserGroupChildGroupCreateResponse = AKBECommDefaultResponseSchema & {
  user_group_id: number;
  child_group: AKBECommUserGroupSchema | null;
};
export type AKBECommUserGroupChildGroupCreateResponseR =
  AKBECommDefaultResponse<AKBECommUserGroupChildGroupCreateResponse>;

export type AKBECommUserGroupChildGroupDeleteResponse = AKBECommDefaultResponseSchema;
export type AKBECommUserGroupChildGroupDeleteResponseR =
  AKBECommDefaultResponse<AKBECommUserGroupChildGroupDeleteResponse>;

//  --------------------------------------------------------------------------------------------------------------------
//  user roles
//  --------------------------------------------------------------------------------------------------------------------
export type AKBECommUserRolesGetResponse = AKBECommDefaultResponseSchema & {
  roles: Array<AKBECommRoleSchema>;
};
export type AKBECommUserRolesGetResponseR = AKBECommDefaultResponse<AKBECommUserRolesGetResponse>;
