<template>
  <AKViewContainer custom-class="bg-white">
    <slot></slot>
  </AKViewContainer>
</template>

<script setup lang="ts">
import { AKViewContainer } from '@ak_tools/components/layout';
import { AKStore } from '@ak_tools/start/App';
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();

//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const setModuleTitle = () => {
  AKStore.App.state.currentModuleTitle = t('AKApp.navigation.playground');
};

//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watch(locale, () => {
  setModuleTitle();
});

//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
setModuleTitle();
</script>

<style lang="sass" scoped></style>
