<template>
  <AKMinWidthContainer min-width="340px">
    <div class="flex flex-col gap-4">
      <AKDropzone @upload-files="uploadFile" />
      <AKFileList
        v-model="fileList"
        :confirm-action-group="props.confirmActionGroup"
        :confirm-delete="props.confirmDelete"
        :with-download-column="props.withDownloadColumn"
        @delete-item="emitDeleteItem"
        @download-item="emitDownloadItem" />
    </div>
  </AKMinWidthContainer>
</template>

<script setup lang="ts">
interface PropsInterface {
  confirmDelete?: boolean;
  maxSumUploads?: number;
  withDownloadColumn?: boolean;
  confirmActionGroup?: string;
}
import { AKMinWidthContainer } from '@ak_tools/components/layout'
import { AKDropzone, AKFileList, AKFileListItem } from '@ak_tools/components/misc'
import { AKStore } from '@ak_tools/start/App'
import { AKAppMessageInfo, AKMessageType } from '@ak_tools/store/app/types'
import { nextTick } from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n();
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const fileList = defineModel<AKFileListItem[]>();
const props = withDefaults(defineProps<PropsInterface>(), {
  confirmDelete: true,
  maxSumUploads: 99999,
  withDownloadColumn: true,
  confirmActionGroup: 'fileUploader',
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const uploadFile = (files: AKFileListItem[]) => {
  nextTick(() => {
    if (
      (!fileList.value || fileList.value.length + files.length <= props.maxSumUploads) &&
      files.length <= props.maxSumUploads
    ) {
      emitUploadItems(files);
    } else {
      showMaxFileError();
    }
  });
};
const showMaxFileError = () => {
  const maxFileInfoMessage: AKAppMessageInfo = {
    messageType: AKMessageType.INFO,
    messageSummary: t('AKFileUploader.message.to-much-files-error-short'),
    messageTranslatableParts: [t('AKFileUploader.message.to-much-files-error')],
  };
  AKStore.App.state.messages.push(maxFileInfoMessage);
};
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  uploadItems: [items: AKFileListItem[]];
  deleteItem: [item: AKFileListItem];
  downloadItem: [item: AKFileListItem];
}>();
const emitUploadItems = (items: AKFileListItem[]) => {
  emits('uploadItems', items);
};
const emitDeleteItem = (item: AKFileListItem) => {
  emits('deleteItem', item);
};
const emitDownloadItem = (item: AKFileListItem) => {
  emits('downloadItem', item);
};
</script>

<style lang="sass" scoped></style>
