<template>
  <div :class="[containerClasses]">
    <div :class="[labelColClasses]">
      <label
        v-if="props.label"
        class="flex w-full hyphens-auto text-balance break-words align-middle"
        lang="de"
        :class="{ 'mb-2': !props.labelCols }">
        <AKTooltip
          v-if="props.showTooltip"
          :placement="props.tooltipPlacement"
          :tooltip-distance="10"
          :text="props.tooltipText"
          :theme="props.tooltipTheme">
          <span class="w-full" v-text="$t(props.label)" />

          <template #content>
            <slot name="tooltipContent" />
          </template>
        </AKTooltip>
        <span v-else class="w-full" v-text="$t(props.label)" />
      </label>
    </div>
    <div :class="[inputColClasses]">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
type _VTI_TYPE_AKTooltipValidThemes = 'ak-white'
type _VTI_TYPE_AKTooltipValidPlacements = | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end'
interface PropsInterface {
  label?: string | false;
  labelCols?: number | 'auto';
  tooltipText?: string;
  tooltipPlacement?: _VTI_TYPE_AKTooltipValidPlacements;
  tooltipTheme?: _VTI_TYPE_AKTooltipValidThemes;
  showTooltip?: boolean;
}
import { AKTooltip } from '@ak_tools/components/overlay'
import { computed } from 'vue'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  label: false,
  labelCols: undefined,
  tooltipText: undefined,
  tooltipPlacement: undefined,
  tooltipTheme: undefined,
  showTooltip: false,
});
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const containerClasses = computed(() => {
  if (props.labelCols) {
    return props.labelCols !== 'auto'
      ? 'grid grid-cols-12 content-center place-items-center'
      : 'flex gap-2 items-center';
  }
  return '';
});
const labelColClasses = computed(() => {
  if (props.labelCols) {
    return props.labelCols !== 'auto'
      ? `ak-col-span-${props.labelCols} flex h-full w-full items-center pr-4`
      : 'flex h-full items-center';
  }
  return '';
});
const inputColClasses = computed(() => {
  if (props.labelCols) {
    return props.labelCols !== 'auto'
      ? `ak-col-span-${12 - props.labelCols} flex h-full w-full items-center`
      : 'flex h-full grow items-center';
  }
  return '';
});
</script>

<style lang="sass" scoped>
@for $i from 1 through 12
  .ak-col-span-#{$i}
    @apply col-span-#{$i}
</style>
