<template>
  <AKSelect
    v-model="userGroupValue"
    track-by="user_group_id"
    :selectable-options="filteredOptions"
    :label="props.label"
    :label-cols="props.labelCols"
    :tooltip-text="props.tooltipText"
    :tooltip-placement="props.tooltipPlacement"
    :tooltip-theme="props.tooltipTheme"
    :show-tooltip="props.showTooltip"
    :default-search="false"
    :placeholder="props.placeholder"
    :multi-select-tag-content="multiSelectTagContent"
    :allow-empty="props.allowEmpty"
    :select-by-index="selectByIndex"
    :select-label="false"
    :deselect-label="false"
    :select-by-indexes="selectByIndexes"
    :show-loading="showLoading"
    :multiselect="props.multiselect"
    :enable-switch-read-write-mode="props.enableSwitchReadWriteMode"
    :disabled="props.disabled"
    @on-open="onOpen"
    @on-close="emitOnClose"
    @on-select="emitOnSelect"
    @on-remove="emitOnRemove"
    @on-search="onSearch">
    <template #tooltipContent>
      <slot name="tooltipContent" />
    </template>
    <template #singleLabel="{ option }: { option: AKBECommUserGroupSchema }">
      <span v-text="option.title" />
    </template>
    <template #option="{ option }: { option: AKBECommUserGroupSchema }">
      <span v-text="`${option.abbr} - ${option.title}`" />
    </template>
    <template #ak-text="{ value }: { value: AKBECommUserGroupSchema | AKBECommUserGroupSchema[] }">
      <AKSelectUserGroupsTemplate v-if="value && valueIsArray(value)" :user-groups="value" />
      <AKSelectUserGroupTemplate v-else-if="value && !valueIsArray(value)" :user-group="value" />
    </template>
  </AKSelect>
</template>

<script setup lang="ts">
type _VTI_TYPE_AKBECommSelectUserGroupFilter = /* enum */ string
type _VTI_TYPE_AKTooltipValidThemes = 'ak-white'
type _VTI_TYPE_AKTooltipValidPlacements = | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end'
interface PropsInterface {
  label?: string | false;
  labelCols?: number | 'auto';
  selectableOptions?: AKBECommUserGroupSchema[];
  placeholder?: string;
  selectById?: number;
  selectByIds?: number[];
  allowEmpty?: boolean;
  multiselect?: boolean;
  setOptionsOnMount?: boolean;
  disabled?: boolean;
  enableSwitchReadWriteMode?: boolean;
  tooltipText?: string;
  tooltipPlacement?: _VTI_TYPE_AKTooltipValidPlacements;
  tooltipTheme?: _VTI_TYPE_AKTooltipValidThemes;
  showTooltip?: boolean;
  userGroupFilter?: _VTI_TYPE_AKBECommSelectUserGroupFilter;
  excludedUserGroupIds?: number[];
  preventBackendComm?: boolean;
}
import { AKBECommUserGroupSmall } from '@ak_tools/backend_communication/definitions/hr/AKBECommPersons'
import { AKBECommSelectUserGroupFilter, AKBECommUserGroupSchema } from '@ak_tools/backend_communication/definitions/users/AKBECommUsers'
import { AKBECommUsers } from '@ak_tools/backend_communication/implementations/users/AKBECommUsers'
import { AKSelect, AKSelectUserGroupsTemplate, AKSelectUserGroupTemplate } from '@ak_tools/components/select'
import { AKVueProvider } from '@ak_tools/framework_support/AKVueProvider'
import { computed, onMounted, ref } from 'vue'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const userGroupValue = defineModel<
  AKBECommUserGroupSchema | AKBECommUserGroupSchema[] | AKBECommUserGroupSmall | AKBECommUserGroupSmall[] | null
>({
  default: null,
});
const props = withDefaults(defineProps<PropsInterface>(), {
  label: 'AKSelectUserGroup.default-label',
  labelCols: undefined,
  selectableOptions: () => [],
  placeholder: 'AKSelectUserGroup.default-placeholder',
  selectById: undefined,
  selectByIds: () => [],
  allowEmpty: undefined,
  multiselect: undefined,
  setOptionsOnMount: undefined,
  disabled: undefined,
  enableSwitchReadWriteMode: undefined,
  tooltipText: undefined,
  tooltipPlacement: undefined,
  tooltipTheme: undefined,
  showTooltip: undefined,
  userGroupFilter: AKBECommSelectUserGroupFilter.all_existing_groups,
  excludedUserGroupIds: () => [],
  preventBackendComm: undefined,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const filteredOptions = ref<AKBECommUserGroupSchema[]>([]);
const internalSelectableOptions = ref(props.selectableOptions);
const showLoading = ref(false);
const AKBECommUser = new AKBECommUsers(new AKVueProvider());
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const selectByIndex = computed(() => {
  if (props.selectById) {
    return filteredOptions.value.findIndex((userGroup) => userGroup.user_group_id === props.selectById);
  }
  return -1;
});
const selectByIndexes = computed(() => {
  if (props.selectByIds.length) {
    return filteredOptions.value
      .filter((option) => props.selectByIds.includes(option.user_group_id))
      .map((option) => filteredOptions.value.indexOf(option));
  }
  return [];
});
//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
onMounted(() => {
  emitOnMount();
  if (props.setOptionsOnMount) {
    setSelectableOptions();
  }
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const onOpen = () => {
  emitOnOpen();
  if (!props.setOptionsOnMount) {
    setSelectableOptions();
  }
};
const onSearch = (query: string) => {
  const queryStr = query.toLowerCase();
  const options = internalSelectableOptions.value.filter((option) => customSearch(option).includes(queryStr));
  filteredOptions.value = options;
};
const setSelectableOptions = () => {
  if (props.selectableOptions.length || props.preventBackendComm) {
    filteredOptions.value = props.selectableOptions;
  } else {
    getSelectableOptionsFromBackend();
  }
};
const customSearch = (userGroup: AKBECommUserGroupSchema) => {
  const searchArray: Array<string | number> = [];
  searchArray.push(userGroup.user_group_id);
  searchArray.push(userGroup.title);
  searchArray.push(userGroup.abbr);
  searchArray.push(userGroup.description);
  return searchArray.join(' ').toLowerCase();
};
const setResultAsOptions = (userGroups: Array<AKBECommUserGroupSchema>) => {
  internalSelectableOptions.value = userGroups;
  filteredOptions.value = internalSelectableOptions.value;
};
const multiSelectTagContent = (userGroup: AKBECommUserGroupSchema) => {
  return userGroup.title;
};
const valueIsArray = (val: AKBECommUserGroupSchema | AKBECommUserGroupSchema[]): val is AKBECommUserGroupSchema[] => {
  return Array.isArray(val);
};
//  --------------------------------------------------------------------------------------------------------------------
//  backend communication
//  --------------------------------------------------------------------------------------------------------------------
const getSelectableOptionsFromBackend = () => {
  showLoading.value = true;
  AKBECommUser.beCommGetUserGroupsByFilter(props.userGroupFilter, props.excludedUserGroupIds)
    .then((response) => {
      setResultAsOptions(response.data.user_groups);
    })
    .finally(() => {
      showLoading.value = false;
    });
};
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  onMount: [];
  onOpen: [];
  onClose: [];
  onRemove: [userGroup: AKBECommUserGroupSchema | AKBECommUserGroupSmall];
  onSelect: [userGroup: AKBECommUserGroupSchema | AKBECommUserGroupSmall];
}>();
const emitOnMount = () => {
  emits('onMount');
};
const emitOnOpen = () => {
  emits('onOpen');
};
const emitOnClose = () => {
  emits('onClose');
};
const emitOnRemove = (userGroup: AKBECommUserGroupSchema | AKBECommUserGroupSmall) => {
  emits('onRemove', userGroup);
};
const emitOnSelect = (userGroup: AKBECommUserGroupSchema | AKBECommUserGroupSmall) => {
  emits('onSelect', userGroup);
};
</script>

<style lang="sass" scoped></style>
