import { BeCommAll } from '@ak_tools/backend_communication/AKBEComm';
import { AKBECommBaseMessages } from '@ak_tools/backend_communication/AKBECommBase';

import {
  BECommTournamentConLocationCreateRequest,
  BECommTournamentConLocationCreateResponseR,
  BECommTournamentConLocationDeleteResponseR,
  BECommTournamentConLocationsGetResponseR,
} from '../definitions/BECommTournamentConLocations';

export default class BECommTournamentConLocations extends BeCommAll {
  public beCommGetTournamentConLocations(): Promise<BECommTournamentConLocationsGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommTournamentConLocations.error.get.tournament_con_locations.message'),
    };
    return this.beComm.backendCommGet(`/tournaments_con_locations`, {}, messages, {
      returnOnError: false,
    });
  }

  public beCommCreateTournamentConLocation(
    postBody: BECommTournamentConLocationCreateRequest,
  ): Promise<BECommTournamentConLocationCreateResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommTournamentConLocations.error.post.tournament_con_location.message'),
    };
    return this.beComm.backendCommPost('/tournaments_con_locations', postBody, messages);
  }

  public beCommDeleteTournamentConLocation(
    tournament_id: number,
    location_id: number,
  ): Promise<BECommTournamentConLocationDeleteResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommTournamentConLocations.error.delete.tournament_con_location.message'),
    };

    return this.beComm.backendCommDelete(`/tournaments/${tournament_id}/locations/${location_id}`, {}, messages);
  }
}
