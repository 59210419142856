import { AKVueProvider } from '@ak_tools/framework_support/AKVueProvider';

import { BECommMatch, BECommMatchComplete } from '@/backend_communication/definitions/BECommMatches';
import { BECommTournament } from '@/backend_communication/definitions/BECommTournaments';
import BECommMatches from '@/backend_communication/implementations/BECommMatches';

class MatchesDataHandlerDefaultData {
  elementsAreLoading = false;
  elementIsLoading = false;
  matches: BECommMatchComplete[] = [];
  current_match: BECommMatchComplete | null = null;
  tournaments: BECommTournament[] = [];
  selectedTableRows: BECommMatchComplete[] = [];
  current_tournament: BECommTournament | null = null;
}

export class MatchesDataHandler {
  private beCommMatches = new BECommMatches(new AKVueProvider());
  data = new MatchesDataHandlerDefaultData();

  async getMatchesFromBackend() {
    this.data.elementsAreLoading = true;
    return this.beCommMatches
      .beCommGetMatchesByTournament(this.data.current_tournament?.tournament_id || 0)
      .then((response) => {
        const matcheswithoutfullnames = response.data.matches;

        this.data.matches = this.assignFullnameToParticipants(matcheswithoutfullnames);
      })
      .finally(() => {
        this.data.elementsAreLoading = false;
      });
  }

  async getMatchById(match_id: number) {
    return this.beCommMatches
      .beCommGetMatchById(match_id)
      .then((response) => {
        if (response.data.match) {
          this.data.current_match = this.assignFullnameToParticipants([response.data.match]).at(0) ?? null;
        }
      })
      .finally(() => {
        this.data.elementIsLoading = false;
      });
  }

  async updateMatch(match: BECommMatch) {
    return this.beCommMatches
      .beCommUpdateMatch(match.match_id, {
        participant_one_score: match.participant_one_score,
        participant_two_score: match.participant_two_score,
        tournament_id: match.tournament.tournament_id,
        participant_one_id: match.participant_one.participant_id,
        participant_two_id: match.participant_two.participant_id,
        match_number: match.match_day_number,
      })
      .then(() => {
        return this.getMatchesFromBackend();
      });
  }

  private assignFullnameToParticipants(matches: BECommMatch[]): BECommMatchComplete[] {
    return matches.map((match) => {
      return {
        ...match,
        participant_one_fullname: `${match.participant_one.person.firstname} ${match.participant_one.person.lastname}`,
        participant_two_fullname: `${match.participant_two.person.firstname} ${match.participant_two.person.lastname}`,
      };
    });
  }
}
