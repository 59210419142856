<template>
  <svg viewBox="0 0 498.9 109.9" xml:space="preserve" :width="props.width">
    <path
      v-if="areaAIsVisible"
      id="ABUS_A"
      :fill="fillColorValue"
      d="M98.8,61.8c0,1.5-1.2,2.7-2.7,2.7l-17.9,0v0h0c-1.5,0-2.7-1.1-2.7-2.6c0-0.6,0.3-1.2,0.6-1.7l17.8-29.9l0,0
			c0.5-0.8,1.3-1.4,2.3-1.4c1.5,0,2.7,1.2,2.7,2.7l0,0V61.8z M139.5,105.3V2.7c0-1.5-1.2-2.7-2.7-2.7L75,0c-1.2,0-2.2,0.6-2.8,1.5
			c0,0-44,65.2-61.8,91.5h-0.2L0,108.1h5.2h40.6l0,0h0c1.1,0,2.1-0.7,2.7-1.7c0,0,0,0,0,0l10.5-17.8c0,0,0,0,0,0
			c0.4-0.9,1.3-1.5,2.4-1.5l34.6,0c1.5,0,2.7,1.2,2.7,2.7v15.5c0,1.5,1.2,2.7,2.7,2.7h35.2C138.3,108.1,139.5,106.8,139.5,105.3" />
    <path
      v-if="areaBIsVisible"
      id="ABUS_B"
      :fill="fillColorValue"
      d="M498.9,0.1h-84.6l-1.4,0c-11.9,0.1-21.4,10-23.3,21.9c-1.2,7.6-1.2,15.6,0.1,23.8c2,11.8,11.2,21.9,23.2,22
			l35.2,0c0,0,0,0,0,0c3.7,0,6.6,3,6.6,6.6c0,3.6-2.9,6.5-6.4,6.6l-48.3,0c-1,0-1.9,0.6-2.3,1.4l-17.4,25.8H471
			c12,0,21.4-10,23.3-21.9c1.2-7.5,1.2-15.1,0.1-24c-1.5-11.9-11.4-21.9-23.4-21.9l-36.5,0.1c0,0-0.2,0-0.5-0.1
			c-3.4-0.2-6.1-3.1-6.1-6.6c0-3.5,2.7-6.3,6.1-6.6c0.3-0.1,0.5-0.1,0.5-0.1l44.8,0c1,0,1.9-0.6,2.3-1.4L498.9,0.1z" />
    <path
      v-if="areaUIsVisible"
      id="ABUS_U"
      :fill="fillColorValue"
      d="M375,0h-28.2h-4.6h-4.7c-1.5,0-2.7,1.2-2.7,2.7v7.9l0,0l0,65.3c0,5.7-4.6,10.3-10.3,10.3
			c-5.7,0-10.3-4.6-10.3-10.3c0,0,0-73.2,0-73.2c0-1.5-1.2-2.7-2.7-2.7h-37.4c-1.5,0-2.7,1.2-2.7,2.7v6.1v1.8v73.3
			c0,13.1,10.1,21.8,21.9,23.8c17.4,2.9,45,2.9,62.5,0c11.9-2,21.9-10.6,21.9-24V11.6V5.8V2.7C377.7,1.2,376.5,0,375,0" />
    <path
      v-if="areaSIsVisible"
      id="ABUS_S"
      :fill="fillColorValue"
      d="M220.3,75.7c0,5.7-4.6,10.3-10.3,10.3h-8.9l0,0h-4c0,0-0.1,0-0.1,0c-1.5,0-2.7-1.2-2.7-2.7V72.1l0,0v-3.9
			c0,0,0-0.1,0-0.1c0-1.5,1.2-2.7,2.7-2.7h11.3l1.6,0C215.6,65.4,220.3,70.1,220.3,75.7 M218.7,32.4c0,5.7-4.6,10.3-10.3,10.3h-7.2
			l0,0h-4c0,0-0.1,0-0.1,0c-1.5,0-2.7-1.2-2.7-2.7V28.7l0,0v-3.9c0,0,0-0.1,0-0.1c0-1.5,1.2-2.7,2.7-2.7h11.3v0
			C214,22.1,218.7,26.7,218.7,32.4 M240.4,53.8c9.9-2.1,16.5-10.9,17.3-21.4c0.2-2.7,0.2-7.9,0-10.6C256.7,9.9,247.8,0,235.8,0
			h-79.3l0,0c-1.5,0-2.7,1.2-2.7,2.7v102.6c0,1.5,1.2,2.7,2.7,2.7l0,0c12.4,0,63.9,0,82.6,0c12,0,21.2-9.9,21.9-21.9
			c0.2-3.9,0.3-6.9,0-10.6C260,64.1,251.8,54.5,240.4,53.8" />
  </svg>
</template>

<script setup lang="ts">
interface PropsInterface {
  colorValue?: string;
  parentTextColor?: boolean;
  width?: string;
  visibleArea?: AKLogoValidAreas[];
}
import { akColors } from '@ak_tools/assets/ak_styles/vendors/tailwind/akColors'
import { AKLogoValidAreas } from '@ak_tools/components/media/'
import { computed } from 'vue'
//  --------------------------------------------------------------------------------------------------------------------
//  props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  colorValue: undefined,
  parentTextColor: false,
  width: '100%',
  visibleArea: () => ['A', 'B', 'U', 'S'],
});
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const fillColorValue = computed(() => {
  if (props.colorValue) {
    return props.colorValue;
  } else {
    return props.parentTextColor ? 'currentColor' : akColors.primary.DEFAULT;
  }
});
const areaAIsVisible = computed(() => {
  return props.visibleArea.includes('A');
});
const areaBIsVisible = computed(() => {
  return props.visibleArea.includes('B');
});
const areaUIsVisible = computed(() => {
  return props.visibleArea.includes('U');
});
const areaSIsVisible = computed(() => {
  return props.visibleArea.includes('S');
});
</script>

<style scoped lang="sass"></style>
