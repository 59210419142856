<template>
  <div>
    <AKDialog
      v-model="dialogIsVisible"
      :show-cancel-button="false"
      :headline-text="$t(props.placeholder)"
      show-maximized>
      <VueMultiselect
        ref="vueMultiSelectMobile"
        v-model="selectValue"
        :show-labels="props.showLabels"
        :internal-search="props.defaultSearch"
        :options="selectableOptions"
        :multiple="props.multiselect"
        :placeholder="$t(props.placeholder)"
        :tag-placeholder="$t(props.createTagPlaceholder)"
        :custom-label="props.multiSelectTagContent"
        :track-by="props.trackBy"
        :allow-empty="props.allowEmpty"
        :close-on-select="!props.multiselect"
        :loading="props.showLoading"
        :options-limit="props.optionsDisplayLimit"
        :taggable="props.allowCreateTags"
        :disabled="props.disabled"
        :group-values="props.groupValues"
        :group-label="props.groupLabel"
        :group-select="props.groupSelectAllowed"
        :select-label="selectLabelData"
        :select-group-label="selectGroupLabelData"
        :selected-label="selectedLabelData"
        :deselect-label="deselectLabelData"
        :deselect-group-label="deselectGroupLabelData"
        @update:model-value="onValueChange"
        @close="onClose"
        @remove="onRemove"
        @select="onSelect"
        @open="onOpen"
        @search-change="onSearch"
        @tag="onCreateTag">
        <template #placeholder>
          <span v-text="$t(placeholder)" />
        </template>
        <template #noResult>
          <span v-text="$t('AKSelect.no-result')" />
        </template>
        <template #noOptions>
          <span v-text="$t('AKSelect.no-options')" />
        </template>
        <template #maxElements>
          <span v-text="$t('AKSelect.max-elements')" />
        </template>
        <template #singleLabel="{ option }">
          <slot name="singleLabel" :option="option" />
        </template>
        <template #option="{ option, search }">
          <slot name="option" :option="option" :search="search" />
        </template>
        <template #tag="{ option, remove, search }">
          <slot name="tag" :option="option" :search="search" :remove="remove" />
        </template>
      </VueMultiselect>
    </AKDialog>

    <AKInputContainer
      :label="props.label"
      :label-cols="props.labelCols"
      :tooltip-text="props.tooltipText"
      :tooltip-placement="props.tooltipPlacement"
      :tooltip-theme="props.tooltipTheme"
      :show-tooltip="props.showTooltip">
      <template #tooltipContent>
        <slot name="tooltipContent"></slot>
      </template>
      <div v-show="!breakpointIsMobile && (!readMode || !props.enableSwitchReadWriteMode)" class="w-full">
        <VueMultiselect
          ref="vueMultiSelect"
          v-model="selectValue"
          :show-labels="props.showLabels"
          :internal-search="props.defaultSearch"
          :options="selectableOptions"
          :multiple="props.multiselect"
          :placeholder="$t(props.placeholder)"
          :tag-placeholder="$t(props.createTagPlaceholder)"
          :custom-label="props.multiSelectTagContent"
          :track-by="props.trackBy"
          :allow-empty="props.allowEmpty"
          :close-on-select="!props.multiselect"
          :loading="props.showLoading"
          :options-limit="props.optionsDisplayLimit"
          :taggable="props.allowCreateTags"
          :open-direction="props.openDirection"
          :disabled="props.disabled"
          :group-values="props.groupValues"
          :group-label="props.groupLabel"
          :group-select="props.groupSelectAllowed"
          :select-label="selectLabelData"
          :select-group-label="selectGroupLabelData"
          :selected-label="selectedLabelData"
          :deselect-label="deselectLabelData"
          :deselect-group-label="deselectGroupLabelData"
          @update:model-value="onValueChange"
          @close="onClose"
          @remove="onRemove"
          @select="onSelect"
          @open="onOpen"
          @search-change="onSearch"
          @tag="onCreateTag">
          <template #placeholder>
            <span v-text="$t(placeholder)" />
          </template>
          <template #noResult>
            <span v-text="$t('AKSelect.no-result')" />
          </template>
          <template #noOptions>
            <span v-text="$t('AKSelect.no-options')" />
          </template>
          <template #maxElements>
            <span v-text="$t('AKSelect.max-elements')" />
          </template>
          <template #singleLabel="{ option }">
            <slot name="singleLabel" :option="option" />
          </template>
          <template #option="{ option, search }">
            <slot name="option" :option="option" :search="search" />
          </template>
          <template #tag="{ option, remove, search }">
            <slot name="tag" :option="option" :search="search" :remove="remove" />
          </template>
        </VueMultiselect>
      </div>
      <div
        v-show="readMode && enableSwitchReadWriteMode && !breakpointIsMobile"
        tabindex="0"
        @focusin="displayNormalMode"
        @click="displayNormalMode"
        @keydown="displayNormalMode">
        <div
          class="ak-multiselect--reader flex cursor-pointer items-center rounded-lg"
          :class="{ disabled: props.disabled, 'ak-multiselect--hover-enabled': !props.disableHover }">
          <slot v-if="displayPlaceholder" name="ak-placeholder">
            <span v-text="$t(props.placeholder)" />
          </slot>
          <slot v-else name="ak-text" :value="selectValue"></slot>
          <i v-if="!props.disabled" class="ak-multiselect--reader-icon pi pi-angle-down ml-2" />
        </div>
      </div>
      <div v-if="breakpointIsMobile" @click="showDialog">
        <div
          class="ak-multiselect--reader flex cursor-pointer items-center rounded-lg"
          :class="{ disabled: props.disabled, 'select-hover': !props.disableHover }">
          <slot v-if="displayPlaceholder" name="ak-placeholder">
            <span v-text="$t(props.placeholder)" />
          </slot>
          <slot v-else name="ak-text" :value="selectValue"></slot>
          <i v-if="!props.disabled" class="ak-multiselect--reader-icon pi pi-angle-down ml-2" />
        </div>
      </div>
    </AKInputContainer>
  </div>
</template>

<script setup lang="ts">
type _VTI_TYPE_AKTooltipValidThemes = 'ak-white'
type _VTI_TYPE_AKTooltipValidPlacements = | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end'
interface PropsInterface {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectableOptions: any[];
  trackBy?: string;
  label?: string | false;
  labelCols?: number | 'auto';
  placeholder?: string;
  createTagPlaceholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  multiSelectTagContent?: (item: any) => string;
  tooltipText?: string;
  showTooltip?: boolean;
  tooltipPlacement?: _VTI_TYPE_AKTooltipValidPlacements;
  tooltipTheme?: _VTI_TYPE_AKTooltipValidThemes;
  showLoading?: boolean;
  showLabels?: boolean;
  disabled?: boolean;
  multiselect?: boolean;
  allowCreateTags?: boolean;
  allowEmpty?: boolean;
  selectByIndex?: number;
  selectByIndexes?: number[];
  optionsDisplayLimit?: number;
  groupValues?: string;
  groupLabel?: string;
  groupSelectAllowed?: boolean;
  showEditButton?: boolean;
  enableSwitchReadWriteMode?: boolean;
  initialOpenMultiSelect?: boolean;
  openDirection?: 'top' | 'bottom';
  selectLabel?: string | false;
  selectGroupLabel?: string | false;
  selectedLabel?: string | false;
  deselectLabel?: string | false;
  deselectGroupLabel?: string | false;
  defaultSearch?: boolean;
  disableHover?: boolean;
}
import { AKInputContainer } from '@ak_tools/components/layout'
import { AKDialog } from '@ak_tools/components/overlay'
import { AKStore } from '@ak_tools/start/App'
import { AKBreakpoints } from '@ak_tools/store/app/types'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import VueMultiselect, { Multiselect } from 'vue-multiselect'
const { t } = useI18n();
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const selectValue = defineModel<any | any[] | null>({ default: null });
const props = withDefaults(defineProps<PropsInterface>(), {
  trackBy: undefined,
  label: false,
  labelCols: undefined,
  placeholder: 'AKSelect.default-placeholder',
  createTagPlaceholder: 'AKSelect.default-create-tag-placeholder',
  multiSelectTagContent: undefined,
  tooltipText: undefined,
  showTooltip: undefined,
  tooltipPlacement: undefined,
  tooltipTheme: undefined,
  showLoading: undefined,
  showLabels: false,
  disabled: undefined,
  multiselect: undefined,
  allowCreateTags: undefined,
  allowEmpty: true,
  selectByIndex: -1,
  selectByIndexes: () => [],
  optionsDisplayLimit: 100,
  groupValues: undefined,
  groupLabel: undefined,
  groupSelectAllowed: undefined,
  showEditButton: undefined,
  enableSwitchReadWriteMode: false,
  initialOpenMultiSelect: undefined,
  openDirection: 'bottom',
  selectLabel: 'AKSelect.default-select-label',
  selectGroupLabel: 'AKSelect.default-select-group-label',
  selectedLabel: 'AKSelect.default-selected-label',
  deselectLabel: 'AKSelect.default-deselect-label',
  deselectGroupLabel: 'AKSelect.default-deselect-group-label',
  defaultSearch: true,
  disableHover: false,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const vueMultiSelect = ref<InstanceType<typeof Multiselect>>();
const readMode = ref(true);
const dialogIsVisible = ref(false);
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const displayPlaceholder = computed(() => {
  return !selectValue.value || (Array.isArray(selectValue.value) && selectValue.value.length === 0);
});
const selectLabelData = computed(() => (props.selectLabel ? t(props.selectLabel) : ''));
const selectGroupLabelData = computed(() => (props.selectGroupLabel ? t(props.selectGroupLabel) : ''));
const selectedLabelData = computed(() => (props.selectedLabel ? t(props.selectedLabel) : ''));
const deselectLabelData = computed(() => (props.deselectLabel ? t(props.deselectLabel) : ''));
const deselectGroupLabelData = computed(() => (props.deselectGroupLabel ? t(props.deselectGroupLabel) : ''));
const breakpointIsMobile = computed(() => AKStore.App.state.breakpoint < AKBreakpoints.MD);
//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
onMounted(() => {
  if (props.initialOpenMultiSelect) {
    openMultiSelect();
  }
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const showDialog = () => {
  dialogIsVisible.value = true;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onValueChange = (value: any | any[] | null) => {
  emitOnValueChange(value);
};
const onOpen = () => {
  emitOnOpen();
};
const onClose = () => {
  displayReadMode();
  emitOnClose();
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onSelect = (item: any) => {
  emitOnSelect(item);
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onRemove = (item: any) => {
  emitOnRemove(item);
};
const onSearch = (query: string) => {
  emitOnSearch(query);
};
const onCreateTag = (tag: string) => {
  emitOnCreateTag(tag);
};
const openMultiSelect = () => {
  vueMultiSelect.value?.activate();
};
const closeMultiSelect = () => {
  vueMultiSelect.value?.deactivate();
};
const displayReadMode = () => {
  readMode.value = props.enableSwitchReadWriteMode;
};
const displayNormalMode = () => {
  if (!props.disabled) {
    readMode.value = false;
    openMultiSelect();
  }
};
//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watch(
  () => [props.selectByIndex, props.selectByIndexes],
  () => {
    if (props.selectByIndex >= 0) {
      selectValue.value = props.selectableOptions[props.selectByIndex];
    }
    if (props.selectByIndexes.length) {
      selectValue.value = props.selectByIndexes.map((index) => props.selectableOptions[index]);
    }
  },
  { immediate: true },
);
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onValueChange: [value: any | any[] | null];
  onOpen: [];
  onClose: [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect: [item: any];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRemove: [item: any];
  onSearch: [query: string];
  onCreateTag: [tag: string];
  onEditButtonClick: [];
}>();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const emitOnValueChange = (value: any | any[] | null) => {
  emits('onValueChange', value);
};
const emitOnOpen = () => {
  emits('onOpen');
};
const emitOnClose = () => {
  emits('onClose');
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const emitOnSelect = (item: any) => {
  emits('onSelect', item);
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const emitOnRemove = (item: any) => {
  emits('onRemove', item);
};
const emitOnSearch = (query: string) => {
  emits('onSearch', query);
};
const emitOnCreateTag = (tag: string) => {
  emits('onCreateTag', tag);
};
//  --------------------------------------------------------------------------------------------------------------------
//  public expose
//  --------------------------------------------------------------------------------------------------------------------
defineExpose({ openMultiSelect, closeMultiSelect });
</script>

<style lang="sass">
@import "@ak_tools/assets/ak_styles/ak_variables"
@import "@ak_tools/assets/ak_styles/ak_mixins"
@import "~/vue-multiselect/dist/vue-multiselect.css"

.ak-multiselect
  &--mobile
    overflow-y: scroll

  &--reader
    min-height: 35px
    padding: 0.571rem

    &.ak-multiselect--hover-enabled
      &:hover
        background: $gray-100
        font-weight: bold

        .ak-multiselect--reader-icon
          color: $ak-theme-color-inv

    &.disabled
      &:hover
        background: $gray-100
        font-weight: normal
        cursor: not-allowed

.multiselect
  font-size: $ak-text-size-base

.multiselect__content-wrapper
  @include ak-scrollbar-primary

  min-width: 25rem
  min-width: 100%

  .multiselect__option
    white-space: normal
    line-height: 1rem
    font-size: $ak-text-size-base

    &--highlight
      background: $ak-backdrop
      color: $ak-text-on-backdrop

.multiselect__tag
  background: $ak-primary
  color: $ak-text-on-primary
  white-space: normal
  text-overflow: unset
  font-size: $ak-text-size-base
  padding-left: 0.5rem !important

.multiselect__tags
  border: 1px solid $zinc-200
  border-radius: $ak-border-radius
  font-size: $ak-text-size-base
  white-space: normal
  text-overflow: unset
  @apply pt-2

.multiselect__select
  height: 100%


  &::before
    content: ''
    height: $ak-text-size-xs
    width: $ak-text-size-xs
    background: $pi-angle-down center no-repeat
    top:50%
    line-height: 1
    transform: translate(-50%,-50%) rotate(0)
    transform-origin: 100% 50%
    position: absolute
    border: none
    margin: 0
    left: 50%

.multiselect__spinner
  height: 33px
  z-index: 1

.multiselect__single
  margin-bottom: 0
  vertical-align: middle
  font-size: $ak-text-size-base

.multiselect__input
  margin-bottom: 0
  font-size: $ak-text-size-base
  padding: 0rem

.multiselect__placeholder
  margin-bottom: 0
  color: $gray-600
  font-size: $ak-text-size-base

.multiselect__tag-icon
  background: $ak-primary
  color: $ak-text-on-primary

  &::after
    color: $ak-text-on-primary

  &:hover
    background: rgba(0, 0, 0, .10) !important
</style>
