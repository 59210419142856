import { BeCommAll } from '@ak_tools/backend_communication/AKBEComm';
import { AKBECommBaseMessages } from '@ak_tools/backend_communication/AKBECommBase';

import {
  BECommMatchDeleteResponseR,
  BECommMatchesByParticipantResponse,
  BECommMatchesGetResponseR,
  BECommMatchGetResponseR,
  BECommMatchplanResponse,
  BECommMatchUpdateRequest,
  BECommMatchUpdateResponseR,
} from '@/backend_communication/definitions/BECommMatches';

export default class BECommMatches extends BeCommAll {
  public beCommGetMatchesByTournament(tournamentId: number): Promise<BECommMatchesGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommMatches.error.get.matches.message'),
    };

    return this.beComm.backendCommGet(`/tournaments/${tournamentId}/matches`, { timeout: 60000 }, messages, {
      returnOnError: false,
    });
  }

  public beCommGetMatchById(match_id: number): Promise<BECommMatchGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommMatches.error.get.match.message'),
    };

    return this.beComm.backendCommGet(`/matches/${match_id}`, { timeout: 10000 }, messages, {
      returnOnError: false,
    });
  }

  public beCommGetMatchesByParticipantId(participant_id: number): Promise<BECommMatchesGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommMatches.error.get.matches.message'),
    };

    const postBody = { participant_id };

    return this.beComm.backendCommPost(`/matches-by-participant-id`, postBody, {}, messages);
  }

  public beCommDeleteMatch(match_id: number): Promise<BECommMatchDeleteResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommMatches.error.delete.match.message'),
    };

    return this.beComm.backendCommDelete(`/matches/${match_id}`, {}, messages);
  }

  public beCommUpdateMatch(match_id: number, putBody: BECommMatchUpdateRequest): Promise<BECommMatchUpdateResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommMatches.error.update.match.message'),
    };

    return this.beComm.backendCommPut(`/matches/${match_id}`, putBody, {}, messages);
  }

  public beCommGenerateMatchplan(participant_ids: Array<number>): Promise<BECommMatchplanResponse> {
    const postBody = { participant_ids };
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommMatches.error.matchplan.message'),
    };
    return this.beComm.backendCommPost(`/generate-matchplan`, postBody, {}, messages);
  }

  public beCommGetMatchesByParticipant(participant_id: number): Promise<BECommMatchesByParticipantResponse> {
    const postBody = { participant_id };
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommMatches.error.get.matches.message'),
    };
    return this.beComm.backendCommPost(`/get-matches-by-participant-id`, postBody, {}, messages);
  }
}
