<template>
  <AKSidePanelAccordion
    v-if="providedAttachmentsData.showAttachmentAccordion"
    data-test="ak-sidepanel-attachments-accordion"
    :header-title="$t('AKSidepanelAttachments.accordion-title')">
    <AKFileUploader
      v-model="matchedAttachmentsArray"
      with-download-column
      @upload-items="emitUploadAttachments"
      @delete-item="emitDeleteAttachment"
      @download-item="emitDownloadAttachment" />
  </AKSidePanelAccordion>

  <AKFileUploader
    v-else
    v-model="matchedAttachmentsArray"
    with-download-column
    @upload-items="emitUploadAttachments"
    @delete-item="emitDeleteAttachment"
    @download-item="emitDownloadAttachment" />
</template>

<script setup lang="ts">
import { AKSidePanelAccordion, AKSidePanelAttachmentsInjectedData } from '@ak_tools/components/layout';
import { AKFileListItem, AKFileUploader } from '@ak_tools/components/misc';
import { computed, inject, Ref } from 'vue';

//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const providedAttachmentsData = inject('providedAttachmentsData') as Ref<AKSidePanelAttachmentsInjectedData>;

//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const matchedAttachmentsArray = computed(() => {
  return providedAttachmentsData.value.attachments.map((attachment) => {
    return {
      name: attachment.filename,
      lastModified: attachment.upload_date,
      size: attachment.file_size,
      type: attachment.file_type,
    };
  });
});

//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  uploadAttachments: [files: AKFileListItem[]];
  deleteAttachment: [item: AKFileListItem];
  downloadAttachment: [item: AKFileListItem];
}>();

const emitUploadAttachments = (files: AKFileListItem[]) => {
  emits('uploadAttachments', files);
};

const emitDownloadAttachment = (item: AKFileListItem) => {
  emits('downloadAttachment', item);
};

const emitDeleteAttachment = (item: AKFileListItem) => {
  emits('deleteAttachment', item);
};
</script>

<style lang="sass" scoped></style>
