import { AKAppConfig } from '@ak_tools/framework_support/AKAppConfigTypes';
import { AppNavigationItem } from '@ak_tools/store/app/types';

function sortByOrder(uniqueItems: AppNavigationItem[]): AppNavigationItem[] {
  return uniqueItems.sort((item1, item2) => {
    if (item1.order === undefined && item2.order === undefined) {
      return 0;
    }
    if (item1.order === undefined) {
      return 1;
    }
    if (item2.order === undefined) {
      return -1;
    }
    return item1.order - item2.order;
  });
}

function removeDuplicateNavigationItems(items: Array<AppNavigationItem>): Array<AppNavigationItem> {
  const seenLinks = new Set();
  const uniqueItems = [];
  for (const item of items) {
    if (!item.link) {
      uniqueItems.push(item);
    } else {
      if (!seenLinks.has(item.link)) {
        seenLinks.add(item.link);
        if (item.children) {
          item.children = removeDuplicateNavigationItems(item.children);
        }
        uniqueItems.push(item);
      }
    }
  }
  return sortByOrder(uniqueItems);
}

export const akCombineConfigs = (akConfig: AKAppConfig, appConfig: AKAppConfig): AKAppConfig => {
  const result: AKAppConfig = {
    currentVersion: appConfig.currentVersion,
    name: appConfig.name,
    navigationItems: removeDuplicateNavigationItems([...appConfig.navigationItems, ...akConfig.navigationItems]),
  };
  return result;
};
