import lodash from 'lodash';

class AKTimings {
  static debounce(func: () => void, delay: number): () => void {
    return lodash.debounce(func, delay);
  }
}

export default AKTimings;
export { AKTimings };
