<template>
  <div v-show="isActive" v-if="slotInUse.default" class="w-full grow rounded-b-xl rounded-tr-xl bg-white p-4">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
interface PropsInterface {
  title: string;
}
import { inject, onBeforeMount, Ref, ref, useSlots, watch } from 'vue'
import { AKTabAddTabFunction } from './AKTabTypes'
//  --------------------------------------------------------------------------------------------------------------------
//  props + model
//  --------------------------------------------------------------------------------------------------------------------
const activeModel = defineModel<boolean>('active', { default: false });
const props = defineProps<PropsInterface>();
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const addTab = inject('addTab') as AKTabAddTabFunction;
const activeTabHash = inject('activeTabHash') as Ref<string>;
const hash = ref('');
const isActive = ref(activeModel.value);
const slotInUse = useSlots();
//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watch(activeTabHash!, () => {
  isActive.value = activeTabHash!.value === hash.value;
  activeModel.value = isActive.value;
});
watch(isActive, () => {
  if (isActive.value) {
    emitOnTabClick();
  }
});
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  onTabClick: [];
}>();
const emitOnTabClick = () => {
  emits('onTabClick');
};
//  --------------------------------------------------------------------------------------------------------------------
//  public expose
//  --------------------------------------------------------------------------------------------------------------------
defineExpose({ hash });
//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
onBeforeMount(() => {
  hash.value = `#${props.title.toLowerCase().replace(/ /g, '-')}${Math.floor(Math.random() * 1000000)}}`;
  if (addTab) {
    addTab({
      title: props.title,
      hash: hash.value,
    });
    if (isActive.value && activeTabHash) {
      activeTabHash.value = hash.value;
    }
  }
});
</script>

<style scoped></style>
