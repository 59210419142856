export enum AKSidePanelState {
  MINIMIZED,
  MAXIMIZED,
}

export type AKSidePanelHeaderInjectedData = {
  showCloseButton: boolean;
  showMaximizeButton: boolean;
  showMinimizeButton: boolean;
  showDeleteButton: boolean;
  showCopyLocationButton: boolean;
  showArchiveButton: boolean;
  showHistoryButton: boolean;
  showPrintButton: boolean;
  showDuplicateButton: boolean;
  itemIsArchived: boolean;
};
