import { akProvideTranslation } from '@ak_tools/framework_support/AKTranslator';

export type TranslateFunctionType = (key: string, ...args: unknown[]) => string;

export interface IAKVueProvider {
  /**
   * The function `t` is specifically named as such because VSCode interprets it
   * as the default translation function. Therefore, it's imperative for it to be named `t`.
   */
  t: TranslateFunctionType;
}

export class AKVueProvider implements IAKVueProvider {
  t: TranslateFunctionType;
  constructor() {
    this.t = akProvideTranslation().t;
  }
}
