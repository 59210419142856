<template>
  <AKTooltip theme="ak-white" placement="bottom-start">
    <template #content>
      <AKEmployeeCard :show-groups="false" :person-data="props.person" />
    </template>
    <span tabindex="0" v-text="hrHelperMethods.getPersonFullName(props.person)" />
  </AKTooltip>
</template>

<script setup lang="ts">
type _VTI_TYPE_AKBECommPersonEmployeeUserSchema = AKBECommPersonSchema & {
  employee: AKBECommEmployeeSchema | null;
  user: AKBECommUserSchema | null;
}
interface PropsInterface {
  person: _VTI_TYPE_AKBECommPersonEmployeeUserSchema;
}
import { AKTooltip } from '@ak_tools/components/overlay'
import { AKEmployeeCard } from '@ak_tools/components/panel'
import { AKHR } from '@ak_tools/ts_modules/hr/AKHR'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const props = defineProps<PropsInterface>();
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const hrHelperMethods = new AKHR();
</script>

<style lang="sass" scoped></style>
