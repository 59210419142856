<template>
  <AKModuleHeader>
    <slot name="moduleHeaderActions"></slot>
  </AKModuleHeader>

  <div
    class="ak-scrollbar-primary relative m-2 !mt-0 h-full overflow-x-hidden rounded-xl sm:m-4"
    :class="props.customClass">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
interface PropsInterface {
  customClass?: string;
}
import { AKModuleHeader } from '.'
const props = withDefaults(defineProps<PropsInterface>(), {
  customClass: undefined,
});
</script>

<style lang="sass" scoped></style>
