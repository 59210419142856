import { AKVueProvider } from '@ak_tools/framework_support/AKVueProvider';

import { BECommMatch, BECommMatchWithVersusAndScore } from '@/backend_communication/definitions/BECommMatches';
import { BECommParticipant, BECommRankedParticipant } from '@/backend_communication/definitions/BECommParticipants';
import { BECommTournament } from '@/backend_communication/definitions/BECommTournaments';
import BECommParticipants from '@/backend_communication/implementations/BECommParticipants';

class ParticipantDataHandlerDefaultData {
  elementsAreLoading = false;
  elementIsLoading = false;
  participants: BECommRankedParticipant[] = [];
  current_participant: BECommRankedParticipant | null = null;
  selectedTableRows: BECommRankedParticipant[] = [];
  current_tournament: BECommTournament | null = null;
}

export class ParticipantDataHandler {
  private beCommParticipants = new BECommParticipants(new AKVueProvider());
  data = new ParticipantDataHandlerDefaultData();

  async getParticipantsOfTournamentFromBackend() {
    if (this.data.current_tournament) {
      this.data.elementsAreLoading = true;
      return this.beCommParticipants
        .beCommGetParticipantsByTournament(this.data.current_tournament.tournament_id)
        .then((response) => {
          const unsorted_participants = response.data.participants;
          const sorted_participants = unsorted_participants.sort((a, b) => (b.points ?? 0) - (a.points ?? 0));
          this.data.participants = this.assignRanksToParticipants(sorted_participants);
        })
        .finally(() => {
          this.data.elementsAreLoading = false;
        });
    }
  }

  private assignRanksToParticipants(participants: BECommParticipant[]): BECommRankedParticipant[] {
    return participants.map((participant, index) => {
      return {
        ...participant,
        rank: index + 1,
        name: `${participant.person.firstname} ${participant.person.lastname}`,
      };
    });
  }

  async getParticipantById(participant_id: number) {
    this.data.elementIsLoading = true;
    const participant = this.data.participants.find((p) => p.participant_id === participant_id) || null;
    if (participant) {
      participant.matches = this.assignVersusAndScoreToParticipantMatches(participant.matches);
      this.data.current_participant = participant;
    }
    this.data.elementIsLoading = false;
  }

  private assignVersusAndScoreToParticipantMatches(matches: BECommMatch[]): BECommMatchWithVersusAndScore[] {
    return matches.map((match) => {
      return {
        ...match,
        participant_one_vs_two_names: `${match.participant_one.person.firstname} ${match.participant_one.person.lastname} vs. ${match.participant_two.person.firstname} ${match.participant_two.person.lastname}`,
        participant_one_vs_two_score: `${match.participant_one_score ?? 0} : ${match.participant_two_score ?? 0}`,
      };
    });
  }
}
