import { BeCommAll } from '@ak_tools/backend_communication/AKBEComm';
import { AKBECommBaseMessages } from '@ak_tools/backend_communication/AKBECommBase';
import {
  AKBECommPersonEmployeeUserRequest,
  AKBECommPersonEmployeeUserResponseR,
  AKBECommProfilePictureDeleteResponseR,
  BECommProfilePictureFinalizeUploadPostRequest,
  BECommProfilePictureFinalizeUploadPostResponseR,
  BECommProfilePicturePrepareUploadPostRequest,
  BECommProfilePicturePrepareUploadPostResponseR,
} from '@ak_tools/backend_communication/definitions/hr/AKBECommPersons';

export class AKBECommHR extends BeCommAll {
  public beCommGetPersons(postBody: AKBECommPersonEmployeeUserRequest): Promise<AKBECommPersonEmployeeUserResponseR> {
    const messages: AKBECommBaseMessages = {
      error: 'Fehler beim laden der Personen',
    };
    return this.beComm.backendCommPost('/hr/persons/get-person-by-filter', postBody, { timeout: 15000 }, messages);
  }

  //  ------------------------------------------------------------------------------------------------------------------
  //  profile picture
  //  ------------------------------------------------------------------------------------------------------------------
  public beCommDeleteProfilePicture(userID: number): Promise<AKBECommProfilePictureDeleteResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommZPs.error.delete.profile-picture.message'),
    };

    return this.beComm.backendCommDelete(`/hr/person/profile-picture/${userID}`, {}, messages);
  }

  public beCommProfilePicturePrepareUpload(
    postBody: BECommProfilePicturePrepareUploadPostRequest,
    messages?: AKBECommBaseMessages,
  ): Promise<BECommProfilePicturePrepareUploadPostResponseR> {
    return this.beComm.backendCommPost('/hr/person/profile-picture-prepare-upload-file', postBody, {}, messages);
  }

  public beCommProfilePictureFinalizeUpload(
    postBody: BECommProfilePictureFinalizeUploadPostRequest,
    messages?: AKBECommBaseMessages,
  ): Promise<BECommProfilePictureFinalizeUploadPostResponseR> {
    return this.beComm.backendCommPost('/hr/person/profile-picture-finalize-upload-file', postBody, {}, messages);
  }
}
