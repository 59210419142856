<template>
  <AKModuleGridContainer :data="providedDataHandler.data.locations" show-pagination>
    <template #gridItem="{ data }: { data: BECommLocation }">
      <AKCard
        class="hover:border-primary h-72 w-[350px] cursor-pointer border border-transparent transition-colors sm:w-[300px]"
        :class="{ '!border-primary': data.location_id === providedDataHandler.data.current_location?.location_id }"
        :title="data.name"
        @click="emitOnCardClick(data)">
        <span v-text="data.description"></span>
      </AKCard> </template
  ></AKModuleGridContainer>
</template>

<script setup lang="ts">
import AKModuleGridContainer from '@ak_tools/components/layout/AKModuleGridContainer.vue';
import { AKCard } from '@ak_tools/components/panel';
import { inject } from 'vue';

import { BECommLocation } from '@/backend_communication/definitions/BECommLocations';
import { ReservationDataHandler } from '@/components/reservations/ReservationDataHandler';

const providedDataHandler = inject('ReservationDataHandler') as ReservationDataHandler;

//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emitOnCardClick = (location: BECommLocation) => {
  emits('onCardClick', location);
};

const emits = defineEmits<{
  onCardClick: [BECommLocation];
}>();
</script>

<style lang="sass" scoped></style>
