import { defineStore } from 'pinia';
import { ref } from 'vue';

import { HRState } from './types';

const hr_state: HRState = {
  selectablePersons: [],
  personsAvailable: false,
  selectableEmployees: [],
  employeesAvailable: false,
  selectableUsers: [],
  usersAvailable: false,
  selectableUserGroups: [],
  userGroupsAvailable: false,
};

export const AKHRStore = defineStore('hr', () => {
  const state = ref(hr_state);
  return { state };
});
