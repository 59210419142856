import { AKFormatString } from './AKFormat';
/**
 * These are the formats you can print out the Version
 */
enum AKOutFormats {
  IntFullString = '%(major)i.%(minor)i.%(patch)i',
  HexFullString = '%(major)02X.%(minor)02X.%(patch)02X',
}
/**
 * This are all the Version numbers itself
 */
type AKVersionNumbers = {
  /**
   * Major Version
   */
  major: number;
  /**
   * Minor Version
   */
  minor: number;
  /**
   * Patch Version
   */
  patch: number;
};

type AKVersionNumbersParam = {
  major?: number;
  minor?: number;
  patch?: number;
  /**
   * here you can pass the Version as a Text. Format is 0.0.0.
   */
  byText?: string;
};

export interface AKVersionInterface {
  versions: AKVersionNumbers;
  defaultOutFormat: AKOutFormats;
}

/**
 * This class is for a default version
 */
class AKVersion {
  /**
   * This ist the content of the current version
   */
  versions: AKVersionNumbers = { major: 0, minor: 0, patch: 0 };

  /**
   * This ist  the default toString format
   */
  defaultOutFormat = AKOutFormats.IntFullString;

  /**
   * Constructor
   * @param number {@link AKVersionNumbers}
   */
  constructor(passedVersion: AKVersionNumbersParam = { minor: 0, major: 0, patch: 0 }) {
    if (passedVersion.minor !== undefined) {
      this.versions.minor = passedVersion.minor;
    }
    if (passedVersion.major !== undefined) {
      this.versions.major = passedVersion.major;
    }
    if (passedVersion.patch !== undefined) {
      this.versions.patch = passedVersion.patch;
    }
    if (passedVersion.byText !== undefined) {
      this.readByString(passedVersion.byText);
    }
  }

  private readByString(versionText: string) {
    const decodePattern = /(\d{1,3})\.(\d{1,3})\.(\d{1,3})/;
    const arrayVersions = versionText.match(decodePattern);
    if (arrayVersions !== null) {
      if (arrayVersions.length > 3) {
        this.versions.major = Number(arrayVersions[1]);
        this.versions.minor = Number(arrayVersions[2]);
        this.versions.patch = Number(arrayVersions[3]);
      }
    }
  }

  public toString(): string {
    const result = AKFormatString.format(this.defaultOutFormat, this.versions);
    return result;
  }
}
//  --------------------------------------------------------------------------------------------------------------------
//  Exports
//  --------------------------------------------------------------------------------------------------------------------
export { AKOutFormats, AKVersion };
export type { AKVersionNumbers, AKVersionNumbersParam };
