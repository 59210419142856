<template>
  <AKDataTable
    v-model:table-filters="filters"
    data-key="timeslot_id"
    :table-data="providedDataHandler.data.current_timeslots"
    table-column-size="sm"
    :selection-mode="'single'"
    show-pagination
    @on-row-click="onRowClick">
    <AKColumn class="w-32" field="timeslotString" :header="formatTimestamp">
      <template #body="slotProps">
        {{ formatTimeslot(slotProps.data) }}
      </template>
    </AKColumn>
    <AKColumn field="reservations">
      <template #body="slotProps">
        <div class="flex w-full justify-center">
          <div v-if="getReservationForTimestampLocationAndDay(slotProps.data)">
            <div class="text-center">
              {{ formatPlayerNames(getReservationForTimestampLocationAndDay(slotProps.data)) }}
            </div>
          </div>
          <div v-else class="w-full text-center">TBA vs TBA</div>
        </div>
      </template>
    </AKColumn>
    <AKColumn class="w-60">
      <template #body="slotProps">
        <div class="flex justify-end py-1">
          <AKButton
            v-if="reservationPresentInCurrentSlot(slotProps.data)"
            class="w-full py-1"
            :label="$t('AKButton.reservation.delete.label')"
            variant="danger"
            @click="confirmDeleteReservation(slotProps.data)"></AKButton>
          <AKButton
            v-if="getReservationForTimestampLocationAndDay(slotProps.data) == undefined"
            class="w-full py-1"
            :label="$t('AKButton.reservation.label')"
            @click="showReservationDialog(slotProps.data)"></AKButton>
        </div>
      </template>
    </AKColumn>
  </AKDataTable>

  <AKDialog
    :key="timestamp"
    v-model="showDialog"
    class="w-[600px]"
    :headline-text="$t('ReservationPlanDataTable.AKDialog.reservation-headline-text')"
    selected-player-one
    enable-custom-width
    content-class="overflow-y-visible"
    :show-cancel-button="false"
    @ok-click="addReservation(timestamp)">
    <h4>{{ providedDataHandler.data.current_location?.name }}</h4>
    <h4>{{ formatTimestamp }}</h4>
    <div class="ak-flex-col">
      <AKSelectPerson
        v-model="selectedPlayerOne"
        :label="$t('AKDialog.PlayerOne')"
        :label-cols="labelCols"
        enable-switch-read-write-mode
        :selectable-options="selectablePlayersForFirst" />
      <AKSelectPerson
        v-model="selectedPlayerTwo"
        :label="$t('AKDialog.PlayerTwo')"
        :label-cols="labelCols"
        enable-switch-read-write-mode
        :selectable-options="selectablePlayersForSecond" />
    </div>
  </AKDialog>

  <AKDialog
    v-model="showDeleteConfirmation"
    :headline-text="$t('ReservationPlanDataTable.AKDialog.delete-headline-text')"
    @ok-click="deleteConfirmedReservation">
    <p>
      {{ $t('ReservationPlanDataTable.AKDialog.reservation-headline-text.delete') }} {{ formatTimestamp }}
      {{ $t('ReservationPlanDataTable.AKDialog.reservation-headline-text.delete-text-base') }}
    </p>
  </AKDialog>
</template>

<script setup lang="ts">
//  --------------------------------------------------------------------------------------------------------------------
//  imports
//  --------------------------------------------------------------------------------------------------------------------
import { AKBECommPersonEmployeeUserSchema } from '@ak_tools/backend_communication/definitions/hr/AKBECommPersons';
import AKButton from '@ak_tools/components/button/AKButton.vue';
import { AKColumn, AKDataTable, AKDataTableFilterMeta, AKDataTableRowClickEvent } from '@ak_tools/components/data';
import AKDialog from '@ak_tools/components/overlay/AKDialog.vue';
import AKSelectPerson from '@ak_tools/components/select/AKSelectPerson.vue';
import { AKStore } from '@ak_tools/start/App';
import { computed, inject, ref } from 'vue';

import { BECommReservation } from '@/backend_communication/definitions/BECommReservations';
import { BECommTimeslot } from '@/backend_communication/definitions/BECommTimeslots';
import { ReservationDataHandler } from '@/components/reservations/ReservationDataHandler';

//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const props = defineProps<{ timestamp: number }>();
const showDialog = ref(false);
const showDeleteConfirmation = ref(false);
const reservationToDelete = ref<BECommTimeslot | null>(null);

const providedDataHandler = inject('ReservationDataHandler') as ReservationDataHandler;
const filters = ref<AKDataTableFilterMeta>({});
const date = new Date(props.timestamp);
const formatTimestamp = `${date.getDate()}. ${date.getMonth() + 1}. ${date.getFullYear()}`;
const labelCols = ref(4);

const selectedPlayerOne = ref<AKBECommPersonEmployeeUserSchema | null>(null);
const selectedPlayerTwo = ref<AKBECommPersonEmployeeUserSchema | null>(null);
const currentTimeslot = ref<BECommTimeslot | null>(null);

//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const selectablePlayersForFirst = computed(() => {
  const participants = providedDataHandler.data.current_tournament!.participants;
  if (selectedPlayerTwo.value) {
    const a = participants.filter((participant) => participant.person_id !== selectedPlayerTwo.value!.person_id);
    return a;
  }
  return participants;
});

const selectablePlayersForSecond = computed(() => {
  const participants = providedDataHandler.data.current_tournament!.participants;
  if (selectedPlayerOne.value) {
    return participants.filter((participant) => participant.person_id !== selectedPlayerOne.value!.person_id);
  }
  return participants;
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const getReservationForTimestampLocationAndDay = (timeslot: BECommTimeslot): BECommReservation | undefined => {
  return providedDataHandler.data.reservations.find((reservation) => {
    const reservationDate = new Date(reservation.date);
    const propsDate = new Date(props.timestamp);
    const isSameDay =
      reservationDate.getDate() === propsDate.getDate() &&
      reservationDate.getMonth() === propsDate.getMonth() &&
      reservationDate.getFullYear() === propsDate.getFullYear();

    const isSameLocation = reservation.location_id === providedDataHandler.data.current_location?.location_id;

    const timeslotStart = new Date(`1970-01-01T${timeslot?.start}:00`);
    const timeslotEnd = new Date(`1970-01-01T${timeslot?.end}:00`);
    const reservationStart = new Date(`1970-01-01T${reservation.time_slot?.start}:00`);
    const reservationEnd = new Date(`1970-01-01T${reservation.time_slot?.end}:00`);

    const isOverlapping = timeslotStart < reservationEnd && timeslotEnd > reservationStart;
    const isSameOrOverlappingTimeslot = reservation.time_slot?.timeslot_id === timeslot.timeslot_id || isOverlapping;

    return isSameDay && isSameLocation && isSameOrOverlappingTimeslot;
  });
};

const showReservationDialog = (timeslot: BECommTimeslot) => {
  currentTimeslot.value = timeslot;
  showDialog.value = true;
};

const formatPlayerNames = (reservation: BECommReservation | undefined): string => {
  if (!reservation) {
    return 'TBA vs TBA';
  }
  const playerOne = reservation.match.participant_one.person;
  const playerOneName = `${playerOne.firstname} ${playerOne.lastname}`;
  const playerTwo = reservation.match.participant_two.person;
  const playerTwoName = `${playerTwo.firstname} ${playerTwo.lastname}`;
  return `${playerOneName} vs ${playerTwoName}`;
};

const formatTimeslot = (timeslot: BECommTimeslot): string => {
  return `${timeslot.start} - ${timeslot.end}`;
};

const addReservation = async (date_timestamp: number) => {
  await providedDataHandler.createReservationOnBackend();
  const match_id = providedDataHandler.getMatchIdByPlayers(
    selectedPlayerOne.value!.person_id,
    selectedPlayerTwo.value!.person_id,
  );
  await providedDataHandler
    .updateReservationOnBackend(
      providedDataHandler.data.current_reservation!.reservation_id,
      match_id,
      providedDataHandler.data.current_location!.location_id,
      currentTimeslot.value!.timeslot_id,
      date_timestamp,
    )
    .finally(() => {
      selectedPlayerOne.value = null;
      selectedPlayerTwo.value = null;
    });
};

const reservationPresentInCurrentSlot = (timeslot: BECommTimeslot) => {
  const reservation = getReservationForTimestampLocationAndDay(timeslot);
  if (!reservation) {
    return false;
  }
  return true;
};

const confirmDeleteReservation = (timeslot: BECommTimeslot) => {
  reservationToDelete.value = timeslot;
  showDeleteConfirmation.value = true;
};

const deleteConfirmedReservation = async () => {
  if (reservationToDelete.value) {
    const reservation = getReservationForTimestampLocationAndDay(reservationToDelete.value);
    if (reservation) {
      await providedDataHandler.deleteReservationByIdOnBackend(reservation.reservation_id);
    }
    showDeleteConfirmation.value = false;
    reservationToDelete.value = null;
  }
};

//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  onRowClick: [AKDataTableRowClickEvent, BECommTimeslot];
}>();

const onRowClick = (rowEvent: AKDataTableRowClickEvent, timeslotRowData: BECommTimeslot) => {
  emits('onRowClick', rowEvent, timeslotRowData);
};
</script>

<style lang="sass" scoped>
::v-deep(.p-datatable-thead tr th)
  @apply bg-primary text-theme font-bold
</style>
