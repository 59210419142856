import { routeMenuItem as akRouteMenuItem } from '@ak_tools/router/AKRouterTypes';

import PlayGroundView from '@/views/ak_views/PlayGroundView.vue';
import StartView from '@/views/ak_views/StartView.vue';
import SupportView from '@/views/ak_views/SupportView.vue';

const AKRoutes: akRouteMenuItem[] = [
  { path: '/', component: StartView, name: 'start' },
  { path: '/login', name: 'login' },
  { path: '/support', component: SupportView, name: 'support' },
  { path: '/playground', component: PlayGroundView, name: 'playground' },
];

export default AKRoutes;
