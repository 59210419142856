<template>
  <div>
    <div class="bg-primary text-theme flex items-center justify-between rounded-t-lg p-3">
      <span v-text="commentTitle" />
      <AKIcon
        v-if="userIsCommentOwner"
        class="cursor-pointer hover:text-slate-300"
        :scale="0.85"
        :framework-icon="PrimeIcons.TRASH"
        @click="showConfirmDelete = true" />
    </div>
    <div class="rounded-b-lg border bg-white">
      <AKWYSIWYGTextReader :text="props.commentItem.description" />
    </div>
  </div>

  <AKConfirmAction
    v-model="showConfirmDelete"
    :confirm-method="ValidConfirmMethods.DEFAULT_CONFIRM"
    :group="`confirmDeleteComment_${commentItem.comment_id}`"
    :check-value="$t('AKCommentItem.confirmDeleteValue')"
    :message="$t('AKCommentItem.confirmDeleteMessage')"
    @on-confirm="emitDeleteComment" />
</template>

<script setup lang="ts">
type _VTI_TYPE_AKBECommComment = {
  comment_id: number;
  title: string;
  description: string;
  owner_id: number;
  owner_firstname: string;
  owner_lastname: string;
  created_datetime: number;
}
interface PropsInterface {
  commentItem: _VTI_TYPE_AKBECommComment;
}
import { AKWYSIWYGTextReader } from '@ak_tools/components/form'
import { AKIcon } from '@ak_tools/components/media'
import { AKConfirmAction, ValidConfirmMethods } from '@ak_tools/components/overlay'
import { AKStore } from '@ak_tools/start/App'
import { AKDateTime } from '@ak_tools/ts_modules/core/AKDateTime'
import { AKHR } from '@ak_tools/ts_modules/hr/AKHR'
import { PrimeIcons } from 'primevue/api'
import { computed, inject, Ref, ref } from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n();
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const props = defineProps<PropsInterface>();
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const showConfirmDelete = ref(false);
const akDateTime = inject('akDateTime') as Ref<AKDateTime>;
const akHr = new AKHR();
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const formattedTime = computed(() => {
  return akDateTime.value.getTimeStringFromTimeStamp(props.commentItem.created_datetime, 'HH:mm');
});
const formattedDate = computed(() => {
  return akDateTime.value.getStringFromTimeStamp(props.commentItem.created_datetime);
});
const commentOwnerFullName = computed(() => {
  const person = {
    firstname: props.commentItem.owner_firstname,
    lastname: props.commentItem.owner_lastname,
  };
  return akHr.getPersonFullName(person);
});
const userIsCommentOwner = computed(() => {
  return AKStore.User.state.userId === props.commentItem.owner_id;
});
const commentTitle = computed(() => {
  if (userIsCommentOwner.value) {
    return t('AKCommentItem.comment-title.comment-owner', {
      date: formattedDate.value,
      time: formattedTime.value,
    });
  }
  return t('AKCommentItem.comment-title.not-comment-owner', {
    userName: commentOwnerFullName.value,
    date: formattedDate.value,
    time: formattedTime.value,
  });
});
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  deleteComment: [];
}>();
const emitDeleteComment = () => {
  emits('deleteComment');
};
</script>

<style lang="sass" scoped></style>
