import { AKVueProvider } from '@ak_tools/framework_support/AKVueProvider';

import { BackendComm } from './AKBECommBase';

export class BeCommAll {
  beComm = new BackendComm();
  vueProvider: AKVueProvider;
  constructor(vueProvider: AKVueProvider) {
    this.vueProvider = vueProvider;
  }
}
