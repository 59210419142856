/**
 * https://floating-vue.starpad.dev/
 */
import 'floating-vue/dist/style.css';

import FloatingVue from 'floating-vue';
import { App } from 'vue';

class AKFloatingVuePlugin {
  constructor(app: App) {
    app.use(FloatingVue, {
      themes: {
        'ak-white': {
          $extend: 'tooltip',
        },
      },
    });
  }
}

export default AKFloatingVuePlugin;
