<template>
  <AKSystemAccess ref="referenceAKSystemAccess" />
  <AKConfirmAction
    v-model="showConfirmAction"
    data-test="confirm-logout"
    group="confirm-mobile-logout"
    :message="'Wirklich ausloggen?'"
    :headline="'Ausloggen'"
    @on-confirm="logoutConfirmed">
  </AKConfirmAction>

  <div class="flex flex-col gap-4 p-4">
    <div class="flex w-full cursor-pointer items-center gap-2" @click="displayConfirmAction()">
      <AKIcon class="min-w-6" :framework-icon="PrimeIcons.SIGN_OUT" />
      <div class="text-lg" v-text="'Ausloggen'" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { AKIcon } from '@ak_tools/components/media';
import { AKConfirmAction } from '@ak_tools/components/overlay';
import { AKSystemAccess } from '@ak_tools/components/renderless';
import { PrimeIcons } from 'primevue/api';
import { ref } from 'vue';

//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const showConfirmAction = ref<boolean>(false);
const referenceAKSystemAccess = ref<InstanceType<typeof AKSystemAccess> | null>(null);

//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const logoutConfirmed = () => {
  referenceAKSystemAccess.value?.logout();
};

const displayConfirmAction = () => {
  showConfirmAction.value = true;
};
</script>

<style lang="sass" scoped></style>
