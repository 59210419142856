import {
  AKBECommDefaultResponse,
  AKBECommDefaultResponseSchema,
  AKBECommFileUploadReadySchema,
} from './../../AKBECommDefault';

export enum AKBECommSelectPersonFilter {
  all_existing_persons = 'all_existing_persons',
  just_all_users = 'just_all_users',
  just_all_employees = 'just_all_employees',
}

export type AKBECommPersonSchema = {
  person_id: number;
  firstname: string;
  lastname: string;
  gender: string;
  title: string;
  picture_path: string;
  phone_number: string | null;
  birthday: string;
  company_mobile_phone?: string | null;
  pneumatic_tube_number?: string | null;
  po_box_number?: string | null;
  company_fax?: string | null;
  internal_security?: boolean | null;
  fire_protection_officer?: boolean | null;
  security_officer?: boolean | null;
  room_number?: string | null;
};

export type AKBECommPersonSmallSchema = {
  person_id: number;
  firstname: string;
  lastname: string;
};

export type AKBECommEmployeeSchema = {
  employee_id: number;
  personal_number: string;
  main_org_unit_abbr: string;
  main_org_unit_title: string;
};

export type AKBECommUserGroupSmall = {
  user_group_id: number;
  title: string;
  abbr: string;
  description: string;
  source_type_id: number;
  type_id: number;
  active: boolean;
};

export type AKBECommUserSchema = {
  user_id: number;
  email: string;
  personal_user_group: AKBECommUserGroupSmall | null;
  groups?: Array<AKBECommUserGroupSmall>;
};

export type AKBECommPersonEmployeeUserSchema = AKBECommPersonSchema & {
  employee: AKBECommEmployeeSchema | null;
  user: AKBECommUserSchema | null;
};

export type AKBECommPersonEmployeeSchema = AKBECommPersonSchema & {
  employee: AKBECommEmployeeSchema | null;
};

export type AKBECommPersonEmployeeSmallSchema = AKBECommPersonSmallSchema & {
  employee: AKBECommEmployeeSchema | null;
};

export type AKBECommPersonUserSchema = AKBECommPersonSchema & {
  user: AKBECommUserSchema | null;
};

export type AKBECommPersonEmployeeUserRequest = {
  selected_filter: string;
};

export type AKBECommPersonEmployeeUserResponse = AKBECommDefaultResponseSchema & {
  persons: Array<AKBECommPersonEmployeeUserSchema>;
};

export type AKBECommPersonEmployeeUserResponseR = AKBECommDefaultResponse<AKBECommPersonEmployeeUserResponse>;

//  --------------------------------------------------------------------------------------------------------------------
//  profile picture
//  --------------------------------------------------------------------------------------------------------------------
export type BECommProfilePicturePrepareUploadPostRequest = {
  person_id: number;
  filename: string;
};
export type BECommProfilePicturePrepareUploadPostResponse = AKBECommDefaultResponseSchema &
  AKBECommFileUploadReadySchema;
export type BECommProfilePicturePrepareUploadPostResponseR =
  AKBECommDefaultResponse<BECommProfilePicturePrepareUploadPostResponse>;

export type BECommProfilePictureFinalizeUploadPostRequest = {
  person_id: number;
  filename: string;
};
export type BECommProfilePictureFinalizeUploadPostResponse = AKBECommDefaultResponseSchema &
  AKBECommFileUploadReadySchema;
export type BECommProfilePictureFinalizeUploadPostResponseR =
  AKBECommDefaultResponse<BECommProfilePictureFinalizeUploadPostResponse>;

export type AKBECommProfilePictureDeleteResponse = AKBECommDefaultResponseSchema;
export type AKBECommProfilePictureDeleteResponseR = AKBECommDefaultResponse<AKBECommProfilePictureDeleteResponse>;
