<template>
  <IconField :icon-position="props.iconPosition">
    <InputIcon>
      <AKIcon
        :framework-icon="PrimeIcons[props.inputFrameworkIcon]"
        :ak-icon="props.inputAKIcon"
        :scale="iconScale"
        :icon-classes="props.iconClasses" />
    </InputIcon>
    <AKInputText
      ref="akInputText"
      v-model="value"
      data-test="ak-icon-field-input"
      :autofocus="props.autofocus"
      :placeholder="props.inputPlaceholder"
      :size="props.inputSize"
      :custom-iterable="props.customIterable"
      variant="filled"
      :custom-class="[inputPadding, customInputClass].join(' ')"
      :enable-switch-read-write-mode="props.enableSwitchReadWriteMode" />
  </IconField>
</template>

<script setup lang="ts">
type _VTI_TYPE_AKSVGIcons = /* enum */ string
interface PropsInterface {
  inputFrameworkIcon?: keyof typeof PrimeIcons;
  inputAKIcon?: _VTI_TYPE_AKSVGIcons;
  iconPosition?: 'left' | 'right';
  iconClasses?: string;
  enableSwitchReadWriteMode?: boolean;
  customInputClass?: string;
  inputPlaceholder?: string;
  inputSize?: 'small' | 'large';
  autofocus?: boolean;
  hasCloseButton?: boolean;
  customIterable?: boolean;
}
import { AKInputText } from '@ak_tools/components/form'
import { AKIcon } from '@ak_tools/components/media'
import { AKSVGIcons } from '@ak_tools/ts_modules/graphics/AKSVGIcons'
import { PrimeIcons } from 'primevue/api'
import IconField from 'primevue/iconfield'
import InputIcon from 'primevue/inputicon'
import { computed, ref } from 'vue'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const value = defineModel<string>({ default: '' });
const props = withDefaults(defineProps<PropsInterface>(), {
  inputFrameworkIcon: 'SEARCH',
  inputAKIcon: undefined,
  iconPosition: 'left',
  iconClasses: undefined,
  enableSwitchReadWriteMode: false,
  customInputClass: undefined,
  inputPlaceholder: undefined,
  inputSize: undefined,
  autofocus: false,
  hasCloseButton: undefined,
  customIterable: false,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const akInputText = ref<InstanceType<typeof AKInputText> | null>(null);
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const inputPadding = computed(() => {
  if (props.hasCloseButton) {
    return 'px-8';
  }
  return props.iconPosition === 'left' ? 'pl-8' : 'pr-8';
});
const iconScale = computed(() => {
  return props.inputSize === 'small' ? 0.75 : 0.8;
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const focusInput = () => {
  akInputText.value?.focus();
};
const blurInput = () => {
  akInputText.value?.blur();
};
//  --------------------------------------------------------------------------------------------------------------------
//  public expose
//  --------------------------------------------------------------------------------------------------------------------
defineExpose({ focusInput, blurInput });
</script>

<style lang="sass" scoped></style>
