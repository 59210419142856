import { LocaleMessages } from 'vue-i18n';

const loadLocaleMessages = async () => {
  const locales = [
    import.meta.glob('@ak_tools/locales/*.json', { eager: true }), // ak_tools locale path
    import.meta.glob('@/locales/*.json', { eager: true }), // application locale path
  ];

  const messages: LocaleMessages<{ key: string; value: string }> = {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  locales.forEach((localeFiles: any) => {
    for (const path in localeFiles) {
      const matched = path.match(/\/([A-Za-z0-9-_]+)\.json$/i);

      if (matched && matched.length > 1) {
        const locale = matched[1];
        if (!messages[locale]) {
          messages[locale] = { key: '', value: '' } as { key: string; value: string };
        }
        Object.assign(messages[locale], localeFiles[path].default);
      }
    }
  });

  return messages;
};

export default await loadLocaleMessages();
