import { BeCommAll } from '@ak_tools/backend_communication/AKBEComm';
import { AKBECommBaseMessages } from '@ak_tools/backend_communication/AKBECommBase';

import {
  BECommReservationCreateResponseR,
  BECommReservationDeleteResponseR,
  BECommReservationGetResponseR,
  BECommReservationsByLocationResponse,
  BECommReservationsGetResponseR,
  BECommReservationUpdateRequest,
  BECommReservationUpdateResponseR,
} from '../definitions/BECommReservations';

export default class BECommReservations extends BeCommAll {
  public beCommGetReservations(): Promise<BECommReservationsGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommReservations.error.get.reservations.message'),
    };

    return this.beComm.backendCommGet(`/reservations`, {}, messages, {
      returnOnError: false,
    });
  }

  public beCommGetReservationById(reservation_id: number): Promise<BECommReservationGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommReservations.error.get.reservation.message'),
    };

    return this.beComm.backendCommGet(`/reservations/${reservation_id}`, { timeout: 10000 }, messages, {
      returnOnError: false,
    });
  }

  public beCommDeleteReservation(reservationId: number): Promise<BECommReservationDeleteResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommReservations.error.delete.reservation.message'),
    };

    return this.beComm.backendCommDelete(`/reservations/${reservationId}`, {}, messages);
  }

  public async beCommCreateReservation(): Promise<BECommReservationCreateResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommReservations.error.post.reservation.message'),
    };
    const postBody = { reservation: 0 };
    return this.beComm.backendCommPost('/reservations', postBody, {}, messages);
  }

  public beCommUpdateReservation(
    reservation_id: number,
    putBody: BECommReservationUpdateRequest,
  ): Promise<BECommReservationUpdateResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommReservations.error.update.reservation.message'),
    };
    return this.beComm.backendCommPut(`/reservations/${reservation_id}`, putBody, {}, messages);
  }
}
