<template>
  <div class="bg-primary text-theme min-h-[0.5rem] rounded-full text-center text-sm" :style="intervalWidth">
    <div v-if="remainingTime">
      <slot>
        <span v-if="props.showRemainingTime" v-text="remainingTime" />
      </slot>
    </div>
  </div>
</template>
<script setup lang="ts">
interface PropsInterface {
  timer?: number;
  showRemainingTime?: boolean;
}
import { computed, onBeforeUnmount, onMounted, ref, watchEffect } from 'vue'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const remainingTime = defineModel<number>('remainingTime', { default: 0 });
const props = withDefaults(defineProps<PropsInterface>(), {
  timer: 5000,
  showRemainingTime: false,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const elapsedMS = ref(0);
const interval = ref<NodeJS.Timeout | null>(null);
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const remainingTimeInMS = computed(() => {
  return props.timer - elapsedMS.value;
});
const currentWidth = computed(() => {
  return (remainingTimeInMS.value / props.timer) * 100;
});
const intervalWidth = computed(() => {
  return `width: ${currentWidth.value}%`;
});
//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
onMounted(() => {
  startInterval();
});
onBeforeUnmount(() => {
  endInterval();
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const startInterval = () => {
  interval.value = setInterval(() => {
    let newValue = (elapsedMS.value += 5);
    if (newValue >= props.timer) {
      newValue = props.timer;
      endInterval();
    }
    elapsedMS.value = newValue;
  }, 5);
};
const endInterval = () => {
  if (interval.value) {
    clearInterval(interval.value);
    interval.value = null;
  }
};
//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watchEffect(() => {
  remainingTime.value = Math.ceil(remainingTimeInMS.value / 1000);
});
//  --------------------------------------------------------------------------------------------------------------------
//  provided data + public expose
//  --------------------------------------------------------------------------------------------------------------------
defineExpose({ endInterval });
</script>

<style scoped lang="sass"></style>
