import { BeCommAll } from '@ak_tools/backend_communication/AKBEComm';
import { AKBECommBaseMessages } from '@ak_tools/backend_communication/AKBECommBase';

import {
  BECommTimeframeCreateResponseR,
  BECommTimeframeDeleteResponseR,
  BECommTimeframesGetResponseR,
  BECommTimeframeUpdateRequest,
  BECommTimeframeUpdateResponseR,
} from '../definitions/BECommTimeframes';

export default class BECommTimeframes extends BeCommAll {
  public beCommCreateTimeframe(tournamentId: number): Promise<BECommTimeframeCreateResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommTimeframes.error.post.timeframe.message'),
    };

    return this.beComm.backendCommPost('/timeframes', { tournament_id: tournamentId }, messages);
  }

  public beCommDeleteTimeframe(timeframeId: number): Promise<BECommTimeframeDeleteResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommTimeframes.error.delete.timeframe.message'),
    };

    return this.beComm.backendCommDelete(`/timeframes/${timeframeId}`, { timeout: 10000 }, messages);
  }

  public beCommUpdateTimeframe(
    timeframeId: number,
    putBody: BECommTimeframeUpdateRequest,
  ): Promise<BECommTimeframeUpdateResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommTimeframes.error.update.timeframe.message'),
    };

    return this.beComm.backendCommPut(`/timeframes/${timeframeId}`, putBody, { timeout: 10000 }, messages);
  }

  public beCommGetTimeframesByTournament(tournamentId: number): Promise<BECommTimeframesGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommTimeframes.error.get.timeframe.message'),
    };
    return this.beComm.backendCommGet(`/tournaments/${tournamentId}/timeframes`, { timeout: 10000 }, messages);
  }
}
