<template>
  <AKConfirmAction
    v-model="showConfirmDeleteDialog"
    data-test="delete-from-file-list"
    :group="props.confirmActionGroup"
    :headline="$t('AKFileList.confirm-delete.headline')"
    @on-confirm="emitDeleteItem(selectedFile!)" />

  <AKDataTable v-if="fileList && fileList.length > 0" :table-data="fileList">
    <AKColumn field="name" :header="$t('AKFileList.Table.Name')" />
    <AKColumn :header="$t('AKFileList.list-header.type')">
      <template #body="{ data }: { data: AKFileListItem }">
        <AKIcon :framework-icon="getTypeIcon(data)" />
      </template>
    </AKColumn>
    <AKColumn field="size" :header="$t('AKFileList.list-header.size')" />
    <AKColumn field="lastModified" :header="$t('AKFileList.list-header.last-modified')">
      <template #body="{ data }: { data: AKFileListItem }">
        <span v-text="akDateTime.getStringFromTimeStamp(data.lastModified)" />
      </template>
    </AKColumn>
    <div v-if="props.withDownloadColumn">
      <AKColumn :header="$t('AKFileList.list-header.download')">
        <template #body="{ data }: { data: AKFileListItem }">
          <AKIcon
            class="text-primary cursor-pointer hover:brightness-50"
            :framework-icon="PrimeIcons.DOWNLOAD"
            @click="emitDownloadItem(data)" />
        </template>
      </AKColumn>
    </div>
    <AKColumn :header="$t('AKFileList.list-header.delete')">
      <template #body="{ data }: { data: AKFileListItem }">
        <AKIcon
          class="cursor-pointer text-red-600 hover:brightness-75"
          :framework-icon="PrimeIcons.TRASH"
          @click="() => deleteItem(data)" />
      </template>
    </AKColumn>
  </AKDataTable>
</template>
<script setup lang="ts">
interface PropsInterface {
  confirmActionGroup: string;
  confirmDelete?: boolean;
  withDownloadColumn?: boolean;
}
import { AKColumn, AKDataTable } from '@ak_tools/components/data'
import { AKIcon } from '@ak_tools/components/media'
import { AKFileListItem } from '@ak_tools/components/misc/'
import { AKConfirmAction } from '@ak_tools/components/overlay'
import { AKDateTime } from '@ak_tools/ts_modules/core/AKDateTime'
import { PrimeIcons } from 'primevue/api'
import { inject, Ref, ref } from 'vue'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const fileList = defineModel<AKFileListItem[]>();
const props = withDefaults(defineProps<PropsInterface>(), {
  confirmDelete: true,
  withDownloadColumn: true,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const akDateTime = inject('akDateTime') as Ref<AKDateTime>;
const selectedFile = ref<AKFileListItem>();
const showConfirmDeleteDialog = ref(false);
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const deleteItem = (item: AKFileListItem) => {
  if (props.confirmDelete) {
    showConfirmDeleteDialog.value = true;
    selectedFile.value = item;
  } else {
    emitDeleteItem(item);
  }
};
const getTypeIcon = (item: AKFileListItem) => {
  const fileExtension = item.type ? item.type : item.name.split('.').pop()?.toLowerCase();
  switch (fileExtension) {
    case 'pdf':
      return PrimeIcons.FILE_PDF;
    case 'doc':
    case 'docx':
      return PrimeIcons.FILE_WORD;
    case 'xls':
    case 'xlsx':
      return PrimeIcons.FILE_EXCEL;
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'gif':
      return PrimeIcons.IMAGE;
    default:
      return PrimeIcons.FILE;
  }
};
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  deleteItem: [item: AKFileListItem];
  downloadItem: [item: AKFileListItem];
}>();
const emitDeleteItem = (item: AKFileListItem) => {
  emits('deleteItem', item);
};
const emitDownloadItem = (item: AKFileListItem) => {
  emits('downloadItem', item);
};
</script>

<style lang="sass" scoped></style>
@ak_tools/components/misc/types/AKFileListTypes
