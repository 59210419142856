import AKFloatingVuePlugin from '@ak_tools/framework_support/plugins/AKFloatingVue';
import AKI18nPlugin from '@ak_tools/framework_support/plugins/AKI18n';
import AKPrimeVuePlugin from '@ak_tools/framework_support/plugins/AKPrimeVue';
import AKRouterPlugin from '@ak_tools/framework_support/plugins/AKRouter';
import AKStorePlugin from '@ak_tools/framework_support/plugins/AKStore';
import AKVueObserveVisibilityPlugin from '@ak_tools/framework_support/plugins/AKVueObserveVisibility';
import App from '@ak_tools/start/AKApp.vue';
import { createApp } from 'vue';

import { AKStoreFactory } from './Store';

const AKApp = createApp(App);

export const AKPrimeVueApp = new AKPrimeVuePlugin(AKApp);
export const AKFloatingVueApp = new AKFloatingVuePlugin(AKApp);
export const AKRouterApp = new AKRouterPlugin(AKApp);
export const AKI18nApp = new AKI18nPlugin(AKApp);
export const AKStoreApp = new AKStorePlugin(AKApp);
export const AKVueObserveVisibility = new AKVueObserveVisibilityPlugin(AKApp);

export const AKStore = new AKStoreFactory();

AKApp.mount('#app');

export default AKApp;
