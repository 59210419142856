<template>
  <div class="ak-scrollbar-primary ak-flex-col !gap-2 overflow-auto p-4">
    <slot name="applicationSearchResults"></slot>
    <AKAppSearchResultsNavigation v-if="showNavigationSearch" @reset-input="emitResetInput" />
  </div>
</template>

<script setup lang="ts">
import AKAppSearchResultsNavigation from '@ak_tools/components/app_support/app_search/AKAppSearchResultsNavigation.vue';
import { inject } from 'vue';

//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const showNavigationSearch = inject('showNavigationSearch') as boolean;

//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  resetInput: [];
}>();

const emitResetInput = () => {
  emits('resetInput');
};
</script>

<style lang="sass" scoped></style>
