<template>
  <div class="flex flex-col gap-2">
    <AKCommentItem
      v-for="comment in props.comments"
      :key="comment.comment_id"
      :comment-item="comment"
      @delete-comment="emitDeleteComment(comment.comment_id)" />
  </div>
</template>

<script setup lang="ts">
interface PropsInterface {
  comments?: AKBECommComment[];
}
import { AKBECommComment } from '@ak_tools/backend_communication/definitions/comments/AKBECommComments'
import { AKCommentItem } from '@ak_tools/components/form'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  comments: () => [],
});
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  deleteComment: [itemId: number];
}>();
const emitDeleteComment = (itemId: number) => {
  emits('deleteComment', itemId);
};
</script>

<style lang="sass" scoped></style>
