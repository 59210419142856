<template>
  <i
    v-if="!props.akIcon && !props.customTemplate"
    :class="[frameworkIconClass, props.iconClasses]"
    :style="`font-size: ${computedSize}rem; font-weight: ${props.bold ? 700 : 400}`" />
  <div v-else-if="props.customTemplate">
    <slot></slot>
  </div>
  <i
    v-else
    class="flex aspect-square text-center"
    :class="props.iconClasses"
    :style="`width: ${computedSize}rem`"
    v-html="props.akIcon" />
</template>
<script setup lang="ts">
type _VTI_TYPE_AKSVGIcons = /* enum */ string
interface PropsInterface {
  frameworkIcon?: string;
  akIcon?: _VTI_TYPE_AKSVGIcons;
  scale?: number | 'auto';
  iconClasses?: string;
  bold?: boolean;
  customTemplate?: boolean;
}
import { AKSVGIcons } from '@ak_tools/ts_modules/graphics/AKSVGIcons'
import { computed } from 'vue'
//  --------------------------------------------------------------------------------------------------------------------
//  props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  frameworkIcon: undefined,
  akIcon: undefined,
  scale: 1,
  iconClasses: undefined,
  bold: false,
  customTemplate: undefined,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const defaultDimension = 1.25;
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const computedSize = computed(() => {
  if (props.scale === 'auto') {
    return 'auto';
  } else {
    return defaultDimension * props.scale;
  }
});
const frameworkIconClass = computed(() => {
  return !props.akIcon && props.frameworkIcon ? props.frameworkIcon : '';
});
</script>
