// !! Dont import anything here !!

// Hier wird das Farbschema für die Applikation gesetzt.
// Dies betrifft allerdings nur die TailwindConfig. Um die Farben für die Primevue Komponenten zu ändern
// müssen die Pfade unter src/assets angepasst werden.

export enum AKColorScheme {
  DEFAULT,
  VITAL,
}

export const appColorScheme = {
  ColorScheme: AKColorScheme.VITAL,
};
