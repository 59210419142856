<template>
  <div class="flex flex-col gap-2 p-4">
    <div v-for="(item, index) in $i18n.availableLocales" :key="index" class="flex w-full gap-2">
      <div class="flex min-w-6 items-center border-white" :class="{ 'border-l': item !== $i18n.locale }">
        <AKIcon v-if="item === $i18n.locale" class="ml-[-.4rem]" :framework-icon="PrimeIcons.ANGLE_RIGHT" :scale="1" />
      </div>
      <div class="flex w-full grow cursor-pointer flex-col" @click="$i18n.locale = item">
        <span class="text-lg font-bold" v-text="getNameByLanguageKey(item)" />
        <span v-text="getLanguageTranslationByLanguageKey(item)" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AKIcon } from '@ak_tools/components/media';
import { PrimeIcons } from 'primevue/api';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const getNameByLanguageKey = (languageKey: string) => {
  if (languageKey === 'de_DE') {
    return 'Deutsch';
  } else if (languageKey === 'en_EN') {
    return 'English';
  }
};

const getLanguageTranslationByLanguageKey = (languageKey: string) => {
  return languageKey === 'de_DE' ? t('AKMainMenuContainer.language.de_DE') : t('AKMainMenuContainer.language.en_EN');
};
</script>

<style lang="sass" scoped></style>
