<template>
  <AKInputContainer
    :label="props.label"
    :label-cols="props.labelCols"
    :tooltip-text="props.tooltipText"
    :tooltip-placement="props.tooltipPlacement"
    :tooltip-theme="props.tooltipTheme">
    <AKInputText
      v-model="emailValue"
      data-test="login-email"
      type="email"
      :placeholder="props.placeholder"
      @keyup.enter="enterPressed" />
    <template #tooltipContent>
      <slot name="tooltipContent" />
    </template>
  </AKInputContainer>
</template>

<script setup lang="ts">
type _VTI_TYPE_AKTooltipValidThemes = 'ak-white'
type _VTI_TYPE_AKTooltipValidPlacements = | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end'
interface PropsInterface {
  placeholder?: string;
  label?: string | false;
  labelCols?: number | 'auto';
  tooltipText?: string;
  tooltipPlacement?: _VTI_TYPE_AKTooltipValidPlacements;
  tooltipTheme?: _VTI_TYPE_AKTooltipValidThemes;
}
import { AKInputText } from '@ak_tools/components/form'
import { AKInputContainer } from '@ak_tools/components/layout'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const emailValue = defineModel<string>();
const props = withDefaults(defineProps<PropsInterface>(), {
  placeholder: 'AKInputEmail.default-placeholder',
  label: 'AKInputEmail.default-label',
  labelCols: undefined,
  tooltipText: undefined,
  tooltipPlacement: undefined,
  tooltipTheme: undefined,
});
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  onEnterPressed: [];
}>();
const enterPressed = () => {
  emits('onEnterPressed');
};
</script>

<style lang="sass" scoped></style>
