<template>
  <div class="flex flex-wrap items-center gap-2" tabindex="0">
    <AKTag v-for="userGroup in props.userGroups" :key="userGroup.user_group_id">
      <span class="text-base font-normal" v-text="userGroup.title" />
    </AKTag>
  </div>
</template>

<script setup lang="ts">
interface PropsInterface {
  userGroups: AKBECommUserGroupSchema[];
}
import { AKBECommUserGroupSchema } from '@ak_tools/backend_communication/definitions/users/AKBECommUsers'
import { AKTag } from '@ak_tools/components/misc'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const props = defineProps<PropsInterface>();
</script>

<style lang="sass" scoped></style>
