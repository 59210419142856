<template>
  <AKModuleContainer ref="akModuleContainer">
    <template #moduleHeaderActions>
      <GeneralTournamentSelect
        class="z-module-layer-flex-2"
        @on-tournament-select="handleTournamentSelect"
        @on-tournament-clear="handleTournamentClear" />
    </template>
    <template #table>
      <AKLoadingContent
        v-if="dataHandler.data.elementsAreLoading"
        class="ak-container-main-overlay-default z-module-overlay-layer" />
      <ParticipantDataTable @on-row-click="onRowClick" />
    </template>
    <template #sidePanel>
      <AKLoadingContent
        v-if="dataHandler.data.elementIsLoading"
        class="ak-container-main-overlay-default z-module-overlay-layer" />
      <ParticipantDataSidePanel
        v-if="dataHandler.data.current_participant"
        @close-side-panel="closeSidePanel"
        @set-current-participant="setCurrentParticipant" />
    </template>
    <template #moduleActions> </template>
  </AKModuleContainer>
</template>

<script setup lang="ts">
import { AKDataTableRowClickEvent } from '@ak_tools/components/data';
import AKModuleContainer from '@ak_tools/components/layout/AKModuleContainer.vue';
import { AKLoadingContent } from '@ak_tools/components/overlay';
import { AKStore } from '@ak_tools/start/App';
import { computed, nextTick, onMounted, provide, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

import { BECommParticipant } from '@/backend_communication/definitions/BECommParticipants';
import { BECommTournament } from '@/backend_communication/definitions/BECommTournaments';
import GeneralTournamentSelect from '@/components/layout/select/GeneralTournamentSelect.vue';
import { TournamentSelectDataHandler } from '@/components/layout/select/TournamentSelectDataHandler';
import { ParticipantDataHandler } from '@/components/participants/ParticipantDataHandler';
import ParticipantDataTable from '@/components/participants/ParticipantDataTable.vue';
import ParticipantDataSidePanel from '@/components/participants/ParticipantSidePanel.vue';

import { tournamentStore } from '../../store/tournaments/index';

const { locale } = useI18n();
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const dataHandler = ref(new ParticipantDataHandler());
const akModuleContainer = ref<InstanceType<typeof AKModuleContainer> | null>(null);
const router = useRouter();
const route = useRoute();
const currentRouteName = String(route.name);
const tournamentSelectDataHandler = ref(new TournamentSelectDataHandler());
const currentTournamentStore = tournamentStore();

//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------

const currentRouterItemId = computed(() => {
  return Number(route.params.item_id);
});

//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------

const addApplicationIdToRoute = (participant_id: number) => {
  if (currentRouterItemId.value !== participant_id) {
    router.push({
      name: currentRouteName,
      params: { item_id: participant_id },
    });
  }
};

const onRowClick = (_: AKDataTableRowClickEvent, participantRowData: BECommParticipant) => {
  const participant_id = participantRowData.participant_id;
  nextTick(() => {
    addApplicationIdToRoute(participant_id);
    showSidePanel();
  });
};

const showSidePanel = () => {
  akModuleContainer.value?.showSidePanel();
};

const closeSidePanel = () => {
  resetView();
};

const resetView = () => {
  akModuleContainer.value?.closeSidePanel();
  dataHandler.value.data.current_participant = null;
  updateRoute();
};

const updateRoute = () => {
  const participant_id = dataHandler.value.data.current_participant?.participant_id || null;

  router.push({ name: currentRouteName, params: { item_id: participant_id } });
};

const updateCurrentItem = async () => {
  if (currentRouterItemId.value > 0) {
    openItemById(currentRouterItemId.value);
  }
};

const openItemById = async (id: number) => {
  if (id !== dataHandler.value.data.current_participant?.participant_id) {
    await dataHandler.value.getParticipantById(currentRouterItemId.value);
    if (!dataHandler.value.data.current_participant) {
      resetView();
    }
  }
};

const closeSidepanel = () => {
  akModuleContainer.value?.closeSidePanel();
};

const setCurrentParticipant = async () => {
  dataHandler.value.data.current_participant =
    dataHandler.value.data.participants.find((p) => p.person.person_id === AKStore.User.state.person.personId) || null;
  if (dataHandler.value.data.current_participant) {
    await dataHandler.value.getParticipantById(dataHandler.value.data.current_participant.participant_id);
    await openItemById(dataHandler.value.data.current_participant.participant_id);
    showSidePanel();
  } else {
    closeSidepanel();
  }
};

const handleTournamentSelect = (tournament: BECommTournament) => {
  dataHandler.value.data.current_tournament = tournament;
};

const handleTournamentClear = () => {
  dataHandler.value.data.current_tournament = null;
  dataHandler.value.data.participants = [];
};
const setModuleTitle = () => {
  AKStore.App.state.currentModuleTitle = 'Rangliste';
};

//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------

watch(currentRouterItemId, async () => {
  await updateCurrentItem();
});

watch(
  () => dataHandler.value.data.current_tournament,
  async (newTournament) => {
    if (newTournament) {
      await dataHandler.value.getParticipantsOfTournamentFromBackend();
      await setCurrentParticipant();
    } else {
      dataHandler.value.data.participants = [];
    }
  },
);

watch(locale, () => {
  setModuleTitle();
});
//  --------------------------------------------------------------------------------------------------------------------
//  provided data + public expose
//  --------------------------------------------------------------------------------------------------------------------
provide('ParticipantDataHandler', dataHandler.value);
provide('TournamentSelectDataHandler', tournamentSelectDataHandler.value);
//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
onMounted(async () => {
  await tournamentSelectDataHandler.value.fetchTournaments();
  if (!currentTournamentStore.currentTournament) {
    currentTournamentStore.currentTournament = tournamentSelectDataHandler.value.data.tournaments[0];
  }
  dataHandler.value.data.current_tournament = currentTournamentStore.currentTournament;
  await updateCurrentItem();
});

setModuleTitle();
</script>

<style lang="sass" scoped></style>
