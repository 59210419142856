import messages from '@ak_tools/dev_tools/AKCombineLocales';
import { App } from 'vue';
import { createI18n } from 'vue-i18n';

class AKI18nPlugin {
  i18n;

  constructor(app: App) {
    const i18nConfig = {
      legacy: false, // Vue 3 Modus
      locale: 'de_DE',
      fallbackLocale: 'en_EN',
      fallbackWarn: false,
      missingWarn: false,
      globalInjection: true,
      warnHtmlMessage: false,
      messages,
    };

    this.i18n = createI18n(i18nConfig);

    app.use(this.i18n);
  }
}

export default AKI18nPlugin;
