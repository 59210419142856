<template>
  <AKSidePanel
    v-model:default-label-cols="labelCols"
    @delete-item="deleteItem"
    @close-side-panel="emitCloseSidePanel"
    @maximize-side-panel="emitMaximizeSidePanel"
    @minimize-side-panel="emitMinimizeSidePanel">
    <AKTextarea
      v-model="tournamentName"
      class="w-full"
      data-test="`textarea-tournament-title"
      enable-switch-read-write-mode
      :placeholder="$t('TournamentDataSidePanel.title.label')"
      font-style="headline"
      font-size="h2"
      @update:model-value="updateTournament" />

    <AKDivider />

    <AKCalendar
      v-model="startDate"
      :label="$t('TournamentDataSidePanel.start-date.label')"
      enable-switch-read-write-mode
      :label-cols="labelCols"
      @update:model-value="updateTournament">
    </AKCalendar>
    <AKCalendar
      v-model="endDate"
      :label="$t('TournamentDataSidePanel.end-date.label')"
      enable-switch-read-write-mode
      :label-cols="labelCols"
      @update:model-value="updateTournament">
    </AKCalendar>
    <AKDivider />
    <AKInputNumber
      v-model="matchDuration"
      data-test="example-min-max"
      enable-switch-read-write-mode
      lazy
      :min-value="5"
      :max-value="90"
      :label="$t('TournamentDataSidePanel.input-number.duration')"
      :label-cols="labelCols"
      :tooltip-text="$t('AKTooltip.matchDuration')"
      :tooltip-placement="'top'"
      show-tooltip />

    <div
      v-for="(timeframe, index) in providedTournamentHandler.data.tournament_timeframes"
      :key="timeframe.timeframe_id">
      <AKInputContainer
        :label-cols="labelCols"
        :label="index == 0 ? $t('TournamentDataSidePanel.input-number.timeframe') : ''"
        :tooltip-text="$t('AKTooltip.timeframe')"
        :tooltip-placement="'top'"
        show-tooltip>
        <div class="flex items-center gap-2">
          <div>
            <AKInputNumber
              v-model="timeframe.startHours!"
              class="w-12"
              data-test="example-min-max"
              enable-switch-read-write-mode
              :min-value="0"
              :max-value="23" />
          </div>
          <span class="text-center">:</span>

          <div>
            <AKInputNumber
              v-model="timeframe.startMinutes!"
              class="w-12"
              data-test="example-min-max"
              enable-switch-read-write-mode
              :min-value="0"
              :max-value="59" />
          </div>
          <span>bis</span>

          <div>
            <AKInputNumber
              v-model="timeframe.endHours!"
              class="w-12"
              data-test="example-min-max"
              enable-switch-read-write-mode
              :min-value="0"
              :max-value="23" />
          </div>
          <span class="text-center">:</span>

          <div>
            <AKInputNumber
              v-model="timeframe.endMinutes!"
              class="w-12"
              data-test="example-min-max"
              enable-switch-read-write-mode
              :min-value="0"
              :max-value="59" />
          </div>
          <div></div>

          <div>
            <AKButton
              v-if="index > 0"
              data-test="remove-timeframe"
              variant="danger"
              size="small"
              :icon="PrimeIcons.MINUS"
              @click="removeTimeframe(timeframe.timeframe_id)" />
          </div>
        </div>
      </AKInputContainer>
    </div>
    <AKInputContainer :label-cols="labelCols">
      <AKButton data-test="add-timeframe" size="small" :icon="PrimeIcons.PLUS" @click="createTimeframe" />
    </AKInputContainer>

    <AKInputContainer :label="$t('TournamentDataSidePanel.timeslots.label')" :label-cols="labelCols">
      <div class="flex flex-wrap items-center gap-2 py-1">
        <div v-if="showTimeslotSkeleton" class="flex flex-wrap gap-2">
          <AKSkeleton v-for="n in 15" :key="n" width="6rem" height="1.7rem" />
        </div>
        <AKTag
          v-for="timeslot in providedTournamentHandler.data.current_tournament?.timeslots"
          v-else
          :key="timeslot.timeslot_id"
          class="... transform motion-safe:hover:scale-110"
          data-test="tournament-badge">
          <span class="text-base font-normal" v-text="timeslot.start + ' - ' + timeslot.end"></span>
        </AKTag>
      </div>
    </AKInputContainer>

    <AKButton
      class="py-1"
      :label="$t('TournamentDataHandler.Button.GenerateTimeslots')"
      @click="proveStartDateBeforeSaving()"></AKButton>

    <AKDivider />

    <AKSelectPerson
      :model-value="providedTournamentHandler.data.current_tournament?.participants"
      enable-switch-read-write-mode
      :multiselect="true"
      :label-cols="labelCols"
      :label="$t('TournamentDataSidePanel.tournament-group.label')"
      @on-select="addPersonToTournament"
      @on-remove="removePersonFromTournament"></AKSelectPerson>

    <AKDivider />

    <AKSelect
      v-model="providedTournamentHandler.data.current_tournament!.locations"
      :label="$t('TournamentDataSidePanel.location-overview.label')"
      :label-cols="labelCols"
      track-by="location_id"
      :selectable-options="locations"
      :multi-select-tag-content="multiSelectTagContent"
      :multiselect="true"
      allow-create-tags
      @on-select="addLocation"
      @on-remove="removeLocation"
      @on-create-tag="createLocation">
      <template #option="{ option }">
        <p>{{ option.name }}</p>
      </template>
    </AKSelect>

    <AKDivider />

    <AKButton
      class="py-1"
      :label="$t('TournamentDataSidePanel.button.create-match-plan.label')"
      @click="generateMatchPlan()">
    </AKButton>
  </AKSidePanel>
  <AKConfirmAction
    v-model="showConfirmSaveTimeslots"
    :confirm-method="ValidConfirmMethods.DEFAULT_CONFIRM"
    :group="`confirmDeleteComment_${providedTournamentHandler.data.current_tournament?.tournament_id}`"
    :headline="$t('TournamentDataSidePanel.confirmSave.headline')"
    :message="$t('TournamentDataSidePanel.confirmSave.message')"
    @on-confirm="generateTimeslots" />
</template>

<script setup lang="ts">
import { AKBECommPersonEmployeeUserSchema } from '@ak_tools/backend_communication/definitions/hr/AKBECommPersons';
import AKButton from '@ak_tools/components/button/AKButton.vue';
import { AKTextarea } from '@ak_tools/components/form';
import AKCalendar from '@ak_tools/components/form/AKCalendar.vue';
import AKInputNumber from '@ak_tools/components/form/AKInputNumber.vue';
import { AKInputContainer, AKSidePanel } from '@ak_tools/components/layout';
import AKSkeleton from '@ak_tools/components/misc/AKSkeleton.vue';
import AKTag from '@ak_tools/components/misc/AKTag.vue';
import { AKConfirmAction, ValidConfirmMethods } from '@ak_tools/components/overlay';
import AKDivider from '@ak_tools/components/panel/AKDivider.vue';
import AKSelect from '@ak_tools/components/select/AKSelect.vue';
import AKSelectPerson from '@ak_tools/components/select/AKSelectPerson.vue';
import { AKStore } from '@ak_tools/start/App';
import { AKAppMessageError, AKMessageType } from '@ak_tools/store/app/types';
import { AKDateTime } from '@ak_tools/ts_modules/core/AKDateTime';
import { PrimeIcons } from 'primevue/api';
import { computed, inject, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { BECommLocation } from '@/backend_communication/definitions/BECommLocations';
import { TournamentDataHandler } from '@/components/tournaments/TournamentDataHandler';

//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const providedTournamentHandler = inject('TournamentDataHandler') as TournamentDataHandler;
const labelCols = ref(4);
const showTimeslotSkeleton = ref(false);
const akDateTime = new AKDateTime();
const showConfirmSaveTimeslots = ref(false);
const i18n = useI18n();

const multiSelectTagContent = (location: BECommLocation) => {
  return location.name;
};

//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  -------------------------------------------------------------------------------------------------------------------
const endDate = computed({
  get() {
    const { current_tournament } = providedTournamentHandler.data;
    if (!current_tournament || !current_tournament.end_date) {
      return new Date();
    }
    return akDateTime.getDateFromTimeStamp(current_tournament.end_date);
  },
  set(newEndDate) {
    let convertedEndDate = akDateTime.getTimeStampFromDate(newEndDate);
    if (providedTournamentHandler.data.current_tournament) {
      if (convertedEndDate < providedTournamentHandler.data.current_tournament.start_date) {
        convertedEndDate = providedTournamentHandler.data.current_tournament.start_date;
      }
      providedTournamentHandler.data.current_tournament.end_date = convertedEndDate;
    }
  },
});

const startDate = computed({
  get() {
    const { current_tournament } = providedTournamentHandler.data;
    if (!current_tournament || !current_tournament.start_date) {
      return new Date();
    }
    return akDateTime.getDateFromTimeStamp(current_tournament.start_date);
  },
  set(newStartDate) {
    if (providedTournamentHandler.data.current_tournament) {
      providedTournamentHandler.data.current_tournament.start_date = akDateTime.getTimeStampFromDate(newStartDate);
      if (startDate.value > endDate.value) {
        endDate.value = newStartDate;
      }
    }
  },
});

const matchDuration = computed({
  get() {
    return providedTournamentHandler.data.current_tournament?.match_duration ?? undefined;
  },

  set(newMatchDuration) {
    if (providedTournamentHandler.data.current_tournament) {
      providedTournamentHandler.data.current_tournament.match_duration =
        newMatchDuration || newMatchDuration === 0 ? newMatchDuration : null;
    }
  },
});

const tournamentName = computed({
  get() {
    return providedTournamentHandler.data.current_tournament?.name ?? undefined;
  },
  set(newName: string) {
    if (providedTournamentHandler.data.current_tournament) {
      providedTournamentHandler.data.current_tournament.name = newName;
    }
  },
});
const locations = computed({
  get() {
    return providedTournamentHandler.data.all_locations;
  },
  set() {},
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------

const addLocation = (location: BECommLocation) => {
  const location_id = location.location_id;
  providedTournamentHandler.addLocationToCurrentTournament(location_id);
};

const removeLocation = (location: BECommLocation) => {
  const location_id = location.location_id;
  providedTournamentHandler.removeLocationFromTournament(location_id);
};

const createLocation = (name: string) => {
  providedTournamentHandler.createLocation(name);
};

const addPersonToTournament = (person: AKBECommPersonEmployeeUserSchema) => {
  if (person.user) {
    if (person.user.personal_user_group) {
      providedTournamentHandler.addParticipantToCurrentTournament(person.user.personal_user_group.user_group_id);
    }
  }
};
const removePersonFromTournament = (person: AKBECommPersonEmployeeUserSchema) => {
  let participantId = null;
  providedTournamentHandler.data.current_tournament?.participants.forEach((participant) => {
    if (participant.user?.personal_user_group?.user_group_id === person.user?.personal_user_group?.user_group_id) {
      participantId = participant.participant_id;
    }
  });
  if (participantId) {
    providedTournamentHandler.removeParticipantFromCurrentTournament(participantId);
  }
};

const proveStartDateBeforeSaving = async () => {
  if (
    !providedTournamentHandler.data.current_tournament?.start_date ||
    providedTournamentHandler.data.current_tournament?.start_date > akDateTime.getCurrentTimeStamp()
  ) {
    await generateTimeslots();
  } else {
    showConfirmSaveTimeslots.value = true;
  }
};

const generateTimeslots = async () => {
  const tournament = providedTournamentHandler.data.current_tournament;
  const currentTimeframes = providedTournamentHandler.data.tournament_timeframes;

  currentTimeframes.forEach((timeframe) => {
    timeframe.start_time_of_day = (timeframe.startHours || 0) * 60 + (timeframe.startMinutes || 0);
    const end_time_of_day = (timeframe.endHours || 0) * 60 + (timeframe.endMinutes || 0);
    timeframe.length = end_time_of_day - timeframe.start_time_of_day;
  });

  if (!tournament) {
    return;
  }

  const matchDurationValid = tournament.match_duration != null;
  const timeframesValid = currentTimeframes.every((timeframe) => timeframe.length != null && timeframe.length > 0);

  if (!matchDurationValid && !timeframesValid) {
    triggerGenerateBothMissing();
    return;
  }
  if (!matchDurationValid) {
    triggerGenerateDurationMissing();
    return;
  }
  if (!timeframesValid) {
    triggerGenerateTimeframesMissing();
    return;
  }

  if (checkTimeframesOverlapping()) {
    triggerOverlappingTimeframes();
    return;
  }

  showTimeslotSkeleton.value = true;
  await providedTournamentHandler.generateTimeslotsFromBackend().then(() => (showTimeslotSkeleton.value = false));
};

const deleteItem = async () => {
  const tournament = providedTournamentHandler.data.current_tournament;
  if (tournament) {
    providedTournamentHandler.deleteTournamentByIdOnBackend(tournament.tournament_id);
  }
  emitCloseSidePanel();
};

const updateTournament = async () => {
  if (providedTournamentHandler.data.current_tournament) {
    providedTournamentHandler.updateTournamentBaseOnBackend(providedTournamentHandler.data.current_tournament);
  }
};

const generateMatchPlan = async () => {
  const tournament_id = providedTournamentHandler.data.current_tournament!.tournament_id;
  await providedTournamentHandler.generateMatchPlan(tournament_id);
};

const createTimeframe = async () => {
  await providedTournamentHandler.createTimeframeOnBackend();
};

const removeTimeframe = async (timeframeId: number) => {
  await providedTournamentHandler.deleteTimeframeOnBackend(timeframeId);
};

const checkTimeframesOverlapping = () => {
  const timeframes = providedTournamentHandler.data.tournament_timeframes;
  const sortedTimeframes = timeframes.sort((timeframeOne, timeframeTwo) => {
    return timeframeOne.start_time_of_day - timeframeTwo.start_time_of_day;
  });

  for (let i = 0; i < sortedTimeframes.length - 1; i++) {
    const currentTimeframe = sortedTimeframes[i];
    const nextTimeframe = sortedTimeframes[i + 1];

    const endCurrentTimeframe = currentTimeframe.start_time_of_day + currentTimeframe.length;
    if (endCurrentTimeframe > nextTimeframe.start_time_of_day) {
      return true;
    }
  }

  return false;
};

const triggerGenerateBothMissing = () => {
  const conditionsNotMetMessage: AKAppMessageError = {
    messageType: AKMessageType.ERROR,
    messageSummary: i18n.t('TournamentDataHandler.saveTimeslot.error-missing-both'),
  };
  AKStore.App.state.messages.push(conditionsNotMetMessage);
};

const triggerGenerateDurationMissing = () => {
  const conditionsNotMetMessage: AKAppMessageError = {
    messageType: AKMessageType.ERROR,
    messageSummary: i18n.t('TournamentDataHandler.saveTimeslot.error-missing-duration'),
  };
  AKStore.App.state.messages.push(conditionsNotMetMessage);
};

const triggerGenerateTimeframesMissing = () => {
  const conditionsNotMetMessage: AKAppMessageError = {
    messageType: AKMessageType.ERROR,
    messageSummary: i18n.t('TournamentDataHandler.saveTimeslot.error-missing-timeframe'),
  };
  AKStore.App.state.messages.push(conditionsNotMetMessage);
};

const triggerOverlappingTimeframes = () => {
  const conditionsNotMetMessage: AKAppMessageError = {
    messageType: AKMessageType.ERROR,
    messageSummary: i18n.t('TournamentDataHandler.saveTimeslot.error-overlap-timeframe'),
  };
  AKStore.App.state.messages.push(conditionsNotMetMessage);
};

function checkTimeslotGenerationPossible() {
  if (!matchDuration.value || providedTournamentHandler.data.current_tournament?.match_duration) {
    return false;
  }

  for (const timeframe of providedTournamentHandler.data.tournament_timeframes) {
    if (timeframe.length <= 0) {
      return false;
    }
  }
  return true;
}

//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watch(matchDuration, async () => {
  if (checkTimeslotGenerationPossible()) {
    await generateTimeslots();
  }
  await updateTournament();
});
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  closeSidePanel: [];
  maximizeSidePanel: [];
  minimizeSidePanel: [];
}>();

const emitCloseSidePanel = () => {
  emits('closeSidePanel');
};

const emitMaximizeSidePanel = () => {
  emits('maximizeSidePanel');
};

const emitMinimizeSidePanel = () => {
  emits('minimizeSidePanel');
};
</script>

<style lang="sass" scoped></style>
