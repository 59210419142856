<template>
  <Menu
    ref="menu"
    class="ak-menu w-auto"
    :style="[zIndexStyle]"
    :auto-z-index="false"
    :popup="menuProps.popup"
    :model="menuProps.items"
    :append-to="menuProps.appendTo"
    @blur="emitOnBlur"
    @focus="emitOnFocus">
    <template v-if="slotInUse.start" #start>
      <slot name="start"></slot>
    </template>
    <template #item="{ item, props }">
      <slot name="item" :item="item" :item-props="props">
        <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
          <a class="flex items-center gap-2" :href="href" v-bind="props.action" @click="navigate">
            <AKIcon :framework-icon="item.icon" :ak-icon="item.akIcon" :icon-classes="item.iconClass" :scale="0.85" />
            <span v-text="item.label" />
          </a>
        </router-link>
        <a v-else class="flex items-center gap-2" v-bind="props.action">
          <div class="flex items-center gap-2" :class="item.textClass">
            <AKIcon
              v-if="item.icon || item.akIcon"
              :framework-icon="item.icon"
              :ak-icon="item.akIcon"
              :icon-classes="item.iconClass"
              :scale="0.85" />
            <span v-text="item.label" />
          </div>
          <div v-if="item.badge || item.shortcut" class="ml-auto">
            <AKBadge v-if="item.badge" class="ml-auto" :value="item.badge" />
            <span
              v-if="item.shortcut"
              class="bg-backdrop ml-auto rounded-lg border p-1 text-sm"
              v-text="item.shortcut" />
          </div>
        </a>
      </slot>
    </template>
    <template v-if="slotInUse.end" #end>
      <slot name="end"></slot>
    </template>
  </Menu>
</template>

<script setup lang="ts">
type _VTI_TYPE_AKLayersKey = keyof typeof akLayers
interface PropsInterface {
  items?: AKMenuItem[];
  popup?: boolean;
  appendTo?: 'body' | 'self' | string | _VTI_TYPE_HTMLElement;
  zLayer?: _VTI_TYPE_AKLayersKey;
}
import { akGetLayer } from '@ak_tools/assets/ak_styles/vendors/tailwind/akLayers'
import { AKIcon } from '@ak_tools/components/media'
import { AKBadge } from '@ak_tools/components/misc'
import AKShortcutManager from '@ak_tools/ts_modules/core/AKShortcutManager'
import { AKSVGIcons } from '@ak_tools/ts_modules/graphics/AKSVGIcons'
import Menu from 'primevue/menu'
import { MenuItem } from 'primevue/menuitem'
import { computed, onMounted, onUnmounted, ref, useSlots, watch } from 'vue'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const menuProps = withDefaults(defineProps<PropsInterface>(), {
  items: () => [],
  popup: true,
  appendTo: undefined,
  zLayer: 'maxValue',
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const menu = ref<InstanceType<typeof Menu> | null>(null);
const shortcutManager = new AKShortcutManager();
const slotInUse = useSlots();
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const zIndexStyle = computed(() => {
  return `z-index: ${akGetLayer(menuProps.zLayer)};`;
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const toggleMenu = (event: Event) => {
  menu.value?.toggle(event);
};
const showMenu = (event: Event) => {
  menu.value?.show(event);
};
const hideMenu = () => {
  menu.value?.hide();
};
const registerShortcuts = () => {
  for (const item of menuProps.items) {
    const command = item.command;
    const shortcut = item.shortcut;
    const disabled = item.disabled;
    if (shortcut && command && !disabled) {
      shortcutManager.registerShortcut(shortcut, command);
    }
  }
  shortcutManager.addEventListener();
};
const unregisterShortcuts = () => {
  shortcutManager.unregisterShortcuts();
  shortcutManager.removeEventListener();
};
//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watch(
  () => menuProps.items,
  () => {
    unregisterShortcuts();
    registerShortcuts();
  },
);
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  onBlur: [];
  onFocus: [];
}>();
const emitOnBlur = () => {
  emits('onBlur');
};
const emitOnFocus = () => {
  emits('onFocus');
};
//  --------------------------------------------------------------------------------------------------------------------
//  public expose
//  --------------------------------------------------------------------------------------------------------------------
defineExpose({ toggleMenu, showMenu, hideMenu });
//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
onMounted(() => {
  registerShortcuts();
});
onUnmounted(() => {
  unregisterShortcuts();
});
</script>

<script lang="ts">
export interface AKMenuItem extends MenuItem {
  languageKey?: string;
  itemId?: number;
  shortcut?: string;
  textClass?: string;
  akIcon?: AKSVGIcons;
  iconClass?: string;
  command?: () => void;
}
</script>

<style lang="sass" scoped></style>
