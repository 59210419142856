<template>
  <AKDialog
    v-if="currentBreakpoint < AKBreakpoints.SM"
    v-model="mobileSearchVisibility"
    show-maximized
    :show-cancel-button="false"
    :show-ok-button="false">
    <AKAppSearchInput
      ref="akAppSearchInput"
      v-model="inputValue"
      class="w-full"
      autofocus
      @reset-input="mobileSearchVisibility = false" />
    <AKAppSearchResults v-if="inputValue" @reset-input="resetInput">
      <template v-if="slotInUse.applicationSearchResults" #applicationSearchResults>
        <slot name="applicationSearchResults"></slot>
      </template>
    </AKAppSearchResults>
  </AKDialog>

  <div
    v-else
    ref="akAppSearchContainer"
    class="ak-flex-col z-wireframe-overlay-layer absolute left-0 right-0 top-0.5 mx-auto flex h-max max-h-[50%] w-full !gap-0 sm:w-[300px] md:w-[400px] lg:w-[600px]"
    :class="{ 'active-search-container': inputValue }">
    <div class="px-4">
      <AKAppSearchInput ref="akAppSearchInput" v-model="inputValue" search-result-item />
    </div>

    <AKAppSearchResults v-if="inputValue" @reset-input="resetInput">
      <template v-if="slotInUse.applicationSearchResults" #applicationSearchResults>
        <slot name="applicationSearchResults"></slot>
      </template>
    </AKAppSearchResults>
  </div>
</template>

<script setup lang="ts">
interface PropsInterface {
  enableNavigationSearch?: boolean;
}
import AKAppSearchInput from '@ak_tools/components/app_support/app_search/AKAppSearchInput.vue'
import AKAppSearchResults from '@ak_tools/components/app_support/app_search/AKAppSearchResults.vue'
import { AKDialog } from '@ak_tools/components/overlay'
import { AKStore } from '@ak_tools/start/App'
import { AKBreakpoints } from '@ak_tools/store/app/types'
import AKShortcutManager from '@ak_tools/ts_modules/core/AKShortcutManager'
import { onClickOutside } from '@vueuse/core'
import { computed, onMounted, onUnmounted, provide, ref, useSlots } from 'vue'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const inputValue = defineModel<string>({ default: '' });
const mobileSearchVisibility = defineModel<boolean>('mobileSearchVisibility', { default: false });
const props = withDefaults(defineProps<PropsInterface>(), {
  enableNavigationSearch: true,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const slotInUse = useSlots();
const shortcutManager = new AKShortcutManager();
const akAppSearchInput = ref<InstanceType<typeof AKAppSearchInput> | null>(null);
const akAppSearchContainer = ref<HTMLElement | null>(null);
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const currentBreakpoint = computed(() => {
  return AKStore.App.state.breakpoint;
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
onClickOutside(akAppSearchContainer, () => resetInput());
const toggleMobileVisibility = () => {
  mobileSearchVisibility.value = !mobileSearchVisibility.value;
};
const resetInput = () => {
  akAppSearchInput.value?.resetInput();
};
const focusInput = () => {
  akAppSearchInput.value?.focusInput();
};
const handleArrowDown = () => {
  if (inputValue.value) {
    const navigationDoms = getNavigationDoms();
    const currentIndex = getActiveNavigationIndex(navigationDoms);
    if (currentIndex < navigationDoms.length - 1) {
      navigationDoms[currentIndex + 1].focus();
    }
  }
};
const handleArrowUp = () => {
  if (inputValue.value) {
    const navigationDoms = getNavigationDoms();
    const currentIndex = getActiveNavigationIndex(navigationDoms);
    if (currentIndex > 0) {
      navigationDoms[currentIndex - 1].focus();
    }
  }
};
const getNavigationDoms = () => {
  return akAppSearchContainer.value?.querySelectorAll('[custom-iterable]') as NodeListOf<HTMLElement>;
};
const getActiveNavigationIndex = (navigationDoms: NodeListOf<HTMLElement>) => {
  return Array.from(navigationDoms).findIndex((input: HTMLElement) => input === document.activeElement);
};
const registerShortcuts = () => {
  shortcutManager.registerShortcut('Escape', resetInput);
  shortcutManager.registerShortcut('CTRL + K', focusInput);
  shortcutManager.registerShortcut('ArrowDown', handleArrowDown);
  shortcutManager.registerShortcut('ArrowUp', handleArrowUp);
  shortcutManager.addEventListener();
};
const unregisterShortcuts = () => {
  shortcutManager.unregisterShortcuts();
  shortcutManager.removeEventListener();
};
//  --------------------------------------------------------------------------------------------------------------------
//  provided data + public expose
//  --------------------------------------------------------------------------------------------------------------------
defineExpose({ toggleMobileVisibility, resetInput });
provide('searchInputValue', inputValue);
provide('showNavigationSearch', props.enableNavigationSearch);
//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
onMounted(() => {
  registerShortcuts();
});
onUnmounted(() => {
  unregisterShortcuts();
});
</script>

<style lang="sass" scoped>
.active-search-container
  @apply bg-white rounded-b-lg shadow-lg pt-2 top-0 border-gray-200
</style>
