import { AKSendNotification } from '@ak_tools/components/misc';
import AKEnv from '@ak_tools/framework_support/AKEnv';
import { AKStore } from '@ak_tools/start/App';

import {
  AKBackendCommunicationCanceler,
  AKBackendCommunicationObj,
  AKBackendSuccess,
  AKBackendWarning,
  AKBECommErrorResponseSchema,
} from './AKBackendCommunication';
import { AKBECommDefaultResponse } from './AKBECommDefault';

export type AKBECommBaseMessages = {
  info?: string;
  error?: string;
  warning?: string;
  success?: string;
};

export type AKBECommBaseSettings = {
  returnOnError: boolean;
  /**
   * If there is a valid response with an error inside, the request will be resent
   */
  retryOnBackendError?: boolean;
  canceler?: AKBackendCommunicationCanceler;
};

export class BackendComm {
  notificationSender = new AKSendNotification();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private backendCommNoErrorOccurred(data: any): boolean {
    let result = true;
    if (typeof data === 'object') {
      if ('error_code' in data) {
        result = AKBackendSuccess.includes(data.error_code);
      }
    }
    return result;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private checkIfBackendAvailable(error: any) {
    if (error.message && error.message.includes('Network Error')) {
      AKStore.App.state.backendAvailable = false;
      return false;
    }
    AKStore.App.state.backendAvailable = true;
    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private backendCommDisplayDefaultError(error: any) {
    let textToDisplay!: string;
    let errorResult!: AKBECommErrorResponseSchema;
    let abortError = false;
    abortError = AKBackendCommunicationObj.isCancelError(error);
    let date_string = '0';
    if (error.data?.server_timestamp) {
      const dateObj = new Date(error.data.server_timestamp);
      date_string = dateObj.toISOString();
    }

    if (error.data && !abortError) {
      textToDisplay = `${String(error.data.error_code)}<br>${String(error.config.url)} `;
      errorResult = {
        error_id: error.data.error_id,
        error_code: String(error.data.error_code),
        error_message: error.data.error_message,
        error_text: error.data.error_text,
        server_timestamp: date_string,
      };
      this.notificationSender.displayErrorNotification([textToDisplay], errorResult);
    }
  }

  private backendCommAKDisplayWarningIfExists(data: AKBECommErrorResponseSchema, messages: AKBECommBaseMessages) {
    let textToDisplay = '';
    let textBody = '';
    if (typeof data === 'object') {
      if ('error_code' in data) {
        if (AKBackendWarning.includes(data.error_code)) {
          textToDisplay = 'General Backend Warning';

          if (messages.warning) {
            textToDisplay = messages.warning;
          }
        }
        textBody = data.error_message;
      }
    }
    if (textToDisplay) {
      this.notificationSender.displayWarningNotification([textToDisplay, textBody]);
    }
  }

  private backendCommAKDisplaySuccessIfExists(messages: AKBECommBaseMessages) {
    if (messages.success) {
      const textToDisplay = messages.success;
      this.notificationSender.displaySuccessNotification([textToDisplay]);
    }
  }

  private backendCommAKDisplayError(data: AKBECommErrorResponseSchema, messages: AKBECommBaseMessages) {
    let textBody = '';
    let textFromCaller = '';

    if ('error_code' in data) {
      textBody = data.error_message;
    }

    if (messages.error) {
      textFromCaller = messages.error;
    }

    const escapeHtml = (unsafeString: string) =>
      unsafeString
        .replaceAll('&', '&amp;')
        .replaceAll('<', '&lt;')
        .replaceAll('>', '&gt;')
        .replaceAll('"', '&quot;')
        .replaceAll("'", '&#039;');

    const finalText = [`<strong>${textFromCaller}</strong>\n`, `<pre>${escapeHtml(textBody)}</pre>\n`];
    this.notificationSender.displayErrorNotification(finalText, data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async convertBlobToJson(response: any): Promise<void> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      if (response.headers['content-type'] === 'application/json') {
        if (response.data instanceof Blob) {
          await response.data.text().then((convertedBlob: string) => {
            response.data = JSON.parse(convertedBlob);
          });
        }
      }
      resolve();
    });
  }

  private interpretBackendResolve(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response: any,
    settings: AKBECommBaseSettings,
    messages: AKBECommBaseMessages,
  ): Promise<void> {
    return new Promise((resolve) => {
      this.convertBlobToJson(response).then(() => {
        if (this.backendCommNoErrorOccurred(response.data)) {
          this.backendCommAKDisplayWarningIfExists(response.data, messages);
          this.backendCommAKDisplaySuccessIfExists(messages);
          resolve();
        }
      });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public backendCommHandleCanceler(config: any, settings: AKBECommBaseSettings): any {
    let result = config;
    if (settings.canceler) {
      if (settings.canceler.controller !== null) {
        settings.canceler.controller.abort();
      }
      settings.canceler.controller = new AbortController();
      result = { ...config, signal: settings.canceler.controller.signal };
    }
    if (settings.retryOnBackendError) {
      if (config.retry === undefined) {
        result = { ...result, retry: 3 };
      }
    } else {
      result = { ...result, retry: 3 };
    }
    return result;
  }

  public backendCommPost(
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    postData: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    config: any,
    messages: AKBECommBaseMessages = {},
    settings: AKBECommBaseSettings = { returnOnError: false, retryOnBackendError: true },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> {
    const newConfig = this.backendCommHandleCanceler(config, settings);
    return new Promise((resolve, reject) => {
      AKBackendCommunicationObj.communicator
        .post(`${AKEnv.VUE_APP_BACKEND_API_PREFIX}${url}`, postData, newConfig)
        .then((response) => {
          AKStore.App.state.backendAvailable = true;
          const result: AKBECommDefaultResponse = {
            response,
            data: response.data,
          };
          this.interpretBackendResolve(response, settings, messages).then(() => {
            resolve(result);
          });
        })
        .catch((error) => {
          if (this.checkIfBackendAvailable(error)) {
            this.backendCommDisplayDefaultError(error);
            if (settings.returnOnError) {
              resolve(error);
            } else {
              reject(error);
            }
          }
        });
    });
  }

  public backendCommPostGraphQL(
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    postData: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    config: any,
    messages: AKBECommBaseMessages = {},
    settings: AKBECommBaseSettings = { returnOnError: false, retryOnBackendError: true },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.backendCommPost(url, postData, config, messages, settings)
        .then((response: AKBECommDefaultResponse) => {
          resolve(response);
        })
        .catch((error) => {
          if (this.checkIfBackendAvailable(error)) {
            this.backendCommDisplayDefaultError(error);
            if (settings.returnOnError) {
              resolve(error);
            } else {
              reject(error);
            }
          }
        });
    });
  }

  public backendCommGet(
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    config: any,
    messages: AKBECommBaseMessages = {},
    settings: AKBECommBaseSettings = { returnOnError: false, retryOnBackendError: true },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> {
    const newConfig = this.backendCommHandleCanceler(config, settings);
    return new Promise((resolve, reject) => {
      AKBackendCommunicationObj.communicator
        .get(`${AKEnv.VUE_APP_BACKEND_API_PREFIX}${url}`, newConfig)
        .then((response) => {
          AKStore.App.state.backendAvailable = true;
          const result: AKBECommDefaultResponse = {
            response,
            data: response.data,
          };
          this.interpretBackendResolve(response, settings, messages).then(() => {
            resolve(result);
          });
        })
        .catch((error) => {
          if (this.checkIfBackendAvailable(error)) {
            this.backendCommDisplayDefaultError(error);
            if (settings.returnOnError) {
              resolve(error);
            } else {
              reject(error);
            }
          }
        });
    });
  }

  public backendCommPut(
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    putData: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    config: any,
    messages: AKBECommBaseMessages = {},
    settings: AKBECommBaseSettings = { returnOnError: false, retryOnBackendError: true },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> {
    const newConfig = this.backendCommHandleCanceler(config, settings);
    return new Promise((resolve, reject) => {
      AKBackendCommunicationObj.communicator
        .put(`${AKEnv.VUE_APP_BACKEND_API_PREFIX}${url}`, putData, newConfig)
        .then((response) => {
          AKStore.App.state.backendAvailable = true;
          const result: AKBECommDefaultResponse = {
            response,
            data: response.data,
          };
          this.interpretBackendResolve(response, settings, messages).then(() => {
            resolve(result);
          });
        })
        .catch((error) => {
          if (this.checkIfBackendAvailable(error)) {
            this.backendCommDisplayDefaultError(error);
            if (settings.returnOnError) {
              resolve(error);
            } else {
              reject(error);
            }
          }
        });
    });
  }

  public backendCommDelete(
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    config: any,
    messages: AKBECommBaseMessages = {},
    settings: AKBECommBaseSettings = { returnOnError: false, retryOnBackendError: true },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> {
    const newConfig = this.backendCommHandleCanceler(config, settings);
    return new Promise((resolve, reject) => {
      AKBackendCommunicationObj.communicator
        .delete(`${AKEnv.VUE_APP_BACKEND_API_PREFIX}${url}`, newConfig)
        .then((response) => {
          AKStore.App.state.backendAvailable = true;
          const result: AKBECommDefaultResponse = {
            response,
            data: response.data,
          };
          this.interpretBackendResolve(response, settings, messages).then(() => {
            resolve(result);
          });
        })
        .catch((error) => {
          if (this.checkIfBackendAvailable(error)) {
            this.backendCommDisplayDefaultError(error);
            if (settings.returnOnError) {
              resolve(error);
            } else {
              reject(error);
            }
          }
        });
    });
  }
}
