<template>
  <AKSidePanel
    v-model:default-label-cols="labelCols"
    :show-delete-button="false"
    @close-side-panel="emitCloseSidePanel"
    @maximize-side-panel="emitMaximizeSidePanel"
    @minimize-side-panel="emitMinimizeSidePanel">
    <template #headerLeft
      ><h4>
        {{ providedMatchDataHandler.data.current_match?.match_day_number + '.' + $t('MatchPlanPageView.matchday') }}
      </h4>
    </template>
    <h2>
      {{ providedMatchDataHandler.data.current_match?.participant_one_fullname }}
      {{ ' vs. ' + providedMatchDataHandler.data.current_match?.participant_two_fullname }}
    </h2>
    <h5>{{ providedMatchDataHandler.data.current_tournament?.name }}</h5>
    <h4>{{ $t('ParticipantSidePanel.h4.result') }}</h4>
    <AKInputNumber
      v-model="participantOneScore"
      data-test="example-min-max"
      :placeholder="$t('AKInputNumber.placeholder')"
      :min-value="0"
      :label="providedMatchDataHandler.data.current_match?.participant_one_fullname"
      :label-cols="labelCols" />
    <AKInputNumber
      v-model="participantTwoScore"
      data-test="example-min-max"
      :placeholder="$t('AKInputNumber.placeholder')"
      :min-value="0"
      :label="providedMatchDataHandler.data.current_match?.participant_two_fullname"
      :label-cols="labelCols" />
  </AKSidePanel>
</template>

<script setup lang="ts">
import AKInputNumber from '@ak_tools/components/form/AKInputNumber.vue';
import { AKSidePanel } from '@ak_tools/components/layout';
import { computed, inject, ref } from 'vue';

import { MatchesDataHandler } from './MatchesDataHandler';
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const providedMatchDataHandler = inject('MatchesDataHandler') as MatchesDataHandler;
const labelCols = ref(4);

//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------

const participantOneScore = computed({
  get() {
    return providedMatchDataHandler.data.current_match!.participant_one_score ?? 0;
  },
  set(value) {
    providedMatchDataHandler.data.current_match!.participant_one_score = value;
    updateMatchScore();
  },
});

const participantTwoScore = computed({
  get() {
    return providedMatchDataHandler.data.current_match!.participant_two_score ?? 0;
  },
  set(value) {
    providedMatchDataHandler.data.current_match!.participant_two_score = value;
    updateMatchScore();
  },
});
//  --------------------------------------------------------------------------------------------------------------------
//  update match score
//  --------------------------------------------------------------------------------------------------------------------

const updateMatchScore = async () => {
  const currentMatch = providedMatchDataHandler.data.current_match;
  if (currentMatch) {
    currentMatch.participant_one_score = participantOneScore.value;
    currentMatch.participant_two_score = participantTwoScore.value;

    await providedMatchDataHandler.updateMatch(currentMatch);
  }
};

//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  closeSidePanel: [];
  maximizeSidePanel: [];
  minimizeSidePanel: [];
}>();

const emitCloseSidePanel = () => {
  emits('closeSidePanel');
};

const emitMaximizeSidePanel = () => {
  emits('maximizeSidePanel');
};

const emitMinimizeSidePanel = () => {
  emits('minimizeSidePanel');
};
</script>

<style lang="sass" scoped></style>
