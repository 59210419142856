import { BeCommAll } from '@ak_tools/backend_communication/AKBEComm';
import { AKBECommBaseMessages } from '@ak_tools/backend_communication/AKBECommBase';
import {
  AKBECommSelectUserGroupFilter,
  AKBECommUserGroupCategoriesGetResponseR,
  AKBECommUserGroupCategoryCreateResponseR,
  AKBECommUserGroupCategoryDeleteResponseR,
  AKBECommUserGroupCategoryGetResponseR,
  AKBECommUserGroupCategoryUpdateRequest,
  AKBECommUserGroupCategoryUpdateResponseR,
  AKBECommUserGroupChildGroupCreateResponseR,
  AKBECommUserGroupChildGroupDeleteResponseR,
  AKBECommUserGroupDeleteResponseR,
  AKBECommUserGroupGetResponseR,
  AKBECommUserGroupParentGroupCreateResponseR,
  AKBECommUserGroupParentGroupDeleteResponseR,
  AKBECommUserGroupPersonRoleCreateResponseR,
  AKBECommUserGroupPersonRoleDeleteResponseR,
  AKBECommUserGroupPersonRoleUpdateRequest,
  AKBECommUserGroupPersonRoleUpdateResponseR,
  AKBECommUserGroupSchema,
  AKBECommUserGroupsCreateResponseR,
  AKBECommUserGroupsGetRequest,
  AKBECommUserGroupsGetResponseR,
  AKBECommUserGroupSourceTypeCreateResponseR,
  AKBECommUserGroupSourceTypeDeleteResponseR,
  AKBECommUserGroupSourceTypeGetResponseR,
  AKBECommUserGroupSourceTypesGetResponseR,
  AKBECommUserGroupSourceTypeUpdateRequest,
  AKBECommUserGroupSourceTypeUpdateResponseR,
  AKBECommUserGroupTypeCreateResponseR,
  AKBECommUserGroupTypeDeleteResponseR,
  AKBECommUserGroupTypeGetResponseR,
  AKBECommUserGroupTypesGetResponseR as AKBECommUserGroupTypesResponseR,
  AKBECommUserGroupTypeUpdateRequest,
  AKBECommUserGroupTypeUpdateResponseR,
  AKBECommUserGroupUpdateRequest,
  AKBECommUserRolesGetResponseR,
} from '@ak_tools/backend_communication/definitions/users/AKBECommUsers';

export class AKBECommUsers extends BeCommAll {
  //  ------------------------------------------------------------------------------------------------------------------
  //  user groups
  //  ------------------------------------------------------------------------------------------------------------------
  public beCommGetUserGroupsByFilter(
    filter: AKBECommSelectUserGroupFilter,
    excluded_user_group_ids: Array<number> = [],
  ): Promise<AKBECommUserGroupsGetResponseR> {
    const postBody: AKBECommUserGroupsGetRequest = {
      selected_filter: filter,
      excluded_user_group_ids,
    };

    const messages: AKBECommBaseMessages = {
      error: 'Fehler beim Laden der User Gruppen',
    };

    return this.beComm.backendCommPost('/ak-users-cmds/get-groups-by-filter', postBody, {}, messages);
  }

  public beCommCreateUserGroup(): Promise<AKBECommUserGroupsCreateResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.post.userGroups.message'),
    };
    return this.beComm.backendCommPost('/ak-users/groups', {}, {}, messages);
  }

  public beCommGetUserGroupById(groupId: number): Promise<AKBECommUserGroupGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.get.userGroup.message'),
    };
    return this.beComm.backendCommGet(`/ak-users/groups/${groupId}`, { timeout: 40000 }, messages);
  }

  public beCommUpdateUserGroupById(groupData: AKBECommUserGroupSchema): Promise<AKBECommUserGroupGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.put.userGroup.message'),
    };

    const putBody: AKBECommUserGroupUpdateRequest = {
      title: groupData.title,
      description: groupData.description,
      abbr: groupData.abbr,
      active: groupData.active,
    };

    return this.beComm.backendCommPut(`/ak-users/groups/${groupData.user_group_id}`, putBody, {}, messages);
  }

  public beCommDeleteUserGroupById(groupId: number): Promise<AKBECommUserGroupDeleteResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.delete.userGroup.message'),
    };
    return this.beComm.backendCommDelete(`/ak-users/groups/${groupId}`, {}, messages);
  }

  //  ------------------------------------------------------------------------------------------------------------------
  //  user group con user con role
  //  ------------------------------------------------------------------------------------------------------------------
  public beCommCreateUserGroupConUserConRole(
    groupId: number,
    userId: number,
    roleId: number,
  ): Promise<AKBECommUserGroupPersonRoleCreateResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.post.userGroupConUserConRole.message'),
    };
    return this.beComm.backendCommPost(`/ak-users/groups/${groupId}/users/${userId}/roles/${roleId}`, {}, {}, messages);
  }

  public beCommUpdateUserGroupConUserConRole(
    groupId: number,
    userId: number,
    roleId: number,
    active: boolean,
  ): Promise<AKBECommUserGroupPersonRoleUpdateResponseR> {
    const putBody: AKBECommUserGroupPersonRoleUpdateRequest = {
      active,
    };

    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.put.userGroupConUserConRole.message'),
    };
    return this.beComm.backendCommPut(
      `/ak-users/groups/${groupId}/users/${userId}/roles/${roleId}`,
      putBody,
      {},
      messages,
    );
  }

  public beCommDeleteUserGroupConUserConRole(
    groupId: number,
    userId: number,
    roleId: number,
  ): Promise<AKBECommUserGroupPersonRoleDeleteResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.delete.userGroupConUserConRole.message'),
    };
    return this.beComm.backendCommDelete(`/ak-users/groups/${groupId}/users/${userId}/roles/${roleId}`, {}, messages);
  }

  //  ------------------------------------------------------------------------------------------------------------------
  //  user group con parent group
  //  ------------------------------------------------------------------------------------------------------------------
  public beCommCreateUserGroupConParentGroup(
    groupId: number,
    parentGroupId: number,
  ): Promise<AKBECommUserGroupParentGroupCreateResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.post.userGroupConParentGroup.message'),
    };
    return this.beComm.backendCommPost(`/ak-users/groups/${groupId}/parent-groups/${parentGroupId}`, {}, {}, messages);
  }

  public beCommDeleteUserGroupConParentGroup(
    groupId: number,
    parentGroupId: number,
  ): Promise<AKBECommUserGroupParentGroupDeleteResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.delete.userGroupConParentGroup.message'),
    };
    return this.beComm.backendCommDelete(`/ak-users/groups/${groupId}/parent-groups/${parentGroupId}`, {}, messages);
  }

  //  ------------------------------------------------------------------------------------------------------------------
  //  user group con child group
  //  ------------------------------------------------------------------------------------------------------------------
  public beCommCreateUserGroupConChildGroup(
    groupId: number,
    childGroupId: number,
  ): Promise<AKBECommUserGroupChildGroupCreateResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.post.userGroupConChildGroup.message'),
    };
    return this.beComm.backendCommPost(`/ak-users/groups/${groupId}/child-groups/${childGroupId}`, {}, {}, messages);
  }

  public beCommDeleteUserGroupConChildGroup(
    groupId: number,
    childGroupId: number,
  ): Promise<AKBECommUserGroupChildGroupDeleteResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.delete.userGroupConChildGroup.message'),
    };
    return this.beComm.backendCommDelete(`/ak-users/groups/${groupId}/child-groups/${childGroupId}`, {}, messages);
  }

  //  ------------------------------------------------------------------------------------------------------------------
  //  user roles
  //  ------------------------------------------------------------------------------------------------------------------
  public beCommGetUserRoles(): Promise<AKBECommUserRolesGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.get.userRoles.message'),
    };
    return this.beComm.backendCommGet('/ak-users/roles', {}, messages);
  }
  public beCommGetUserAdminGroups(userId: number): Promise<AKBECommUserGroupsGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.get.userAdminGroups.message'),
    };
    return this.beComm.backendCommGet(`/ak-users-cmds/user-admin-groups/${userId}`, {}, messages);
  }

  //  ------------------------------------------------------------------------------------------------------------------
  //  user group types + user group source types
  //  ------------------------------------------------------------------------------------------------------------------
  public beCommGetUserGroupTypes(): Promise<AKBECommUserGroupTypesResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.get.userGroupTypes.message'),
    };
    return this.beComm.backendCommGet('/ak-users/user-group-types', {}, messages);
  }
  public beCommCreateUserGroupType(): Promise<AKBECommUserGroupTypeCreateResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.post.userGroupType.message'),
    };
    return this.beComm.backendCommPost('/ak-users/user-group-types', {}, {}, messages);
  }
  public beCommDeleteUserGroupTypeById(userGroupTypeId: number): Promise<AKBECommUserGroupTypeDeleteResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.delete.userGroupType.message'),
    };
    return this.beComm.backendCommDelete(`/ak-users/user-group-types/${userGroupTypeId}`, {}, messages);
  }
  public beCommUpdateUserGroupType(
    userGroupTypeSchema: AKBECommUserGroupTypeUpdateRequest,
    userGroupTypeId: number,
  ): Promise<AKBECommUserGroupTypeUpdateResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.put.userGroupType.message'),
    };
    return this.beComm.backendCommPut(
      `/ak-users/user-group-types/${userGroupTypeId}`,
      userGroupTypeSchema,
      {},
      messages,
    );
  }
  public beCommGetUserGroupTypeById(userGroupTypeId: number): Promise<AKBECommUserGroupTypeGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.get.userGroupType.message'),
    };
    return this.beComm.backendCommGet(`/ak-users/user-group-types/${userGroupTypeId}`, {}, messages);
  }

  public beCommGetUserGroupSourceTypes(): Promise<AKBECommUserGroupSourceTypesGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.get.userGroupSourceTypes.message'),
    };
    return this.beComm.backendCommGet('/ak-users/user-group-source-types', {}, messages);
  }
  public beCommCreateUserGroupSourceType(): Promise<AKBECommUserGroupSourceTypeCreateResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.post.userGroupSourceType.message'),
    };
    return this.beComm.backendCommPost('/ak-users/user-group-source-types', {}, {}, messages);
  }
  public beCommDeleteUserGroupSourceTypeById(
    userGroupSourceTypeId: number,
  ): Promise<AKBECommUserGroupSourceTypeDeleteResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.delete.userGroupSourceType.message'),
    };
    return this.beComm.backendCommDelete(`/ak-users/user-group-source-types/${userGroupSourceTypeId}`, {}, messages);
  }
  public beCommGetUserGroupSourceTypeById(
    userGroupSourceTypeId: number,
  ): Promise<AKBECommUserGroupSourceTypeGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.get.userGroupSourceType.message'),
    };
    return this.beComm.backendCommGet(`/ak-users/user-group-source-types/${userGroupSourceTypeId}`, {}, messages);
  }
  public beCommUpdateUserGroupSourceType(
    userGroupSourceTypeSchema: AKBECommUserGroupSourceTypeUpdateRequest,
    userGroupSourceTypeId: number,
  ): Promise<AKBECommUserGroupSourceTypeUpdateResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.put.userGroupSourceType.message'),
    };
    return this.beComm.backendCommPut(
      `/ak-users/user-group-source-types/${userGroupSourceTypeId}`,
      userGroupSourceTypeSchema,
      {},
      messages,
    );
  }
  //  ------------------------------------------------------------------------------------------------------------------
  //  user roles
  //  ------------------------------------------------------------------------------------------------------------------

  public beCommGetUserGroupCategories(): Promise<AKBECommUserGroupCategoriesGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.get.userGroupCategories.message'),
    };
    return this.beComm.backendCommGet('/ak-users/user-group-categories', {}, messages);
  }
  public beCommCreateUserGroupCategory(): Promise<AKBECommUserGroupCategoryCreateResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.post.userGroupCategory.message'),
    };
    return this.beComm.backendCommPost('/ak-users/user-group-categories', {}, {}, messages);
  }
  public beCommDeleteUserGroupCategoryById(
    userGroupCategoryId: number,
  ): Promise<AKBECommUserGroupCategoryDeleteResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.delete.userGroupCategory.message'),
    };
    return this.beComm.backendCommDelete(`/ak-users/user-group-categories/${userGroupCategoryId}`, {}, messages);
  }
  public beCommGetUserGroupCategoryById(userGroupCategoryId: number): Promise<AKBECommUserGroupCategoryGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.get.userGroupCategory.message'),
    };
    return this.beComm.backendCommGet(`/ak-users/user-group-categories/${userGroupCategoryId}`, {}, messages);
  }
  public beCommUpdateUserGroupCategory(
    userGroupCategorySchema: AKBECommUserGroupCategoryUpdateRequest,
    userGroupCategoryId: number,
  ): Promise<AKBECommUserGroupCategoryUpdateResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.put.userGroupCategory.message'),
    };
    return this.beComm.backendCommPut(
      `/ak-users/user-group-categories/${userGroupCategoryId}`,
      userGroupCategorySchema,
      {},
      messages,
    );
  }
}
