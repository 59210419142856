<template>
  <div class="min-w-screen max-w-screen max-h-dvh min-h-dvh overflow-hidden">
    <AKTheAppWireframe v-if="currentWireframe === AppWireframes.App" />
    <AKTheLoginWireframe v-else-if="currentWireframe === AppWireframes.LogIn" />
    <AKTheErrorWireframe v-else-if="currentWireframe === AppWireframes.Error" />
    <AKThePrintWireframe v-else-if="currentWireframe === AppWireframes.Print" />
    <AKTheLoadingWireframe v-else-if="currentWireframe === AppWireframes.Loading">
      <AKSystemAccess ref="referenceAKSystemAccess" />
    </AKTheLoadingWireframe>
    <div v-else>NO WIREFRAME SELECTED</div>
  </div>
  <AKDisplayNotification />
  <AKLayoutObserver></AKLayoutObserver>
</template>

<script setup lang="ts">
import { AKLayoutObserver } from '@ak_tools/components/layout';
import { AKDisplayNotification } from '@ak_tools/components/overlay';
import { AKSystemAccess } from '@ak_tools/components/renderless';
import AKEnv from '@ak_tools/framework_support/AKEnv';
import { appConfig } from '@ak_tools/start/AKAppConfig';
import { AKStore } from '@ak_tools/start/App';
import { AppWireframes } from '@ak_tools/store/app/types';
import { LoginState } from '@ak_tools/store/users/types';
import { AKDateTime } from '@ak_tools/ts_modules/core/AKDateTime';
import AKTheAppWireframe from '@ak_tools/wireframes/AKTheAppWireframe.vue';
import AKTheErrorWireframe from '@ak_tools/wireframes/AKTheErrorWireframe.vue';
import AKTheLoadingWireframe from '@ak_tools/wireframes/AKTheLoadingWireframe.vue';
import AKTheLoginWireframe from '@ak_tools/wireframes/AKTheLoginWireframe.vue';
import AKThePrintWireframe from '@ak_tools/wireframes/AKThePrintWireframe.vue';
import { computed, provide, ref, watch, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const route = useRoute();
const referenceAKSystemAccess = ref<InstanceType<typeof AKSystemAccess> | null>(null);
const { locale } = useI18n();
const akDateTime = ref(new AKDateTime());

//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const currentLoginState = computed(() => {
  return AKStore.User.state.loginState;
});

const currentWireframe = computed(() => {
  return AKStore.App.state.currentWireframe;
});

const documentTitle = computed(() => {
  const stringParts = [];

  if (AKStore.App.state.currentModuleTitle) {
    stringParts.push(AKStore.App.state.currentModuleTitle);
  }

  if (!AKStore.App.state.currentModuleTitle.includes(appConfig.name)) {
    stringParts.push('-');
    stringParts.push(appConfig.name);
  }

  return stringParts.join(' ');
});

const envIsDev = computed(() => {
  return AKEnv.VUE_APP_DEV_OR_SERVER === 'DEV';
});

//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const setCurrentLoginState = (loginState: LoginState) => {
  AKStore.User.state.loginState = loginState;
};

const setCurrentWireframe = (appWireframe: AppWireframes) => {
  AKStore.App.state.currentWireframe = appWireframe;
};

const setWireframe = () => {
  /*
  This state-machine is more complex because "setCurrentWireframe" should not be called many times. "setCurrentWireframe"
  changes the variable in the app-store wich is leads to re-renderings. Rendering can be shown as flickering in startup.
  */
  const errorOccurred = route.path.startsWith('/error');
  const print = route.path.endsWith('/print');
  const AppOrPrintWireframe = print ? AppWireframes.Print : AppWireframes.App;

  if (Number(AKEnv.VUE_APP_NO_LOGIN_REQUIRED) === 1) {
    setCurrentLoginState(LoginState.LoggedIn);
  }

  switch (currentWireframe.value) {
    case AppWireframes.App:
    case AppWireframes.Print:
      if (errorOccurred) {
        setCurrentWireframe(AppWireframes.Error);
      } else if (currentLoginState.value === LoginState.LoggedOut) {
        setCurrentWireframe(AppWireframes.Loading);
      }
      break;
    case AppWireframes.Error:
    case AppWireframes.Loading:
      if (errorOccurred) {
        setCurrentWireframe(AppWireframes.Error);
      } else if (currentLoginState.value === LoginState.LoggedIn) {
        setCurrentWireframe(AppOrPrintWireframe);
      } else if (currentLoginState.value === LoginState.KeycloakAccessFailed) {
        setCurrentWireframe(AppWireframes.LogIn);
      }
      break;

    case AppWireframes.LogIn:
      if (currentLoginState.value === LoginState.LoggedIn) {
        setCurrentWireframe(AppOrPrintWireframe);
      } else if (currentLoginState.value === LoginState.LoggedOut) {
        setCurrentWireframe(AppWireframes.Loading);
      }
      break;

    default:
      if (currentLoginState.value === LoginState.LoggedOut) {
        setCurrentWireframe(errorOccurred ? AppWireframes.Error : AppWireframes.Loading);
      } else if (currentLoginState.value === LoginState.KeycloakAccessFailed) {
        setCurrentWireframe(AppWireframes.LogIn);
      }
      break;
  }
};
//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watch(currentLoginState, () => {
  setWireframe();
});

watch(route, () => {
  setWireframe();
});

watchEffect(() => {
  referenceAKSystemAccess.value?.getLoginState();
});

watch(locale, (newLocale) => {
  akDateTime.value = new AKDateTime(newLocale);
});

//  --------------------------------------------------------------------------------------------------------------------
//  provided data + public expose
//  --------------------------------------------------------------------------------------------------------------------
provide('akDateTime', akDateTime);
provide('envIsDev', envIsDev);

//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
AKStore.App.state.version.versions = appConfig.currentVersion;
AKStore.App.state.appName = appConfig.name;
AKStore.App.state.navigationMenu = appConfig.navigationItems;

watchEffect(() => {
  document.title = documentTitle.value;
});
</script>

<style lang="sass"></style>
