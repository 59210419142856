<template>
  <AccordionTab>
    <slot></slot>
  </AccordionTab>
</template>

<script setup lang="ts">
import AccordionTab from 'primevue/accordiontab';
import { defineComponent } from 'vue';
</script>

<script lang="ts">
export default defineComponent({
  name: 'AccordionTab',
});
</script>

<style lang="sass" scoped></style>
