import { routeMenuItem as akRouteMenuItem } from '@ak_tools/router/AKRouterTypes';
import { AppNavigationItem } from '@ak_tools/store/app/types';

import { appConfig } from '@/AppConfig';

function extractRoutesFromNavigationItems(items: Array<AppNavigationItem>): akRouteMenuItem[] {
  let routes: Array<akRouteMenuItem> = [];

  for (const item of items) {
    if (item.link) {
      routes.push({
        path: item.link,
        component: item.vueComponent,
        name: item.name,
      });
    }

    if (item.children) {
      routes = routes.concat(extractRoutesFromNavigationItems(item.children));
    }
  }

  return routes;
}

const Routes: akRouteMenuItem[] = extractRoutesFromNavigationItems(appConfig.navigationItems);

export default Routes;
