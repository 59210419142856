<template>
  <Card :pt="props.pt">
    <template v-if="slotInUse.title || props.title" #title>
      <slot name="title">
        <span v-text="props.title" />
      </slot>
    </template>
    <template v-if="slotInUse.header" #header>
      <slot name="header"> </slot>
    </template>
    <template v-if="slotInUse.default" #content>
      <slot></slot>
    </template>
    <template v-if="slotInUse.footer" #footer>
      <slot name="footer"></slot>
    </template>
  </Card>
</template>

<script setup lang="ts">
interface PropsInterface {
  title?: string;
  pt?: _VTI_TYPE_PassThrough;
}
import Card, { CardPassThroughOptions } from 'primevue/card'
import { useSlots } from 'vue'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  title: undefined,
  pt: undefined,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const slotInUse = useSlots();
</script>

<style lang="sass" scoped></style>
