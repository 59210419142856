<template>
  <div>
    <AKMenu ref="akMenu" class="ak-language-select" :items="selectableOptions" />
    <AKButton
      data-test="button-select-language"
      size="small"
      :label="$t('AKSelectLanguage.button.label')"
      icon-pos="right"
      :icon="PrimeIcons.LANGUAGE"
      @click="akMenu?.toggleMenu" />
  </div>
</template>

<script setup lang="ts">
import { AKButton } from '@ak_tools/components/button';
import { AKMenu, AKMenuItem } from '@ak_tools/components/menu';
import { AKSVGIcons } from '@ak_tools/ts_modules/graphics/AKSVGIcons';
import { PrimeIcons } from 'primevue/api';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { locale } = useI18n();

//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const akMenu = ref<InstanceType<typeof AKMenu> | null>(null);
const selectableOptions = ref<AKMenuItem[]>([
  {
    languageKey: 'de_DE',
    label: 'Deutsch',
    akIcon: AKSVGIcons.flag_de,
    command: () => {
      locale.value = 'de_DE';
    },
  },
  {
    languageKey: 'en_EN',
    label: 'English',
    akIcon: AKSVGIcons.flag_en,
    command: () => {
      locale.value = 'en_EN';
    },
  },
]);

//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const showMenu = (event: Event) => {
  akMenu.value?.showMenu(event);
};

const hideMenu = () => {
  akMenu.value?.hideMenu();
};

//  --------------------------------------------------------------------------------------------------------------------
//  provided data + public expose
//  --------------------------------------------------------------------------------------------------------------------
defineExpose({ showMenu, hideMenu });
</script>

<style lang="sass">
.ak-language-select
  &.p-menu
    @apply min-w-0 #{!important}
</style>
